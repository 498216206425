import { Box, Container, Dialog, DialogContent, FormHelperText, FormLabel, Grid, Button, IconButton, Modal, TextField } from '@mui/material'
import React, { useContext, useState } from 'react'
import Navbar from '../navbar-page/Navbar'
import Footer from '../footer-page/Footer'
import { Link } from 'react-router-dom'
import CloseIcon from "@mui/icons-material/Close";
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import DataContext from '../../../context/DataContext'
import notifyService from '../../../api/notifySerivce'
import apiService from '../../../api/apiService'
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const PdfModal = ({ open, handleClose, pdfUrl }) => {

    return (
        <Modal open={open} onClose={handleClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '90%',
                    height: '90%',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 6,
                    overflow: 'auto',
                }}
            >
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: "absolute",
                        right: 16,
                        top: 16,
                        backgroundColor: "red",
                        borderRadius: "50%",
                        color: "white",
                        padding: "4px",
                        '&:hover': {
                            backgroundColor: "red",
                            color: "white",
                        },
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <iframe
                    src={pdfUrl}
                    title="PDF Document"
                    style={{ width: '100%', height: '100%' }}
                />
            </Box>
        </Modal>
    );
};

const ContactUs = () => {
    const { VisuallyHiddenInput, setPageLoading, isWhitespace, validateEmail, validatePhoneNumber, checkAlphanumeric, checkAlpha, validateNumberonly } = useContext(DataContext);
    const { handleSubmit, control, setValue, getValues, reset, clearErrors, formState: { errors } } = useForm({
        values: {
            "attachments": [
                {
                    id: 0,
                    file: "",
                    fileName: "",
                    imageURL: "",
                }
            ]
        }
    });
    const {
        fields: photosFields,
        append: photosAppend,
        remove: photosRemove,
    } = useFieldArray({
        control,
        name: "attachments",
    });
    const [open, setOpen] = useState(false);
    const [openSupportModal, setOpenSupportModal] = useState(false)
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('sm');
    const pdfUrl = "https://startuptn-public-files.s3.ap-south-1.amazonaws.com/Tansim-Order.pdf";
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleOpenSupport = () => {
        handleClear()
        setOpenSupportModal(true)
    }
    const handleCloseSupport = () => {
        setOpenSupportModal(false)
        handleClear()
    }
    const [smartOpen, setSmartOpen] = useState(false)
    const addPhotoDetails = () => {
        photosAppend({
            id: 0,
            file: "",
            fileName: "",
            imageURL: "",
        });
    };

    let logoselecetdFile = "";
    const handleFileUpload = (
        event,
        filekey,
        fileName,
        fileURL,
        arrayName,
        index
    ) => {
        let logoselecetdFile = "";
        if (event !== null) {
            logoselecetdFile = event.target?.files ? event.target.files[0] : event;
            if (logoselecetdFile) {
                const reader = new FileReader();
                const [imgType, imgExt] = logoselecetdFile.type.split("/");
                if (["jpeg", "jpg", "png"].includes(imgExt)) {
                    reader.readAsDataURL(logoselecetdFile);
                    reader.onload = () => {
                        const imageDataURL = reader.result;
                        const imageData = imageDataURL.split(",")[1];
                        const imgName = logoselecetdFile.name;
                        setValue(`${arrayName}.${index}.${filekey}`, imageData);
                        setValue(`${arrayName}.${index}.${fileName}`, imgName);
                        setValue(`${arrayName}.${index}.${fileURL}`, imageDataURL);
                        clearErrors(filekey);
                        clearErrors(fileName);
                        clearErrors(fileURL);
                        clearErrors(`attachments.${index}.imageURL`);
                        addPhotoDetails();
                    };
                } else {
                    notifyService("danger", "File Format Invalid", "Please check your file format");
                }
            }
        }
    };

    // const handleFileUpload = (
    //     event,
    //     filekey,
    //     fileName,
    //     fileURL,
    //     arrayName,
    //     index
    // ) => {
    //     // 
    //     if (event !== null) {
    //         if (event.target === undefined) {
    //             logoselecetdFile = event;
    //         } else {
    //             logoselecetdFile = event.target.files[0];
    //         }
    //         if (logoselecetdFile) {
    //             var reader = new FileReader();
    //             var imagetype = logoselecetdFile.type;
    //             var imagedatatype = imagetype.split("/");
    //             var img_crt_type = imagedatatype[1];
    //             if (
    //                 img_crt_type === "jpeg" ||
    //                 img_crt_type === "jpg" ||
    //                 img_crt_type === "png"
    //             ) {
    //                 var fileValue = logoselecetdFile;
    //                 reader.readAsDataURL(logoselecetdFile);
    //                 reader.onload = () => {
    //                     var logourl1 = reader.result;
    //                     var spl = logourl1.split(",");
    //                     var ImageValue = spl[1];
    //                     var img_name = fileValue.name;
    //                     // setValue(filekey, ImageValue)
    //                     // setValue(fileName, img_name)
    //                     // setValue(fileURL, logourl1)
    //                     if (arrayName !== "") {
    //                         setValue(`${arrayName}.${index}.${filekey}`, ImageValue);
    //                         setValue(`${arrayName}.${index}.${fileName}`, img_name);
    //                         setValue(`${arrayName}.${index}.${fileURL}`, logourl1);
    //                     } else {
    //                         setValue(filekey, ImageValue);
    //                         setValue(fileName, img_name);
    //                         setValue(fileURL, logourl1);
    //                     }
    //                     clearErrors(filekey);
    //                     clearErrors(fileName);
    //                     clearErrors(fileURL);
    //                     addPhotoDetails()
    //                 };
    //             } else {
    //                 notifyService(
    //                     "danger",
    //                     "File Format Invalid",
    //                     "Please check your file format"
    //                 );
    //             }
    //         }
    //     }
    // };
    const onSubmit = (data) => {
        setPageLoading(true);

      var Attachment =   data.attachments.filter(element => element.fileName !== ''
        );
        data.attachments=Attachment
        
        var req = {
            ...data,
            category: "General Support"
        }
        debugger
        apiService("support/raiseticket", req, "unauthpost")
            .then((result) => {
                setPageLoading(false);
                if (result.data.responseStatus === "Success") {
                    setSmartOpen(true)
                    // setTimeout(() => {
                    //     availsmartClose()
                    //   }, 2000);
                    handleCloseSupport()
                }
            })
            .catch((err) => {
                console.log(err);

            });
    };
    const availsmartClose = () => {
        setSmartOpen(false)
    }
    const deletePhotos = (index) => {
        photosRemove(index);
    };
    const handleClear = () => {
        photosRemove()
        reset();
    };

    const onError = (error) => {
        console.log(error);
        notifyService("danger", "Error", "Please check mandatory fields");
    };


    return (
        <>
            <div className="back-color1">
                <Navbar />
                <div className="back-layer"></div>
                <div className="back-layer1"></div>
                {/* <div className="top-layer"></div> */}
                <div className='Grievances-bg1'>
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <div className="main-title-head-Grievance">
                                <h3 className='res-h3 mb-4 text-center'>Grievance Mechanism</h3>
                            </div>
                            <div className="Grievance-box">
                                <div className='text-center'>
                                    <img src="/images/Grievance-star.png" alt="" />
                                    <h4 className='Grievance-title'>Nodal Officers For Grievances</h4>
                                    <h5 className='Grievance-name'>Thiru. Sivarajah Ramanathan</h5>
                                    <h6 className='Grievance-Chief'>Chief Executive Officer, StartupTN</h6>
                                    <h6 className='Grievance-link'>ceo@startuptn.in</h6>
                                </div>
                            </div>

                            <div className='Submit-Grievances '>
                                <h5 className='mt-5'>Submit Your Grievances.</h5>
                                <h4>Connect with us at ceo@startuptn.in</h4>
                                <p>Here you may request/submit grievances regarding incubation support, funding support, public procurement, regular issues or any other query. The grievances raised will be forwarded to the officers concerned and will be resolved in 5 working days.</p>
                            </div>
                            <div className='text-center'>
                                <button className="ViewOrder-btn" onClick={handleOpen}>
                                    View Order
                                </button>
                                <PdfModal open={open} handleClose={handleClose} pdfUrl={pdfUrl} />
                            </div>
                            <div className='text-center'>
                                <button className="Raise-btn-Grievances mt-4" onClick={() => handleOpenSupport()}>
                                    Raise Support Ticket
                                </button>
                                {/* <button className="Track-btn-Grievances mt-4">
                                    Track an Issue
                                </button> */}
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <div className="Grievances-bg">
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Container>
                                <div className="main-title-head1">
                                    <h3 className='res-h3 mb-4 text-center'>Contact Us</h3>
                                    <Grid
                                        container
                                        direction="row"
                                        alignItems="flex-start"
                                        justifyContent="flex-start"
                                        spacing={2}
                                        className='my-5'
                                    >
                                        <Grid item xs={12} sm={12} md={4} lg={4} >
                                            <div className='white-box text-center'>
                                                <center><img src='/images/CS-Ph.png' className='img-fluid mb-3' alt='' /></center>
                                                <h6 className='belu-clr mt-2'><b>Contact - 155343</b></h6>
                                                <h6 className='belu-clr'><b>(+91) 44 22256789</b></h6>
                                                <p className='gru-clr'>Dedicated helpline for startups</p>
                                                <p className='timings-cont'>Monday - Friday<br />10.00 AM - 06.00 PM</p>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4} lg={4}>
                                            <div className='white-box text-center'>
                                                <center><img src='/images/CS-loc.png' className='img-fluid mb-3' alt='' /></center>
                                                <h6 className='belu-clr mt-2'><b>Office Address</b></h6>
                                                <p className='gru-clr'>StartupTN Office Space, 10th Floor, Chennai Metro Rail Limited Building, near Nandanam Metro Station, Chennai, Tamil Nadu 600035</p>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4} lg={4}>
                                            <div className='white-box text-center'>
                                                <center><img src='/images/CS-supp.png' className='img-fluid mb-3' alt='' /></center>
                                                <p className='gru-clr'>For Support:</p>
                                                <h6 className='belu-clr mt-2'><b><img src='/images/Cs-Mail.png' alt='' className='img-fluid mr-2' />support@startuptn.in</b></h6>
                                                <h6 className='belu-clr mt-2'><b><img src='/images/phn.png' alt='' className='mr-2' />(+91) 44 22256789 / 155343</b></h6>
                                                <p className='gru-clr'>Quick Links</p>
                                                <div className='text-center'>
                                                    <div className='social_div'>
                                                        <ul className='social_medias'>
                                                            <li>
                                                                <Link to={'https://www.facebook.com/TheStartupTN/'} target='_blank'>
                                                                    <img src='/images/icon/fb.png' alt='' className='contact-icon' />
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to={'https://www.youtube.com/channel/UCr0du18taGeXH35dZZD4RnQ'} target='_blank'>
                                                                    <img src='/images/icon/youtube.png' alt='' className='contact-icon' />
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to={'https://www.instagram.com/thestartuptn/'} target='_blank'>
                                                                    <img src='/images/icon/insta.png' alt='' className='contact-icon' />
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to={'https://x.com/TheStartupTN'} target='_blank'>
                                                                    <img src='/images/icon/twitters.png' alt='' className='contact-icon' />
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to={'https://www.linkedin.com/company/thestartuptn/?originalSubdomain=in'} target='_blank'>
                                                                    <img src='/images/icon/linked.png' alt='' className='contact-icon' />
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link >
                                                                    <img src='/images/icon/whatsapp.png' alt='' className='contact-icon' />
                                                                </Link>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <img src='/images/cs-map.png' className='img-fluid mb-5' alt='' />
                                        </Grid>
                                    </Grid>
                                </div>
                            </Container>
                        </Grid>
                    </Grid>
                </div>
                <Dialog
                    fullWidth={fullWidth}
                    maxWidth={maxWidth}
                    open={openSupportModal}
                    onClose={handleCloseSupport}
                >

                    <DialogContent sx={{ padding: '50px' }}>
                        <div className="support_div">
                            <div className='head_divss'>
                                <div className='text-right'>
                                    <IconButton
                                        edge="start"
                                        color="inherit"
                                        onClick={handleCloseSupport}
                                        aria-label="close"
                                        sx={{
                                            background: 'red', color: '#fff', '&:hover': {
                                                background: 'red', color: '#fff'
                                            }
                                        }}
                                    >
                                        <CloseIcon style={{ fontSize: '16px' }} />
                                    </IconButton>
                                </div>
                                <div className='text-center'>
                                    <h4>Grievance Support Center</h4>
                                    <p style={{ fontSize: '14px' }}>Please submit your details below, and our team will address your <br /> concerns promptly and efficiently</p>
                                </div>
                                <div>
                                    <form onSubmit={handleSubmit(onSubmit, onError)}>
                                        <Grid item lg={12} md={12} sm={12} xs={12} className=" mb-3">
                                            <FormLabel id="demo-row-radio-buttons-group-label">
                                                <span className="thick-blue">Full Name<span className="text-danger">*</span></span>
                                            </FormLabel>
                                            <Controller
                                                name="name"
                                                control={control}
                                                defaultValue=""
                                                rules={{
                                                    required: 'Full Name is required', validate: {
                                                        noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                                                    },
                                                }}
                                                render={({ field }) => (
                                                    <TextField
                                                        variant="outlined"
                                                        placeholder="Enter Full Name"
                                                        fullWidth
                                                        type="text"
                                                        onPaste={(e) => e.preventDefault()}
                                                        {...field}
                                                        inputProps={{ maxLength: 100 }}
                                                        error={Boolean(errors.name && errors.name)}
                                                        onKeyDown={(e) => checkAlpha(e)}
                                                    />
                                                )}
                                            />
                                            <FormHelperText className="text-danger">
                                                {errors.name && errors.name.message}
                                            </FormHelperText>
                                        </Grid>
                                        <Grid container spacing={2}>
                                            <Grid item lg={6} md={6} sm={12} xs={12} className=" mb-3">
                                                <FormLabel id="demo-row-radio-buttons-group-label">
                                                    <span className="thick-blue">Mail ID<span className="text-danger">*</span></span>
                                                </FormLabel>
                                                <Controller
                                                    name="email"
                                                    control={control}
                                                    defaultValue=""
                                                    rules={{
                                                        required: 'mail Id is required', validate: validateEmail,
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            variant="outlined"
                                                            placeholder="Enter Mail ID"
                                                            fullWidth
                                                            type="text"
                                                            {...field}
                                                            inputProps={{ maxLength: 45 }}
                                                            error={Boolean(errors.email && errors.email)}
                                                        />
                                                    )}
                                                />
                                                <FormHelperText className="text-danger">
                                                    {errors.email && errors.email.message}
                                                </FormHelperText>
                                            </Grid>
                                            <Grid item lg={6} md={6} sm={12} xs={12} className=" mb-3">
                                                <FormLabel id="demo-row-radio-buttons-group-label">
                                                    <span className="thick-blue">Phone Number<span className="text-danger">*</span></span>
                                                </FormLabel>
                                                <Controller
                                                    name="phone"
                                                    control={control}
                                                    defaultValue=""
                                                    rules={{
                                                        required: 'Phone Number is required', validate: validatePhoneNumber,
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            variant="outlined"
                                                            placeholder="Enter Phone Number"
                                                            fullWidth
                                                            type="text"
                                                            {...field}
                                                            onKeyDown={(e) => validateNumberonly(e)}
                                                            inputProps={{ maxLength: 10 }}
                                                            error={Boolean(errors.phone && errors.phone)}
                                                        />
                                                    )}
                                                />
                                                <FormHelperText className="text-danger">
                                                    {errors.phone && errors.phone.message}
                                                </FormHelperText>
                                            </Grid>
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12} xs={12} className=" mb-3">
                                            <FormLabel id="demo-row-radio-buttons-group-label">
                                                <span className="thick-blue">Query Title <span className="text-danger">*</span></span>
                                            </FormLabel>
                                            <Controller
                                                name="queryTitle"
                                                control={control}
                                                defaultValue=""
                                                rules={{
                                                    required: 'Query Title is required', validate: {
                                                        noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                                                    },
                                                }}
                                                render={({ field }) => (
                                                    <TextField
                                                        variant="outlined"
                                                        placeholder="Enter Query Title"
                                                        fullWidth
                                                        type="text"
                                                        {...field}
                                                        inputProps={{ maxLength: 45 }}
                                                        // onKeyDown={(e) => checkAlphanumeric(e)}
                                                        error={Boolean(errors.queryTitle && errors.queryTitle)}
                                                    />
                                                )}
                                            />
                                            <FormHelperText className="text-danger">
                                                {errors.queryTitle && errors.queryTitle.message}
                                            </FormHelperText>
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12} xs={12} className=" mb-3">
                                            <FormLabel id="demo-row-radio-buttons-group-label">
                                                <span className="thick-blue">Query Description <span className="text-danger">*</span></span>
                                            </FormLabel>
                                            <Controller
                                                name="query"
                                                control={control}
                                                defaultValue=""
                                                rules={{
                                                    required: 'Query Description is required', validate: {
                                                        noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed',
                                                        maxLength: value => value.length <= 200 || 'Max 200 characters',
                                                    },

                                                }}
                                                render={({ field }) => (
                                                    <TextField
                                                        id="outlined-multiline-static"
                                                        placeholder="Enter Query Summary"
                                                        fullWidth
                                                        multiline
                                                        {...field}
                                                        rows={6}
                                                        inputProps={{ maxLength: 200 }}
                                                        defaultValue=""
                                                        error={Boolean(errors.query && errors.query)}
                                                    // onKeyDown={(e) => checkAlphanumeric(e)}
                                                    />
                                                )}
                                            />
                                            <FormHelperText className="text-danger">
                                                {errors.query && errors.query.message}
                                            </FormHelperText>
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12} xs={12} className=" mb-3">
                                            <div className="form-group m-0 ">
                                                <div className="d-flex align-items-center mb-3">
                                                    <FormLabel shrink="true" className="input_Lable">
                                                        {/* <span className="thick-blue"> Attachments (Optional) </span> */}
                                                        <span className="thick-blue"> Attachments <span className='text-danger'>*</span> Maximum 5 mb allowed doc (Accepted Format:  jpeg, jpg, png)</span>

                                                    </FormLabel>
                                                </div>

                                                {/* <div className="d-flex">
                                                   
                                                    {photosFields.map((field, index) => (
                                                        <div key={index}>
                                                            <Controller
                                                                name={`attachments.${index}.imageURL`}
                                                                control={control}
                                                                defaultValue=""
                                                                rules={{
                                                                    required: index === 0 ? !getValues("attachments.0.imageURL") : false,
                                                                }}
                                                                render={({ field }) => (
                                                                    <>
                                                                        <Button
                                                                            {...field}
                                                                            fullWidth
                                                                            className="imgupload_div"
                                                                            component="label"
                                                                            size="small"
                                                                            onChange={(e) =>
                                                                                handleFileUpload(
                                                                                    e,
                                                                                    "file",
                                                                                    "fileName",
                                                                                    "imageURL",
                                                                                    "attachments",
                                                                                    index
                                                                                )
                                                                            }
                                                                        >
                                                                            {getValues(`attachments.${index}.imageURL`) ? (
                                                                                <div className="">
                                                                                    <img
                                                                                        src={getValues(`attachments.${index}.imageURL`)}
                                                                                        className="vehicle_logo"
                                                                                        alt={`attachments.${index}.fileName`}
                                                                                        style={{
                                                                                            width: "67px",
                                                                                            height: "67px",
                                                                                            objectFit: "contain",
                                                                                            border: "1px solid gainsboro",
                                                                                            borderRadius: "5px",
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            ) : (
                                                                                <div className="disPhotos">
                                                                                    <VisuallyHiddenInput type="file" />
                                                                                    <img
                                                                                        src="/images/Attachment.png"
                                                                                        className="no_logo"
                                                                                        alt={`attachments.${index}.fileName`}
                                                                                        style={{
                                                                                            width: "67px",
                                                                                            objectFit: "contain",
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                        </Button>
                                                                        {getValues(`attachments.${index}.imageURL`) && (
                                                                            <div className="text-center">
                                                                                <DeleteForeverIcon
                                                                                    color="error"
                                                                                    onClick={() => deletePhotos(index)}
                                                                                />
                                                                            </div>
                                                                        )}

                                                                      
                                                                        {errors.attachments && errors.attachments[index]?.imageURL && (
                                                                            <FormHelperText error>
                                                                                {errors.attachments[index].imageURL.message || "Attachments is required."}
                                                                            </FormHelperText>
                                                                        )}
                                                                    </>
                                                                )}
                                                            />
                                                        </div>
                                                    ))}

                                                </div> */}
                                                <div className="d-flex">
                                                    {photosFields.map((field, index) => (
                                                        <div key={index}>
                                                            {index < 5 && (
                                                                <Controller
                                                                    name={`attachments.${index}.imageURL`}
                                                                    control={control}
                                                                    defaultValue=""
                                                                    rules={{
                                                                        required: index === 0 ? !getValues("attachments.0.imageURL") : false,
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <>
                                                                            <Button
                                                                                {...field}
                                                                                fullWidth
                                                                                className="imgupload_div"
                                                                                component="label"
                                                                                size="small"
                                                                                onChange={(e) =>
                                                                                    handleFileUpload(
                                                                                        e,
                                                                                        "file",
                                                                                        "fileName",
                                                                                        "imageURL",
                                                                                        "attachments",
                                                                                        index
                                                                                    )
                                                                                }
                                                                            >
                                                                                {getValues(`attachments.${index}.imageURL`) ? (
                                                                                    <div className="">
                                                                                        <img
                                                                                            src={getValues(`attachments.${index}.imageURL`)}
                                                                                            className="vehicle_logo"
                                                                                            alt={`attachments.${index}.fileName`}
                                                                                            style={{
                                                                                                width: "67px",
                                                                                                height: "67px",
                                                                                                objectFit: "contain",
                                                                                                border: "1px solid gainsboro",
                                                                                                borderRadius: "5px",
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                ) : (
                                                                                    <div className="disPhotos">
                                                                                        <VisuallyHiddenInput type="file" />
                                                                                        <img
                                                                                            src="/images/Attachment.png"
                                                                                            className="no_logo"
                                                                                            alt={`attachments.${index}.fileName`}
                                                                                            style={{
                                                                                                width: "67px",
                                                                                                objectFit: "contain",
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                )}
                                                                            </Button>
                                                                            {getValues(`attachments.${index}.imageURL`) && (
                                                                                <div className="text-center">
                                                                                    <DeleteForeverIcon
                                                                                        color="error"
                                                                                        onClick={() => deletePhotos(index)}
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                            {/* Error message display */}
                                                                            {errors.attachments && errors.attachments[index]?.imageURL && (
                                                                                <FormHelperText error>
                                                                                    {errors.attachments[index].imageURL.message || "Attachments is required."}
                                                                                </FormHelperText>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                />
                                                            )}
                                                        </div>
                                                    ))}
                                                </div>

                                            </div>
                                            <div className='create-surya'>
                                                <Button className='clear-btn' onClick={() => handleClear()}>
                                                    Clear
                                                </Button>
                                                <Button className='create-btn ml-3' type='submit' >
                                                    Create
                                                </Button>
                                            </div>
                                        </Grid>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
            </div>
            <div>
                <Dialog
                    fullWidth={fullWidth}
                    maxWidth={maxWidth}
                    open={smartOpen}
                    onClose={availsmartClose}
                >
                    <DialogContent>
                        <div className="text-center">
                            <img src={'/images/session_exp/gif_.gif'} alt="" className="sucess-image" />
                            <h4>Thank you for submitting your request.</h4>
                            <p>Your query has been received and is under review. Our support team will respond to you shortly. We appreciate your trust in us.</p>
                        </div>
                        <div className='text-center'>
                            <Button className='clear-btn' onClick={() => availsmartClose()}>
                                Close
                            </Button>
                        </div>
                    </DialogContent>
                </Dialog>
            </div>
            <Footer />
        </>

    )
}

export default ContactUs