import React, { useState, useEffect, useContext } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Button, CardActionArea, CardActions, Container, Dialog, Paper, DialogContent, DialogContentText, Pagination, Stack, Table, TableBody, TableCell, TableContainer, TableRow, Menu, TableHead } from "@mui/material";
import { Grid } from "@mui/material";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import DataContext from "../../../../context/DataContext";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import apiService from "../../../../api/apiService";
import Norecordsfound from "../../../common-pages/Norecordsfound";
import Eventregister from "../../../Media-module/Media-layout-page/Event-Page/Eventregister";
import AdminEventFilter from "../AdminEventFilter";
import CustomCalendar from "../../../common-pages/CustomCalendar";
import AdminVenueAdd from "./AdminVenueAdd";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
const AdminEventVenue = () => {
  const { fullScreen, setPageLoading, hasPrivilege } = useContext(DataContext);
  const [totalPage, setTotalPage] = useState(1);
  const [page, setPage] = useState(1);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [eventsData, setEventsData] = useState([]);
  const [sortBy, setSortBy] = useState(true)
  useDidMountEffect(() => {
    listApi();
  }, [searchInputValue, page, sortBy]);

  const listApi = () => {
    setPageLoading(true)
    setEventsData([])
    var data = {
      listSize: 10,
      pageNumber: page,
      searchString: searchInputValue,
      sort: sortBy ? 'asc' : 'desc',
    };
    apiService("venue/list", data, "post")
      .then((result) => {
        setPageLoading(false)
        if (result && result.data) {
          setTotalPage(result.data.totalPages);
          if (result.data.venues) {
            setEventsData(result.data.venues);
          }
        }
      })
      .catch((err) => {
        console.error("Error fetching events:", err);
      });
  };
  const handleChange = (event, value) => {
    setPage(value);
  };

  const [opens, setOpens] = useState(false);

  const handleVenueOpen = () => {
    setOpens(true);
  };
  const handleVenueClose = () => {
    setOpens(false);
    setVenueId(0)
  };
  const [venueId, setVenueId] = useState(0);
  const handleEditBtn = (id) => {
    setVenueId(id)
    setOpens(true);
  }
  const handleDeleteBtn = (id) => {
    apiService(`venue/remove?id=${id}`, '', 'delete').then((result) => {
      if (result && result.data && result.data.responseStatus === "Success") {
        listApi()
      }
    }).catch((err) => {

    });
  }
  return (
    <div className="admin-event-page">
      <AdminEventFilter
        setSearchInputValue={setSearchInputValue}
        setSortBy={setSortBy} sortBy={sortBy}
        name={'venue'}
        handleVenueOpen={handleVenueOpen}
      />
      <div className="event-datas-list py-5">
        {eventsData.length > 0 ? (
          <>
            <Grid
              container
              direction="row"
              alignItems="flex-start"
              justifyContent="center"
              spacing={5}
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                {eventsData.length > 0 &&
                  <div className="table_main_div">
                    <TableContainer component={Paper}>
                      <Table sx={{ borderCollapse: 'separate', borderSpacing: '0 10px' }}>
                        <TableHead>
                          <TableRow className="titles1">
                            <TableCell>Venue Name</TableCell>
                            <TableCell>Location</TableCell>
                            <TableCell align="center">Pincode</TableCell>
                            <TableCell align="center">Action</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody sx={{ position: 'relative' }}>
                          {eventsData.map((list, index) => (
                            <>
                              <TableRow
                                sx={{
                                  position: 'relative',
                                  '&:after': {
                                    content: '""',
                                    position: 'absolute',
                                    top: 0,
                                    bottom: 0,
                                    left: 0,
                                    right: 0,
                                    border: '1px solid rgba(224, 224, 224, 1)',
                                    borderRadius: '20px',
                                    pointerEvents: 'none',
                                    marginBottom: '-1px',
                                  },
                                  '& > *': {
                                    padding: '10px',
                                    backgroundColor: '#fff',
                                    '&:last-of-type': { borderTopRightRadius: '20px', borderBottomRightRadius: '20px' },
                                  },
                                  marginBottom: '10px',
                                }}
                                key={index}
                                className={"Sup-list-no-cor"}
                              >
                                <TableCell>
                                  <span className="list-1-clr">{list.name}</span>
                                </TableCell>
                                <TableCell>
                                  <span className="list-ran">{list.location}</span>
                                </TableCell>
                                <TableCell align="center">
                                  <span className="list-ran">{list.pincode}</span>
                                </TableCell>
                                <TableCell align="center">
                                  {
                                    hasPrivilege('14') && (
                                      <EditIcon
                                        sx={{
                                          cursor: "pointer",
                                          marginLeft: "5px",
                                           color: 'green'
                                        }}
                                        onClick={() => handleEditBtn(list.id)}
                                      />
                                    )
                                  }
                                  {
                                    hasPrivilege('15') && (
                                      <DeleteForeverIcon
                                        sx={{
                                          cursor: "pointer",
                                          marginLeft: "5px",
                                          color: 'red'
                                        }}
                                        onClick={() => handleDeleteBtn(list.id)}
                                      />
                                    )
                                  }
                                </TableCell>
                              </TableRow>
                            </>
                          ))}
                        </TableBody>
                      </Table>
                      {totalPage > 1 && (
                        <div className="event-pagination">
                          <Stack spacing={2}>
                            <Pagination
                              count={totalPage}
                              page={page}
                              onChange={handleChange}
                            />
                          </Stack>
                        </div>
                      )}
                    </TableContainer>
                  </div>
                }
              </Grid>
            </Grid>
          </>
        )
          : (
            <>
              <div className='text-center nodatacss_admin'>
                <h5 className='mb-4'>Venus data not found</h5>
                <div>
                  <img src="/images/blue horizontal.png" alt="blue horizontal.png" className='logo_dash img-fluid' />
                </div>
                <div className='mt-5'>
                  <h3 className='title_p opa'>
                    StartupTN 3.0
                  </h3>
                </div>
              </div>
            </>
          )
        }
      </div>

      <Dialog fullScreen={fullScreen} maxWidth={"sm"} open={opens}>
        <DialogContent>
          <AdminVenueAdd handleVenueClose={handleVenueClose} venueId={venueId} listApi={listApi} />
        </DialogContent>
      </Dialog>
    </div >
  );
};
export default AdminEventVenue