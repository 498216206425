import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Divider,
  Grid,
} from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { Link, useNavigate } from "react-router-dom";
import { FaCircleArrowRight } from "react-icons/fa6";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IoIosAddCircle } from "react-icons/io";
import Navbar from "../../home-module/navbar-page/Navbar";

const LaunchPad = ({name}) => {
  const navigate = useNavigate();

  const handlenow = () => {
    navigate('/login')
  }

  return (
    <div className="smartCard-page">
      <Navbar />
      <div className="radial-back-layer">
      <div className="round-layers"></div>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="banner-text mt-5">
              <div className="sm-banner-chip-text">
                <span>{`${name} >  Launchpad`}</span>
                <h1 className="banner-text-gradient">Launchpad</h1>
                <h6>
                  Unique Initiative by StartupTN with the objective of
                  <center>supporting a startup</center>
                </h6>
                <div className="banner-content-actions">
                  <button className="banner-viewbtn">Get in Touch</button>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className="smart-card-info">
        <div className="container acce-content-sec ">
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={2}
            sx={{ paddingBottom: "5px" }}
          >
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="left-side-accePage">
                <h4>What is StartupTN Launchpad?</h4>
                <p>
                  Creating a new product is a remarkable act. Launching a new product in the market properly is an equally skilful process.
                </p>
                <ul className="launch-parad">
                  <li>This process includes preparing the market-fit product, positioning it and communicating it to potential customers using right marketing communications.</li>
                  <li>StartupTN LaunchPad is an unique initiative by StartupTN with the objective of supporting a startup in the moves that is required before, during, and right after the launch a new product.</li>
                </ul>
                {/* <div className="left-content-actions">
                  <button className="ser-viewbtn">Explore Services</button>
                </div> */}
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="smart-crd-img">
                <img
                  className="acce-imag "
                  src={"/images/launchpad.png"}
                  alt="smart-card"
                />
              </div>
            </Grid>
          </Grid>

        </div>
        <div className="container-fluid">
          <div className="why-accel-sec1">
            {/* <div className="shg-bg"> */}
            <div className="launch-color">
              <h1 className="launchedddd">LaunchPad Events</h1>
              <p>StartupTN organises the LaunchPad events regularly at different locations and at least 20 products or services are launched. The events will be conducted both offline and online with participation from industry veterans.
              </p>
              <p className="">The events enable a right product launch with a focussed, comprehensive approach that incorporates the following elements:</p>
              <ul className="">
                <li>Building excitement</li>
                <li>Creating curiosity</li>
                <li>Social media campaign</li>
                <li>Influencer marketing</li>
              </ul>
              <p>All these elements combined will give the launch a more profound meaning – the product or service will become more visible, attract many potential customers and set up for a big success.</p>
            </div>
          </div>
        </div>
        <div className="join-us-now-sec">
          <Container>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="join-us-now-box">
                  <div className="join-us-now-content">
                    <h3>LaunchPad</h3>
                    <p>
                      Perfecting Your Product's Market Debut
                    </p>
                    <Button
                      variant="contained"
                      size="large"
                      className="join-now-btn"
                      onClick={handlenow}
                    >
                      Join us Now
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
    </div>
  )
}

export default LaunchPad