import React, { useContext, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { Avatar, Box, Dialog, IconButton, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import DataContext from "../../../../context/DataContext";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
// import apiService from "../../../../api/apiService";
// import { Margin } from "@mui/icons-material";
// import SideMenu from "./SideMenu";
// import StartupMenu from "./StartupMenu";
// import ebablerMenu from "./StartupMenu";
// import EnablerMenus from "./EnablerMenus";
import Changepassword from "../../../common-pages/Changepassword";

const FastNavbar = () => {
  const { navigator, setUserProfileId, setUserProfileParsona, removeItem, checkSessionTimout } = useContext(DataContext);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const [anchorElAgenda, setAnchorElAgenda] = useState(null);
  let continueWith = localStorage.getItem('continueWith')
  let loignType
  useDidMountEffect(() => {
    loignType = localStorage.getItem('type')
    checkSessionTimout()
    const handleScroll = () => {
      if (window.scrollY > 50) {  // Adjust the scroll position value as needed
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleAgendaMenuOpen = (event) => {
    setAnchorElAgenda(event.currentTarget);
  };

  const handleAgendaMenuClose = () => {
    setAnchorElAgenda(null);
  };

  let logo = localStorage.getItem('logo')
  let name = localStorage.getItem('name')
  let token = localStorage.getItem("jwttoken")
  // useDidMountEffect(() => {
  //   if (token !== null && logo === null) {
  //     // getApi()
  //   }
  // }, [])
  // const [startupName, setStartupName] = useState('')
  // const getApi = () => {
  //   var id = localStorage.getItem("id");
  //   var type = localStorage.getItem('type')
  //   apiService(userprofile/get?persona=${type}&userid=${id}, "", "get")
  //     .then((result) => {
  //       if (result && result.data) {
  //         var data = result.data
  //         if (data.imageName) {
  //           localStorage.setItem('logo', data.imageName)
  //         } else {
  //           setStartupName(data.name)
  //         }

  //       }

  //     }).catch((err) => {

  //     });
  // }
  const logoutBtn = () => {
    handleCloseUserMenu()
    localStorage.clear()
    sessionStorage.clear()
    navigator('/')
  }
  const handleProfileType = () => {
    navigator('/profile')
    removeItem()
  }
  // const profilePage = () => {
  //     setUserProfileId(0)
  //     setUserProfileParsona('')
  //     let type = localStorage.getItem('profile')
  //     var name = localStorage.getItem('type')
  //     if (name === 'STARTUP') {
  //         if (type === 'true') {
  //             navigator('/startup/profile')
  //         } else if (type === 'false') {
  //             navigator('/startups/basicinfo')
  //         }
  //     } else if (name === 'ASPIRANT') {
  //         if (type === 'true') {
  //             navigator('/aspirant/profile')
  //         } else if (type === 'false') {
  //             navigator('/aspirants/basicinfo')
  //         }
  //     } else if (name === 'MENTOR') {
  //         if (type === 'true') {
  //             navigator('/mentor/profile')

  //         } else if (type === 'false') {
  //             navigator('/mentors/basicinfo')
  //         }
  //     } else if (name === 'INNOVATOR') {
  //         if (type === 'true') {
  //             navigator('/innovator/profile')
  //         } else if (type === 'false') {
  //             navigator('/innovators/basicinfo')
  //         }

  //     } else if (name === 'INVESTOR') {
  //         if (type === 'true') {
  //             navigator('/investor/profile')

  //         } else if (type === 'false') {
  //             navigator('/investor/basicinfo')
  //         }

  //     } else if (name === 'STARTUP-ECO') {
  //         if (type === 'true') {
  //             navigator('/ecosystem/profile')

  //         } else if (type === 'false') {
  //             navigator('/ecosystem/basicinfo')
  //         }

  //     } else if (name === 'STARTUPS-SERVPROV') {
  //         if (type === 'true') {
  //             navigator('/service-provider/profile')

  //         } else if (type === 'false') {
  //             navigator('/service-provider/basicinfo')
  //         }

  //     } else {
  //         navigator('/admin')

  //     }
  //     debugger
  //     removeItem()
  // }
  const profilePage = () => {
    setUserProfileId(0)
    setUserProfileParsona('')
    let type = localStorage.getItem('profile')
    var name = localStorage.getItem('type')
    let idVal = localStorage.getItem("idValue")
    if (name === 'STARTUP') {
      if (type === 'true') {
        navigator('/startup/profile')
      } else {
        navigator('/startups/basicinfo')
      }
    } else if (name === 'ASPIRANT') {
      if (type === 'true') {
        navigator('/aspirant/profile')
      } else {
        navigator('/aspirants/basicinfo')
      }
    } else if (name === 'MENTOR') {
      if (type === 'true') {
        navigator('/mentor/profile')

      } else {
        navigator('/mentors/basicinfo')
      }
    } else if (name === 'INNOVATOR') {
      if (type === 'true') {
        navigator('/innovator/profile')
      } else {
        navigator('/innovators/basicinfo')
      }

    } else if (name === 'INVESTOR') {
      if (type === 'true') {
        navigator('/investor/profile')

      } else {
        navigator('/investor/basicinfo')
      }

    } else if (name === 'STARTUP-ECO') {
      if (type === 'true') {
        navigator('/ecosystem/profile')

      } else {
        navigator('/ecosystem/basicinfo')
      }

    } else if (name === 'STARTUPS-SERVPROV') {
      if (type === 'true') {
        navigator('/service-provider/profile')

      } else {
        navigator('/service-provider/basicinfo')
      }

    } else if (name === 'NA') {
      navigator(`letsstart/${btoa(idVal)}`)
    } else {
      navigator('/admin')
    }
    removeItem()
  }
  const adminDashboardPage = () => {
    navigator('/admin')
    removeItem()
  }
  const [openStartupMenu, setOpenStartupMenu] = useState(false)
  const handleStartupMenu = () => {

    setOpenStartupMenu(!openStartupMenu)
    setEnablerMenu(false)

  }
  const [openEnablerMenu, setEnablerMenu] = useState(false)
  const handleEnablerMenu = () => {
    setOpenStartupMenu(false)
    setEnablerMenu(!openEnablerMenu)

  }
  const [isActive, setIsActive] = useState(false);
  const [anchorElStartupMenu, setAnchorElStartupMenu] = React.useState(false);
  const handleOpenStartupMenu = () => {
    setAnchorElStartupMenu(!anchorElStartupMenu);
    setAnchorElEnablersMenu(null);
    setIsActive(!isActive);
    setIsActive2(false);
  };
  const handleCloseStartupMenu = () => {
    setAnchorElStartupMenu(null);
    setIsActive(false);

  };
  const [isActive2, setIsActive2] = useState(false);
  const [anchorElEnablersMenu, setAnchorElEnablersMenu] = React.useState(false);
  const handleOpenEnablersMenu = () => {
    setAnchorElEnablersMenu(!anchorElEnablersMenu);
    setAnchorElStartupMenu(null);
    setIsActive2(!isActive2);
    setIsActive(false);
  };
  const handleCloseEnablersMenu = () => {
    setAnchorElEnablersMenu(null);
    setIsActive2(false);

  };
  const [openPassword, setOpenPassword] = useState(false);
  const handlePasswordOpen = () => {
    setOpenPassword(true);
  }
  const handlePasswordClose = () => {
    setOpenPassword(false);
  }
  return (
    <>
      <div className={`${isScrolled ? 'aleart-msg set-bg' : 'aleart-msg1 set-bg'}`} >
        <span className="sys_view">You are using a beta version of our website. We are still refining features. If you experience any problems or have suggestions, please contact us at tech@startuptn.in / (+91) 44 22256789 / 155343</span>
        {/* <Link to={'/login'} className={`${isScrolled ? 'text-primary' : 'marquee_a'}`}>raise a support ticket.</Link> */}
        <span className="mob_view">This is a beta version, if you experience any problems please contact us at tech@startuptn.in / (+91) 44 22256789 / 155343
          {/* <Link to={'/login'} className={`${isScrolled ? 'text-primary' : 'marquee_a'}`}>raise a support ticket.</Link> */}
        </span>

      </div>
      <div className={`${isScrolled ? 'scrolled fixed-tops' : 'fixed-top'}`} >
        {/* <marquee className={''}> */}

        {/* </marquee> */}
        <div className={token !== null ? 'container' : 'container-fluid margin-css'}>
          <nav className="navbar navbar-expand-lg navbar-light bg-lights nav-pads light-bgs">
            {/* {
                token === null && (
                  <SideMenu />
                )
              } */}
            <Link className="navbar-brand nav-log-res1" to="/" onClick={removeItem}>
              <img src="/images/blue horizontal.png" alt="" />
            </Link>

            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">


              <Link to="/" onClick={removeItem}>
                <img
                  className="img-fluid nav-logo-profile"
                  src="/images/blue horizontal.png"
                  alt=""
                />
              </Link>



              <ul className="navbar-nav ml-auto">
                <div className="nav-align">
                  <li className="nav-item dropdown">
                    <a
                      href="#"
                      className="nav-link dropdown-toggle mr-3"
                      onMouseEnter={handleAgendaMenuOpen}
                      onMouseLeave={handleAgendaMenuClose}
                      style={{ color: '#423c6b' }}
                    >
                      Agenda
                    </a>
                    <div
                      className={`dropdown-menu${Boolean(anchorElAgenda) ? ' show' : ''}`}
                      onMouseEnter={handleAgendaMenuOpen}
                      onMouseLeave={handleAgendaMenuClose}
                    >
                      <Link className="dropdown-item" to="/fest/agenda/day1">Day 1</Link>
                      <Link className="dropdown-item" to="/fest/agenda/day2">Day 2</Link>
                    </div>
                  </li>
                </div>
              </ul>
              <ul className="navbar-nav ml-auto">
                <div className="nav-align">
                  {
                    token === null ? (
                      <>
                        <Link to={'/login'} className="nav-buttons" onClick={removeItem}>Login</Link>
                        <button className="nav-button" onClick={handleProfileType}>Get Started</button>
                      </>
                    ) : (
                      <Box sx={{ flexGrow: 0 }}>
                        {/* onClick={handleOpenUserMenu} */}
                        <Tooltip onClick={handleOpenUserMenu}>
                          <img
                            className="user-brandnewlogo"
                            // src={logo ? logo : "/images/user.png"}
                            src={(logo !== "undefined" && logo !== null) ? logo : "/images/user.png"}
                            alt={name}
                          />
                          {/* <IconButton sx={{ p: 0 }}>
                              <Avatar alt={startupName} src={logo} />
                            </IconButton> */}
                        </Tooltip>
                        <Menu
                          sx={{ mt: '45px' }}
                          id="menu-appbar"
                          anchorEl={anchorElUser}
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          keepMounted
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          open={Boolean(anchorElUser)}
                          onClose={handleCloseUserMenu}
                        >
                          {
                            loignType === 'ADMIN' ? (
                              <MenuItem onClick={adminDashboardPage} >
                                <Typography textAlign="center" sx={{ color: '#000' }}>Dashboard</Typography>
                              </MenuItem>
                            ) : (
                              <>
                                <MenuItem onClick={profilePage} >
                                  <Typography textAlign="center" sx={{ color: '#000' }}>Profile</Typography>
                                </MenuItem>
                                {/* {
                                                                    continueWith === 'false' && (
                                                                        < MenuItem onClick={handlePasswordOpen}>
                                                                            <Typography textAlign="center">Change Password</Typography>
                                                                        </MenuItem>
                                                                    )
                                                                } */}
                              </>
                            )
                          }
                          <MenuItem onClick={logoutBtn} >
                            <Typography textAlign="center" sx={{ color: '#000' }}>Logout</Typography>
                          </MenuItem>
                        </Menu>
                      </Box>
                    )
                  }
                </div>
              </ul>
            </div>
          </nav>
        </div >

      </div >
      {/* <Menu
          sx={{ zIndex: '999' }}
          className="set_menu_nav"
          anchorEl={anchorElStartupMenu}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={Boolean(anchorElStartupMenu)}
          onClose={handleCloseStartupMenu}
        >
          <StartupMenu handleCloseStartupMenu={handleCloseStartupMenu} />
        </Menu>
        <Menu
          sx={{ zIndex: '999' }}
          className="set_menu_nav"
          anchorEl={anchorElEnablersMenu}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={Boolean(anchorElEnablersMenu)}
          onClose={handleCloseEnablersMenu}
        >
          <EnablerMenus handleCloseEnablersMenu={handleCloseEnablersMenu} />
        </Menu>        
        <Dialog open={openPassword} maxWidth="md">
          <Changepassword handlePasswordClose={handlePasswordClose} />
        </Dialog> */}
    </>
  )
}

export default FastNavbar