import { Button, Grid, Box, Divider, FormHelperText, Autocomplete, FormControl, TextField, DialogTitle, InputAdornment, FormControlLabel, Checkbox } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Dialog from "@mui/material/Dialog";
import { Autoplay } from "swiper/modules";
import DialogContent from "@mui/material/DialogContent";
import { Swiper, SwiperSlide } from "swiper/react";
import DialogContentText from "@mui/material/DialogContentText";
// import UserProfileReport from "./UserProfileReport";
import { FaPhoneAlt } from "react-icons/fa";
import { MdOutlineMail } from "react-icons/md";
import { CiGlobe } from "react-icons/ci";
import { styled } from "@mui/material/styles";
import DataContext from "../../../../context/DataContext";
import apiService from "../../../../api/apiService";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import EditIcon from "@mui/icons-material/Edit";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import notifyService from "../../../../api/notifySerivce";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import downloadPDF from "../../../../hooks/downloadPDF";
import Navbar from "../../../home-module/navbar-page/Navbar";
import cryptoJS from "../../../../hooks/cryptoJS";
import MaskDialog from "../../Mask-Dialogbox/MaskDialog";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import Qrcode from "../../startup-profile/Qr-Barcode/Qrcode";
import { useParams } from "react-router-dom";

const EnablersProfilePage = ({ path }) => {
    const { navigator, validateNumberonly, setPageLoading, VisuallyHiddenInput, validatePhoneNumber, fullScreen, modalstyle, addbtnShow, setAddbtnShow,
        linkedInPattern, validateEmail, roleTypes,
        getApi, para, formData, handleOpenEdit, editBtn, setEditBtn, handleEditBtn, editKey, handlemaskClickOpen, setEditKey, userProfileId, showViewbtn } = useContext(DataContext);


    const { handleSubmit, control, setValue, clearErrors, getValues, formState: { errors } } = useForm({ values: formData });

    const {
        fields: founderFields,
        append: founderAppend,
        remove: founderRemove,
    } = useFieldArray({
        control,
        name: "founders",
    });

    const { name, id } = useParams()
    console.log(name, id);
    if (id !== undefined) {
        const personaId = atob(id)
        localStorage.setItem('userpersonaId', personaId)
    }
    if (name !== undefined) {
        const personaName = atob(name)
        localStorage.setItem('userpersona', personaName)
        debugger
    }
    // let roleTypes
    useDidMountEffect(() => {
        getApi();
        // roleTypes = roleTypes
    }, [userProfileId, showViewbtn]);

    useDidMountEffect(() => {
        if (userProfileId === 0) {

            DesignationApi()
            dropdownGenderApi()
        }
    }, []);

    const [designation, setDesignation] = useState([]);
    // const DesignationApi = () => {
    //     apiService(`home/designation/list`, "", "get")
    //         .then((result) => {
    //             if (result && result.data) {
    //                 if (result.data.designations) {
    //                     setDesignation(result.data.designations);

    //                 }
    //             }
    //         })
    //         .catch((err) => { });
    // };
    const DesignationApi = () => {
        apiService(`admin/dropdownvalues/get?key=${"designation"}`, "", "unauthget")
            .then((result) => {
                if (result && result.data) {
                    if (result.data.dropDownValues.values) {
                        setDesignation(result.data.dropDownValues.values);

                    }
                }
            })
            .catch((err) => { });
    };
    const [gender, setGender] = useState([]);
    const dropdownGenderApi = () => {
        apiService(`admin/dropdownvalues/get?key=${"gender"}`, "", "unauthget")
            .then((result) => {

                if (result) {
                    if (result.data) {
                        if (result.data.dropDownValues) {
                            var dropDown = result.data.dropDownValues;
                            setGender(dropDown.values);
                        }
                    }
                }
            })
            .catch((err) => { });
    };

    const handleSelectDesignation = (value, index) => {
        setValue(`founders.${index}.designation`, value);
        clearErrors(`founders.${index}.designation`);
    };
    const [showFull, setShowFull] = useState(false);
    const toggleReadMore = () => {
        setShowFull(!showFull);
    };
    // const fullText = "Apple Inc. is a global Apple Inc. is a globalApple Inc. is a globalApple Inc. is a global";
    const truncateText = (text, wordLimit) => {
        const words = text.split(" ");
        return words.length > wordLimit
            ? words.slice(0, wordLimit).join(" ") + "..."
            : text;
    };
    const wordLimit = 30;
    const displayText = showFull ? para : truncateText(para, wordLimit);
    const isTruncated = para.split(" ").length > wordLimit;

    const onSubmit = (data) => {
        data.phone = cryptoJS(data.phone)
        data.email = cryptoJS(data.email)

        if (data.founders) {
            data.founders.forEach(founder => {
                if (founder.email) {
                    founder.email = cryptoJS(founder.email);
                }
                if (founder.phone) {
                    founder.phone = cryptoJS(founder.phone);
                }
            });
        }

        apiService("userprofile/save", data, "post")
            .then((result) => {
                if (result.data.responseStatus === "Success") {
                    if (editKey === "") {
                        setEditBtn(false);
                    } else {
                        setEditKey("");
                    }
                    setAddbtnShow(true)
                    getApi();
                }
            })
            .catch((err) => { });
    };
    const onError = (e) => {
        console.log(e)
    };
    let logoselecetdFile = "";
    const handleFileUpload = (event, filekey, fileName, fileUrl) => {
        if (event !== null) {
            if (event.target === undefined) {
                logoselecetdFile = event
            } else {
                logoselecetdFile = event.target.files[0]
            }
            const maxFileSize = 5 * 1024 * 1024; // 5MB in bytes
            if (logoselecetdFile) {
                if (logoselecetdFile.size < maxFileSize) {
                    var reader = new FileReader();
                    var imagetype = logoselecetdFile.type;
                    var imagedatatype = imagetype.split("/");
                    var img_crt_type = imagedatatype[1];
                    if (
                        img_crt_type === "jpg" ||
                        img_crt_type === "png" ||
                        img_crt_type === "jpeg"
                    ) {
                        setValue(filekey, "");
                        setValue(fileName, "");
                        setValue(fileUrl, '')
                        var fileValue = logoselecetdFile;
                        reader.readAsDataURL(logoselecetdFile);
                        reader.onload = () => {
                            var logourl1 = reader.result;
                            var spl = logourl1.split(",");
                            var ImageValue = spl[1];
                            var img_name = fileValue.name;
                            // setUrl(logourl1)
                            setValue(filekey, ImageValue);
                            setValue(fileName, img_name);
                            setValue(fileUrl, logourl1)
                            clearErrors(filekey);
                            clearErrors(fileName);
                        };
                    } else {
                        notifyService(
                            "danger",
                            "File Format Invalided",
                            "Please check your file format"
                        );
                    }
                } else {
                    notifyService(
                        "danger",
                        "File Size Exceeded",
                        `Maximum file size allowed is 5 MB`
                    );
                }
            }
        }
    };
    const [qrModalopen, setQrModalOpen] = useState(false);
    const [fullWidth, setFullWidth] = useState(true);
    const [maxWidth, setMaxWidth] = useState('sm');
    const [smartOpen, setSmartOpen] = useState(false)
    const availsmartopen = () => {
        setPageLoading(true)
        setSmartOpen(true)
        apiService('userprofile/smartcard/avail', '', 'get')
            .then((result) => {
                setPageLoading(false)
                setTimeout(() => {
                    availsmartClose()
                }, 1500);
                getApi()
            }).catch((err) => {
                console.log(err);
            });
    }
    const availsmartClose = () => {
        setSmartOpen(false)
    }
    const handleClickOpen = () => {
        setQrModalOpen(true);
    };

    const handleModalClose = () => {
        setQrModalOpen(false);
    };

    const downloadBarcode = () => {
        const link = document.createElement('a');
        link.setAttribute('target', '_blank');
        link.setAttribute('href', formData.barcode);
        link.setAttribute('download', formData.barcode); // Set desired file name here
        document.body.appendChild(link);
        link.click();
        link.remove();
    }
    useEffect(() => {
        size();
    }, []);
    const [screenSize, setScreenSize] = useState(3);
    const size = () => {
        if (window.innerWidth > 1440) {
            setScreenSize(4);
        } else if (window.innerWidth <= 1440 && window.innerWidth > 1024) {
            setScreenSize(4);
        } else if (window.innerWidth <= 1024 && window.innerWidth > 992) {
            setScreenSize(2);
        } else if (window.innerWidth <= 992 && window.innerWidth > 768) {
            setScreenSize(2);
        } else if (window.innerWidth <= 768 && window.innerWidth > 575) {
            setScreenSize(2);
        } else {
            setScreenSize(1);
        }
    };
    // const founder = [
    //     {
    //         name: 'John Doe',
    //         linkedin: 'https://www.linkedin.com/in/johndoe',
    //     },
    //     {
    //         name: 'Jane Smith',
    //         linkedin: 'https://www.linkedin.com/in/janesmith',
    //     },

    // ];

    const [emailVerify, setEmailVerify] = useState(null)
    const handleEmailVerfication = (value, index) => {
        setEmailVerify(null)
        if (value.length > 3) {
            var email = value.toLowerCase()
            apiService(`founder/email/verify?email=${cryptoJS(email)}`, '', 'get').then((result) => {
                if (result && result.data && result.data.responseStatus === 'Success') {
                    setEmailVerify(true)
                    setValue(`founders.${index}.emailVerify`, true)
                } else {
                    setEmailVerify(false)
                    notifyService('danger', 'Error', result.data.responseMessage)
                    setValue(`founders.${index}.emailVerify`, false)
                }
            }).catch((err) => {
                setEmailVerify(null)
                setValue(`founders.${index}.emailVerify`, null)
            });
        }
    }
    const addFounderBtn = () => {
        // var founder = getValues('founders')
        var founder = getValues('founders') ?? [];
        console.log(founder, "founders")
        debugger
        setEditKey(founder.length)

        founderAppend({
            id: 0,
            type: localStorage.getItem("role"),
            name: "",
            email: "",
            phone: "",
            gender: "",
            designation: "",
            profile: "",
            profileName: "",
            profileUrl: "",
        });
        setAddbtnShow(false)
    };

    const founderDetailsRemove = (index) => {
        var id = getValues(`founders.${index}.id`);
        if (id !== 0) {
            apiService(`userprofile/founder/remove?id=${id}`, '', 'delete').then((result) => {
                if (result && result.data) {
                    founderRemove(index);
                    getApi()
                    setAddbtnShow(true)
                    setEditKey(null);
                    notifyService("success", "Success", "Sucessfully Deleted");
                }
            }).catch((err) => {
            });
        } else {
            founderRemove(index);
            setAddbtnShow(true)
        }

    };
    return (
        <>
            <div className="bg-color">
                <div>
                    <Navbar />

                    <div className="container-fluid flu-class">
                        <div className="ecosystem-prof-img common-bg2"></div>


                        <div className="user-profile">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="user-boxs">
                                    <div className="first-box">
                                        <div className="all-userdetails1">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    {editKey === "imageName" ? (
                                                        <div className="form-group mt-2">
                                                            <div className="logo_div">
                                                                <img
                                                                    src={getValues('imageUrl')}
                                                                    className="user_logo"
                                                                    alt={getValues('imageName')}
                                                                />
                                                                <div className="camera_icon">
                                                                    <Controller
                                                                        name="imageName"
                                                                        control={control}
                                                                        defaultValue=""
                                                                        rules={{ required: `logo is required` }}
                                                                        render={({ field }) => (
                                                                            <Button
                                                                                {...field}
                                                                                onChange={(e) =>
                                                                                    handleFileUpload(e, "image", "imageName", "imageUrl")
                                                                                }
                                                                                component="label"
                                                                                startIcon={<CameraAltIcon />}
                                                                            >
                                                                                <VisuallyHiddenInput type="file" />
                                                                            </Button>
                                                                        )}
                                                                    />
                                                                </div>
                                                                <FormHelperText className="text-danger">
                                                                    {errors.imageName && errors.imageName.errorMessage}
                                                                </FormHelperText>
                                                            </div>

                                                            <div className="img-btn-div">
                                                                <Button type="submit" sx={{ marginLeft: "10px" }}>
                                                                    <DoneOutlineIcon
                                                                        sx={{ fontSize: "16px", cursor: "pointer" }}
                                                                    />
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <span>
                                                            {" "}
                                                            <img
                                                                className="user-brandlogo img-fluid"
                                                                src={
                                                                    formData.imageName
                                                                        ? formData.imageName
                                                                        : "/images/user.png"
                                                                }
                                                                alt="logo"
                                                            />
                                                            {editBtn === true && (
                                                                <EditIcon
                                                                    sx={{
                                                                        fontSize: "14px",
                                                                        cursor: "pointer",
                                                                        marginLeft: "5px",
                                                                    }}
                                                                    onClick={() => handleEditBtn("imageName")}
                                                                />
                                                            )}
                                                        </span>
                                                    )}
                                                </div>
                                                <div>
                                                    {
                                                        roleTypes !== 'GOVT-AGENCY' && (
                                                            <div className="brand-details ml-3">
                                                                <h4 className="font-weight-bold">
                                                                    {editKey === "name" ? (
                                                                        <div className="d-inline-block">
                                                                            <div className="d-flex align-items-start">
                                                                                <div>
                                                                                    <Controller
                                                                                        name="name"
                                                                                        control={control}
                                                                                        defaultValue=""
                                                                                        rules={{ required: " Name is required " }}
                                                                                        render={({ field }) => (
                                                                                            <input
                                                                                                placeholder="Enter name"
                                                                                                type="text"
                                                                                                {...field}
                                                                                                className="sml-inp"
                                                                                            />
                                                                                        )}
                                                                                    />
                                                                                    <FormHelperText className="text-danger">
                                                                                        {errors.name && errors.name.message}
                                                                                    </FormHelperText>
                                                                                </div>
                                                                                <Button type="submit">
                                                                                    <DoneOutlineIcon
                                                                                        sx={{ fontSize: "16px", cursor: "pointer" }}
                                                                                    />
                                                                                </Button>
                                                                            </div>
                                                                        </div>
                                                                    ) : (
                                                                        <span className="men-prof-name">
                                                                            <h4 className="font-weight-bold">
                                                                                {formData.name}
                                                                                <span>
                                                                                    <img
                                                                                        src={"/images/verified-tick.png"}
                                                                                        alt="verified"
                                                                                    />
                                                                                </span>
                                                                            </h4>
                                                                            {editBtn === true && (
                                                                                <EditIcon
                                                                                    sx={{
                                                                                        fontSize: "14px",
                                                                                        cursor: "pointer",
                                                                                        marginLeft: "5px",
                                                                                    }}
                                                                                    onClick={() => handleEditBtn("name")}
                                                                                />
                                                                            )}
                                                                        </span>
                                                                    )}
                                                                </h4>

                                                            </div>)}
                                                    {
                                                        roleTypes === 'GOVT-AGENCY' && (
                                                            <>
                                                                <div className="brand-details ml-3">
                                                                    <h4 className="font-weight-bold">
                                                                        {editKey === "name" ? (
                                                                            <div className="d-inline-block">
                                                                                <div className="d-flex align-items-start">
                                                                                    <div>
                                                                                        <Controller
                                                                                            name="agencyorpsuName"
                                                                                            control={control}
                                                                                            defaultValue=""
                                                                                            // rules={{ required: "Agency/PSU Name is required" }}
                                                                                            rules={{
                                                                                                required: 'Agency/PSU is required',
                                                                                            }}
                                                                                            render={({ field }) => (
                                                                                                <TextField
                                                                                                    variant="outlined"
                                                                                                    label="Agency/PSU Name*"
                                                                                                    placeholder="Enter Agency/PSU Name"
                                                                                                    fullWidth
                                                                                                    type="text"
                                                                                                    {...field}
                                                                                                    error={Boolean(errors.agencyorpsuName && errors.agencyorpsuName)}
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                        <FormHelperText className="text-danger">
                                                                                            {errors.agencyorpsuName && errors.agencyorpsuName.message}
                                                                                        </FormHelperText>
                                                                                    </div>
                                                                                    <Button type="submit">
                                                                                        <DoneOutlineIcon
                                                                                            sx={{ fontSize: "16px", cursor: "pointer" }}
                                                                                        />
                                                                                    </Button>
                                                                                </div>
                                                                            </div>
                                                                        ) : (
                                                                            <span className="men-prof-name">
                                                                                <h4 className="font-weight-bold">
                                                                                    {formData.agencyorpsuName}
                                                                                    <span>
                                                                                        <img
                                                                                            src={"/images/verified-tick.png"}
                                                                                            alt="verified"
                                                                                        />
                                                                                    </span>
                                                                                </h4>
                                                                                {editBtn === true && (
                                                                                    <EditIcon
                                                                                        sx={{
                                                                                            fontSize: "14px",
                                                                                            cursor: "pointer",
                                                                                            marginLeft: "5px",
                                                                                        }}
                                                                                        onClick={() => handleEditBtn("name")}
                                                                                    />
                                                                                )}
                                                                            </span>
                                                                        )}
                                                                    </h4>

                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                    <span style={{ fontSize: "12px", color: "gray" }}>
                                                        {formData.district}, {formData.state}
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <img
                                                        className="user-cardlogo"
                                                        src={"/images/debitCrd.png"}
                                                        alt="bankCards"
                                                    />
                                                </div>
                                                <div className="brand-details ml-3">
                                                    <div>
                                                        <p className="par m-0">UID No.</p>

                                                        <span
                                                            style={{
                                                                color: "#020671",
                                                                fontSize: "16px",
                                                                fontWeight: "600",
                                                                cursor: 'pointer'
                                                            }}
                                                            onClick={handleClickOpen}
                                                        >
                                                            {formData.uid}
                                                        </span>
                                                        {(userProfileId === 0) && (
                                                            <div>

                                                                {(formData.smartcardstatus === 0) && (
                                                                    <p className="avail-smart-style" onClick={() => availsmartopen()}>Avail SmartCard</p>)}
                                                                {(formData.smartcardstatus === 1) && (<p className="avail-smart-style-p">Requested</p>)}
                                                                {(formData.smartcardstatus === 2) && (<p className="avail-smart-style-p">Under Process</p>)}
                                                                {(formData.smartcardstatus === 3) && (<p className="avail-smart-style-p" style={{ color: 'green' }}>Availed</p>)}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="d-flex align-items-center justify-content-end">
                                                    <div className="mr-2">
                                                        <span style={{ fontSize: "10px" }}>
                                                            Engagement Level :
                                                            <span
                                                                style={{
                                                                    color: "#020671",
                                                                    fontWeight: "600",
                                                                    fontSize: "12px",
                                                                }}
                                                            >
                                                                ACE
                                                            </span>
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <span style={{ fontSize: "10px" }}>
                                                            Member Since :
                                                            <span
                                                                style={{
                                                                    color: "#020671",
                                                                    fontWeight: "600",
                                                                    fontSize: "12px",
                                                                }}
                                                            >
                                                                {formData.memberSince}
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="det-flex mt-3">
                                                    <div className="mobile-resp">
                                                        <span className="ml-2" style={{ fontSize: "14px" }}>
                                                            {editKey === "linkedIn" ? (
                                                                <div className="d-inline-block">
                                                                    <div className="d-flex align-items-start">
                                                                        <div>
                                                                            <FormControl sx={{ width: 150 }}>
                                                                                <Controller
                                                                                    name="linkedIn"
                                                                                    control={control}
                                                                                    defaultValue=""
                                                                                    // rules={{
                                                                                    //     value: linkedInPattern,
                                                                                    //     message: "Invalid LinkedIn profile URL"
                                                                                    // }}
                                                                                    render={({ field }) => (
                                                                                        <TextField
                                                                                            variant="outlined"
                                                                                            label="LinkedIn"
                                                                                            placeholder="Enter LinkedIn"
                                                                                            fullWidth
                                                                                            type="text"
                                                                                            {...field}
                                                                                        />
                                                                                    )}
                                                                                />
                                                                                <FormHelperText className="text-danger">
                                                                                    {errors.linkedIn && errors.linkedIn.message}
                                                                                </FormHelperText>
                                                                            </FormControl>
                                                                        </div>
                                                                        <Button type="submit">
                                                                            <DoneOutlineIcon
                                                                                sx={{
                                                                                    fontSize: "16px",
                                                                                    cursor: "pointer",
                                                                                }}
                                                                            />
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <span>
                                                                    <a
                                                                        href={formData.linkedIn}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                    >
                                                                        <img src="/images/linkedin-logo.png" alt="linkedin logo" />
                                                                    </a>
                                                                    {/* {formData.linkedIn}{" "} */}
                                                                    {editBtn === true && (
                                                                        <EditIcon
                                                                            sx={{
                                                                                fontSize: "14px",
                                                                                cursor: "pointer",
                                                                            }}
                                                                            onClick={() =>
                                                                                handleEditBtn("linkedIn")
                                                                            }
                                                                        />
                                                                    )}
                                                                </span>
                                                            )}
                                                        </span>
                                                    </div>

                                                    <div className="mobile-resp">
                                                        <div>
                                                            <FaPhoneAlt
                                                                className="ml-2"
                                                                style={{
                                                                    color: "#020671",
                                                                }}
                                                            />
                                                        </div>
                                                        <div>
                                                            <span className="ml-2" style={{ fontSize: "14px" }}>
                                                                {editKey === "phone" ? (
                                                                    <div className="d-inline-block">
                                                                        <div className="d-flex align-items-start">
                                                                            <div>
                                                                                <FormControl sx={{ width: 150 }}>
                                                                                    <Controller
                                                                                        name="phone"
                                                                                        control={control}
                                                                                        defaultValue=""
                                                                                        rules={{
                                                                                            required: `Phone is required`,
                                                                                            maxLength: {
                                                                                                value: 10,
                                                                                                validate: validatePhoneNumber,
                                                                                            },
                                                                                            minLength: {
                                                                                                value: 10,
                                                                                                validate: validatePhoneNumber,
                                                                                            },
                                                                                        }}
                                                                                        render={({ field }) => (
                                                                                            <TextField
                                                                                                variant="outlined"
                                                                                                label="Phone Number *"
                                                                                                placeholder="Enter Phone Number"
                                                                                                fullWidth
                                                                                                type="text"
                                                                                                {...field}
                                                                                                onKeyDown={(e) =>
                                                                                                    validateNumberonly(e)
                                                                                                }
                                                                                                inputProps={{ maxLength: 10 }}
                                                                                            />
                                                                                        )}
                                                                                    />
                                                                                    <FormHelperText className="text-danger">
                                                                                        {errors.phone && errors.phone.message}
                                                                                    </FormHelperText>
                                                                                </FormControl>
                                                                            </div>
                                                                            <Button type="submit">
                                                                                <DoneOutlineIcon
                                                                                    sx={{
                                                                                        fontSize: "16px",
                                                                                        cursor: "pointer",
                                                                                    }}
                                                                                />
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <span>
                                                                        {" "}
                                                                        {/* {formData.phone}{" "} */}
                                                                        {
                                                                            showViewbtn !== 0 ? (
                                                                                <span className="blur_css">+91 XXXXX-XXXXX</span>
                                                                            ) : (
                                                                                <span>+91 {formData.phone}</span>
                                                                            )
                                                                        }
                                                                        {editBtn === true && (
                                                                            <EditIcon
                                                                                sx={{
                                                                                    fontSize: "14px",
                                                                                    cursor: "pointer",
                                                                                }}
                                                                                onClick={() =>
                                                                                    handleEditBtn("phone")
                                                                                }
                                                                            />
                                                                        )}
                                                                    </span>
                                                                )}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="mobile-resp">
                                                        <div>
                                                            <MdOutlineMail
                                                                className="ml-2"
                                                                style={{
                                                                    color: "#020671",
                                                                }}
                                                            />
                                                        </div>
                                                        <div>
                                                            <span className="ml-2" style={{ fontSize: "14px" }}>
                                                                {/* {formData.email} */}
                                                                {
                                                                    showViewbtn !== 0 ? (
                                                                        <span className="blur_css ml-2" style={{ fontSize: "14px" }}>XXXXXXXXXXX</span>
                                                                    ) : (
                                                                        <span className="ml-2" style={{ fontSize: "14px" }}>{formData.email}</span>
                                                                    )
                                                                }
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="mobile-resp">
                                                        {/* <div>
                                                            <CiGlobe
                                                                className="ml-2"
                                                                style={{
                                                                    color: "#020671",
                                                                }}
                                                            />
                                                        </div> */}

                                                        <div>
                                                            <span className="ml-2" style={{ fontSize: "14px" }}>
                                                                {editKey === "website" ? (
                                                                    <div className="d-inline-block">
                                                                        <div className="d-flex align-items-start">
                                                                            <div>
                                                                                <Controller
                                                                                    name="website"
                                                                                    control={control}
                                                                                    defaultValue=""
                                                                                    rules={{
                                                                                        required: false,
                                                                                    }}
                                                                                    render={({ field }) => (
                                                                                        <input
                                                                                            placeholder="Enter Website"
                                                                                            type="text"
                                                                                            {...field}
                                                                                            className="sml-inp"
                                                                                        />
                                                                                    )}
                                                                                />
                                                                                <FormHelperText className="text-danger">
                                                                                    {errors.website &&
                                                                                        errors.website.message}
                                                                                </FormHelperText>
                                                                            </div>
                                                                            <Button type="submit">
                                                                                <DoneOutlineIcon
                                                                                    sx={{
                                                                                        fontSize: "16px",
                                                                                        cursor: "pointer",
                                                                                    }}
                                                                                />
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <span>
                                                                        <a
                                                                            href={formData.website}
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                        >
                                                                            <img src="/images/icon/www.png" alt="website logo" style={{ width: "100%", maxWidth: '33px' }} />
                                                                        </a>
                                                                        {editBtn === true && (
                                                                            <EditIcon
                                                                                sx={{
                                                                                    fontSize: "14px",
                                                                                    cursor: "pointer",
                                                                                }}
                                                                                onClick={() =>
                                                                                    handleEditBtn("website")
                                                                                }
                                                                            />
                                                                        )}
                                                                    </span>
                                                                )}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                {showViewbtn !== 0 && (
                                                    <div className="text-right mt-3">
                                                        <Button
                                                            variant="outlined"
                                                            className="edit-btn-profile"
                                                            sx={{
                                                                textTransform: "none",
                                                                border: "1px solid",
                                                                borderColor: "#020671",
                                                                color: "#020671",
                                                                padding: "6px 30px",
                                                            }}
                                                            onClick={handlemaskClickOpen}
                                                        >
                                                            View Contact
                                                        </Button>
                                                    </div>
                                                )}
                                                {/* {userProfileId === 0 && (
                                                    <div className="profile-btn  mt-3">
                                                        <div className="mr-3 edit-btn-res">
                                                            {editBtn === false && (
                                                                <Button
                                                                    variant="outlined"
                                                                    className="edit-btn-profile"
                                                                    sx={{
                                                                        textTransform: "none",
                                                                        border: "1px solid",
                                                                        borderColor: "#020671",
                                                                        color: "#020671",
                                                                        padding: "6px 20px",
                                                                    }}
                                                                    onClick={handleOpenEdit}
                                                                >
                                                                    Edit Profile
                                                                </Button>
                                                            )}
                                                            {editBtn === true && (
                                                                <Button
                                                                    variant="outlined"
                                                                    className="edit-btn-profile"
                                                                    sx={{
                                                                        textTransform: "none",
                                                                        border: "1px solid",
                                                                        borderColor: "#020671",
                                                                        color: "#020671",
                                                                        padding: "6px 20px",
                                                                    }}
                                                                    type="submit"
                                                                >
                                                                    Save Profile
                                                                </Button>
                                                            )}
                                                        </div>
                                                    </div>
                                                )} */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pb-5">
                                        <Grid
                                            container
                                            direction="row"
                                            alignItems="flex-start"
                                            justifyContent="center"
                                            spacing={5}
                                        >
                                            <Grid item xs={12} sm={12}>
                                                <div className="mt-2">
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justifyContent="space-around"
                                                        alignItems="flex-start"
                                                        spacing={1}
                                                    >
                                                        {(roleTypes !== 'MEDIA-AGENCY' && roleTypes !== "EDU-INST") && (
                                                            <>
                                                                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                                                    <div className="profile-box1">
                                                                        <div>
                                                                            <h6 style={{ fontWeight: "600" }}>Key Highlights</h6>
                                                                            <ul className="p-0">
                                                                                {(
                                                                                    roleTypes === 'INCUBATOR' ||
                                                                                    roleTypes === 'ACCELARATOR' ||
                                                                                    roleTypes === 'BANK' ||

                                                                                    roleTypes === 'BLOGGER-INFLUENCER' ||
                                                                                    roleTypes === 'NGO'
                                                                                ) && (
                                                                                        <li className="d-flex align-items-start justify-content-start">
                                                                                            <div className="key-img mt-2">
                                                                                                <img src="/images/FocussedSector.png" alt="pieChart" />
                                                                                            </div>
                                                                                            <div className="ml-3">
                                                                                                <span className="list-span">Focussed Sector</span>
                                                                                                <p className="list-para">
                                                                                                    {formData.focusedSector ? (
                                                                                                        formData.focusedSector.map((item, index) => (
                                                                                                            <React.Fragment key={index}>
                                                                                                                {index > 0 && ", "}
                                                                                                                {item}
                                                                                                            </React.Fragment>
                                                                                                        ))
                                                                                                    ) : (
                                                                                                        "Focused sectors not available"
                                                                                                    )}
                                                                                                </p>
                                                                                            </div>
                                                                                        </li>
                                                                                    )}
                                                                                {(
                                                                                    roleTypes === 'INCUBATOR' ||
                                                                                    roleTypes === 'ACCELARATOR' ||
                                                                                    roleTypes === 'BANK' ||
                                                                                    roleTypes === 'MEDIA-AGENCY' ||
                                                                                    roleTypes === 'BLOGGER-INFLUENCER' ||
                                                                                    roleTypes === 'NGO'
                                                                                ) && (
                                                                                        <li className="d-flex align-items-start justify-content-start">
                                                                                            <div className="key-img mt-2">
                                                                                                <img src={"/images/PreferredStage.png"} alt="pieChart" />
                                                                                            </div>
                                                                                            <div className="ml-3">
                                                                                                <>
                                                                                                    <span className="list-span">Preferred Stage</span>
                                                                                                </>
                                                                                                <>
                                                                                                    <p className="list-para">

                                                                                                        {formData.preferredStage ? (
                                                                                                            <>
                                                                                                                {formData.preferredStage.map((item, index) => (
                                                                                                                    <React.Fragment key={index}>
                                                                                                                        {index > 0 && ", "}
                                                                                                                        {item}
                                                                                                                    </React.Fragment>
                                                                                                                ))}
                                                                                                            </>
                                                                                                        ) : (
                                                                                                            <>
                                                                                                                -
                                                                                                            </>
                                                                                                        )}
                                                                                                    </p>
                                                                                                </>
                                                                                            </div>
                                                                                        </li>)}
                                                                                {(roleTypes === 'INCUBATOR' || roleTypes === 'ACCELARATOR' || roleTypes === 'BANK' || roleTypes === 'MEDIA-AGENCY' ||
                                                                                    roleTypes === 'BLOGGER-INFLUENCER' || roleTypes === 'NGO') && (
                                                                                        <li className="d-flex align-items-start justify-content-start">
                                                                                            <div className="key-img mt-2">
                                                                                                <img src={"/images/NatureEntity.png"} alt="pieChart" />
                                                                                            </div>
                                                                                            <div className="ml-3">
                                                                                                <>
                                                                                                    <span className="list-span">Nature of Entity</span>
                                                                                                </>
                                                                                                <>
                                                                                                    <p className="list-para">{formData.natureOfEntity ? formData.natureOfEntity : '-'}</p>
                                                                                                </>
                                                                                            </div>
                                                                                        </li>)}
                                                                                {(roleTypes === 'INCUBATOR' || roleTypes === 'ACCELARATOR' || roleTypes === 'BANK' || roleTypes === 'MEDIA-AGENCY' ||
                                                                                    roleTypes === 'BLOGGER-INFLUENCER' || roleTypes === 'NGO') && (<li className="d-flex align-items-start justify-content-start">
                                                                                        <div className="key-img mt-2">
                                                                                            <img src={"/images/AvailableFacilities.png"} alt="pieChart" />
                                                                                        </div>
                                                                                        <div className="ml-3">
                                                                                            <>
                                                                                                <span className="list-span">Available Facilities</span>
                                                                                            </>
                                                                                            <>
                                                                                                <p className="list-para">-</p>
                                                                                            </>
                                                                                        </div>
                                                                                    </li>)}
                                                                                {(roleTypes === 'ACCELARATOR' && (
                                                                                    <li className="d-flex align-items-start justify-content-start">
                                                                                        <div className="key-img mt-2">
                                                                                            <img
                                                                                                src={"/images/funding-ticket.png "}
                                                                                                alt="pieChart"
                                                                                            />
                                                                                        </div>
                                                                                        <div className="ml-3">
                                                                                            <>
                                                                                                <span className="list-span">Fund Ticket Size</span>
                                                                                            </>
                                                                                            <>
                                                                                                <p className="list-para">
                                                                                                    {" "}
                                                                                                    {formData.fundingTicketSize ? formData.fundingTicketSize : '-'}
                                                                                                </p>
                                                                                            </>
                                                                                        </div>
                                                                                    </li>))}
                                                                                {(roleTypes !== 'EDU-INST' && (
                                                                                    <li className="d-flex align-items-start justify-content-start">
                                                                                        <div className="key-img mt-2">
                                                                                            <img
                                                                                                src={"/images/avability.png"}
                                                                                                alt="pieChart"
                                                                                            />
                                                                                        </div>
                                                                                        <div className="ml-3">
                                                                                            <>
                                                                                                <span className="list-span">Incubation availability</span>
                                                                                            </>
                                                                                            <>
                                                                                                <p className="list-para">
                                                                                                    {" "}
                                                                                                    {formData.available ? formData.available : '-'}
                                                                                                </p>
                                                                                            </>
                                                                                        </div>
                                                                                    </li>))}
                                                                                {(roleTypes !== 'EDU-INST' && (
                                                                                    <li className="d-flex align-items-start justify-content-start">
                                                                                        <div className="key-img mt-2">
                                                                                            <img
                                                                                                src={"/images/incubation name.png "}
                                                                                                alt="pieChart"
                                                                                            />
                                                                                        </div>
                                                                                        <div className="ml-3">
                                                                                            <>
                                                                                                <span className="list-span">Incubation Name</span>
                                                                                            </>
                                                                                            <>
                                                                                                <p className="list-para">
                                                                                                    {" "}
                                                                                                    {formData.incubationName ? formData.incubationName : '-'}
                                                                                                </p>
                                                                                            </>
                                                                                        </div>
                                                                                    </li>))}
                                                                                {(roleTypes === 'GOVT-AGENCY' && (
                                                                                    <li className="d-flex align-items-start justify-content-start">
                                                                                        <div className="key-img mt-2">
                                                                                            <img
                                                                                                src={"/images/incubation name.png "}
                                                                                                alt="pieChart"
                                                                                            />
                                                                                        </div>
                                                                                        <div className="ml-3">
                                                                                            <>
                                                                                                <span className="list-span">Department</span>
                                                                                            </>
                                                                                            <>
                                                                                                <p className="list-para">
                                                                                                    {" "}
                                                                                                    {formData.department ? formData.department : '-'}
                                                                                                </p>
                                                                                            </>
                                                                                        </div>
                                                                                    </li>))}
                                                                                {(roleTypes === 'CORPORATE' && (
                                                                                    <li className="d-flex align-items-start justify-content-start">
                                                                                        <div className="key-img mt-2">
                                                                                            <img
                                                                                                src={"/images/incubation name.png "}
                                                                                                alt="pieChart"
                                                                                            />
                                                                                        </div>
                                                                                        <div className="ml-3">
                                                                                            <>
                                                                                                <span className="list-span">Industry</span>
                                                                                            </>
                                                                                            <>
                                                                                                <p className="list-para">
                                                                                                    {" "}
                                                                                                    {formData.industry ? formData.industry : '-'}
                                                                                                </p>
                                                                                            </>
                                                                                        </div>
                                                                                    </li>))}
                                                                                <li className="d-flex align-items-start justify-content-start">
                                                                                    <div className="key-img mt-2">
                                                                                        <img
                                                                                            src={"/images/Year.png "}
                                                                                            alt="pieChart"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="ml-3">
                                                                                       {roleTypes!== 'EDU-INST' && (
                                                                                        <> <>
                                                                                            <span className="list-span">Year of Registration</span>
                                                                                        </>
                                                                                        <>
                                                                                            <p className="list-para">
                                                                                                {" "}
                                                                                                {formData.yearOfReg ? formData.yearOfReg : '-'}
                                                                                            </p>
                                                                                        </>
                                                                                        </>
                                                                                        )}
                                                                                    </div>
                                                                                </li>
                                                                                {(roleTypes === 'CORPORATE' && (
                                                                                    <li className="d-flex align-items-start justify-content-start">
                                                                                        <div className="key-img mt-2">
                                                                                            <img
                                                                                                src={"/images/incubation name.png "}
                                                                                                alt="pieChart"
                                                                                            />
                                                                                        </div>
                                                                                        <div className="ml-3">
                                                                                            <>
                                                                                                <span className="list-span">S2C</span>
                                                                                            </>
                                                                                            <>
                                                                                                <p className="list-para">
                                                                                                    {" "}
                                                                                                    {formData.interestToConductS2c ? formData.interestToConductS2c : '-'}
                                                                                                </p>
                                                                                            </>
                                                                                        </div>
                                                                                    </li>))}
                                                                                {(roleTypes === 'CORPORATE' && (
                                                                                    <li className="d-flex align-items-start justify-content-start">
                                                                                        <div className="key-img mt-2">
                                                                                            <img
                                                                                                src={"/images/open-innovation-profile.png "}
                                                                                                alt="pieChart"
                                                                                            />
                                                                                        </div>
                                                                                        <div className="ml-3">
                                                                                            <>
                                                                                                <span className="list-span">Open Innovation Challenges</span>
                                                                                            </>
                                                                                            <>
                                                                                                <p className="list-para">
                                                                                                    {" "}
                                                                                                    {formData.challenges ? formData.challenges : '-'}
                                                                                                </p>
                                                                                            </>
                                                                                        </div>
                                                                                    </li>))}
                                                                                {(roleTypes === 'CORPORATE' && (
                                                                                    <li className="d-flex align-items-start justify-content-start">
                                                                                        <div className="key-img mt-2">
                                                                                            <img
                                                                                                src={"/images/csr-fund-profile.png"}
                                                                                                alt="pieChart"
                                                                                            />
                                                                                        </div>
                                                                                        <div className="ml-3">
                                                                                            <>
                                                                                                <span className="list-span">CSR Fund Allocation</span>
                                                                                            </>
                                                                                            <>
                                                                                                <p className="list-para">
                                                                                                    {" "}
                                                                                                    {formData.csrFund ? formData.csrFund : '-'}
                                                                                                </p>
                                                                                            </>
                                                                                        </div>
                                                                                    </li>))}
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </Grid></>
                                                        )}

                                                        <Grid item xs={12} sm={12} md={(roleTypes === 'MEDIA-AGENCY' || roleTypes === 'EDU-INST' ) ? 12 : 9} lg={(roleTypes === 'MEDIA-AGENCY' || roleTypes === 'EDU-INST' )  ? 12 : 9} xl={(roleTypes === 'MEDIA-AGENCY' || roleTypes === 'EDU-INST' )  ? 12 : 9}>
                                                            <div className="profile-box2">
                                                                <div className="invest-edit-abt">
                                                                    <div>
                                                                        <h5>About</h5>
                                                                    </div>
                                                                    <div>
                                                                        {editKey === "about" ? (
                                                                            <div>
                                                                                {" "}
                                                                                <Button type="submit">
                                                                                    <DoneOutlineIcon
                                                                                        sx={{ fontSize: "16px", cursor: "pointer" }}
                                                                                    />
                                                                                </Button>
                                                                            </div>
                                                                        ) : (
                                                                            <>
                                                                                {editBtn === true && (
                                                                                    <EditIcon
                                                                                        sx={{
                                                                                            fontSize: "14px",
                                                                                            cursor: "pointer",
                                                                                            marginLeft: "5px",
                                                                                        }}
                                                                                        onClick={() => handleEditBtn("about")}
                                                                                    />
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </div>

                                                                {
                                                                    roleTypes === 'MEDIA-AGENCY' ? (
                                                                        <>
                                                                            {editKey === "about" ? (
                                                                                <div className="d-inline-block">
                                                                                    <div className="d-flex align-items-start">
                                                                                        <div>
                                                                                            <Controller
                                                                                                name="organizationBrief"
                                                                                                control={control}
                                                                                                defaultValue=""
                                                                                                rules={{ required: `Organization Brief is required` }}
                                                                                                render={({ field }) => (
                                                                                                    <textarea
                                                                                                        type="text"
                                                                                                        {...field}
                                                                                                        className="invest-area"
                                                                                                    />
                                                                                                )}
                                                                                            />
                                                                                            <FormHelperText className="text-danger">
                                                                                                {errors.organizationBrief && errors.organizationBrief.message}
                                                                                            </FormHelperText>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ) : (
                                                                                <p className="para">
                                                                                    {formData.organizationBrief}
                                                                                </p>
                                                                            )}</>
                                                                    ) : (
                                                                        <>
                                                                            {editKey === "about" ? (
                                                                                <div className="d-inline-block">
                                                                                    <div className="d-flex align-items-start">
                                                                                        <div>
                                                                                            <Controller
                                                                                                name="about"
                                                                                                control={control}
                                                                                                defaultValue=""
                                                                                                rules={{ required: `Brief is required` }}
                                                                                                render={({ field }) => (
                                                                                                    <textarea
                                                                                                        type="text"
                                                                                                        {...field}
                                                                                                        className="invest-area"
                                                                                                    />
                                                                                                )}
                                                                                            />
                                                                                            <FormHelperText className="text-danger">
                                                                                                {errors.about && errors.about.message}
                                                                                            </FormHelperText>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ) : (
                                                                                <p className="para">
                                                                                    {displayText}
                                                                                    {isTruncated && (
                                                                                        <span
                                                                                            onClick={toggleReadMore}
                                                                                            style={{ cursor: "pointer", color: "#7F9AD2" }}
                                                                                        >
                                                                                            {showFull ? " Read Less" : " Read More..."}
                                                                                        </span>
                                                                                    )}
                                                                                </p>
                                                                            )}
                                                                        </>
                                                                    )
                                                                }
                                                            </div>
                                                            {(roleTypes === "MEDIA-AGENCY" ||
                                                                roleTypes === "BLOGGER-INFLUENCER" ||
                                                                roleTypes === "NGO") && (
                                                                    <>
                                                            {/* {(userProfileId === 0) && ( */}
                                                                <div className="profile-box2 mt-2">
                                                                    <div className="found-add-btn-prof">
                                                                        <div className="d-flex align-items-start justify-content-between">
                                                                            <h5 style={{ fontWeight: "600" }}>Team</h5>
                                                                            {addbtnShow && (
                                                                                <div>
                                                                                    {
                                                                                        // editBtn === true && (
                                                                                        (userProfileId === 0) && (
                                                                                            <div className="text-right mb-3">
                                                                                                <Button
                                                                                                    variant="outlined"
                                                                                                    onClick={() => addFounderBtn()}
                                                                                                >
                                                                                                    +
                                                                                                </Button>
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                            )

                                                                            }
                                                                        </div>
                                                                    </div>

                                                                    <div style={{ width: "100%", overflowX: "auto" }}>
                                                                        <table
                                                                            className="table"
                                                                            style={{ width: "100%", borderCollapse: "collapse" }}
                                                                        >
                                                                            <tbody>
                                                                                {founderFields &&
                                                                                    founderFields.map((field, i) => (
                                                                                        <>
                                                                                            {
                                                                                                editKey === i ? (
                                                                                                    <>
                                                                                                        <tr key={i}>
                                                                                                            <td
                                                                                                                style={{
                                                                                                                    padding: "0.75rem",
                                                                                                                    verticalAlign: "top",
                                                                                                                }}
                                                                                                            >
                                                                                                                <div className="d-flex align-items-center founder-profile-img">
                                                                                                                    <div>
                                                                                                                        <div className="logo_div">
                                                                                                                            <img
                                                                                                                                src={getValues(`founders.${i}.profileUrl`) ? getValues(`founders.${i}.profileUrl`) : "/images/user.png"}
                                                                                                                                className="founder-logo img-fluid"
                                                                                                                                alt={'name'}
                                                                                                                            />
                                                                                                                            <div className="camera_icon">
                                                                                                                                <Controller
                                                                                                                                    name={`founders.${i}.profileUrl`}
                                                                                                                                    control={control}
                                                                                                                                    defaultValue=""
                                                                                                                                    rules={{ required: false }}
                                                                                                                                    render={({ field }) => (
                                                                                                                                        <Button
                                                                                                                                            {...field}
                                                                                                                                            onChange={(e) =>
                                                                                                                                                handleFileUpload(e, `founders.${i}.profile`, `founders.${i}.profileName`, `founders.${i}.profileUrl`)
                                                                                                                                            }
                                                                                                                                            component="label"
                                                                                                                                            startIcon={<CameraAltIcon />}
                                                                                                                                        >
                                                                                                                                            <VisuallyHiddenInput type="file" />
                                                                                                                                        </Button>
                                                                                                                                    )}
                                                                                                                                />
                                                                                                                            </div>

                                                                                                                        </div>
                                                                                                                        <FormHelperText className="text-danger">
                                                                                                                            {errors.founders?.[i]?.profileUrl &&
                                                                                                                                errors.founders?.[i]?.profileUrl.message}
                                                                                                                        </FormHelperText>
                                                                                                                    </div>

                                                                                                                    <div className="ml-3">
                                                                                                                        <p className="list-para mb-0">
                                                                                                                            <div>
                                                                                                                                <FormControl sx={{ width: '200px', marginTop: '5px' }}>
                                                                                                                                    <Controller
                                                                                                                                        name={`founders.${i}.name`}
                                                                                                                                        control={control}
                                                                                                                                        defaultValue=""
                                                                                                                                        rules={{ required: `Name is required` }}
                                                                                                                                        render={({ field }) => (
                                                                                                                                            <TextField
                                                                                                                                                label="Name *"
                                                                                                                                                placeholder="Enter name"
                                                                                                                                                type="text"
                                                                                                                                                {...field}
                                                                                                                                                size="small"
                                                                                                                                                error={errors.founders?.[i]?.name &&
                                                                                                                                                    errors.founders?.[i]?.name}
                                                                                                                                            />
                                                                                                                                        )}
                                                                                                                                    />
                                                                                                                                    <FormHelperText className="text-danger">
                                                                                                                                        {errors.founders?.[i]?.name &&
                                                                                                                                            errors.founders?.[i]?.name.message}
                                                                                                                                    </FormHelperText>
                                                                                                                                </FormControl>
                                                                                                                            </div>
                                                                                                                        </p>

                                                                                                                        <p className="paras mb-0">
                                                                                                                            <div>
                                                                                                                                <Controller
                                                                                                                                    name={`founders.${i}.designation`}
                                                                                                                                    control={control}
                                                                                                                                    defaultValue=""
                                                                                                                                    rules={{ required: true }}
                                                                                                                                    render={({
                                                                                                                                        field,
                                                                                                                                        fieldState: { error },
                                                                                                                                    }) => (
                                                                                                                                        <Autocomplete
                                                                                                                                            disableClearable
                                                                                                                                            size="small"
                                                                                                                                            {...field}
                                                                                                                                            options={designation}
                                                                                                                                            onChange={(event, newValue) =>
                                                                                                                                                handleSelectDesignation(newValue, i)
                                                                                                                                            }
                                                                                                                                            renderInput={(params) => (
                                                                                                                                                <TextField
                                                                                                                                                    {...params}
                                                                                                                                                    label="Designation *"
                                                                                                                                                    placeholder="Select Designation"
                                                                                                                                                    error={!!error}
                                                                                                                                                    helperText={
                                                                                                                                                        error
                                                                                                                                                            ? "Designation is required"
                                                                                                                                                            : ""
                                                                                                                                                    }
                                                                                                                                                />
                                                                                                                                            )}
                                                                                                                                            fullWidth
                                                                                                                                            margin="normal"
                                                                                                                                            ListboxProps={{
                                                                                                                                                style: { textAlign: 'left' }
                                                                                                                                            }}
                                                                                                                                        />
                                                                                                                                    )}
                                                                                                                                />
                                                                                                                                <FormHelperText className="text-danger">
                                                                                                                                    {errors.founders?.[i]?.designation &&
                                                                                                                                        errors.founders?.[i]?.designation
                                                                                                                                            .message}
                                                                                                                                </FormHelperText>

                                                                                                                            </div>
                                                                                                                            <div className="d-flex align-items-center">
                                                                                                                                <Controller
                                                                                                                                    name={`founders.${i}.physicallyChallenged`}
                                                                                                                                    control={control}
                                                                                                                                    defaultValue=""
                                                                                                                                    rules={{ required: false }}
                                                                                                                                    render={({ field }) => (
                                                                                                                                        <FormControlLabel
                                                                                                                                            {...field}
                                                                                                                                            control={
                                                                                                                                                <Checkbox
                                                                                                                                                    value={field.value}
                                                                                                                                                    checked={field.value}
                                                                                                                                                />
                                                                                                                                            }
                                                                                                                                            label="Physically Challenged"
                                                                                                                                        />
                                                                                                                                    )}
                                                                                                                                />
                                                                                                                            </div>
                                                                                                                        </p>

                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </td>
                                                                                                            <td
                                                                                                                style={{
                                                                                                                    padding: "0.75rem",
                                                                                                                    verticalAlign: "top",
                                                                                                                }}
                                                                                                            >
                                                                                                                <div>
                                                                                                                    <div>
                                                                                                                        <FormControl sx={{ width: '200px', marginTop: '5px' }}>
                                                                                                                            <Controller
                                                                                                                                name={`founders.${i}.linkedin`}
                                                                                                                                control={control}
                                                                                                                                defaultValue=""
                                                                                                                                rules={{
                                                                                                                                    required: false,
                                                                                                                                    // pattern: {
                                                                                                                                    //   value: linkedInPattern,
                                                                                                                                    //   message: "Invalid LinkedIn profile URL",
                                                                                                                                    // },
                                                                                                                                }}
                                                                                                                                render={({ field }) => (
                                                                                                                                    <TextField
                                                                                                                                        label="LinkedIn"
                                                                                                                                        placeholder="Enter LinkedIn"
                                                                                                                                        type="text"
                                                                                                                                        {...field}
                                                                                                                                        size="small"
                                                                                                                                        error={errors.founders?.[i]?.linkedin &&
                                                                                                                                            errors.founders?.[i]?.linkedin}
                                                                                                                                    />
                                                                                                                                )}
                                                                                                                            />
                                                                                                                            <FormHelperText className="text-danger">
                                                                                                                                {errors.founders?.[i]?.linkedin &&
                                                                                                                                    errors.founders?.[i]?.linkedin.message}
                                                                                                                            </FormHelperText>
                                                                                                                        </FormControl>
                                                                                                                    </div>
                                                                                                                    <div>

                                                                                                                        <Controller
                                                                                                                            name={`founders.${i}.gender`}
                                                                                                                            control={control}
                                                                                                                            defaultValue=""
                                                                                                                            rules={{ required: true }}
                                                                                                                            render={({
                                                                                                                                field: { onChange, value },
                                                                                                                                fieldState: { error },
                                                                                                                            }) => (
                                                                                                                                <Autocomplete
                                                                                                                                    disableClearable
                                                                                                                                    disablePortal
                                                                                                                                    options={gender}
                                                                                                                                    getOptionLabel={(option) => option}
                                                                                                                                    value={value || []}
                                                                                                                                    onChange={(event, newValue) =>
                                                                                                                                        onChange(newValue)
                                                                                                                                    }
                                                                                                                                    size="small"
                                                                                                                                    renderInput={(params) => (
                                                                                                                                        <TextField
                                                                                                                                            {...params}
                                                                                                                                            label="Gender *"
                                                                                                                                            placeholder="Select Gender"
                                                                                                                                            error={!!error}
                                                                                                                                            helperText={
                                                                                                                                                error ? "Gender is required" : ""
                                                                                                                                            }
                                                                                                                                        />
                                                                                                                                    )}

                                                                                                                                    margin="normal"
                                                                                                                                />
                                                                                                                            )}
                                                                                                                        />
                                                                                                                        <FormHelperText className="text-danger">
                                                                                                                            {errors.founders?.[i]?.gender &&
                                                                                                                                errors.founders?.[i]?.message}
                                                                                                                        </FormHelperText>

                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </td>
                                                                                                            <td
                                                                                                                style={{
                                                                                                                    padding: "0.75rem",
                                                                                                                    verticalAlign: "top",
                                                                                                                }}
                                                                                                            >
                                                                                                                <div>
                                                                                                                    <div>
                                                                                                                        <FormControl sx={{ width: '200px', marginTop: '5px' }}>
                                                                                                                            <Controller
                                                                                                                                name={`founders.${i}.phone`}
                                                                                                                                control={control}
                                                                                                                                defaultValue=""
                                                                                                                                rules={{
                                                                                                                                    required: "Phone Number is required",
                                                                                                                                    validate: validatePhoneNumber,
                                                                                                                                }}
                                                                                                                                render={({ field }) => (
                                                                                                                                    <TextField
                                                                                                                                        variant="outlined"
                                                                                                                                        label="Phone Number *"
                                                                                                                                        placeholder="Enter Phone Number"
                                                                                                                                        fullWidth
                                                                                                                                        type="text"
                                                                                                                                        {...field}
                                                                                                                                        onKeyDown={(e) => validateNumberonly(e)}
                                                                                                                                        inputProps={{ maxLength: 10 }}
                                                                                                                                        size="small"
                                                                                                                                        error={errors.founders?.[i]?.phone &&
                                                                                                                                            errors.founders?.[i]?.phone}
                                                                                                                                    />
                                                                                                                                )}
                                                                                                                            />
                                                                                                                            <FormHelperText className="text-danger">
                                                                                                                                {errors.founders?.[i]?.phone &&
                                                                                                                                    errors.founders?.[i]?.phone.message}
                                                                                                                            </FormHelperText>
                                                                                                                        </FormControl>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <FormControl sx={{ width: '200px', marginTop: '5px' }}>

                                                                                                                            <Controller
                                                                                                                                name={`founders.${i}.email`}
                                                                                                                                control={control}
                                                                                                                                defaultValue=""
                                                                                                                                rules={{
                                                                                                                                    required: "Mail ID is required",
                                                                                                                                    validate: validateEmail,
                                                                                                                                }}
                                                                                                                                render={({ field }) => (
                                                                                                                                    <TextField
                                                                                                                                        size="small"
                                                                                                                                        variant="outlined"
                                                                                                                                        label="Mail ID *"
                                                                                                                                        placeholder="Enter Mail ID"
                                                                                                                                        fullWidth
                                                                                                                                        type="text"
                                                                                                                                        {...field}
                                                                                                                                        error={errors.founders?.[i]?.email &&
                                                                                                                                            errors.founders?.[i]?.email}
                                                                                                                                        onInput={(e) => handleEmailVerfication(e.target.value, i)}
                                                                                                                                        InputProps={{
                                                                                                                                            endAdornment: getValues(`founders.${i}.emailVerify`) === true ? (
                                                                                                                                                <InputAdornment position="end">
                                                                                                                                                    <CheckCircleIcon color="success" />
                                                                                                                                                </InputAdornment>
                                                                                                                                            ) : getValues(`founders.${i}.emailVerify`) === false ? (
                                                                                                                                                <InputAdornment position="end">
                                                                                                                                                    <CloseIcon color="error" />
                                                                                                                                                </InputAdornment>
                                                                                                                                            ) : null,
                                                                                                                                        }}
                                                                                                                                    />
                                                                                                                                )}
                                                                                                                            />
                                                                                                                            <FormHelperText className="text-danger">
                                                                                                                                {errors.founders?.[i]?.email &&
                                                                                                                                    errors.founders?.[i]?.email.message}
                                                                                                                            </FormHelperText>
                                                                                                                        </FormControl>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            </td>





                                                                                                            <td >
                                                                                                                {/* {(editBtn === true || names === "add") && ( */}

                                                                                                                {(userProfileId === 0) && (
                                                                                                                    <div className="setEdittd">


                                                                                                                        <Button
                                                                                                                            type="submit"
                                                                                                                            sx={{ marginLeft: "10px" }}
                                                                                                                            disabled={!getValues(`founders.${i}.emailVerify`)}
                                                                                                                        >
                                                                                                                            <DoneOutlineIcon
                                                                                                                                sx={{
                                                                                                                                    fontSize: "16px",
                                                                                                                                    cursor: "pointer",
                                                                                                                                }}
                                                                                                                            />
                                                                                                                        </Button>
                                                                                                                        <DeleteForeverIcon
                                                                                                                            sx={{
                                                                                                                                fontSize: "16px",
                                                                                                                                cursor: "pointer",
                                                                                                                                marginLeft: "5px",
                                                                                                                                color: 'red'
                                                                                                                            }}
                                                                                                                            onClick={() => founderDetailsRemove(i)} />
                                                                                                                    </div>
                                                                                                                )}
                                                                                                            </td>

                                                                                                        </tr>
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <tr key={i}>
                                                                                                        <td
                                                                                                            style={{
                                                                                                                padding: "0.75rem",
                                                                                                                verticalAlign: "top",
                                                                                                            }}
                                                                                                        >
                                                                                                            <div className="d-flex align-items-center founder-profile-img">
                                                                                                                <img
                                                                                                                    src={field.profileUrl ? field.profileUrl : "/images/user.png"}
                                                                                                                    alt="FounderImage"
                                                                                                                    className="img-fluid"
                                                                                                                />
                                                                                                                <div className="ml-3">
                                                                                                                    <p className="list-para mb-0">
                                                                                                                        {field.name}
                                                                                                                    </p>

                                                                                                                    <p className="paras mb-0">
                                                                                                                        {field.designation}
                                                                                                                    </p>


                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td
                                                                                                            style={{
                                                                                                                padding: "0.75rem",
                                                                                                                verticalAlign: "top",
                                                                                                            }}
                                                                                                        >
                                                                                                            <div className="d-flex align-items-start">
                                                                                                                <div className="mt-3 mr-2">
                                                                                                                    {field.physicallyChallenged === true && (

                                                                                                                        <img src={'/images/icon/disability.png'} alt="disable" style={{ width: '100%', maxWidth: '30px' }} />
                                                                                                                    )

                                                                                                                    }
                                                                                                                </div>
                                                                                                                <div className="mt-3">
                                                                                                                    <a
                                                                                                                        href={field.linkedin}
                                                                                                                        target="_blank"
                                                                                                                        rel="noopener noreferrer"
                                                                                                                    >
                                                                                                                        <img
                                                                                                                            src="/images/linkedin-logo.png"
                                                                                                                            alt="linkedin-icon"
                                                                                                                            className="linkedin-icn"
                                                                                                                        />
                                                                                                                    </a>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td
                                                                                                            style={{
                                                                                                                padding: "0.75rem",
                                                                                                                verticalAlign: "top",
                                                                                                            }}
                                                                                                        >
                                                                                                            <div className="mt-2">
                                                                                                                <div className="d-flex align-items-center table-resp">
                                                                                                                    <div>
                                                                                                                        <FaPhoneAlt
                                                                                                                            style={{
                                                                                                                                fontSize: "13px",
                                                                                                                                color: "#020671",
                                                                                                                            }}
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        {/* <span
                                                                                                                            style={{
                                                                                                                                fontSize: "13px",
                                                                                                                                paddingLeft: "5px",
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            +91 {field.phone}
                                                                                                                        </span> */}
                                                                                                                        {
                                                                                                                            showViewbtn !== 0 ? (
                                                                                                                                <span className="blur_css">+91 XXXXX-XXXXX</span>
                                                                                                                            ) : (
                                                                                                                                <span
                                                                                                                                    style={{
                                                                                                                                        fontSize: "13px",
                                                                                                                                        paddingLeft: "5px",
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    +91 {field.phone}
                                                                                                                                </span>
                                                                                                                            )
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="d-flex align-items-center table-resp">
                                                                                                                    <MdOutlineMail
                                                                                                                        style={{
                                                                                                                            fontSize: "13px",
                                                                                                                            color: "#020671",
                                                                                                                        }}
                                                                                                                    />
                                                                                                                    <div>
                                                                                                                        {/* <span
                                                                                                                            style={{
                                                                                                                                fontSize: "13px",
                                                                                                                                paddingLeft: "5px",
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            {field.email}
                                                                                                                        </span> */}
                                                                                                                        {
                                                                                                                            showViewbtn !== 0 ? (
                                                                                                                                <span className="blur_css ml-2" style={{ fontSize: "14px" }}>XXXXXXXXXXX</span>
                                                                                                                            ) : (
                                                                                                                                <span
                                                                                                                                    style={{
                                                                                                                                        fontSize: "13px",
                                                                                                                                        paddingLeft: "5px",
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    {field.email}
                                                                                                                                </span>
                                                                                                                            )
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td >
                                                                                                            {
                                                                                                                addbtnShow && (
                                                                                                                    <div>
                                                                                                                        {(userProfileId === 0) && (

                                                                                                                            <div className="setEdittd">
                                                                                                                                <EditIcon
                                                                                                                                    sx={{
                                                                                                                                        fontSize: "16px",
                                                                                                                                        cursor: "pointer",
                                                                                                                                        marginLeft: "5px",
                                                                                                                                    }}
                                                                                                                                    onClick={() => handleEditBtn(i)}
                                                                                                                                />
                                                                                                                                <DeleteForeverIcon
                                                                                                                                    sx={{
                                                                                                                                        fontSize: "16px",
                                                                                                                                        cursor: "pointer",
                                                                                                                                        marginLeft: "5px",
                                                                                                                                        color: 'red'
                                                                                                                                    }}
                                                                                                                                    onClick={() => founderDetailsRemove(i)} />
                                                                                                                            </div>
                                                                                                                        )
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                )
                                                                                                            }
                                                                                                            {/* )} */}
                                                                                                        </td>

                                                                                                    </tr>
                                                                                                )
                                                                                            }
                                                                                        </>
                                                                                    ))}

                                                                                {/* <tr>
          <td style={{ padding: '0.75rem', verticalAlign: 'top',  }}>
            <div className="d-flex align-items-center">
              <img src="/images/timcook.png" alt="timcook" />
              <div className="ml-3">
                <p className="list-para mb-0">Tim Cook</p>
                <p className="paras mb-0">CEO</p>
                <span style={{ fontSize: '15px' }}>Masters in Business Administration</span>
              </div>
            </div>
          </td>
          <td style={{ padding: '0.75rem', verticalAlign: 'top',  }}>
            <div>
              <img
                src="/images/linkedin-logo.png"
                alt="linkedin-icon"
                className="linkedin-icn"
              />
            </div>
          </td>
          <td style={{ padding: '0.75rem', verticalAlign: 'top',  }}>
            <div>
              <div className="d-flex align-items-center">
                <div>
                <FaPhoneAlt style={{ fontSize: '13px',color:'#020671' }}/>
                </div>
                <div>
                <span style={{ fontSize: '13px', paddingLeft:'5px' }}>
                    +91 9284857473
                  </span>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div>
                <MdOutlineMail style={{ fontSize: '13px',color:'#020671' }}/>
                </div>
                <div>
                  <span style={{ fontSize: '13px',paddingLeft:'5px' }}>
                    imac@apple.com
                  </span>
                </div>
              </div>
            </div>
          </td>
        </tr> */}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                                </>
                                                                )}

                                                            {/* )} */}
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </Grid>

                                        </Grid>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div>
                    <Dialog
                        fullWidth={fullWidth}
                        maxWidth={maxWidth}
                        open={smartOpen}
                        onClose={availsmartClose}
                    >
                        <DialogContent>
                            <div className="text-center">
                                <img src={'/images/Profile/sucess.png'} alt="" className="sucess-image" />
                                <h4>Thank you for Requesting...</h4>
                            </div>
                        </DialogContent>
                    </Dialog>
                </div>
                {/* Qr-modal */}
                <Dialog
                    fullWidth={fullWidth}
                    maxWidth={maxWidth}
                    open={qrModalopen}
                    onClose={handleModalClose}
                >
                    <DialogContent>
                        <Qrcode path={path} />
                    </DialogContent>
                </Dialog>
                <div>
                    <MaskDialog />
                </div>
            </div>

        </>

    );
};

export default EnablersProfilePage