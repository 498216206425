
import React from 'react'
import '../../../home-module/footer-page/footer.css'
import { Link } from 'react-router-dom'
import $ from 'jquery';
const TnFestFooter = () => {
  $(document).ready(function () {
    $(window).on('scroll', function () {
      if ($(this).scrollTop() > 100) {
        $('#backtotop').fadeIn();
      } else {
        $('#backtotop').fadeOut();
      }
    });
    $('#backtotop').click(function () {
      $("html, body").animate({
        scrollTop: 0
      }, 600);
      return false;
    });
  });

  const scrollToTop = () => {
    $("html, body").animate(
      {
        scrollTop: 0,
      },
      600
    );
  };
  return (
    <>
      <footer className="footer1">

        <div className='footer_details '>
          <div className="container">
            <section className="">
              <div className="row">
                <div className="col-lg-12 mb-4">
                  <Link to={'https://startuptn.in/'} target='_blank'>
                    <img src="/images/startup-white.png" className="p-0 footer-img" alt="" />
                    {/* <img src="/images/TamilNadu_Logo.svg" alt="" className="img-fluid nav-logo-profile1"/> */}
                  </Link>
                  <img src="/images/Banner/Frame.png" className="p-0 footer-img1" alt="" />
                  {/* <img src="/images/TNLogoWhiteLogo1.png" alt="blue horizontal.png" className='logo_tanfund_img' /> */}
                </div>
                <div className="col-lg-6 col-md-6 footer-text-white">
                  <h3 className="my-3 font-weight-bolder c-white">CONTACT</h3>
                  <ul className="list-unstyled mb-0">
                    <li className='set_flex1'>
                      <div>
                        <img src='/images/location.png' className='foo_icon_img' alt="" />
                      </div>
                      <div>
                      <p>StartupTN Office Space, 10th Floor, <br /> Chennai Metro Rail Limited Building,<br /> near Nandanam Metro Station, <br /> Chennai, Tamil Nadu-600035</p>
                        {/* <p>Office Space, 10th Floor, Chennai Metro Rail Limited, <br />Metro Station, Tamil Nadu Startup and Innovation, <br /> Mission (StartupTN), near Nandanam, <br /> Nandanam, Chennai, Tamil Nadu - 600035.</p> */}
                      </div>
                    </li>
                    <li className='set_flex1'>
                      <div>
                        <img src='/images/mail-footer.png' className='foo_icon_img' alt="" />
                      </div>
                      <p>support@startuptn.in</p>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-4 col-md-4 footer-text-white">
                  <h3 className="tn-foot-menu-head">TN Startup Fest 2024</h3>
                  <ul className="list-unstyled mb-0">
                    <li className='pb-3'>
                      <Link className="footer-text-white" to='' onClick={scrollToTop} >
                      Speakers
                      </Link>
                    </li>
                    <li className='pb-3'>
                      <Link className="footer-text-white" to='/tnfest-privacy-policy' onClick={scrollToTop} >
                      Privacy policy

                      </Link>
                    </li>
                    <li className='pb-3'>
                      <Link className="footer-text-white" to='/tnfest-terms-condition' onClick={scrollToTop} >
                      Terms & Conditions
                      </Link>
                    </li>
                    <li className='pb-3'>
                      <Link className="footer-text-white" to='/tnfest-refund-cancellation' onClick={scrollToTop} >
                      Refunds and Cancellation
                      </Link>
                    </li>
                    <li className='pb-3'>
                      <Link className="footer-text-white" to='/tnfest-refund-cancellation' onClick={scrollToTop} >
                      Event Ticket Collection Policy
                      </Link>
                    </li>
                    <li className='pb-3'>
                    <Link className="footer-text-white" to='/tnfest-product-policy' onClick={scrollToTop} >
                    Product Pricing
                      </Link>
                    </li>
                  </ul>

                </div>
                <div className="col-lg-6 col-md-6">
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className='my-3'>
                    <div className="social_icon">
                      <Link to={'https://www.facebook.com/TheStartupTN/'} target='_blank'>
                        <img src='/images/Banner/Vector (1).png' className='img-fluid' alt="" />
                      </Link>
                      <Link to={'https://www.youtube.com/channel/UCr0du18taGeXH35dZZD4RnQ'} target='_blank'>
                        <img src='/images/Banner/youtube-round_svgrepo.com.png' className='img-fluid' alt="" />
                      </Link>
                      <Link to={'https://www.instagram.com/thestartuptn/'} target='_blank'>
                        <img src='/images/Banner/instagram-f_svgrepo.com.png' className='img-fluid' alt="" />
                      </Link>
                      <Link to={'https://x.com/TheStartupTN'} target='_blank'>
                        <img src='/images/logo/icons8-twitter.svg' className='img-fluid' alt="" style={{width:'30px',borderRadius:'50%',background:'#fff'}}/>
                      </Link>
                      <Link to={'https://www.linkedin.com/company/thestartuptn/?originalSubdomain=in'} target='_blank'>
                        <img src='/images/Banner/linkedin-round_svgrepo.com.png' className='img-fluid' alt="" />
                      </Link>
                      <Link to={''} target='_blank'>
                        <img src='/images/Banner/Vector.png' className='img-fluid ' alt="" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

            </section>
            {/* <p className='hr' /> */}
            {/* <div className="text-center">
            

          </div> */}
          </div>
        </div>
        <span id="backtotop" title="Back to top" style={{ display: 'none' }}>&uarr;</span>
      </footer>
    </>
  )
}

export default TnFestFooter