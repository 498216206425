import React, { useContext, useState } from "react";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import ToggleButton from "@mui/material/ToggleButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import apiService from "../../../api/apiService";
import DataContext from "../../../context/DataContext";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import { useNavigate } from "react-router-dom";
import { Autocomplete, Menu, Pagination, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, } from "@mui/material";
import axios from "axios";
import CustomDateRangePicker from "../../common-pages/CustomDateRangePicker";
import notifyService from "../../../api/notifySerivce";
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "24px",
  backgroundColor: "rgb(255 255 255)",
  border: "1px solid #00000017",
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));
const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#D9D9D9",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "20ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const AdminUserList = () => {
  const navigate = useNavigate();
  const { navigator, setPageLoading, startDate, setStartDate, endDate, setEndDate, hasPrivilege, setUserProfileId, setUserProfileParsona } = useContext(DataContext);
  useDidMountEffect(() => {
    parsonaGet()
    hubListApi()
    setStartDate('')
    setEndDate('')
  }, [])
  const [parsonaList, setParsonaList] = useState([])
  const parsonaGet = () => {
    setPageLoading(true)
    axios.get("/json/profiledetails.json", "")
      .then((result) => {
        setPageLoading(false)
        if (result.data) {
          setParsonaList(result.data);
        }
      })
      .catch((err) => { });
  }
  const [hubList, setHubList] = useState([])
  const hubListApi = () => {
    apiService(`hub/list`, {}, 'unauthpost').then((result) => {
      if (result && result.data && result.data.hubs) {
        let data = []
        const val = {
          "id": 0,
          "name": "All",
        }
        data.push(val)
        result.data.hubs.forEach(element => {
          data.push(element)
        });
        setHubList(data)
      }
    })
  }


  const [persona, setPersona] = useState("STARTUP");
  const [searchInputValue, setSearchInputValue] = useState('');
  const [selectedPeriod, setSelectedPeriod] = useState("All")
  const [verified, setVerified] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [sortBy, setSortBy] = useState(true)
  const [hubId, setHubId] = useState(0);
  const [districtId, setDistrictId] = useState("");
  useDidMountEffect(() => {
    userlistApi();
  }, [persona, page, searchInputValue, verified, selectedPeriod, startDate, endDate, sortBy, hubId, districtId]);
  const [userList, setUserList] = useState([]);
  const userlistApi = () => {
    setUserList([])
    setPageLoading(true);
    var data = {
      listSize: 10,
      pageNumber: page,
      persona: persona,
      searchString: searchInputValue,
      verified: verified,
      period: selectedPeriod,
      startDate: startDate ? (startDate + 'T00:00:00.174Z') : '',
      endDate: endDate ? (endDate + 'T00:00:00.174Z') : '',
      sort: sortBy ? 'asc' : 'desc',
      hubId: hubId,
      districtId: districtId
    }
    apiService("user/admin/list", data, "post")
      .then((result) => {
        setPageLoading(false);
        if (result) {
          if (result.data) {
            setTotalPage(result.data.totalPages);
            if (result.data.responsemodelList) {
              setUserList(result.data.responsemodelList);
            }
          }
        }
      })
      .catch((err) => { });
  };
  const [hubName, setHubName] = useState('All')
  const handleHubName = (val) => {
    setHubName(val)
    var depValue = hubList.filter((list) => list.name === val)
    setHubId(depValue[0].id)
    districtsdropdownApi(depValue[0].id)
  }
  const [district, setDistrict] = useState([]);
  const districtsdropdownApi = (id) => {
    setDistrict([])
    setDistrictId('')
    apiService(`home/district/list?hubId=${id}`, "", "get")
      .then((result) => {
        if (result && result.data && result.data.districts) {
          setDistrict(result.data.districts);
        }
      })
      .catch((err) => { });
  };
  const handleSelectDistrict = (name) => {
    var value = district.filter((option) => option.name === name);
    setDistrictId(value[0].id);
  };
  const handlePersonaBtn = (role) => {
    setPersona(role)
  };
  const handleVerifiBtn = (value) => {
    setVerified(value);
  };

  const handleSelectPeriod = (value) => {
    setSelectedPeriod(value)
  }
  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleSearch = (e) => {
    setSearchInputValue(e.target.value);
  };
  const [anchorElUser, setAnchorElUser] = useState(null);
  const handleOpenSortMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleSortBy = (value) => {
    setSortBy(value)
    handleCloseUserMenu();
  }
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleUserPersonna = (id) => {
    navigate(`/user/profile/${btoa(id)}`)
  }
  const handleVerified = (id) => {
    var req = {
      "id": id,
      "verified": true,
    }
    apiService('user/manual/verify', req, 'post').then((result) => {
      if (result && result.data && result.data.responseStatus === "Success") {
        notifyService("success", "Success", "");
        userlistApi()
      }
    }).catch((err) => {

    });
  }
  const handleUserProfile = (id) => {
    // console.log(id, "id");
    setUserProfileId(id)
    setUserProfileParsona(persona)
    if (persona === "INVESTOR") {
      navigator("/investor/profile");
    } else if (persona === "STARTUP") {
      navigator("/startup/profile");
    } else if (persona === "MENTOR") {
      navigator("/mentor/profile");
    }
  }
  return (
    <div>
      <section>
        <div className="container-fluid p-0">
          <div className="double-clr1">
            <div className="tabs">
              <div className="titles">
                {parsonaList.map((role) => (
                  <button
                    key={role.profileType}
                    className={persona === role.profileType ? 'startup-sup-btn active' : 'startup-sup-btn'}
                    onClick={() => handlePersonaBtn(role.profileType)}
                  >
                    {role.profileName}
                  </button>
                ))}
              </div>
            </div>
            <div className="sec-clr">
              <div className="sec-btn-bg1">
                <Search>
                  <SearchIconWrapper>
                    <img src="/images/admin/search-black.png" alt="" />
                  </SearchIconWrapper>
                  <StyledInputBase
                    placeholder="Search Startup Name"
                    inputProps={{ "aria-label": "search" }}
                    onChange={handleSearch}
                    type="search"
                  />
                </Search>
              </div>
              <div className="sec-filters1">
                <div className="sec-btn-bg">
                  <Autocomplete
                    size="small"
                    disableClearable
                    disablePortal
                    id="combo-box-demo"
                    options={hubList.map((val) => val.name)}
                    value={hubName}
                    onChange={(e, selectedOptions) =>
                      handleHubName(selectedOptions)
                    }
                    className="userListDropdown"
                    renderInput={(params) => <TextField {...params} label="Choose Hub" />}
                  />
                </div>
                {
                  hubId !== 0 && (
                    <div className="sec-btn-bg">
                      <Autocomplete
                        size="small"
                        disableClearable
                        disablePortal
                        id="combo-box-demo"
                        options={district.map((option) => option.name)}
                        onChange={(e, selectedOptions) =>
                          handleSelectDistrict(selectedOptions)
                        }
                        className="userListDropdown"
                        renderInput={(params) => <TextField {...params} label="Choose District" />}
                      />
                    </div>
                  )
                }

                <div className="frt-fil1">
                  <div className="all-btn-bg1">
                    <button
                      className={verified === false ? 'sec-btn-1-li active' : "sec-btn-1-li"}
                      onClick={() => handleVerifiBtn(false)}>
                      UnVerified
                    </button>
                    <button className={verified === true ? 'sec-btn-1-li active' : "sec-btn-1-li"}
                      onClick={() => handleVerifiBtn(true)} >
                      Verified
                    </button>
                  </div>
                </div>
                <div><CustomDateRangePicker setStartDate={setStartDate} setEndDate={setEndDate} /> </div>
                <div>
                  <FormControl
                    variant="outlined"
                    size="small"
                    sx={{ width: 120 }}
                  >
                    <InputLabel id="dropdown-label">Duration</InputLabel>
                    <Select
                      labelId="dropdown-label"
                      label="Select Option"
                      value={selectedPeriod}
                      variant="outlined"
                      style={{ borderRadius: 10 }}
                      onChange={(e) => handleSelectPeriod(e.target.value)}
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      <MenuItem value={"This Week"}>This Week</MenuItem>
                      <MenuItem value={"This Month"}>This Month</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div>
                  <ToggleButton className="toggle-fil" onClick={handleOpenSortMenu} >
                    {<img src="/images/filter icon.svg" alt="" />}
                  </ToggleButton>
                  <Menu
                    sx={{ mt: '45px' }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    <MenuItem onClick={() => handleSortBy(true)}>
                      <Typography textAlign="center" sx={{ color: sortBy === true ? '#0061D4' : '' }}>StartDate Ascending</Typography>
                    </MenuItem>
                    <MenuItem onClick={() => handleSortBy(false)}>
                      <Typography textAlign="center" sx={{ color: sortBy === false ? '#0061D4' : '' }}>StartDate Descending</Typography>
                    </MenuItem>
                  </Menu>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section >
      <div className="table_main_div">
        {userList.length > 0 ? (
          <>
            <TableContainer component={Paper}>
              <Table sx={{ borderCollapse: "separate", borderSpacing: "0 10px" }}>
                <TableHead>
                  <TableRow className="titles1">
                    <TableCell>Startup Name</TableCell>
                    <TableCell>Profile Role</TableCell>
                    <TableCell>DPIIT No.</TableCell>
                    <TableCell>Mail ID</TableCell>
                    <TableCell>Mobile Number</TableCell>
                    <TableCell>District</TableCell>
                    {/* <TableCell align="center">Profile Completeness</TableCell> */}
                    <TableCell align="center">Status</TableCell>
                    <TableCell align="center">View Profile</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userList.map((list, i) => (
                    <>
                      <TableRow
                        key={i}
                        sx={{
                          position: 'relative',
                          '&:after': {
                            content: '""',
                            position: 'absolute',
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            border: '1px solid rgba(224, 224, 224, 1)',
                            borderRadius: '20px',
                            pointerEvents: 'none',
                            marginBottom: '-1px',
                          },
                          '& > *': {
                            padding: '10px',
                            backgroundColor: '#fff',
                            '&:last-of-type': { borderTopRightRadius: '20px', borderBottomRightRadius: '20px' },
                          },
                          marginBottom: '10px',
                        }}
                        className="Sup-list"
                      >
                        <TableCell className='list-1'>
                          <span className="list-1-clr">
                            {list.name ? list.name : '-'}
                          </span>
                        </TableCell>
                        <TableCell>
                          <span className="list-ran">{list.role}</span>
                        </TableCell>
                        <TableCell>
                          <span className="list-ran">{list.dpiitNo}</span>
                        </TableCell>
                        <TableCell>
                          <span className="list2-ran">{list.email}</span>
                        </TableCell>
                        <TableCell>
                          <span className="list2-ran">{list.phone ? list.phone : `-`}</span>
                        </TableCell>
                        <TableCell>
                          <span className="list2-ran">{list.district ? list.district : `-`}</span>
                        </TableCell>
                        {/* <TableCell align="center">
                        <span className={`list-5-ran ${list.Profile === "80%" ? "profile-80" : ""
                          }`}>{list.Profile}</span>
                      </TableCell> */}
                        <TableCell align="center">
                          {list.verified === true ? (
                            <span className="status-verified">Verified</span>
                          ) : (
                            <>
                              {
                                hasPrivilege('43') ? (
                                  <span className="list-6-clr c-pointer" onClick={() => handleVerified(list.id)}>Mark as Verified</span>
                                ) : (
                                  <span className="list-6-clr">Mark as Verified</span>
                                )
                              }
                            </>
                          )}
                          {/* <span className={`list-6-clr ${list.Status === "Verified" ? "status-verified" : ""
                          }`}>{list.Status}</span> */}
                        </TableCell>
                        <TableCell align="center">
                          <ToggleButton className="toggle-eve1" onClick={() => handleUserProfile(list.id)}>
                            <img src="/images/chevron.svg" alt="" />
                          </ToggleButton>
                        </TableCell>
                        <span className="date mt-2 mr-3">{list.formattedTime}</span>
                      </TableRow>
                      <span className='mt-2 mr-3'>{' '}</span>
                    </>
                  ))}
                </TableBody>
              </Table>
              {totalPage > 1 && (
                <div className="event-pagination">
                  <Stack spacing={2}>
                    <Pagination
                      count={totalPage}
                      page={page}
                      onChange={handleChange}
                    />
                  </Stack>
                </div>
              )}
            </TableContainer>
          </>
        ) : (
          <>
            <div className='text-center nodatacss_admin'>
              <h5 className='mb-4'>User data not found</h5>
              <div>
                <img src="/images/blue horizontal.png" alt="blue horizontal.png" className='logo_dash img-fluid' />
              </div>
              <div className='mt-5'>
                <h3 className='title_p opa'>
                  StartupTN 3.0
                </h3>
              </div>
            </div>
          </>
        )
        }
      </div>
    </div >
  );
};

export default AdminUserList;
