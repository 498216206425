import { Grid } from '@mui/material'
import React from 'react'

const TnfestMilestones = () => {
  const handleRedirectmap = ()=>{
    window.open('https://maps.app.goo.gl/aHSLtjwL5DCLwcr19',"_blank","noopener,noreferrer")
  }
  return (
    <div>
    <div className='video-bg1'>
      <Grid container direction="row" alignItems="flex-end" justifyContent="center" spacing={2}>
        <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
          <div className='this-mdu'>
            <h2>This Time in <br />
              <span>மதுரை!</span></h2>
            <p >This two-day event is designed to highlight the vibrant startup ecosystem emerging from Tamil Nadu. The event is designed to foster knowledge sharing and enhance networking opportunities.</p>
          </div>
          <div className="card-containers">
          <h2 className="location-title">Location</h2>
            <div className='map-location'>
          <div>
          <h3 className="location-name">Tamukkam Convention Centre</h3>
            <p className="location-address">
              W4JM+7H5, Gandhi Museum Rd,<br />
              Mellur, Alwarpuram, Madurai,<br />
              Tamil Nadu 625002
            </p>
            <button className="button-effect" onClick={()=>handleRedirectmap()}>
              Get Direction
            </button>
          </div>
            <div className='dis-map-thi'>
              <img src={'/images/Banner/madurai-map.webp'} alt='mdu-map' className='mdu-map-ig'/>
            </div>
            </div>
           
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
        <iframe
            style={{borderRadius:'15px',border:0,width:'100%'}}
            title="google-map"
            src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3730.9500954897703!2d78.13120084502243!3d9.930841232529497!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zOcKwNTUnNTEuNSJOIDc4wrAwOCcwMS4xIkU!5e0!3m2!1sen!2sin!4v1722334469432!5m2!1sen!2sin"
            width="500"
            height="350"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </Grid>
      </Grid>
   
    </div >
       <div className='strip-img-div'>
       <img src={'/images/Banner/strip_banner.svg'} alt="strip" className='strip-img' />
     </div>
     </div>
  )
}

export default TnfestMilestones