import { FormHelperText, Grid } from '@mui/material'
import React, { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import DataContext from '../../../../context/DataContext'
import apiService from '../../../../api/apiService'
import useDidMountEffect from '../../../../hooks/useDidMountEffect'
import notifyService from '../../../../api/notifySerivce'
import cryptodecryptJS from '../../../../hooks/cryptodecryptJS'
const TeamsizeServiceProvider = () => {
  const { navigator, handleNextClick,fastUrlPath } = useContext(DataContext)
  const [formData, setFormData] = useState({});
  const { handleSubmit, control, getValues, watch, setError, clearErrors, formState: { errors }, } = useForm({ values: formData });
  const [teamSize, setTeamSize] = useState(0)
  const [teamValiation, setTeamValiation] = useState(false)
  useDidMountEffect(() => {
    handleNextClick(100)
    getApi()
  }, [])
  const getApi = () => {
    var id = localStorage.getItem('id')
    var type = localStorage.getItem('type')
    apiService(`userprofile/get?persona=${type}&userid=${id}`, "", "get").then((result) => {
      if (result && result.data) {
        var data = result.data
        if (data.teamSize) {
          setTeamSize(data.teamSize)
          setTeamValiation(false)
        }
        setFormData(data)
      }
    }).catch((err) => {
    });
  }
  const handleTeamSize = (value) => {
    setTeamSize(value)
    setTeamValiation(false)
  }
  const onSubmit = () => {
    if (teamSize !== 0) {
      var data = formData
      data.teamSize = teamSize
      console.log('Form Data:', data);
      setTeamValiation(false)
      data.profileUpdated = true
      apiService('userprofile/save', data, 'post').then((result) => {
        if (result.data.responseStatus === 'Success') {
          localStorage.setItem('profile', true)
          localStorage.setItem('email', data.email)
          navigator('/service-provider/profile');
        }
      }).catch((err) => {
      });
    } else {
      setTeamValiation(true)
    }
  };
  const onError = (event) => {
    console.log('error Data:', event);
    notifyService('danger', 'Submit Failed', 'Please check the mandatory fields')
  }
  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <div className='signup-data_div'>
            <div className='forms-title d-flex'>
              <p>Team Info {`>`} <span className='path_sapn'>Team Size</span></p>
              <p className='ml-auto'><span className='count_num'>2</span>/2</p>
            </div>
            <div className='signup_box_div mt-4'>
              {/* <form> */}
              <Grid container spacing={1}>
                <Grid item lg={12} md={12} sm={12} xs={12} className='mb-3'>
                  <p><b>Select your Team Size</b></p>
                  <Grid container spacing={1}>
                    <Grid item lg={4} md={4} sm={4} xs={4} className='my-3'>
                      <button className={teamSize === '1-5' ? ('team_size_btn teamActBtn') : ('team_size_btn')} onClick={() => handleTeamSize('1-5')}>1-5</button>
                    </Grid>
                    <Grid item lg={4} md={4} sm={4} xs={4} className='my-3'>
                      <button className={teamSize === '5-10' ? ('team_size_btn teamActBtn') : ('team_size_btn')} onClick={() => handleTeamSize('5-10')}>5-10</button>
                    </Grid>
                    <Grid item lg={4} md={4} sm={4} xs={4} className='my-3'>
                      <button className={teamSize === '10-25' ? ('team_size_btn teamActBtn') : ('team_size_btn')} onClick={() => handleTeamSize('10-25')}>10-25</button>
                    </Grid>
                    <Grid item lg={4} md={4} sm={4} xs={4} className='my-3'>
                      <button className={teamSize === '25-50' ? ('team_size_btn teamActBtn') : ('team_size_btn')} onClick={() => handleTeamSize('25-50')}>25-50</button>
                    </Grid>
                    <Grid item lg={4} md={4} sm={4} xs={4} className='my-3'>
                      <button className={teamSize === '>50' ? ('team_size_btn teamActBtn') : ('team_size_btn')} onClick={() => handleTeamSize('>50')}>{`>`}50</button>
                    </Grid>
                    <Grid item lg={4} md={4} sm={4} xs={4} className='my-3'>
                      <button className={teamSize === '>100' ? ('team_size_btn teamActBtn') : ('team_size_btn')} onClick={() => handleTeamSize('>100')}>{`>`}100</button>
                    </Grid>
                  </Grid>
                  <FormHelperText className='text-danger'>{teamValiation === true && 'Team Size is required'}</FormHelperText>

                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} className='text-center'>
                  <button className='next-btn' onClick={onSubmit}>Save</button>
                </Grid>
              </Grid>
              {/* </form> */}
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default TeamsizeServiceProvider
