import { Autocomplete, Button, Container, Divider, FormHelperText, Grid, InputAdornment, TextField } from '@mui/material'
import React, { useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import DataContext from '../../../context/DataContext';
import { Link, useParams } from 'react-router-dom';
import { FaArrowRightLong } from "react-icons/fa6";
import notifyService from '../../../api/notifySerivce';
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import apiService from '../../../api/apiService';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import cryptodecryptJS from '../../../hooks/cryptodecryptJS';
import Viewimage from '../../../api/Viewimage';

const Joinusorganizationdetails = () => {
    const [formData, setFormData] = useState()
    const { handleSubmit, control, clearErrors, setValue, formState: { errors }, getValues } = useForm({ values: formData });
    const { navigator, validateEmail, validateNumberonly, setPageLoading, validatePhoneNumber, isWhitespace, VisuallyHiddenInput } = useContext(DataContext);
    const { id } = useParams()
    let ids
    if (id !== null) {
        ids = atob(id)
    }
    useDidMountEffect(() => {
        getApi()
        getCategoryData()
    }, []);

    const [categoryList, setCategoryList] = useState([]);
    const getCategoryData = () => {
        setPageLoading(true);
        apiService('smartcard/category/list', {}, 'unauthpost').then((result) => {
            setPageLoading(false);
            if (result && result.data && result.data.categories) {
                setCategoryList(result.data.categories);
            }
        })
    }
    const handlecategorices = (value) => {
        setValue("specilization", value);
        clearErrors("specilization")
    };
    const [updId, setUpdId] = useState(0)
    const getApi = () => {
        setPageLoading(true)
        apiService(`smartcard/organization/get?userid=${ids}`, "", "get")
            .then((result) => {
                setPageLoading(false)
                if (result && result.data) {
                    var data = result.data;
                    console.log(data.smartcardId, "getSmcrd")
                    if (data.email) {
                        data.email = cryptodecryptJS(data.email);
                    }
                    if (data.imageName) {
                        data.imageUrl = data.imageName
                    }
                    setFormData(data)
                }
            })
            .catch((err) => {
                console.log(err);
            });

    };

    const handleKeyDown = (event) => {
        if (event.key === ' ') {
            event.preventDefault();
        }
    };
     const [open, setOpen] = useState(false);
    const [viewImage, setViewImage] = useState("");
    const [viewImageName, setViewImageName] = useState("");
    const handleClickOpen = (value, name) => {
        setViewImage(value);
        setViewImageName(name);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    let logoselecetdFile = "";
    const handleFileUpload = (event, filekey, fileName,fileUrl, format) => {
        if (event !== null) {
            if (event.target === undefined) {
                logoselecetdFile = event
            } else {
                logoselecetdFile = event.target.files[0]
            }
            const maxFileSize = 5 * 1024 * 1024; // 5MB in bytes
            if (logoselecetdFile) {
                if (logoselecetdFile.size < maxFileSize) {
                    var reader = new FileReader();
                    var imagetype = logoselecetdFile.type;
                    var imagedatatype = imagetype.split("/");
                    var img_crt_type = imagedatatype[1];
                    if (
                        ((format === 'file') && (img_crt_type === "jpeg" || img_crt_type === "jpg" || img_crt_type === "png" || img_crt_type === "pdf")) ||
                        ((format === 'image') && (img_crt_type === "jpeg" || img_crt_type === "jpg" || img_crt_type === "png"))
                    ) {
                        setValue(filekey, "");
                        setValue(fileName, "");
                        setValue(fileUrl, '')
                        var fileValue = logoselecetdFile;
                        reader.readAsDataURL(logoselecetdFile);
                        reader.onload = () => {
                            var logourl1 = reader.result;
                            var spl = logourl1.split(",");
                            var ImageValue = spl[1];
                            var img_name = fileValue.name;
                            // setUrl(logourl1)
                            setValue(filekey, ImageValue);
                            setValue(fileName, img_name);
                            setValue(fileUrl, logourl1)
                            clearErrors(filekey);
                            clearErrors(fileName);
                            clearErrors(fileUrl);
                            // 
                            // clearErrors('dpiitCertificateName')
                        };
                    } else {
                        notifyService(
                            "danger",
                            "File Format Invalided",
                            "Please check your file format"
                        );
                    }
                } else {
                    notifyService(
                        "danger",
                        "File Size Exceeded",
                        `Maximum file size allowed is 5 MB`
                    );
                }
            }
        }
    };

    let smids
    const onsubmit = (data) => {
        console.log(data);
        const categ = categoryList.filter(val => val.category === data.specilization);
        data.categoryId = categ[0]?.id;

        setPageLoading(true);
        apiService("smartcard/organization/save", data, "post")
            .then((result) => {
                setPageLoading(false);
                if (result && result.data && result.data.id) {
                    smids = result.data.id
                    setUpdId(smids)
                    localStorage.setItem("joinusId", smids)
                }
                if (result && result.data && result.data.responseStatus === "Success") {

                    console.log(smids, "updId");
                    navigator(`/smartcard/joinuspartner/${id}/list/${btoa(smids)}`);
                }
            })

            .catch((err) => {
                console.log(err);
            });
    };

    const handleBackjoin = () => {
        navigator('/smartcard/service')
    }
    return (
        <Container>
            <div className='joinus-design'>
                <div className='mt-4'>
                    <span className='bck-join c-pointer' onClick={() => handleBackjoin()}>{`< Back`}</span>
                </div>
                <div className='title-joinus text-center'>
                    <h2 className='join-h3 mb-2'>Join as a Service Partner</h2>
                    <p>Join our network of trusted service partners and grow together.</p>
                    <div className='my-3'>
                        <img src="/images/join-us/first.png" alt="tab" />
                    </div>
                    <h6><b>Let’s Get Started with Your Organization Details</b></h6>
                </div>

            </div>
            <div className="pb-5">
                <form onSubmit={handleSubmit(onsubmit)}>
                    <div>
                        <fieldset className="fieldset-container">
                            <legend className='legend-container'><span>Organization Details</span></legend>
                            <Grid container direction="row" alignItems="flex-start" jusifyContent="center" spacing={5}>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <div className='input_background'>
                                        <Controller
                                            name="name"
                                            control={control}
                                            defaultValue=""
                                            rules={{
                                                required: "Please enter Company Name",
                                                // required:false,
                                                validate: {
                                                    noWhitespace: value => !isWhitespace(value) || 'Please enter valid Company Name'
                                                },
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    variant="outlined"
                                                    label="Company Name *"
                                                    placeholder="Enter Company Name"
                                                    fullWidth
                                                    type="text"
                                                    {...field}

                                                    inputProps={{
                                                        maxLength: 100,
                                                        readOnly: true
                                                    }}
                                                    error={Boolean(errors.name && errors.name)}
                                                />
                                            )}
                                        />
                                        <FormHelperText className="text-danger">
                                            {errors.name &&
                                                errors.name.message}
                                        </FormHelperText>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <div className='input_background'>
                                        <Controller
                                            name="email"
                                            control={control}
                                            defaultValue=""
                                            rules={{
                                                required: "Company Mail Address is required",
                                                // required:false,
                                                validate: validateEmail,
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    variant="outlined"
                                                    label="Company Mail Address *"
                                                    placeholder="Enter Company Mail Address"
                                                    fullWidth
                                                    type="text"
                                                    {...field}

                                                    inputProps={{
                                                        maxLength: 45,
                                                        readOnly: true
                                                    }}
                                                    error={Boolean(errors.email &&
                                                        errors.email)}
                                                />
                                            )}
                                        />
                                        <FormHelperText className="text-danger">
                                            {errors.email && errors.email.message}
                                        </FormHelperText>
                                    </div>
                                </Grid>

                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <div className='input_background'>
                                        <Controller
                                            name="website"
                                            control={control}
                                            defaultValue=""
                                            rules={{
                                                required: false,
                                                // validate: {
                                                //     pattern: (value) =>
                                                //         /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d{1,5})?(\/\S*)?$/.test(value) || "Please enter valid  Company Website"
                                                // }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    variant="outlined"
                                                    label="Company Website"
                                                    placeholder="Company Website"
                                                    fullWidth
                                                    type="text"
                                                    {...field}
                                                    onKeyDown={handleKeyDown}
                                                    inputProps={{ maxLength: 45 }}
                                                    error={Boolean(errors.website && errors.website)}
                                                />
                                            )}
                                        />
                                        <FormHelperText className="text-danger">
                                            {errors.website && errors.website.message}
                                        </FormHelperText>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <div className='input_background'>
                                        {
                                            getValues('imageUrl') ? (
                                                <>
                                                    <label >Upload Company Logo</label>
                                                    <div className="row">
                                                        <Grid item lg={7} md={7} sm={12} xs={12} className="mb-3">

                                                            <Controller
                                                                name="imageName"
                                                                control={control}
                                                                defaultValue=""
                                                                rules={{ required: "Company Logo is required" }}
                                                                render={({ field }) => (
                                                                    <Button
                                                                        component="label"
                                                                        variant="contained"
                                                                        {...field}
                                                                        fullWidth
                                                                        onChange={(e) =>
                                                                            handleFileUpload(e, "image", "imageName", "imageUrl",'image')
                                                                        }
                                                                        startIcon={<CloudUploadIcon />}
                                                                        href="#file-upload"
                                                                    >
                                                                        Change
                                                                        <VisuallyHiddenInput type="file" />
                                                                    </Button>
                                                                )}
                                                            />

                                                        </Grid>
                                                        <Grid item lg={5} md={5} sm={12} xs={12} className="mb-3 logo-btn-margin">
                                                            <Button
                                                                className="view_doc_btn_document c-pointer"

                                                                size="medium"
                                                                variant="outlined"
                                                                onClick={() =>
                                                                    handleClickOpen(
                                                                        getValues("image"),
                                                                        getValues("imageUrl")
                                                                    )
                                                                }
                                                            >
                                                                Preview
                                                            </Button>
                                                        </Grid>
                                                        <div>
                                                            <span className="fn-12   mt-1 ">
                                                                Maximum 5 mb allowed doc (Accepted Format: jpeg, jpg, png)

                                                            </span>
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <div className="form-group mt-2">
                                                    <Controller
                                                        name="imageName"
                                                        control={control}
                                                        defaultValue=""
                                                        rules={{ required: false }}
                                                        render={({ field }) => (
                                                            <TextField
                                                                variant="outlined"
                                                                label="Upload Company Logo "
                                                                placeholder="Upload Image "
                                                                fullWidth
                                                                type="text"
                                                                {...field}
                                                                disabled
                                                            />
                                                        )}
                                                    />
                                                    <FormHelperText className="text-danger">
                                                        {errors.imageName && errors.imageName.message}
                                                    </FormHelperText>
                                                    <div className='d-flex align-items-center w-100 my-3'>
                                                        <p className='font-sm'>
                                                            Recommended aspect ratio 4:3 <br />
                                                            Max size upto 5mb <br />
                                                            Accepted Format: jpeg, jpg, png</p>
                                                        <label className='ml-auto lbl_btn1'
                                                            onChange={(e) => handleFileUpload(e, "image", "imageName","imageUrl", 'image')}
                                                            href="#file-upload">
                                                            <img src='/images/admin/upload.png' className='mr-3' alt='' />
                                                            Upload
                                                            <VisuallyHiddenInput type="file" />
                                                        </label>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <div className="input_background">
                                        <Controller
                                            name="specilization"
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: "Please choose Company Specialization" }}
                                            render={({ field, fieldState: { error } }) => (
                                                <Autocomplete
                                                    disableClearable
                                                    disablePortal
                                                    {...field}
                                                    options={categoryList.map((option) => option.category)}
                                                    onChange={(event, newValue) => handlecategorices(newValue)}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Company Specialization *"
                                                            placeholder="Select Company Specialization *"
                                                            error={!!error}
                                                        />
                                                    )}
                                                    fullWidth
                                                    margin="normal"
                                                />
                                            )}
                                        />
                                        <FormHelperText className="text-danger">
                                            {errors.specilization && errors.specilization.message}
                                        </FormHelperText>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                    <div className='input_background'>
                                        <Controller
                                            name="about"
                                            control={control}
                                            defaultValue=""
                                            rules={{
                                                required: "Please enter Description",
                                                validate: {
                                                    noWhitespace: value => !isWhitespace(value) || 'Please enter Valid Description'
                                                },
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    variant="outlined"
                                                    label="Company Description *"
                                                    placeholder="Enter Company Description"
                                                    fullWidth
                                                    {...field}
                                                    inputProps={{ maxLength: 500 }}
                                                    error={Boolean(errors.about && errors.about)}
                                                />
                                            )}
                                        />
                                        <FormHelperText className="text-danger">
                                            {errors.about && errors.about.message}
                                        </FormHelperText>
                                    </div>
                                </Grid>
                            </Grid>
                        </fieldset>
                    </div>
                    <div>
                        <fieldset className="fieldset-container">
                            <legend className='legend-container1'><span>Point Of Contact</span></legend>
                            <Grid container direction="row" alignItems="flex-start" jusifyContent="center" spacing={5}>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <div className='input_background'>
                                        <Controller
                                            name="pocName"
                                            control={control}
                                            defaultValue=""
                                            rules={{
                                                required: "Please enter POC Name", validate: {
                                                    noWhitespace: value => !isWhitespace(value) || 'Please enter valid POC Name'
                                                },
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    variant="outlined"
                                                    label="POC Name *"
                                                    placeholder="Enter POC Name"
                                                    fullWidth
                                                    type="text"
                                                    {...field}
                                                    inputProps={{ maxLength: 45 }}
                                                    error={Boolean(errors.pocName &&
                                                        errors.pocName)}
                                                />
                                            )}
                                        />
                                        <FormHelperText className="text-danger">
                                            {errors.pocName &&
                                                errors.pocName.message}
                                        </FormHelperText>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <div className='input_background'>
                                        <Controller
                                            name="pocEmail"
                                            control={control}
                                            defaultValue=""
                                            rules={{
                                                required: "Please enter POC Email Address",
                                                validate: validateEmail,
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    variant="outlined"
                                                    label="POC Mail Address*"
                                                    placeholder="Enter POC Mail Address"
                                                    fullWidth
                                                    type="text"
                                                    {...field}
                                                    error={Boolean(errors.pocEmail &&
                                                        errors.pocEmail)}
                                                />
                                            )}
                                        />
                                        <FormHelperText className="text-danger">
                                            {errors.pocEmail && errors.pocEmail.message}
                                        </FormHelperText>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <div className='input_background'>
                                        <Controller
                                            name="pocPhone"
                                            control={control}
                                            defaultValue=""
                                            rules={{
                                                required: "Please enter POC Phone Number",
                                                validate: validatePhoneNumber,
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    variant="outlined"
                                                    label="POC Mobile No.*"
                                                    placeholder="Enter POC Mobile Number"
                                                    fullWidth
                                                    type="text"
                                                    {...field}
                                                    error={Boolean(errors.pocPhone && errors.pocPhone)}
                                                    onKeyDown={(e) => validateNumberonly(e)}
                                                    inputProps={{ maxLength: 10 }}
                                                />
                                            )}
                                        />
                                        <FormHelperText className="text-danger">
                                            {errors.pocPhone && errors.pocPhone.message}
                                        </FormHelperText>
                                    </div>
                                </Grid>

                            </Grid>
                        </fieldset>
                    </div>
                    <div>
                        <Grid container direction="row" alignItems="flex-start" jusifyContent="center" spacing={5}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <div className='input_background'>
                                    <Controller
                                        name="privilegeOffers"
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                            required: "Please enter privilegeOffers", validate: {
                                                noWhitespace: value => !isWhitespace(value) || 'Please enter valid Privilege'
                                            },
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                multiline
                                                rows={3}
                                                variant="outlined"
                                                label="Privilege Offers for Startups *"
                                                placeholder="Please Enter Privilege"
                                                fullWidth
                                                type="text"
                                                {...field}
                                                inputProps={{ maxLength: 100 }}
                                                error={Boolean(errors.privilegeOffers &&
                                                    errors.privilegeOffers)}
                                            />
                                        )}
                                    />
                                    <div className='d-flex align-items-start justify-content-between'>
                                        <FormHelperText style={{ color: 'darkgray' }}>
                                            Eg., 30% Offer Exclusively for StartupTN Members, Flat 30% discount for all services
                                        </FormHelperText>
                                        <FormHelperText style={{ color: 'darkgray' }}>
                                            Max 75-100 Characters
                                        </FormHelperText>
                                    </div>
                                    <FormHelperText className="text-danger">
                                        {errors.privilegeOffers &&
                                            errors.privilegeOffers.message}
                                    </FormHelperText>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <div className='text-center mt-3'>
                        <Button variant='contained' endIcon={<FaArrowRightLong />} size="large" type="submit" sx={{ background: "#0B40AA", padding: '15px 25px', textTransform: 'none', borderRadius: '10px' }}>Submit & Proceed Next</Button>
                    </div>
                </form>
            </div>
            <Viewimage
                viewImage={viewImage}
                viewImageName={viewImageName}
                open={open}
                handleClose={handleClose}
            />
        </Container>
    )
}

export default Joinusorganizationdetails