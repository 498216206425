import { Button } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import useDidMountEffect from '../../hooks/useDidMountEffect';

const SessionTimeoutPopup = ({ show, onClose, onContinue }) => {

    const [timerId, setTimerId] = useState(null);

    useDidMountEffect(() => {
        if (show) {
            const id = setTimeout(() => {
                onClose();
            }, 10000);

            setTimerId(id);

            return () => clearTimeout(id);
        }
    }, [show, onClose]);

    const handleContinue = () => {
        if (timerId) {
            clearTimeout(timerId);
        }
        onContinue();
    };


    if (!show) return null;

    return (
        <div style={styles.overlay}>
            <div style={styles.popup}>
                <img src="/images/Alarm_Clock.gif" alt="" className="Alarm_gif" />
                <h2>Session Timeout</h2>
                <p>Your session is about to expire. Do you want to extend your session?</p>
                {/* <button >Extend Session</button> */}
                <div className='my-3'>
                    <Button className="discard-btn" onClick={onClose}>
                        Logout
                    </Button>
                    <Button onClick={handleContinue} className="publish-button">
                        Continue
                    </Button>
                </div>
            </div>
        </div>
    )
}

const styles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999
    },
    popup: {
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '5px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
        textAlign: 'center',
    },
};


export default SessionTimeoutPopup




