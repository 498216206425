import { Autocomplete, Button, Dialog, DialogContent, FormHelperText, Grid, InputAdornment, TextField } from '@mui/material'
import React, { useContext, useState } from 'react'
import DataContext from '../../../../context/DataContext';
import { Controller, useForm } from 'react-hook-form'
import IconButton from '@mui/material/IconButton';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import apiService from '../../../../api/apiService'
import cryptoJS from '../../../../hooks/cryptoJS'
import { Link } from 'react-router-dom'
import { auth, googleAuthProvider } from '../../../auth-module/Firebase';
// import LinkedInLogin from '../continue-with/LinkedInLogin';
// import StartupIndia from '../continue-with/StartupIndia';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';
// import UpdatePersona from './UpdatePersona';
// import ForgotPassword from './ForgotPassword';
import notifyService from '../../../../api/notifySerivce';
// import Captchatext from '../../common-pages/Captchatext';
// import cryptodecryptJS from '../../../hooks/cryptodecryptJS';
import ProfileCheckDialog from '../Tn_fest/Ticketbookdialog/ProfileCheckDialog';
import Delegatefreepass from '../Tn_fest/PassDialog/Delegatefreepass';
import Captchatext from '../../../common-pages/Captchatext';
import ForgotPassword from '../../../auth-module/login-details-page/ForgotPassword';
import Timer from '../../../auth-module/signup-details-page/signup-page/Timer';
import ThiruvizhaTimer from './ThiruvizhaTimer';
import axios from 'axios';

const ThiruvizhaLogin = ({ getApi }) => {
    const { validateNumberonly, validateEmail, setPageLoading, handleHomePage, setProfileDialog, setProfileDialogBox, fullWidth, captchaValue, setCaptchaValue, setInputValue, fastUrlPath, handleParsonaClose } = useContext(DataContext)
    const { handleSubmit, control, watch, getValues, setError, setValue, clearErrors, reset, formState: { errors }, } = useForm({ values: {}, mode: 'onBlur' });
    const [formValid, setFormValid] = useState(false);
    const watchFields = watch(['userName', 'password']);
    useDidMountEffect(() => {
        const isFormValid = Object.values(watchFields).every(field => !!field);
        setFormValid(isFormValid);
    }, [watchFields]);
    const [emailVerify, setEmailVerify] = useState(null)
    const handleEmailVerfication = (value) => {
        var val = validateEmail(value)
        setValue('persona', '')
        setValue('role', '')
        console.log(val);
        setEmailVerify(null)
        setProfileRoleList('')
        setSendOTP(false)
        // emailVerify
        if (val === true) {
            var email = value.toLowerCase()
            setPageLoading(true)
            apiService(`register/email/verify?email=${cryptoJS(email)}`, '', 'unauthget').then((result) => {
                setPageLoading(false)
                if (result && result.data && result.data.responseStatus === 'Success') {
                    setEmailVerify(true)
                    profileGet()
                } else {
                    setEmailVerify(false)
                    // notifyService('danger', 'Error', result.data.responseMessage)
                }
            }).catch((err) => {
                setEmailVerify(null)
            });
        }
    }
    const [profileType, setProfileType] = useState([])
    const profileGet = () => {
        axios.get('/json/profiledetails.json', '').then((result) => {
            if (result.data) {
                setProfileType(result.data)
            }
        }).catch((err) => {

        });
    }
    const [profileRoleList, setProfileRoleList] = useState([])
    const handleSelectProfileType = (value) => {
        setValue('persona', value)
        clearErrors('persona')
        setSendOTP(false)
        var val = profileType.filter((list) => list.profileName === value)
        setProfileRoleList(val[0].role)
        setValue('role', '')
        setError('role', 'Role is required')
    }
    const handleSelectProfileRole = (value) => {
        setValue('role', value)
        clearErrors('role')
        setSendOTP(false)
    }
    const onSubmit = (data) => { }
    const onError = (event) => {
        console.log('error Data:', event);
    }
    const handleClose = () => {
        // handelCloseThirivizha()
        // handleProfileClose()
    }
    const [sendOTP, setSendOTP] = useState(false)
    const handleGenerateOTP = () => {
        setSeconds(60)
        var getform = getValues()
        var req
        if (emailVerify === false) {
            req = {
                "email": cryptoJS(getform.email)
            }
        } else if (emailVerify === true) {
            var email = validateEmail(getform.email)
            // console.log(email);
            if (email === 'Invalid email address') {
                return validateEmail()
            }
            var type = profileType.filter((list) => list.profileName === getform.persona)
            var role = profileRoleList.filter((list) => list.roleName === getform.role)
            if (type && role) {
            req = {
                "email": cryptoJS(getform.email),
                "persona": type[0].profileType,
                "role": role[0].roleValue
            }
        }
        }
        setPageLoading(true)
        apiService(`user/thiruvizha/register`, req, 'unauthpost').then((res) => {
            setPageLoading(false)
            console.log(res);
            if (res && res.data) {
                if (res.data.responseStatus === "Success") {
                    localStorage.setItem('logDetails', JSON.stringify(req))
                    localStorage.setItem('email', getform.email)
                    if (emailVerify === true) {
                        localStorage.setItem('role', role[0].roleValue)
                        localStorage.setItem('type', type[0].profileType)
                    }
                    setSendOTP(true)
                }

            }
        }).catch((err) => {

        });
    }
    const [formValidate, setFormValidate] = useState(false)
    const [otp, setOtp] = useState('');
    const handleCheckotp = (value) => {
        if (value.length === 6) {
            setPageLoading(true);
            setFormValidate(false);
            setOtp(value);
            var getform = localStorage.getItem('email');
            var req = {
                "email": cryptoJS(getform),
                "otp": cryptoJS(value)
            }
            apiService(`user/thiruvizha/register/verify`, req, 'unauthpost').then((res) => {
                setPageLoading(false);
                // responseCode(res)
                if (res && res.data) {
                    if (res.data.response.responseStatus === "Success") {
                        // var name = res.data.firstName + `${res.data.lastName ? res.data.lastName : ''}`
                        localStorage.setItem('jwttoken', res.data.token)
                        localStorage.setItem('id', res.data.id)
                        localStorage.setItem('userId', res.data.id)
                        localStorage.setItem('profileId', res.data.id)
                        // localStorage.setItem('profile', res.data.profileUpdated)
                        // localStorage.setItem('profile', res.data.profileUpdated ? res.data.profileUpdated : false)
                        localStorage.setItem('role', res.data.role)
                        // localStorage.setItem('privileges', JSON.stringify(res.data.privileges))
                        localStorage.setItem('name', res.data.firstName)
                        localStorage.setItem('type', res.data.persona)
                        // localStorage.setItem('logo', res.data.imageName)
                        localStorage.setItem('email', res.data.email)
                        if(res.data.persona!='NA'){
                            getApi()
                        }
                        
                    }
                }
                else {
                    setFormValidate(true);
                }
            });
        } else {
            setFormValidate(true);
            setOtp(value);
        }
    };
    const [seconds, setSeconds] = useState(60);

    const resenOtp = () => {
        var getform = JSON.parse(localStorage.getItem('logDetails'))
        if (getform) {
            setOtp('')
            setValue('otp', '')
            // var getform = JSON.parse(localStorage.getItem('logDetails'))
            setPageLoading(true)
            apiService(`user/thiruvizha/register`, getform, 'unauthpost').then((res) => {
                setPageLoading(false)
                if (res) {
                    if (res.data.responseStatus === "Success") {
                        localStorage.setItem('logDetails', JSON.stringify(getform))
                        notifyService('success', 'Success', 'OTP resent successfully !!')
                        setSeconds(60)
                    }
                }
            })
        }

    }
    return (
        <>
            <Grid
                container
                direction="row"
                justifyContent="center"
                // alignItems="center"
                spacing={2}
                className='mt-3'
            >
                <Grid item lg={4} md={4} sm={12} xs={12}>
                    <Controller
                        name="email"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Email Address is required", validate: validateEmail }}
                        render={({ field }) => (
                            <TextField
                                variant="outlined"
                                label="Email Address *"
                                placeholder="Enter Email Address"
                                fullWidth
                                type="text"
                                {...field}
                                autoFocus
                                error={Boolean(errors.email && errors.email)}
                                onInput={(e) => handleEmailVerfication(e.target.value)}
                            />
                        )}
                    />
                    <FormHelperText className="text-danger">
                        {errors.email && errors.email.message}
                    </FormHelperText>
                </Grid>
                {/* {
                emailVerify === false && (

                    <Grid item lg={4} md={4} sm={12} xs={12}>
                        {
                            (sendOTP === false) && (
                                <Button onClick={handleGenerateOTP} variant="outlined" className='my-2'>Generate OTP</Button>
                            )
                        }
                        {
                            sendOTP === true && (
                                <>
                                    <Controller
                                        name="otp"
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: "OTP is required" }}
                                        render={({ field }) => (
                                            <TextField
                                                variant="outlined"
                                                label="OTP *"
                                                placeholder="Enter OTP"
                                                fullWidth
                                                type="text"
                                                value={otp}
                                                onInput={(e) => { handleCheckotp(e.target.value); }}
                                                onKeyDown={(e) => validateNumberonly(e)}
                                                inputProps={{ maxLength: 6, minLength: 6 }}
                                                {...field}
                                                error={Boolean(errors.otp && errors.otp)}
                                            />
                                        )}
                                    />
                                    <FormHelperText className="text-danger">
                                        {errors.otp && errors.otp.message}
                                    </FormHelperText>
                                    {
                                        formValidate === true && (<FormHelperText className='text-center text-danger-white'>OTP is required</FormHelperText>)
                                    }
                                    <ThiruvizhaTimer resent={resenOtp} seconds={seconds} setSeconds={setSeconds} />
                                </>
                            )
                        }
                    </Grid>

                )
            } */}

                {
                    emailVerify === true && (
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Controller name="persona" control={control} defaultValue="" rules={{ required: `Profile Type is required` }}
                                render={({ field }) =>
                                    <Autocomplete
                                        disableClearable
                                        disablePortal
                                        {...field}
                                        options={profileType.map((val => val.profileName))}
                                        onChange={(e, selectedOptions) =>
                                            handleSelectProfileType(selectedOptions)
                                        }
                                        renderInput={(params) => (
                                            <TextField {...params} label="Select Profile Type  " />
                                        )}
                                    />} />
                            <FormHelperText className='text-danger'>{errors.persona && errors.persona.message}</FormHelperText>
                        </Grid>
                    )
                }
                {
                    (emailVerify === true && profileRoleList.length > 0) && (
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Controller name="role" control={control} defaultValue="" rules={{ required: `Role is required` }}
                                render={({ field }) =>
                                    <Autocomplete
                                        disableClearable
                                        disablePortal
                                        {...field}
                                        options={profileRoleList.map((val => val.roleName))}
                                        onChange={(e, selectedOptions) =>
                                            handleSelectProfileRole(selectedOptions)
                                        }
                                        renderInput={(params) => (
                                            <TextField {...params} label="Select Profile Role  " />
                                        )}
                                    />} />
                            <FormHelperText className='text-danger'>{errors.role && errors.role.message}</FormHelperText>
                        </Grid>
                    )
                }

            </Grid>
            {
                (emailVerify === true || emailVerify === false) && (
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        // alignItems="center"
                        spacing={2}
                        className='mt-3'
                    >
                        <Grid item lg={4} md={4} sm={12} xs={12} className='text-center'>
                            {
                                ((emailVerify === false && sendOTP === false) || (sendOTP === false && emailVerify === true && getValues('role') )) && (
                                    <Button onClick={handleGenerateOTP} variant="outlined">Generate OTP</Button>
                                )
                            }
                            {
                                sendOTP === true && (
                                    <>
                                        <Controller
                                            name="otp"
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: "OTP is required" }}
                                            render={({ field }) => (
                                                <TextField
                                                    variant="outlined"
                                                    label="OTP *"
                                                    placeholder="Enter OTP sent to your above Email"
                                                    fullWidth
                                                    type="text"
                                                    value={otp}
                                                    onInput={(e) => { handleCheckotp(e.target.value); }}
                                                    onKeyDown={(e) => validateNumberonly(e)}
                                                    inputProps={{ maxLength: 6, minLength: 6 }}
                                                    {...field}
                                                    error={Boolean(errors.otp && errors.otp)}
                                                />
                                            )}
                                        />
                                        <FormHelperText className="text-danger">
                                            {errors.otp && errors.otp.message}
                                        </FormHelperText>
                                        {
                                            formValidate === true && (<FormHelperText className='text-center text-danger-white'>OTP is required</FormHelperText>)
                                        }
                                        <ThiruvizhaTimer resent={resenOtp} seconds={seconds} setSeconds={setSeconds} />
                                    </>
                                )
                            }
                        </Grid>
                    </Grid>
                )
            }

        </>

    )
}


export default ThiruvizhaLogin