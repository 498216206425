import React, { useContext, useState } from "react";

import { Container, Grid, Tab, Tabs } from "@mui/material";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import axios from "axios";
import DataContext from "../../../context/DataContext";
import apiService from "../../../api/apiService";

const HomeServicesSection = () => {
  const { navigator, t } = useContext(DataContext);
  const [tabDetails, setTabDetails] = useState([]);
  const [tabIndex, setTabIndex] = useState(1);

  const [tabDetailsList, setTabDetailsList] = useState([]);
  const [selectedtab, setSelectedtab] = useState("");
  const lang = localStorage.getItem("lang");
  useDidMountEffect(() => {
    getApi();
  }, []);
  // const getApi = () => {
  //     axios.get('./json/serviceslist.json', '').then((result) => {

  //         if (result.data) {
  //             var data = result.data[0]
  //             setTabIndex(0 + 1)
  //             if (data) {
  //                 setTabDetails(data)
  //                 setTabSerive(data.serviceList)
  //             }
  //             setTabDetailsList(result.data)
  //         }
  //     })
  // }
  const getApi = () => {
    setTabSerive([]);
    apiService(`home/services?serviceName=`, "", "get")
      .then((result) => {
        if (result && result.data) {
          //  var data = result.data[0]
          setTabIndex(0 + 1);
          setTabDetailsList(result.data);
          if (result.data[0].projects) {
            setTabSerive(result.data[0].projects);
            setTabDetails(result.data[0].name);
          }
        }
      })
      .catch((err) => {});
  };
  const [tabSerive, setTabSerive] = useState([]);
  const ShowgetApi = (value) => {
    setTabSerive([]);
    apiService(`home/services?serviceName=${value}`, "", "get")
      .then((result) => {
        if (result && result.data) {
          var data = result.data[0];

          if (data && data.projects) {
            setTabSerive(data.projects);
          }
        }
      })
      .catch((err) => {});
  };

  // const handleTabDetails = (index) => {
  //     var data = tabDetailsList[index]
  //     setTabIndex(index + 1)
  //     if (data) {
  //         setTabDetails(data)
  //         setTabSerive(data.serviceList)
  //     }

  // }

  const handleTabDetails = (value, index) => {
    setTabIndex(index + 1);
    setTabDetails(value);
    setSelectedtab(value);
    ShowgetApi(value);
  };

  const handleRouteValue = (path) => {
    if (path !== "") {
      navigator(path);
    }
  };
  return (
    <section className="service-offers-section">
      <Container>
        <div className="Services-header-details">
          {lang === "en" ? (
            <h3>
              Services <span>We Offer</span>
            </h3>
          ) : (
            <h3>
              நாங்கள் அளிக்கும் <span>சேவைகள்</span>
            </h3>
          )}
          <p className="px-lg-5 px-md-5 px-0">{t("Homeservice.headpara")}</p>
          {/* <p className="px-lg-5 px-md-5 px-0">Here is our directory of services based on our various policies to support Startups.</p> */}
        </div>
        <div className="service-tab-details">
          <div className="tab-btn-details">
            <Tabs variant="scrollable" scrollButtons="auto">
              {tabDetailsList.map((menu, index) => (
                // <button  onClick={() => handleTabDetails(index)}>0{index + 1}. {menu.tabName}</button>
                <Tab
                  label={`0${index + 1}. ${menu.name}`}
                  onClick={(e) => handleTabDetails(menu.name, index)}
                  className={
                    tabIndex === index + 1 ? "menuBtn actMenu" : "menuBtn"
                  }
                  role="tab"
                  tabIndex={tabIndex === index + 1 ? 0 : -1}
                />
              ))}
            </Tabs>
          </div>
          <div className="tab-data-details">
            <Grid container spacing={4}>
              {/* <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <div className="servcer-fund-details">
                                    <div className="text-center fund-title">
                                        <h1>0{tabIndex}</h1>
                                        <h3>{tabDetails.tabName}</h3>
                                    </div>
                                    <div className="connection_div">
                                        <img src={tabDetails.tabImg} alt="" className="connect_img" />
                                        <div className="connect_details">
                                            <p className="mb-2">{tabDetails.investorConnect} {tabDetails.value}</p>
                                            <p className="mb-0 connect-address">{tabDetails.address}</p>
                                            <p className="mb-0 connect-date">{tabDetails.date}</p>
                                            <button className="connection_apply">Apply Now</button>
                                        </div>
                                    </div>
                                    <div className="exploreMore">
                                        <Link>Click to explore more about {tabDetails.tabName} Service</Link>
                                    </div>
                                </div>
                            </Grid> */}
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="service-list-div">
                  <h5 className="mb-4">{tabDetails} Service</h5>
                  <div>
                    <Grid container spacing={2}>
                      {tabSerive.map((list, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                          <div className="service_data">
                            <h4>{list.name}</h4>
                            <h6>{list.department}</h6>
                            <div className="home-Services-para">
                              <p>{list.brief}</p>
                              <ul className="service_ul">
                                {list.fundingDetails?.map((a, i) => (
                                  <li key={i}>{a}</li>
                                ))}
                              </ul>
                            </div>
                            <div className="arrow-btn-service-sec" >
                              <ArrowForwardIcon
                                onClick={() =>
                                  handleRouteValue(list.redirectPath)
                                }
                              />
                            </div>
                          </div>
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default HomeServicesSection;
