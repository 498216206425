import React, { useContext, useState } from "react";
import Rating from "@mui/material/Rating";
import { Grid } from "@mui/material";
import Stack from "@mui/material/Stack";
import LinearProgress from "@mui/material/LinearProgress";
import DataContext from "../../../../context/DataContext";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import apiService from "../../../../api/apiService";

const SmartRateReviewPage = () => {
  const [value, setValue] = useState(0);
  const { setPageLoading, ratinglist, setRatinglist, totalPage, setTotalPage, page, ratelist, setPage, ratingData, setRatingData,showHighlights, setshowHighlights } = useContext(DataContext)

  let useIds = localStorage.getItem('id')
  useDidMountEffect(() => {
    averagerating()
    ratelist()
    setshowHighlights(false)
  }, [])


  const [avgRate, setAvgRate] = useState([])
  const averagerating = () => {
    setPageLoading(true)
    apiService(`smartcard/rating/details/get?userId=${useIds}`, "", "get").then((res) => {
      setPageLoading(false)
      if (res) {
        setAvgRate(res.data)
        setValue(res.data.avgRating)
      }
    }).catch((err) => {
      console.log(err);

    });
  }
  const totalRatings = Object.values(avgRate).reduce((sum, count) => sum + count, 0);
  const calculatePercentage = (count) => (totalRatings ? (count / totalRatings) * 100 : 0);
  return (
    <div>
      <Grid
        container
        direction="row"
        alignItems="flex-start"
        justifyContent="flex-start"
        spacing={3}
      >
        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
          <div className="investment-whole-div">
            <div className="profile-box2 mt-2">
              <h5 style={{ fontWeight: "600" }} className="mb-3">Ratings & Reviews</h5>
              <div style={{ width: "100%", overflowX: "auto" }}>
              {ratinglist.length > 0 && ( <h5 className="color-blue"><img src={"/images/star-rate.png"} alt="star" /> {ratingData} / 5 <span style={{ color: '#000', fontWeight: 100 }}>({ratinglist.length})</span></h5>)}
                <div className="my-3">
                  {ratinglist.length > 0 ? (
                    <>
                      {ratinglist && ratinglist.map((item, i) => (
                        <div className="rate-boxshad mb-3" key={i}>
                          {/* <div className="mr-2">
                            <img src={item.logo} className="review_logo" alt="logo" />
                          </div>
                          <div>
                            <div className="d-flex align-items-center">
                              <h6>{item.name} </h6>
                              <p className="ml-auto list-para mr-5"><img src={"/images/star-rate.png"} alt="star" /> {item.rating}</p>
                            </div>
                            <p className="mb-0 paras">{item.comment}</p>
                          </div> */}
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                              <div> <img src={item.logo ? item.logo : "/images/user.png"} className="review_logo2" alt="logo" /></div>
                              <div className="ml-3">
                                <h6>{item.name} </h6>

                              </div>
                            </div>
                            <div> <p className="ml-auto list-para mr-5"><img src={"/images/star-rate.png"} alt="star" /> {item.rating}</p></div>
                          </div>
                          <p className="mb-0  paras1">{item.comment}</p>
                        </div>
                      ))}
                    </>
                  ) : (<>
                    <div className="profile-box3 mt-2">
                      <div className="text-center">
                        <h5 style={{ fontWeight: "600" }}>No Ratings & Reviews Are Available.</h5>
                     {/* <img src={'/images/join-us/rb_2150544955.png'} alt="no data" className="nodata"/> */}
                      </div>
                    </div></>)}
                </div>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <div className="set-ratingbox">
            <div>
              <h6 className="avg-rating mb-3">Average Rating</h6>
              <div className="avg-rate-div">
                <h1 >
                  {avgRate.avgRating}
                  <span className="ml-2">
                    <Rating
                      name="simple-controlled"
                      value={value}
                      readOnly={true}
                      onChange={(event, newValue) => {
                        setValue(newValue);
                      }}
                    />
                  </span>
                </h1>
                <p className="para-smcrd-rate">Average Rating on this year</p>
              </div>
              <div>
                <Stack sx={{ width: "100%" }} spacing={2}>
                  <div className="prog-flexs">
                    <span>5</span>
                    <LinearProgress
                      variant="determinate"
                      value={calculatePercentage(avgRate.fiveStar)}
                      color="success"
                      // sx={{ width: `${calculatePercentage(avgRate.fiveStar)}%`, mx: 1 }}
                      sx={{ width: '100%', mx: 1 }}
                    />
                    <span>{avgRate.fiveStar}</span>
                  </div>
                  <div className="prog-flexs">
                    <span>4</span>
                    <LinearProgress
                      variant="determinate"
                      value={calculatePercentage(avgRate.fourStar)}
                      color="error"
                      // sx={{ width: `${calculatePercentage(avgRate.fourStar)}%`, mx: 1 }}
                      sx={{ width: '100%', mx: 1 }}
                    />
                    <span>{avgRate.fourStar}</span>
                  </div>
                  <div className="prog-flexs">
                    <span>3</span>
                    <LinearProgress
                      variant="determinate"
                      value={calculatePercentage(avgRate.threeStar)}
                      color="warning"
                      // sx={{ width: `${calculatePercentage(avgRate.threeStar)}%`, mx: 1 }}
                      sx={{ width: '100%', mx: 1 }}
                    />
                    <span>{avgRate.threeStar}</span>
                  </div>
                  <div className="prog-flexs">
                    <span>2</span>
                    <LinearProgress
                      variant="determinate"
                      value={calculatePercentage(avgRate.twoStar)}
                      color="info"
                      // sx={{ width: `${calculatePercentage(avgRate.twoStar)}%`, mx: 1 }}
                      sx={{ width: '100%', mx: 1 }}
                    />
                    <span>{avgRate.twoStar}</span>
                  </div>
                  <div className="prog-flexs">
                    <span>1</span>
                    <LinearProgress
                      variant="determinate"
                      value={calculatePercentage(avgRate.oneStar)}
                      color="error"
                      // sx={{ width: `${calculatePercentage(avgRate.oneStar)}%`, mx: 1 }}
                      sx={{ width: '100%', mx: 1 }}
                    />
                    <span>{avgRate.oneStar}</span>
                  </div>
                </Stack>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default SmartRateReviewPage;
