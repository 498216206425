import { Autocomplete, Button, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid, Menu, MenuItem, Radio, RadioGroup, Switch, TextField } from '@mui/material';
import React, { useContext, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form';
// import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { FaArrowLeft } from "react-icons/fa6";
import DataContext from '../../../context/DataContext';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import notifyService from '../../../api/notifySerivce';
import DeleteIcon from '@mui/icons-material/Delete';
import apiService from '../../../api/apiService';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import SearchableDropdown from './SearchableDropdown';
const today = dayjs();
const tomorrow = dayjs().add(0, 'day');
const AdminEventAdd = () => {
  const { id } = useParams()
  let eventId
  if (id !== undefined) {
    eventId = atob(id)
  }
  const { navigator, validateEmail, yearListApi, validateNumberonly, setPageLoading, VisuallyHiddenInput, } = useContext(DataContext);
  const linkedInPattern = /^https?:\/\/(www\.)?linkedin\.com\/in\/[A-Za-z0-9-]+\/?$/;
  const [formData, setFormData] = useState({
    "specialParticipants": [
      {
        "id": 0,
        "name": "",
        "organization": "",
        "role": "",
        "linkedIn": ""
      }
    ],
    "managementTeam": [
      {
        "id": 0,
        "name": "",
        "mail": "",
        "role": ""
      }
    ]
  });
  const { handleSubmit, control, setValue, reset, clearErrors, getValues, formState: { errors } } = useForm({ values: formData });
  const {
    fields: participantFields,
    append: participantrAppend,
    remove: participantRemove,
  } = useFieldArray({
    control,
    name: "specialParticipants",
  });
  const {
    fields: managementFields,
    append: managementAppend,
    remove: managementRemove,

  } = useFieldArray({
    control,
    name: "managementTeam",
  });
  useDidMountEffect(() => {
    getEventCategoryList()
    getEventServiceList()
    getParsonaList()
    if (eventId) {
      getApi()
    }
  }, [])
  const getApi = () => {
    setPageLoading(true);
    apiService(`event/get?id=${eventId}`, '', 'get').then((result) => {
      setPageLoading(false);
      if (result && result.data) {
        var data = result.data
        data.paid = data.paid === true ? 'paid' : 'free'
        if (data.startDate) {
          data.startDate = dayjs(data.startDate, "YYYY-MM-DD");
        }
        if (data.endDate) {
          data.endDate = dayjs(data.endDate, "YYYY-MM-DD");
        }

        data.registrationEndDate = dayjs(data.registrationEndDate, "YYYY-MM-DD");
        if (data.startTime) {
          data.startTime = dayjs(data.startTime, "HH:mm");
        }
        if (data.endTime) {
          data.endTime = dayjs(data.endTime, "HH:mm");
        }

        if (data.mode === 'Offline') {
          data.venueId = data.venue
          getVenusNameList()
        }
        // data.paid = data.paid === true ? true : false
        setFormData(data)
      }
    }).catch((err) => {

    });
  }
  const formatDateTime = (dateString) => {
    return dayjs(dateString).format('DD/MM/YYYY HH:mm');
  };
  const handleEventMode = (value) => {
    setValue("mode", value);
    clearErrors('mode')
    if (value === 'Offline') {
      getVenusNameList()
    }
  }
  const handleFeeMode = (value) => {
    setValue("paid", value);
    clearErrors('paid')
    if (value === 'free') {
      setValue('amount', '')
      clearErrors('amount')
    }
  }

  const [startdate, setStartDate] = useState(null)
  const handleStartDateFormat = (newValue) => {
    setStartDate(newValue)
    setValue('startDate', newValue)
    clearErrors('startDate');
    // const datePart = newValue.format('YYYY-MM-DD');
  }
  const [enddate, setEndDate] = useState(null)
  const handleEndDateFormat = (newValue) => {
    setEndDate(newValue)
    setValue('endDate', newValue)
    clearErrors('endDate');
    // const datePart = newValue.format('YYYY-MM-DD');
  }

  const [regdate, setRegdate] = useState(null)
  const handleRegDateFormat = (newValue) => {
    setRegdate(newValue)
    setValue('registrationEndDate', newValue)
    clearErrors('registrationEndDate');
    // const datePart = newValue.format('YYYY-MM-DD');
  }

  const [startTime, setStartTime] = useState(null)
  const handleStartTimeFormat = (newValue) => {
    setStartTime(newValue)
    setValue('startTime', newValue)
    clearErrors('startTime');
    // const timePart = newValue.format('HH:mm');
  }
  const [endTime, setEndTime] = useState(null)
  const handleEndTimeFormat = (newValue) => {
    setEndTime(newValue)
    setValue('endTime', newValue)
    clearErrors('endTime');
    // const timePart = newValue.format('HH:mm');
  }
  // const [bannerImageValue, setBannerImageValue] = useState(null)
  // const [bannerImageName, setBannerImageName] = useState(null)
  // const [bannerImageURL, setBannerImageURL] = useState(null)
  let logoselecetdFile = ''
  const handleFileUpload = (event, filekey, fileName) => {
    if (event !== null) {
      if (event.target === undefined) {
        logoselecetdFile = event
      } else {
        logoselecetdFile = event.target.files[0]
      }
      const maxFileSize = 5 * 1024 * 1024; // 5MB in bytes
      if (logoselecetdFile) {
        if (logoselecetdFile.size < maxFileSize) {
          var reader = new FileReader();
          var imagetype = logoselecetdFile.type
          var imagedatatype = imagetype.split("/")
          var img_crt_type = imagedatatype[1]
          if (img_crt_type === "jpeg" ||
            img_crt_type === "jpg" ||
            img_crt_type === "png") {
            setValue(filekey, "")
            setValue(fileName, "")
            // setName('')
            // setUrl('')
            // if (event.target === undefined) {
            //   setdata(event)
            // } else {
            //   setdata(event.target.files[0])
            // }
            var fileValue = logoselecetdFile
            reader.readAsDataURL(logoselecetdFile);
            reader.onload = () => {
              var logourl1 = reader.result;
              var spl = logourl1.split(',')
              var ImageValue = spl[1]
              var img_name = fileValue.name
              // setUrl(logourl1)
              setValue(filekey, ImageValue)
              setValue(fileName, img_name)
              // setName(img_name)
              clearErrors(filekey);
              clearErrors(fileName);
            }
          } else {
            notifyService('danger', 'File Format Invalided', 'Please check your file format')
          }
        } else {
          notifyService(
            "danger",
            "File size exceeds 5MB",
            "Please check your file size"
          );
        }
      }
    } else {

      // setValue(filekey, "")
      // setValue(fileName, "")
      // setName('')
      // setUrl('')
      // inputRef.current.value = '';
    }

  }
  const [eventCategoryList, setEventCategoryList] = useState(['category 1', 'category 2'])
  const getEventCategoryList = () => {
    apiService(`home/category/list?type=${'event'}`, '', 'unauthget').then((result) => {
      if (result && result.data && result.data.categories) {
        setEventCategoryList(result.data.categories);
      }
    }).catch((err) => {

    });
  }
  const handleSelectEventCategory = (value) => {
    setValue('category', value)
    clearErrors('category')
  }

  const [eventServiceList, setEventServiceList] = useState([])
  const getEventServiceList = () => {
    apiService(`home/service/list`, '', 'unauthget').then((result) => {
      if (result && result.data && result.data.services) {
        setEventServiceList(result.data.services);
      }
    }).catch((err) => {

    });
  }
  const handleSelectEventService = (value) => {
    setValue('service', value)
    clearErrors('service')
  }

  const [eventVenueList, setEventVenueList] = useState([])
  const getVenusNameList = () => {
    apiService(`venue/list`, {}, 'post').then((result) => {
      if (result && result.data && result.data.venues) {
        let data = []
        result.data.venues.forEach(element => {
          data.push(element)
        });
        const val = {
          "id": 0,
          "name": 'Add Venue',
          "location": '',
          "pincode": '',
          "mapLink": '',
        }
        data.push(val)
        setEventVenueList(data)

      } else {
        let data = []
        const val = {
          "id": 0,
          "name": 'Add Venue',
          "location": '',
          "pincode": '',
          "mapLink": '',
        }
        data.push(val)
        setEventVenueList(data)
      }
    }).catch((err) => {

    });
  }
  const handleSelectEventVenue = (value) => {
    var data = eventVenueList.filter((list) => list.name === value)
    setValue('venueId', data[0].name)
    clearErrors('venueId')
    setValue('location', data[0].location)
    clearErrors('location')
    setValue('pincode', data[0].pincode)
    clearErrors('pincode')
    setValue('mapLink', data[0].mapLink)
    clearErrors('mapLink')
  }
  const [participantRoleList, setParticipantRoleList] = useState([])
  const getParsonaList = () => {
    axios.get('/json/profiledetails.json', '').then((result) => {
      if (result.data) {
        setParticipantRoleList(result.data)
      }
    }).catch((err) => {

    });
  }


  const addParticipantBtn = () => {
    participantrAppend({
      id: 0,
      name: "",
      organization: "",
      role: "",
      linkedIn: ""
    });
  };
  const [isOpen, setIsOpen] = useState(false);
  const handleOffParticipantDropDown = (options) => {
    setIsOpen(false)
    var val = options
    if (val !== '') {
      var getFrom = getValues('specialParticipants')
      if (getFrom.length === 1 && getFrom[0].name === '') {
        setValue(`specialParticipants.${0}.id`, 0)
        setValue(`specialParticipants.${0}.userId`, val.id)
        setValue(`specialParticipants.${0}.name`, val.firstName)
        setValue(`specialParticipants.${0}.organization`, val.organizationName)
        setValue(`specialParticipants.${0}.role`, val.persona)
        setValue(`specialParticipants.${0}.linkedIn`, val.linkedIn)
      } else {
        participantrAppend({
          id: 0,
          userId: val.id,
          name: val.firstName,
          organization: val.organizationName,
          role: val.persona,
          linkedIn: val.linkedIn
        });
      }

    }

  }
  const handleSelectParticipantRole = (value, index) => {
    setValue(`specialParticipants.${index}.role`, value);
    clearErrors(`specialParticipants.${index}.role`);
  }
  const handleParticipantDelete = (index) => {
    var id = getValues(`specialParticipants.${index}.id`)

    if (id !== 0) {
      apiService(`event/participantes/remove?id=${id}`, '', 'delete').then((result) => {
        if (result && result.data) {
          participantRemove(index)
        }
      }).catch((err) => {

      });
    } else {
      participantRemove(index)
    }

  }


  const addManagementBtn = () => {
    managementAppend({
      id: 0,
      userId: 0,
      name: "",
      email: "",
      role: ""
    });
  };
  const [isManOpen, setIsManOpen] = useState(false);
  const handleOffManagementDropDown = (options) => {
    setIsManOpen(false)
    var val = options
    if (val !== '') {
      var getFrom = getValues('managementTeam')
      if (getFrom.length === 1 && getFrom[0].name === '') {
        setValue(`managementTeam.${0}.id`, 0)
        setValue(`managementTeam.${0}.userId`, val.id)
        setValue(`managementTeam.${0}.name`, val.firstName)
        setValue(`managementTeam.${0}.email`, val.email)
        setValue(`managementTeam.${0}.role`, val.role)
      } else {
        managementAppend({
          id: 0,
          userId: val.id,
          name: val.firstName,
          email: val.email,
          role: val.role
        });
      }

    }

  }
  const handleSelectManagementTeamRole = (value, index) => {
    setValue(`managementTeam.${index}.role`, value);
    clearErrors(`managementTeam.${index}.role`);
  }
  const handleManagementDelete = (index) => {
    var id = getValues(`managementTeam.${index}.id`)

    if (id !== 0) {
      apiService(`event/management/remove?id=${id}`, '', 'delete').then((result) => {
        if (result && result.data) {
          managementRemove(index)
        }
      }).catch((err) => {

      });
    } else {
      managementRemove(index)
    }
  }



  const onSubmit = (data) => {
    setPageLoading(true);
    if (data.startDate) {
      data.startDate = data.startDate.format('YYYY-MM-DD');
    }
    if (data.endDate) {
      data.endDate = data.endDate.format('YYYY-MM-DD');
    }

    data.registrationEndDate = data.registrationEndDate.format('YYYY-MM-DD');
    if (data.startTime) {
      data.startTime = data.startTime.format('HH:mm');
    }
    if (data.endTime) {
      data.endTime = data.endTime.format('HH:mm');
    }

    var cateValue = eventCategoryList.filter((list) => list.category === data.category)
    data.categoryId = cateValue[0].id
    var serValue = eventServiceList.filter((list) => list.service === data.service)
    data.serviceId = serValue[0].id
    // data.managementTeam.forEach(element => {
    //   var type = participantRoleList.filter((list) => list.profileName === element.role)
    //   element.role = type[0].profileType
    // });
    // data.specialParticipants.forEach(element => {
    //   var type = participantRoleList.filter((list) => list.profileName === element.role)
    //   element.role = type[0].profileType
    // });
    if (data.mode === 'Offline' && data.venueId) {
      var venuData = eventVenueList.filter((list) => list.name === data.venueId)
      data.venueId = venuData[0].id
    }
    data.paid = data.paid === 'paid' ? true : false

    console.log(data)

    apiService('event/save', data, 'post').then((result) => {
      setPageLoading(false);
      if (result && result.data && result.data.responseStatus === "Success") {
        backBtn();
        // supportistApi()
      }
    }).catch((err) => {

    });
  }
  const onError = (event) => {
    console.log("error Data:", event);
    notifyService(
      "danger",
      "Submit Failed",
      "Please check the mandatory fields"
    );
  };
  const handlDiscard = () => {
    reset()
    navigator('admin/event/list')
  }
  const backBtn = () => {
    reset()
    navigator('admin/event/list')
  }
  const options = [
    'Apple',
    'Banana',
    'Cherry',
    'Date',
    'Fig',
    'Grape',
    'Honeydew',
  ];
  return (
    <main className='event_add_section'>
      <div className='event_add_header mb-4'>
        <div className='d-flex w-100 align-items-center'>
          <div>
            <p className="back-btns mb-0" onClick={backBtn}>
              <FaArrowLeft />
              <span style={{ marginLeft: "5px" }}>Back</span>
            </p>
          </div>
          <div className='m-auto'>
            <h4 className='font-color-black'>{eventId ? 'Update' : 'Create'} Event</h4>
            <p>Setup your Event Details to start listing events</p>
          </div>
        </div>
      </div>
      <p className='font-color-black'>Event Details</p>
      <div className=''>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <div className='event_add_details_div mb-4'>
            <Grid container spacing={3}>
              <Grid item lg={3} md={3} sm={12} xs={12} className="mb-3">
                <div className='input_background'>
                  <Controller
                    name="mode"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'Event Mode is required' }}
                    render={({ field }) => (
                      <FormControl>
                        <FormLabel> Choose Event Mode </FormLabel>
                        <RadioGroup row  {...field} onChange={(e) => handleEventMode(e.target.value)}>
                          <FormControlLabel value={'Online'} control={<Radio />} label="Online" />
                          <FormControlLabel value={'Offline'} control={<Radio />} label="Offline" />
                        </RadioGroup>
                      </FormControl>
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.mode && errors.mode.message}
                  </FormHelperText>
                </div>
              </Grid>
              <Grid item lg={3} md={3} sm={12} xs={12} className="mb-3">
                <div className='input_background'>
                  <Controller
                    name="paid"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'Fee Status is required' }}
                    render={({ field }) => (
                      <FormControl>
                        <FormLabel> Registration Fee </FormLabel>
                        <RadioGroup row  {...field} onChange={(e) => handleFeeMode(e.target.value)}>
                          <FormControlLabel value={'free'} control={<Radio />} label="Free" />
                          <FormControlLabel value={'paid'} control={<Radio />} label="Paid" />
                        </RadioGroup>
                      </FormControl>
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.paid && errors.paid.message}
                  </FormHelperText>
                </div>
              </Grid>
              {
                getValues('paid') === 'paid' ? (
                  <Grid item lg={3} md={3} sm={12} xs={12} className="mb-3">
                    <div className='input_background mb-4'>
                      <Controller
                        name="amount"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Registration amount is required" }}
                        render={({ field }) => (
                          <TextField
                            variant="outlined"
                            label="Registration Amount"
                            placeholder="Enter Registration Amount"
                            fullWidth
                            type="text"
                            {...field}
                            onKeyDown={(e) => validateNumberonly(e)}
                          />
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.amount && errors.amount.message}
                      </FormHelperText>
                    </div>
                  </Grid>
                ) : (
                  <Grid item lg={6} md={6} sm={12} xs={12} className="mb-3"></Grid>
                )
              }

              {/* <Grid item lg={8} md={8} sm={12} xs={12} className="mb-3">
                <div className='input_background'>
                  <Controller
                    name="type"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'Event type is required' }}
                    render={({ field }) => (
                      <FormControl>
                        <FormLabel> Choose Event type </FormLabel>
                        <RadioGroup row  {...field} >
                          <FormControlLabel value={'Startup / Enablers Event'} control={<Radio />} label="Startup / Enablers Event" />
                          <FormControlLabel value={'Team Event'} control={<Radio />} label="Team Event (eg. Internal Team Meetings  / Commitee meeting) " />
                        </RadioGroup>
                      </FormControl>
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.type && errors.type.message}
                  </FormHelperText>
                </div>
              </Grid> */}
              <Grid item lg={5} md={5} sm={12} xs={12} className="mb-3">
                <div className='input_background mb-4'>
                  <Controller
                    name="title"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Event Title is required" }}
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        label="Event Title"
                        placeholder="Please Enter Event Title"
                        fullWidth
                        type="text"
                        {...field}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.title && errors.title.message}
                  </FormHelperText>
                </div>
                <div className='input_background mb-4'>
                  <Controller
                    name="description"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Event Description is required" }}
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        label="Event Description"
                        placeholder="Please Enter Event Description"
                        fullWidth
                        type="text"
                        {...field}
                        multiline
                        rows={4}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.description && errors.description.message}
                  </FormHelperText>
                </div>
                <div className='input_background'>
                  <Controller
                    name="imageName"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Event Banner is required" }}
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        label="Upload Event Banner"
                        placeholder="Upload Image"
                        fullWidth
                        type="text"
                        {...field}
                        disabled
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.imageName && errors.imageName.message}
                  </FormHelperText>
                  <div className='d-flex align-items-center w-100 my-3'>
                    <p className='font-sm'>recommended aspect ratio 4:3
                      max size upto 5mb</p>
                    <label className='ml-auto lbl_btn'
                      onChange={(e) => handleFileUpload(e, "image", "imageName")}
                      href="#file-upload">
                      <img src='/images/admin/upload.png' className='mr-3' alt='' />
                      Upload Image
                      <VisuallyHiddenInput type="file" />
                    </label>
                  </div>
                </div>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12} className="mb-3">
                <p>Date & Time</p>
                <Grid container spacing={1}>
                  <Grid item lg={6} md={6} sm={6} xs={6} className="mb-3">
                    <div className='input_background'>
                      <Controller
                        name={`startDate`}
                        control={control}
                        rules={{ required: false }}
                        render={({ field }) => (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker']} fullWidth>
                              <DatePicker label="Start Date" format="DD/MM/YYYY"
                                minDate={tomorrow}
                                value={startdate}
                                {...field}
                                sx={{ width: "100%" }}
                                inputFormat="DD/MM/YYYY"
                                onChange={(newValue) => handleStartDateFormat(newValue)}
                                renderInput={(params) => <TextField {...params} />}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.startDate &&
                          errors.startDate.message}
                      </FormHelperText>
                    </div>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6} className="mb-3">
                    <div className='input_background'>
                      <Controller
                        name={`endDate`}
                        control={control}
                        rules={{ required: false }}
                        render={({ field }) => (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker']} fullWidth>
                              <DatePicker label="End Date" format="DD/MM/YYYY"
                                minDate={startdate ? startdate : tomorrow}
                                value={enddate}
                                {...field}
                                sx={{ width: "100%" }}
                                inputFormat="DD/MM/YYYY"
                                onChange={(newValue) => handleEndDateFormat(newValue)}
                                renderInput={(params) => <TextField {...params} />}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.endDate &&
                          errors.endDate.message}
                      </FormHelperText>
                    </div>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6} className="mb-3">
                    <div className='input_background'>
                      <Controller
                        name={`startTime`}
                        control={control}
                        rules={{ required: false }}
                        render={({ field }) => (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['TimePicker']} fullWidth>
                              <TimePicker label="Start Time " format="HH:mm"
                                value={startTime}
                                {...field}
                                sx={{ width: "100%" }}
                                inputFormat="HH:mm"
                                onChange={(newValue) => handleStartTimeFormat(newValue)}
                                renderInput={(params) => <TextField {...params} />}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.startTime &&
                          errors.startTime.message}
                      </FormHelperText>
                    </div>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6} className="mb-3">
                    <div className='input_background'>
                      <Controller
                        name={`endTime`}
                        control={control}
                        rules={{ required: false }}
                        render={({ field }) => (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['TimePicker']} fullWidth>
                              <TimePicker label="End Time " format="HH:mm"
                                value={endTime}
                                {...field}
                                sx={{ width: "100%" }}
                                inputFormat="HH:mm"
                                onChange={(newValue) => handleEndTimeFormat(newValue)}
                                renderInput={(params) => <TextField {...params} />}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.endTime &&
                          errors.endTime.message}
                      </FormHelperText>
                    </div>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6} className="mb-3">
                    <div className='input_background'>
                      <Controller
                        name={`registrationEndDate`}
                        control={control}
                        rules={{ required: 'Registration End Date is required' }}
                        render={({ field }) => (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker']} fullWidth>
                              <DatePicker label="Registration End Date" format="DD/MM/YYYY"
                                minDate={tomorrow}
                                value={regdate}
                                {...field}
                                sx={{ width: "100%" }}
                                inputFormat="DD/MM/YYYY"
                                onChange={(newValue) => handleRegDateFormat(newValue)}
                                renderInput={(params) => <TextField {...params} />}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.registrationEndDate &&
                          errors.registrationEndDate.message}
                      </FormHelperText>
                    </div>
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12} className="mb-3">
                    <div className='input_background'>
                      <Controller
                        name="registrationLimit"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Registration Limit is required" }}
                        render={({ field }) => (
                          <TextField
                            variant="outlined"
                            label="Registration Limit"
                            placeholder="Enter limit"
                            fullWidth
                            type="text"
                            {...field}
                            onKeyDown={(e) => validateNumberonly(e)}
                          />
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.registrationLimit && errors.registrationLimit.message}
                      </FormHelperText>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12} className="mb-3">
                <Grid container spacing={1}>
                  <Grid item lg={6} md={6} sm={12} xs={12} className="mb-3">
                    <div className='input_background'>
                      <Controller
                        name="category"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Event Category is required" }}
                        render={({ field }) => (
                          <Autocomplete
                            disableClearable
                            disablePortal
                            {...field}
                            options={eventCategoryList.map((name) => name.category)}
                            onChange={(e, selectedOptions) =>
                              handleSelectEventCategory(selectedOptions)
                            }
                            renderInput={(params) => (
                              <TextField {...params} label="Choose Event Category " />
                            )}
                          />
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.category && errors.category.message}
                      </FormHelperText>
                    </div>
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12} className="mb-3">
                    <div className='input_background'>
                      <Controller
                        name="service"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Event Service  is required" }}
                        render={({ field }) => (
                          <Autocomplete
                            disableClearable
                            disablePortal
                            {...field}
                            options={eventServiceList.map((name) => name.service)}
                            onChange={(e, selectedOptions) =>
                              handleSelectEventService(selectedOptions)
                            }
                            renderInput={(params) => (
                              <TextField {...params} label="Choose Event Service  " />
                            )}
                          />
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.service && errors.service.message}
                      </FormHelperText>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {
              getValues('mode') !== '' && (
                <>
                  <p className='font-color-gray'>Venue details</p>
                  <Grid container spacing={3}>
                    {
                      getValues('mode') === 'Online' ? (
                        <>
                          <Grid item lg={5} md={5} sm={12} xs={12} className="mb-3">
                            <p className='font-color-gray'>Online Meeting Details</p>
                            <div className='input_background'>
                              <Controller
                                name="meetLink"
                                control={control}
                                defaultValue=""
                                rules={{ required: "Zoom / Other Meet Link  is required" }}
                                render={({ field }) => (
                                  <TextField
                                    variant="outlined"
                                    label="Zoom / Other Meet Link "
                                    placeholder="Enter meeting Link"
                                    fullWidth
                                    type="text"
                                    {...field}
                                  />
                                )}
                              />
                              <FormHelperText className="text-danger">
                                {errors.meetLink && errors.meetLink.message}
                              </FormHelperText>
                            </div>
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid item lg={3} md={3} sm={12} xs={12} className="mb-3">
                            <div className='input_background'>
                              <Controller
                                name="venueId"
                                control={control}
                                defaultValue=""
                                rules={{ required: "Venue is required" }}
                                render={({ field }) => (
                                  <Autocomplete
                                    disableClearable
                                    disablePortal
                                    {...field}
                                    options={eventVenueList.map((val => val.name))}
                                    onChange={(e, selectedOptions) =>
                                      handleSelectEventVenue(selectedOptions)
                                    }
                                    renderInput={(params) => (
                                      <TextField {...params} label="Venue" />
                                    )}
                                  />
                                )}
                              />
                              <FormHelperText className="text-danger">
                                {errors.venueId && errors.venueId.message}
                              </FormHelperText>
                            </div>
                          </Grid>
                          {
                            getValues('venueId') === 'Add Venue' && (
                              <Grid item lg={3} md={3} sm={12} xs={12} className="mb-3">
                                <div className='input_background'>
                                  <Controller
                                    name="venue"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: "Venue is required" }}
                                    render={({ field }) => (
                                      <TextField
                                        variant="outlined"
                                        label="Venue Name"
                                        placeholder="Enter Venue Name"
                                        fullWidth
                                        type="text"
                                        {...field}
                                      />
                                    )}
                                  />
                                  <FormHelperText className="text-danger">
                                    {errors.venue && errors.venue.message}
                                  </FormHelperText>
                                </div>
                              </Grid>
                            )
                          }

                          <Grid item lg={6} md={6} sm={12} xs={12} className="mb-3">
                            <Grid container spacing={1}>
                              <Grid item lg={6} md={6} sm={12} xs={12}>
                                <div className='input_background'>
                                  <Controller
                                    name="location"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: "Location is required" }}
                                    render={({ field }) => (
                                      <TextField
                                        variant="outlined"
                                        label="Location"
                                        placeholder="Enter Full Address "
                                        fullWidth
                                        type="text"
                                        {...field}
                                      />
                                    )}
                                  />
                                  <FormHelperText className="text-danger">
                                    {errors.location && errors.location.message}
                                  </FormHelperText>
                                </div>
                              </Grid>
                              <Grid item lg={6} md={6} sm={12} xs={12}>
                                <div className='input_background'>
                                  <Controller
                                    name="pincode"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: "Pincode is required" }}
                                    render={({ field }) => (
                                      <TextField
                                        variant="outlined"
                                        label="Pincode"
                                        placeholder="Enter Pincode "
                                        fullWidth
                                        type="text"
                                        {...field}
                                        onKeyDown={(e) => validateNumberonly(e)}
                                        inputProps={{ maxLength: 6, minLength: 6 }}
                                      />
                                    )}
                                  />
                                  <FormHelperText className="text-danger">
                                    {errors.pincode && errors.pincode.message}
                                  </FormHelperText>
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item lg={5} md={5} sm={12} xs={12} className="mb-3">
                            <div className='input_background'>
                              <Controller
                                name="mapLink"
                                control={control}
                                defaultValue=""
                                rules={{ required: "Map Link is required" }}
                                render={({ field }) => (
                                  <TextField
                                    variant="outlined"
                                    label="Map Link"
                                    placeholder="Enter Map Destination link"
                                    fullWidth
                                    type="text"
                                    {...field}
                                  />
                                )}
                              />
                              <FormHelperText className="text-danger">
                                {errors.mapLink && errors.mapLink.message}
                              </FormHelperText>
                            </div>
                          </Grid>
                          {
                            getValues('venueId') === 'Add Venue' && (
                              <Grid item lg={4} md={6} sm={6} xs={12}>
                                <div className='input_background mb-4'>
                                  <Controller
                                    name="saveAddress"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: false }}
                                    render={({ field }) => (
                                      <FormControl>
                                        <FormGroup row  {...field}>
                                          <FormControlLabel control={<Switch />} label="Save to Address Book" />
                                        </FormGroup>
                                      </FormControl>
                                    )}
                                  />
                                  <FormHelperText className="text-danger">
                                    {errors.saveAddress && errors.saveAddress.message}
                                  </FormHelperText>
                                </div>
                              </Grid>
                            )
                          }
                        </>
                      )
                    }

                  </Grid>
                </>
              )
            }

          </div>
          <div className='mb-4'>
            <div className='special_title_header d-flex align-items-center mb-3'>
              <p className='font-color-black mb-0'>Add Special Participants</p>
              <div className='ml-3'>
                <SearchableDropdown isOpen={isOpen} setIsOpen={setIsOpen} handleOffDropDown={handleOffParticipantDropDown} lblName="Search Name" />
              </div>
              <div className='ml-auto'>
                <Button className='add_icon_btn' onClick={addParticipantBtn}>+ Add</Button>
              </div>
            </div>
            <div className='event_add_details_div'>
              {participantFields.map((field, index) => (
                <Grid key={field.id} container spacing={3} alignItems={'center'} className='mb-3'>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <div className='input_background'>
                      <Controller
                        name={`specialParticipants.${index}.name`}
                        control={control}
                        defaultValue=""
                        rules={{ required: "Name is required" }}
                        render={({ field }) => (
                          <TextField
                            variant="outlined"
                            label="Name"
                            placeholder="Enter Full Name "
                            fullWidth
                            type="text"
                            {...field}
                          />
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.specialParticipants?.[index]?.name && errors.specialParticipants?.[index]?.name.message}
                      </FormHelperText>
                    </div>
                  </Grid>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <div className='input_background'>
                      <Controller
                        name={`specialParticipants.${index}.organization`}
                        control={control}
                        defaultValue=""
                        rules={{ required: "Organization Name is required" }}
                        render={({ field }) => (
                          <TextField
                            variant="outlined"
                            label="Organization"
                            placeholder="Enter Organization Name"
                            fullWidth
                            type="text"
                            {...field}
                          />
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.specialParticipants?.[index]?.organization && errors.specialParticipants?.[index]?.organization.message}
                      </FormHelperText>
                    </div>
                  </Grid>
                  <Grid item lg={2} md={2} sm={6} xs={12}>
                    <div className='input_background'>
                      <Controller
                        name={`specialParticipants.${index}.role`}
                        control={control}
                        defaultValue=""
                        rules={{ required: "Role is required" }}
                        render={({ field }) => (
                          <Autocomplete
                            disableClearable
                            disablePortal
                            {...field}
                            options={participantRoleList.map((val => val.profileType))}
                            onChange={(e, selectedOptions) =>
                              handleSelectParticipantRole(selectedOptions, index)
                            }
                            renderInput={(params) => (
                              <TextField {...params} label="Role" />
                            )}
                          />
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.specialParticipants?.[index]?.role && errors.specialParticipants?.[index]?.role.message}
                      </FormHelperText>
                    </div>
                  </Grid>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <div className='input_background'>
                      <Controller
                        name={`specialParticipants.${index}.linkedIn`}
                        control={control}
                        defaultValue=""
                        rules={{
                          required: "linkedIn is required",
                          // pattern: {
                          //   value: linkedInPattern,
                          //   message: "Invalid linkedIn URL"
                          // }
                        }}

                        render={({ field }) => (
                          <TextField
                            variant="outlined"
                            label="linkedIn"
                            placeholder="linkedIn Link"
                            fullWidth
                            type="text"
                            {...field}
                          />
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.specialParticipants?.[index]?.linkedIn && errors.specialParticipants?.[index]?.linkedIn.message}
                      </FormHelperText>
                    </div>
                  </Grid>
                  <Grid item lg={1} md={1} sm={6} xs={12}>
                    <div className='text-center'>
                      <DeleteIcon onClick={() => handleParticipantDelete(index)} sx={{ color: 'red' }} />
                    </div>
                  </Grid>
                </Grid>
              ))
              }
            </div>
          </div>
          <div className='mb-4'>
            <div className='special_title_header d-flex align-items-center mb-3'>
              <p className='font-color-black'>Event Management & Coordination</p>
              <div className='ml-3'>
                <SearchableDropdown isOpen={isManOpen} setIsOpen={setIsManOpen} handleOffDropDown={handleOffManagementDropDown} lblName="Search Name" />
              </div>
              <div className='ml-auto'>
                <Button className='add_icon_btn' onClick={addManagementBtn}>+ Add</Button>
              </div>
            </div>
            <div className='event_add_details_div'>
              {managementFields.map((field, index) => (
                <Grid key={field.id} container spacing={3} alignItems={'center'} className='mb-3'>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <div className='input_background'>
                      <Controller
                        name={`managementTeam.${index}.name`}
                        control={control}
                        defaultValue=""
                        rules={{ required: "Name is required" }}
                        render={({ field }) => (
                          <TextField
                            variant="outlined"
                            label="Name"
                            placeholder="Enter Full Name "
                            fullWidth
                            type="text"
                            {...field}
                          />
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.managementTeam?.[index]?.name && errors.managementTeam?.[index]?.name.message}
                      </FormHelperText>
                    </div>
                  </Grid>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <div className='input_background'>
                      <Controller
                        name={`managementTeam.${index}.email`}
                        control={control}
                        defaultValue=""
                        rules={{ required: "Mail Id is required", validate: validateEmail }}
                        render={({ field }) => (
                          <TextField
                            variant="outlined"
                            label="Mail Id"
                            placeholder="Enter Mail ID"
                            fullWidth
                            type="text"
                            {...field}
                          />
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.managementTeam?.[index]?.email && errors.managementTeam?.[index]?.email.message}
                      </FormHelperText>
                    </div>
                  </Grid>
                  <Grid item lg={2} md={2} sm={6} xs={12}>
                    <div className='input_background'>
                      <Controller
                        name={`managementTeam.${index}.role`}
                        control={control}
                        defaultValue=""
                        rules={{ required: "Role is required" }}
                        render={({ field }) => (
                          <Autocomplete
                            disableClearable
                            disablePortal
                            {...field}
                            options={participantRoleList.map((val => val.profileType))}
                            onChange={(e, selectedOptions) =>
                              handleSelectManagementTeamRole(selectedOptions, index)
                            }
                            renderInput={(params) => (
                              <TextField {...params} label="Role" />
                            )}
                          />
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.managementTeam?.[index]?.role && errors.managementTeam?.[index]?.role.message}
                      </FormHelperText>
                    </div>
                  </Grid>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                  </Grid>
                  <Grid item lg={1} md={1} sm={6} xs={12}>
                    <div className='text-center'>
                      <DeleteIcon onClick={() => handleManagementDelete(index)} sx={{ color: 'red' }} />
                    </div>
                  </Grid>
                </Grid>
              ))
              }
            </div>
          </div>
          <div className="text-center">
            <Button className="discard-btn" onClick={() => handlDiscard()}>
              Discard
            </Button>
            <Button type="submit" className="publish-button">
              Publish
            </Button>
          </div>
        </form>
      </div>
    </main>
  )
}

export default AdminEventAdd