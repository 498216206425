import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
} from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { Link, useNavigate } from "react-router-dom";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Navbar from "../../home-module/navbar-page/Navbar";

const Hackathon = ({ name }) => {

  const navigate = useNavigate();

  const handlenow = () => {
    navigate('/login')
  }

  return (
    <div className="smartCard-page">
      <Navbar />
      <div className="radial-back-layer">
        <div className="round-layers"></div>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="banner-text">
              <div className="sm-banner-chip-text">
                <span>{`${name}   >  HACKATHON`}</span>
                <h1 className="banner-text-gradient">HACKATHON</h1>
                <h6>From Ideas to Impact</h6>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>

      <div className="smart-card-info">
        <div className="grid-1-hacka">
          <div className="hack-layer"></div>
          <Container>
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={2}
            >
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="left-side-smartcardPage mt-5">
                  <h4>What is HACKATHON ?</h4>
                  <p className="mt-3">
                    StartupTN's Hackathon is typically a time-bound competitive
                    event where participants collaborate in teams to build
                    proofs of concept or minimum viable products for a specific
                    pre-defined problem statement. It is a time bound event in
                    which teams made up of both technical and business knowledge
                    get together to collaborate intensively on projects. The aim
                    is to design, build and present a most innovative solution
                    to a problem, and then pitch a final concept, prototype.
                  </p>
                </div>
                <div className="left-content-actions pb-5">
                  <button className="ser-viewbtn">Learn More</button>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="s2g-crd-img mt-5">
                  <img
                    className="s2g-imag"
                    src={"/images/startupLogo.png"}
                    alt="smart-card"
                  />
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
        <div className="how-works-div">
          <Container>
            <Grid
              container
              direction="row"
              alignItems="flex-start"
              justifyContent="center"
              spacing={2}
            >
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div className="works-section mt-5">
                  <h4>How it Works?</h4>
                  <p>
                    Hackathons have some clear advantages over traditional
                    innovation processes. They promote multidisciplinary
                    collaboration and have shorter innovation cycles that are
                    better suited to address fast-changing startup ecosystem
                  </p>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                <div className="layer-bg-smcrd">
                  <div className="works-mesh-layer"></div>
                  <div className="how-works-details">
                    <div className="sm-crd-process">
                      <div className="sm-crd-process-steps">
                        <div className="process-div">
                          <div>
                            <img
                              src={"/images/smcrd-reg.png"}
                              alt="registration"
                              className="process-img"
                            />
                          </div>
                          <div className="process-text">
                            <h3>
                              01 <span>Ideation </span>
                            </h3>
                            <p>
                              Establish clear goals and specific problems to
                              address.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="sm-crd-process-steps">
                        <div className="process-div">
                          <div>
                            <img
                              src={"/images/smcrd.png"}
                              alt="signup"
                              className="process-img"
                            />
                          </div>
                          <div className="process-text">
                            <h3>
                              02 <span>Promoting the Event</span>
                            </h3>
                            <p>
                              Develop a comprehensive promotional plan using
                              various channels
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="sm-crd-process-steps">
                        <div className="process-div">
                          <div>
                            <img
                              src={"/images/fill-application.png"}
                              alt="fill-application"
                              className="process-img"
                            />
                          </div>
                          <div className="process-text">
                            <h3>
                              03 <span>Driving Registrations</span>
                            </h3>
                            <p>
                              Set up an easy-to-use system for participant
                              sign-ups and logistics.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="sm-crd-process-steps">
                        <div className="process-div">
                          <div>
                            <img
                              src={"/images/submit-application.png"}
                              alt="submit-application"
                              className="process-img"
                            />
                          </div>
                          <div className="process-text">
                            <div>
                              <h3>
                                04 <span>Creating Solutions:</span>
                              </h3>
                            </div>
                            <div>
                              <p>
                                Facilitate the creation of balanced teams based
                                on skills and interests
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="sm-crd-process-steps">
                        <div className="process-div">
                          <div>
                            <img
                              src={"/images/track-application.png "}
                              alt="track-application"
                              className="process-img"
                            />
                          </div>
                          <div className="process-text">
                            <h3>
                              05 <span>Judging</span>
                            </h3>
                            <p>
                              Implement a structured evaluation process with
                              pitches and demos
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>

        <Container>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="join-us-now-box">
                <div className="join-us-now-content">
                  <h4>Let’s Get Started</h4>
                  <p className="mb-0">
                    HACKATHON</p>
                  <p>From Ideas to Impact</p>
                  <Button
                    variant="contained"
                    size="large"
                    className="join-now-btn"
                    onClick={handlenow}
                  >
                    Join us Now
                  </Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};

export default Hackathon;
