import { Pagination, Stack, Grid } from "@mui/material";
import React, { useContext, useState } from "react";
import apiService from "../../../../api/apiService";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import DataContext from "../../../../context/DataContext";
import Mediafilter from "../Mediafilter";
import Norecordsfound from "../../../common-pages/Norecordsfound";
import { Link } from "react-router-dom";
const Newsletter = () => {
    const { setPageLoading } = useContext(DataContext)
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [searchInputValue, setSearchInputValue] = useState("");
    const [categoryId, setCategoryId] = useState("");
    useDidMountEffect(() => {
        listApi();
    }, [page, searchInputValue, categoryId]);
    const [eventList, setEventList] = useState([]);

    const listApi = () => {
        setEventList([])
        setPageLoading(true)
        var req = {
            searchString: searchInputValue,
            categoryId: categoryId,
            listSize: 12,
            pageNumber: page,
        };
        apiService(`newsletter/home/list`, req, "unauthpost")
            .then((result) => {
                setPageLoading(false)
                if (result && result.data) {
                    if (result.data.newsLetter) {
                        setEventList(result.data.newsLetter);
                        setTotalPage(result.data.totalPages)
                    }
                }
            })
            .catch((err) => { });
    };
    const handleChange = (event, value) => {
        setPage(value);
    };
    return (
        <>
            <div className="event-title">
                <h2 className="event-titles">Newsletters</h2>
                <div className="event-line">
                    <img src="/images/event-line.png" alt="" />
                </div>
            </div>
            <Grid container direction="row" alignItems="flex-start" justifyContent="center" >
                {/* <Grid item sm={12} md={3} lg={3}><Mediafilter setSearchInputValue={setSearchInputValue} setCategoryId={setCategoryId} type={'newsletter'} seachLableName={'Enter Keywords'} /></Grid> */}
                <Grid item sm={12} md={12} lg={12} >
                    <div className="container">
                        <Grid container direction="row" alignItems="flex-start" justifyContent="flex-start" spacing={2}>
                            {eventList.length > 0 ?
                                eventList.map((item, index) => (
                                    <Grid item sm={12} md={4} lg={3}>
                                        <div className="news-list">
                                            <Link to={item.fileName} target="_blank">
                                                <img src={item.imageName} className="newsletter-img img-fluid" alt="" />
                                            </Link>
                                            <div className="news-title">
                                                <p className="mb-0">{item.title}, {item.date}</p>
                                            </div>
                                        </div>
                                    </Grid>
                                )) : (
                                    <Norecordsfound name="Newsletters Not Found" />
                                )
                            }

                        </Grid>
                        {totalPage > 1 ? (
                            <div className="event-pagination">
                                <Stack spacing={2}>
                                    <Pagination
                                        count={totalPage}
                                        page={page}
                                        onChange={handleChange}
                                    />
                                </Stack>
                            </div>
                        ) : (<></>)}
                    </div>
                </Grid>
            </Grid>
        </>

    );
};
export default Newsletter