import React from "react";
import "./footer.css";
import { Link } from "react-router-dom";
import $ from "jquery";
import { useContext } from "react";
import DataContext from "../../../context/DataContext";
const Footer = () => {
  const { t } = useContext(DataContext);
  $(document).ready(function () {
    $(window).on("scroll", function () {
      if ($(this).scrollTop() > 100) {
        $("#backtotop").fadeIn();
      } else {
        $("#backtotop").fadeOut();
      }
    });
    $("#backtotop").click(function () {
      $("html, body").animate(
        {
          scrollTop: 0,
        },
        600
      );
      return false;
    });
  });

  const scrollToTop = () => {
    $("html, body").animate(
      {
        scrollTop: 0,
      },
      600
    );
  };
  return (
    <>
      <footer className="footer">
        <div className="footer_details ">
          <div className="container">
            <section className="">
              <div className="row">
                <div className="col-lg-12 mb-4">
                  <Link to={"https://startuptn.in/"} target="_blank"  aria-label="Visit startup TN website">
                    <img
                      src="/images/startup-white.png"
                      className="p-0 footer-img"
                      alt="Staruptn Website"
                    />
                    {/* <img src="/images/TamilNadu_Logo.svg" alt="" className="img-fluid nav-logo-profile1"/> */}
                  </Link>

                  {/* <img src="/images/TNLogoWhiteLogo1.png" alt="blue horizontal.png" className='logo_tanfund_img' /> */}
                </div>
                <div className="col-lg-6 col-md-6 footer-text-gray">
                  <h3 className="my-3 font-weight-bolder c-gray">
                    {t("Footer.contact")}
                  </h3>
                  <ul className="list-unstyled mb-0">
                    <li className="set_flex">
                      <div>
                        <img
                          src="/images/location.png"
                          className="foo_icon_img"
                          alt=""
                        />
                      </div>
                      <div>
                        <p>
                          {t("Footer.add1")} <br /> {t("Footer.add2")},<br />{" "}
                          {t("Footer.add3")}, <br /> {t("Footer.add4")}
                        </p>
                      </div>
                    </li>
                    <li className="set_flex">
                      <div>
                        <img
                          src="/images/mail-footer.png"
                          className="foo_icon_img"
                          alt=""
                        />
                      </div>
                      <p>support@startuptn.in</p>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-3 col-md-3 footer-text-gray">
                  <h3 className="my-3 font-weight-bolder c-gray">
                    {t("Footer.quicklinks")}
                  </h3>
                  <ul className="list-unstyled mb-0">
                    <li className="pb-3">
                      <Link
                        className="footer-text-gray"
                        to=""
                        onClick={scrollToTop}
                      >
                        {t("Footer.forstartups")}
                      </Link>
                    </li>
                    <li className="pb-3">
                      <Link
                        className="footer-text-gray"
                        to=""
                        onClick={scrollToTop}
                      >
                        {t("Footer.forenablers")}
                      </Link>
                    </li>
                    <li className="pb-3">
                      <Link
                        className="footer-text-gray"
                        to="/ecosystem-info"
                        onClick={scrollToTop}
                      >
                        {t("Footer.ecosystem")}
                      </Link>
                    </li>
                    <li className="pb-3">
                      <Link
                        className="footer-text-gray"
                        to="/events/list"
                        onClick={scrollToTop}
                      >
                        {t("Footer.events")}
                      </Link>
                    </li>
                    <li className="pb-3">
                      <Link
                        className="footer-text-gray"
                        to="/resource-page"
                        onClick={scrollToTop}
                      >
                        {t("Footer.resources")}
                      </Link>
                    </li>
                    <li className="pb-3">
                      <Link
                        className="footer-text-gray"
                        to="/tender"
                        onClick={scrollToTop}
                      >
                        {t("Footer.tenders")}
                      </Link>
                    </li>
                    <li className="pb-3">
                      <Link
                        className="footer-text-gray"
                        to="/careers"
                        onClick={scrollToTop}
                      >
                        {t("Footer.careers")}
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-3 col-md-3 footer-text-gray">
                  <h3 className="my-3 font-weight-bolder c-gray">
                    {t("Footer.legallinks")}
                  </h3>
                  <ul className="list-unstyled mb-0">
                    <li className="pb-3">
                      <Link
                        className="footer-text-gray"
                        to="/privacy-policy"
                        onClick={scrollToTop}
                      >
                        {t("Footer.privacypolicy")}
                      </Link>
                    </li>
                    <li className="pb-3">
                      <Link
                        className="footer-text-gray"
                        to="/terms-condition"
                        onClick={scrollToTop}
                      >
                        {t("Footer.termscondition")}
                      </Link>
                    </li>
                    <li className="pb-3">
                      <Link
                        className="footer-text-gray"
                        to="/contactus"
                        onClick={scrollToTop}
                      >
                        {t("Footer.contactsupport")}
                      </Link>
                    </li>

                    {/* <li className='pb-3'>
                      <Link className="footer-text-gray" to='' onClick={scrollToTop} >
                        FAQ's Page
                      </Link>
                    </li> */}
                  </ul>
                </div>
                <div className="col-lg-6 col-md-6"></div>
                <div className="col-lg-6 col-md-6">
                  <div className="my-3">
                    <div className="social_icon">
                      <Link
                        to={"https://www.facebook.com/TheStartupTN/"}
                        target="_blank"
                        aria-label="Visit The Startup TN Facebook page"
                      >
                        <img
                          src="/images/facebook.svg"
                          className="img-fluid"
                          alt="Facebook logo"
                        />
                      </Link>
                      <Link to={"https://x.com/TheStartupTN"} target="_blank"  aria-label="Visit The Startup TN on X (formerly Twitter)">
                        <img
                          src="/images/logo/icons8-twitter.svg"
                          className="img-fluid"
                          alt="Twitter logo"
                          style={{
                            width: "35px",
                            borderRadius: "50%",
                            background: "#fff",
                          }}
                        />
                      </Link>
                      <Link
                        to={
                          "https://www.linkedin.com/company/thestartuptn/?originalSubdomain=in"
                        }
                        target="_blank"
                         aria-label="Visit The Startup TN on LinkedIn"
                      >
                        <img
                          src="/images/linkedin.svg"
                          className="img-fluid"
                         alt="LinkedIn logo"
                        />
                      </Link>
                      <Link
                        to={"https://www.instagram.com/thestartuptn/"}
                        target="_blank"
                         aria-label="Visit The Startup TN on Instagram"
                      >
                        <img
                          src="/images/instagram.svg"
                          className="img-fluid"
                            alt="Instagram logo"
                        />
                      </Link>
                      <Link
                        to={
                          "https://www.youtube.com/channel/UCr0du18taGeXH35dZZD4RnQ"
                        }
                        target="_blank"
                         aria-label="Visit The Startup TN on YouTube"
                      >
                        <img
                          src="/images/youtube.svg"
                          className="img-fluid"
                         alt="YouTube logo"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* <p className='hr' /> */}
            {/* <div className="text-center">
            

          </div> */}
          </div>
        </div>
        <span id="backtotop" title="Back to top" style={{ display: "none" }}>
          &uarr;
        </span>
      </footer>
    </>
  );
};

export default Footer;
