// import React, { useContext, useEffect, useState } from "react";
// import axios from "axios";
// import List from "@mui/material/List";
// import ListItem from "@mui/material/ListItem";
// import ListItemText from "@mui/material/ListItemText";
// import ListItemAvatar from "@mui/material/ListItemAvatar";
// import Avatar from "@mui/material/Avatar";
// import { FaArrowLeft } from "react-icons/fa6";
// import { FiArrowRight, FiArrowUpRight } from "react-icons/fi";
// import { Button, Container, Grid } from "@mui/material";
// import useDidMountEffect from "../../../hooks/useDidMountEffect";
// import DataContext from "../../../context/DataContext";

// const StartupMenu = ({ handleCloseStartupMenu }) => {
//     const { navigator } = useContext(DataContext)
//     const [clickedButton, setClickedButton] = useState(0);
//     const [hoveredButton, setHoveredButton] = useState(0);
//     const [tabDetailsList, setTabDetailsList] = useState([]);
//     useDidMountEffect(() => {
//         getApi()
//     }, [])
//     const getApi = () => {
//         axios.get('/json/startupmenu.json', '').then((result) => {
//             if (result.data) {
//                 setTabDetailsList(result.data)
//                 setSelectServiceData(result.data[0])
//                 if (result.data[0].serviceList) {
//                     setTabDetails(result.data[0].serviceList);
//                 }
//             }
//         })
//     }
//     const [tabDetails, setTabDetails] = useState([]);
//     const [selectServiceData, setSelectServiceData] = useState({});
//     const handleClick = (index) => {
//         setClickedButton(index);
//         const data = tabDetailsList[index];
//         setSelectServiceData(data)
//         if (data.serviceList) {
//             setTabDetails(data.serviceList);
//         }
//     };

//     const handleMouseEnter = (index) => {
//         if (clickedButton !== index) {
//             setHoveredButton(index);
//         }
//     };

//     const handleMouseLeave = (index) => {
//         if (clickedButton !== index) {
//             setHoveredButton(null);
//         }
//     };

//     const handleRouteValue = (path) => {
//         if (path !== '') {
//             handleCloseStartupMenu(false)
//             navigator(path)
//         }
//     }

//     return (

//         // <Container>
//         <div className="set-bg-menu">
//             <Grid
//                 container
//                 direction="row"
//                 justifyContent="space-between"
//                 alignItems="flex-start"
//                 spacing={3}
//             >
//                 <Grid className="grid1" item xs={12} sm={12} md={3} lg={3} xl={3}>
//                     <div className="mobile-flex">
//                         <div className="start">
//                             <p>Startup Services</p>
//                         </div>
//                         <div className="mobile-back-btn">
//                             <div className="back-BTN">
//                                 <Button
//                                     onClick={() => handleCloseStartupMenu(false)}
//                                     className="BTN-back"
//                                     startIcon={<FaArrowLeft style={{ fontSize: "15px" }} />}
//                                 >
//                                     Back
//                                 </Button>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="list">
//                         <List>
//                             {tabDetailsList.map((list, index) => (
//                                 <ListItem key={index}>
//                                     <ListItemAvatar>
//                                         <Avatar className="avatar">
//                                             <img
//                                                 className="icons"
//                                                 src={list.iconImg}
//                                                 alt={list.tabName}
//                                             />
//                                         </Avatar>
//                                     </ListItemAvatar>
//                                     <Button
//                                         className={clickedButton === index ? 'Btn-text actMenuBtn' : 'Btn-text'}
//                                         onClick={() => handleClick(index)}
//                                         onMouseEnter={() => handleMouseEnter(index)}
//                                         onMouseLeave={() => handleMouseLeave(index)}
//                                     >
//                                         {list.tabName}{" "}
//                                         {clickedButton === index ? (
//                                             <FiArrowRight />
//                                         ) : hoveredButton === index ? (
//                                             <FiArrowUpRight />
//                                         ) : null}
//                                     </Button>
//                                 </ListItem>
//                             ))}
//                         </List>
//                     </div>

//                 </Grid>
//                 <Grid className="grid2" item xs={12} sm={12} md={6} lg={6} xl={6}>
//                     <div className="">
//                         {selectServiceData !== null && (
//                             <div>
//                                 <Avatar className="avatar">
//                                     <img src={selectServiceData.iconImg} alt="" />
//                                 </Avatar>
//                                 <p className="listname">
//                                     {selectServiceData.tabName}
//                                     <FiArrowRight />
//                                 </p>

//                                 <List className="droplist">
//                                     {tabDetails.map((dropItem) => (
//                                         <ListItem key={dropItem.serviceId} className="dropitem">
//                                             <ListItemText primary={dropItem.serviceName} onClick={() => handleRouteValue(dropItem.path)} className="set_hyper_link"/>
//                                         </ListItem>
//                                     ))}
//                                 </List>
//                             </div>
//                         )}
//                     </div>
//                 </Grid>
//                 <Grid className="grid3" item xs={12} sm={12} md={3} lg={3} xl={3}>
//                     <div className="lab-back-btn">
//                         <div className="back-BTN">
//                             <Button
//                                 onClick={() => handleCloseStartupMenu(false)}
//                                 className="BTN-back"
//                                 startIcon={<FaArrowLeft style={{ fontSize: "15px" }} />}
//                             >
//                                 Back
//                             </Button>
//                         </div>
//                     </div>
//                     <div>
//                         <div className="mini1">
//                             <div>
//                                 <Avatar className="avatar1">
//                                     <img className="icons1" src="/images/icon/Star.png" alt="" />
//                                 </Avatar>
//                             </div>
//                             <div>
//                                 <p className="start1">Features</p>
//                                 <Button className="Btn-texts">
//                                     Browse all
//                                     <FiArrowRight />
//                                 </Button>
//                             </div>
//                         </div>
//                         <div className="mini1">
//                             <div>
//                                 <Avatar className="avatar1">
//                                     <img className="icons1" src="/images/icon/Filter.png" alt="" />
//                                 </Avatar>
//                             </div>
//                             <div>
//                                 <p className="start1">Resources</p>
//                                 <Button className="Btn-texts">
//                                     More resources
//                                     <FiArrowRight />
//                                 </Button>
//                             </div>
//                         </div>
//                         <div className="mini1">
//                             <div>
//                                 <Avatar className="avatar1">
//                                     <img className="icons1" src="/images/icon/glass.png" alt="" />
//                                 </Avatar>
//                             </div>
//                             <div>
//                                 <p className="start1">About us</p>
//                                 <Button className="Btn-texts">
//                                     Learn more
//                                     <FiArrowRight />
//                                 </Button>
//                             </div>
//                         </div>
//                     </div>
//                 </Grid>
//             </Grid>
//         </div>
//         // </Container>

//     );
// };

// export default StartupMenu

import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import DataContext from "../../../context/DataContext";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";

const StartupMenu = ({ handleCloseStartupMenu }) => {
  const { navigator } = useContext(DataContext);
  const [tabDetailsList, setTabDetailsList] = useState([]);

  useEffect(() => {
    getApi();
  }, []);

  const getApi = () => {
    axios.get("/json/startupNavMenu.json").then((result) => {
      if (result.data) {
        setTabDetailsList(result.data);
      }
    });
  };

  const handleRouteValue = (path) => {
    if (path) {
      handleCloseStartupMenu();
      navigator(path);
    }
  };

  const handleExploremore = (path) => {
    navigator(path)
    handleCloseStartupMenu();
  }

  return (
    <div className="set-bg-menu">
      <div className="startup-menu">
        {tabDetailsList.map((tab, index) => (
          <div className="tab" key={index}>
            <div className="tab-header">
              <img src={tab.mainMenuLogo} alt={tab.mainMenuLogo} />
              <h6 className="mt-2">{tab.mainMenuName}</h6>
            </div>
            <ul className="service-list">
              {tab.subMenu.map((service, i) => (
                <li className="service" key={i}>
                  <span
                    className="c-pointer"
                    onClick={() => handleRouteValue(service.path)}
                  >
                    {service.subMenuName}
                  </span>
                </li>
              ))}
              <p className="explore-more"  onClick={() => handleExploremore(tab.path)}>
                {tab.more} <FaArrowRight />
              </p>
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StartupMenu;
