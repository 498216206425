import React, { useContext, useState } from 'react';
import apiService from '../../api/apiService';
import { Button, Dialog, DialogContent } from '@mui/material';
import DataContext from '../../context/DataContext';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import * as CryptoJS from 'crypto-js';
const CCAvenueIntegration = () => {
  const { setPageLoading, fullScreen } = useContext(DataContext);
  const [signature, setSignature] = useState('');
  const [opens, setOpens] = useState(false);
  const callBack = `${process.env.REACT_APP_CCAVENUECALLBACK}`;
  const generateSignature = () => {
    // setPageLoading(true)
    const newOrderId = `order-${Math.random().toString(36).substr(2, 9)}-${Date.now()}`;
    // var req = {
    //   orderId: newOrderId,
    //   amount: '10.56',
    //   currency: 'INR',
    //   redirectUrl: callBack,
    //   cancelUrl: callBack,
    // }
    var ccavenuemerchantid = 3443286
    var originalString = "merchant_id=" + ccavenuemerchantid + "&order_id=" + newOrderId + "&amount=" + '10.56' + "&currency=" + 'INR' + "&redirect_url=" + callBack + "&cancel_url=" + callBack;
    const ciphertext = CryptoJS.AES.encrypt(originalString, 'F919CE411AF31F11A334DE51BA61BE47').toString();
    console.log(originalString);
    console.log(ciphertext);
    setSignature(ciphertext)
    setOpens(true)
    // apiService('user/ccavanue/hash', req, 'post').then((result) => {
    //   setPageLoading(false)
    //   if (result && result.data && result.data.hash) {
    //     setSignature(result.data.hash)
    //     setOpens(true)
    //   }
    // }).catch((err) => {

    // });
    // const response = await fetch('http://localhost:3001/generate-signature', {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify(formData),
    // });
    // const data = await response.json();
    // setSignature(data.hash);
  };
  const handleClose = () => {
    setOpens(false)
  }
  // https://secure.ccavenue.com/transaction/transaction.do
  return (
    <>
      {/* <button onClick={generateSignature}>Proceed to Payment</button> */}
      <Button
        onClick={generateSignature}
        variant="contained"
        className="ml-lg-3 ml-md-3 ml-2 mt-lg-0 mt-md-0 mt-3"
        sx={{ borderRadius: "50px", padding: "10px 40px" }}
      >
        Proceed to Payment
      </Button>
      <Dialog fullScreen={fullScreen} maxWidth={"lg"} open={opens} >
        <DialogContent sx={{ minWidth: '800px' }}>
          <div className='mb-4'>
            <span
              style={{ color: "#2260FF", cursor: "pointer" }}
              onClick={handleClose}
            >
              <ArrowBackIcon /> Back
            </span>
          </div>
          <iframe
            src={`https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction&encRequest=${signature}&access_code=AVXL79LD16BF30LXFB`}
            width="100%"
            height="600"
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CCAvenueIntegration;