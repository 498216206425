import { Autocomplete, Button, Checkbox, FormControlLabel, FormHelperText, Grid, InputAdornment, TextField } from "@mui/material";
import React, { useContext, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import DataContext from "../../../../context/DataContext";
import apiService from "../../../../api/apiService";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import Accordion from "@mui/material/Accordion";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import notifyService from "../../../../api/notifySerivce";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Viewimage from "../../../../api/Viewimage";
import cryptoJS from "../../../../hooks/cryptoJS";
import cryptodecryptJS from "../../../../hooks/cryptodecryptJS";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
const Foundedetails = ({ url, navigate }) => {
  const { isWhitespace, navigator, validateEmail, validateNumberonly, setPageLoading, handleNextClick, fastUrlPath, validatePhoneNumber, VisuallyHiddenInput, linkedInPattern } = useContext(DataContext);
  const [formData, setFormData] = useState({});
  const { handleSubmit, control, getValues, setValue, clearErrors, formState: { errors } } = useForm({ values: formData });
  const {
    fields: founderFields,
    append: founderAppend,
    remove: founderRemove,
  } = useFieldArray({
    control,
    name: "founders",
  });
  const [expanded, setExpanded] = useState(founderFields.length ? "panel0" : "");
  useDidMountEffect(() => {
    if (founderFields.length === 1) {
      setExpanded("panel0");
    }
  }, [founderFields]);
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  useDidMountEffect(() => {
    handleNextClick(93);
    DesignationApi();
    dropdownApi();
  }, []);
  const [designation, setDesignation] = useState([]);
  let designationsValue
  // const DesignationApi = () => {
  //   apiService(`home/designation/list`, "", "get")
  //     .then((result) => {
  //       if (result && result.data) {
  //         if (result.data.designations) {
  //           setDesignation(result.data.designations);
  //           designationsValue = result.data.designations
  //         }
  //       }
  //     })
  //     .catch((err) => { });
  // };
  const DesignationApi = () => {
    apiService(`admin/dropdownvalues/get?key=${"designation"}`, "", "unauthget")
      .then((result) => {
        if (result && result.data) {
          if (result.data.dropDownValues.values) {
            setDesignation(result.data.dropDownValues.values);

          }
        }
      })
      .catch((err) => { });
  };
  const [gender, setGender] = useState([]);
  const dropdownApi = () => {
    apiService(`admin/dropdownvalues/get?key=${"gender"}`, "", "unauthget")
      .then((result) => {

        if (result) {
          if (result.data) {
            if (result.data.dropDownValues) {
              var dropDown = result.data.dropDownValues;
              setGender(dropDown.values);
            }
          }
        }
      })
      .catch((err) => { });
  };
  useDidMountEffect(() => {
    getApi();
  }, []);
  const getApi = () => {
    setPageLoading(true)
    var id = localStorage.getItem("id");
    var type = localStorage.getItem('type')
    apiService(`userprofile/get?persona=${type}&userid=${id}`, "", "get")
      .then((result) => {
        setPageLoading(false)
        if (result && result.data) {
          var data = result.data;
          if (data.founders) {
            data.founders.forEach(element => {
              var designationId = Number(element.designation)
              if (designationsValue) {
                designationsValue.forEach(value => {
                  if (value.id === designationId) {
                    element.designation = value.name
                  }
                });
              }
              if (element.email) {
                element.email = cryptodecryptJS(element.email);
              }
              if (element.phone) {
                element.phone = cryptodecryptJS(element.phone);
              }
              element.emailVerify = true
            });
          }
          if (data.teamSize) {
            setTeamSize(data.teamSize)
            setTeamValiation(false)
          }
        }
        setFormData(data);
      })
      .catch((err) => { });
  };
  const addFounderBtn = () => {
    var value = getValues('founders')
    console.log(value);
    // debugger
    if (value === undefined || (value[value.length - 1].id !== 0 && value[value.length - 1].emailVerify === true)) {
      founderAppend({
        id: 0,
        type: localStorage.getItem("role"),
        name: "",
        email: "",
        phone: "",
        gender: "",
        designation: "",
        profile: "",
        profileName: "",
        profileUrl: "",
        emailVerify: ''
      });
    } else if (value[value.length - 1].id === 0 && value[value.length - 1].emailVerify === true) {
      founderAppend({
        id: 0,
        type: localStorage.getItem("role"),
        name: "",
        email: "",
        phone: "",
        gender: "",
        designation: "",
        profile: "",
        profileName: "",
        profileUrl: "",
        emailVerify: ''
      });
    } else {
      // debugger
      notifyService('danger', 'Error', 'Please fill the mandatory fields')
    }

  };
  const founderDetailsRemove = (index) => {
    var id = getValues(`founders.${index}.id`);
    if (id !== 0 && id !== undefined) {
      apiService(`userprofile/founder/remove?id=${id}`, '', 'delete').then((result) => {
        if (result && result.data) {
          founderRemove(index);
          getApi()
        }
      }).catch((err) => {
      });
    } else {
      founderRemove(index);
    }

  };
  const handleSelectDesignation = (value, index) => {
    setValue(`founders.${index}.designation`, value);
    clearErrors(`founders.${index}.designation`);
  };
  let logoselecetdFile = "";
  const handleFileUpload = (event, filekey, profileName, arrayName, index) => {
    if (event !== null) {
      if (event.target === undefined) {
        logoselecetdFile = event;
      } else {
        logoselecetdFile = event.target.files[0];
      }
      const maxFileSize = 5 * 1024 * 1024;
      if (logoselecetdFile) {
        if (logoselecetdFile.size < maxFileSize) {
          var reader = new FileReader();
          var imagetype = logoselecetdFile.type;
          var imagedatatype = imagetype.split("/");
          var img_crt_type = imagedatatype[1];
          if (
            img_crt_type === "jpeg" ||
            img_crt_type === "jpg" ||
            img_crt_type === "png"
          ) {
            var fileValue = logoselecetdFile;
            reader.readAsDataURL(logoselecetdFile);
            reader.onload = () => {
              var logourl1 = reader.result;
              var spl = logourl1.split(",");
              var ImageValue = spl[1];
              var img_name = fileValue.name;
              if (arrayName !== "") {
                setValue(`${arrayName}.${index}.${filekey}`, ImageValue);
                setValue(`${arrayName}.${index}.${profileName}`, img_name);
                // setValue(`${arrayName}.${index}.${profileUrl}`, logourl1);
              } else {
                setValue(filekey, ImageValue);
                setValue(profileName, img_name);
                // setValue(profileUrl, logourl1);
              }
              clearErrors(filekey);
              clearErrors(profileName);
              // clearErrors(profileUrl);
            };
          } else {
            notifyService(
              "danger",
              "File Format Invalid",
              "Please check your file format"
            );
          }
        } else {
          notifyService(
            "danger",
            "File Size Exceeded",
            `Maximum file size allowed is 5 MB`
          );
        }
      }
    }
  };
  const [open, setOpen] = useState(false);
  const [viewImage, setViewImage] = useState("");
  const [viewImageName, setViewImageName] = useState("");
  const handleClickOpen = (value, name) => {
    setViewImage(value);
    setViewImageName(name);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [emailVerify, setEmailVerify] = useState(null)
  const handleEmailVerfication = (value, index) => {
    setEmailVerify(null)
    if (value.length > 3) {
      var email = value.toLowerCase()
      apiService(`founder/email/verify?email=${cryptoJS(email)}`, '', 'get').then((result) => {
        if (result && result.data && result.data.responseStatus === 'Success') {
          setEmailVerify(true)
          setValue(`founders.${index}.emailVerify`, true)
        } else {
          setEmailVerify(false)
          notifyService('danger', 'Error', result.data.responseMessage)
          setValue(`founders.${index}.emailVerify`, false)
        }
      }).catch((err) => {
        setEmailVerify(null)
        setValue(`founders.${index}.emailVerify`, null)
      });
    }
  }
  const [teamSize, setTeamSize] = useState(0)
  const handleTeamSize = (value) => {
    setTeamSize(value)
    setTeamValiation(false)
  }
  const [teamValiation, setTeamValiation] = useState(false)
  const onSubmit = (data) => {
    if (url === 'mediablogngo' || url === 'startupsserviceproviders' || url === 'startup') {
      data.profileUpdated = true;
    }
    if (teamSize !== 0) {
      data.teamSize = teamSize;
      // setTeamValiation(false);
    } else {
      // return setTeamValiation(true)
    }
    var value = getValues('founders') || [];
    if (value.length === 0 || (value.length > 0 && value[value.length - 1].emailVerify === true)) {
      if (data.founders) {
        data.founders.forEach(founder => {
          if (founder.email) {
            founder.email = cryptoJS(founder.email.toLowerCase());
          }
          if (founder.phone) {
            founder.phone = cryptoJS(founder.phone);
          }
        });
      }
      debugger
      apiService('userprofile/save', data, 'post')
        .then((result) => {
          if (result && result.data && result.data.responseStatus === "Success") {
            if (url === 'mediablogngo' || url === 'startupsserviceproviders' || url === 'startup') {
              localStorage.setItem('profile', true)
              localStorage.setItem('email', data.email)
              navigator(navigate);
            } else {
              navigator(navigate);
            }           
          }
        })
        .catch((err) => {
        });
    } else {
      return notifyService('danger', 'Error', 'Email Already Exists')
    }
  };

  const onError = (event) => {
    console.log('error Data:', event);
    notifyService('danger', 'Form is invalid', 'Please check the mandatory fields')
  };
  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <div className="signup-data_div">
            <div className="forms-title d-flex">
              <p>
                Team Info {`>`}{" "}
                <span className="path_sapn">Team Details</span>
              </p>
              <p className="ml-auto">
                <span className="count_num">1</span>/1
              </p>
            </div>
            {/* <div class="alert alert-primary" role="alert">
              Startup Thiruvizha' 2024 - Add Members here to book Conference / Visitor Pass
            </div> */}
            <div className="signup_box_div mt-4">
              <form onSubmit={handleSubmit(onSubmit, onError)}>
                <Grid container spacing={1}>
                  <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                    {founderFields.length ? (
                      <>
                        <div className="text-right mb-3">
                          <Button
                            variant="outlined"
                            onClick={() => addFounderBtn()}
                          >
                            + Team Member
                          </Button>
                        </div>
                        {founderFields.map((field, index) => (
                          <Accordion
                            key={index}
                            expanded={expanded === `panel${index}`}
                            onChange={handleChange(`panel${index}`)}
                          >
                            <AccordionSummary
                              aria-controls="panel1d-content"
                              id={`panel${index}d-header`}
                            >
                              <div className="d-flex w-100 align-item-center justify-content-between">
                                <h5>
                                  {field.name ? field.name : `Add Team Member`}
                                </h5>
                                {founderFields.length !== 1 && (
                                  <Button
                                    className="red_btn"
                                    onClick={() => founderDetailsRemove(index)}
                                  >
                                    <DeleteForeverIcon />
                                  </Button>
                                )}
                              </div>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Grid container spacing={1}>
                                <Grid
                                  item
                                  lg={6}
                                  md={6}
                                  sm={12}
                                  xs={12}
                                  className="mb-3"
                                >
                                  <Controller
                                    name={`founders.${index}.name`}
                                    control={control}
                                    defaultValue=""

                                    rules={{
                                      required: 'Name is required', validate: {
                                        noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                                      },
                                    }}
                                    render={({ field }) => (
                                      <TextField
                                        variant="outlined"
                                        label="Name *"
                                        placeholder="Enter  Name"
                                        fullWidth
                                        type="text"
                                        {...field}
                                        error={errors.founders?.[index]?.name &&
                                          errors.founders?.[index]?.name}
                                      />
                                    )}
                                  />
                                  <FormHelperText className="text-danger">
                                    {errors.founders?.[index]?.name &&
                                      errors.founders?.[index]?.name.message}
                                  </FormHelperText>
                                </Grid>
                                <Grid
                                  item
                                  lg={6}
                                  md={6}
                                  sm={12}
                                  xs={12}
                                  className="mb-3"
                                >
                                  <Controller
                                    name={`founders.${index}.linkedin`}
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                      required: false,
                                      // pattern: {
                                      //   value: linkedInPattern,
                                      //   message: "Invalid LinkedIn profile URL",
                                      // },
                                    }}
                                    render={({ field }) => (
                                      <TextField
                                        variant="outlined"
                                        label="LinkedIn"
                                        placeholder="Enter LinkedIn"
                                        fullWidth
                                        type="text"
                                        {...field}
                                        error={errors.founders?.[index]?.linkedin &&
                                          errors.founders?.[index]?.linkedin}
                                      />
                                    )}
                                  />
                                  <FormHelperText className="text-danger">
                                    {errors.founders?.[index]?.linkedin &&
                                      errors.founders?.[index]?.linkedin.message}
                                  </FormHelperText>
                                </Grid>
                                <Grid
                                  item
                                  lg={6}
                                  md={6}
                                  sm={12}
                                  xs={12}
                                  className="mb-3"
                                >
                                  <Controller
                                    name={`founders.${index}.email`}
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                      required: "Mail ID is required",
                                      validate: validateEmail,
                                    }}
                                    render={({ field }) => (
                                      <TextField
                                        variant="outlined"
                                        label="Mail ID *"
                                        placeholder="Enter Mail ID"
                                        fullWidth
                                        type="text"
                                        {...field}
                                        error={errors.founders?.[index]?.email &&
                                          errors.founders?.[index]?.email}
                                        onInput={(e) => handleEmailVerfication(e.target.value, index)}
                                        InputProps={{
                                          endAdornment: getValues(`founders.${index}.emailVerify`) === true ? (
                                            <InputAdornment position="end">
                                              <CheckCircleIcon color="success" />
                                            </InputAdornment>
                                          ) : getValues(`founders.${index}.emailVerify`) === false ? (
                                            <InputAdornment position="end">
                                              <CloseIcon color="error" />
                                            </InputAdornment>
                                          ) : null,
                                        }}
                                      />
                                    )}
                                  />
                                  <FormHelperText className="text-danger">
                                    {errors.founders?.[index]?.email &&
                                      errors.founders?.[index]?.email.message}
                                  </FormHelperText>
                                </Grid>
                                <Grid
                                  item
                                  lg={6}
                                  md={6}
                                  sm={12}
                                  xs={12}
                                  className="mb-3"
                                >
                                  <Controller
                                    name={`founders.${index}.phone`}
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                      required: "Phone Number is required",
                                      validate: validatePhoneNumber,
                                    }}
                                    render={({ field }) => (
                                      <TextField
                                        variant="outlined"
                                        label="Phone Number *"
                                        placeholder="Enter Phone Number"
                                        fullWidth
                                        type="text"
                                        {...field}
                                        onKeyDown={(e) => validateNumberonly(e)}
                                        inputProps={{ maxLength: 10 }}
                                        error={errors.founders?.[index]?.phone &&
                                          errors.founders?.[index]?.phone}
                                      />
                                    )}
                                  />
                                  <FormHelperText className="text-danger">
                                    {errors.founders?.[index]?.phone &&
                                      errors.founders?.[index]?.phone.message}
                                  </FormHelperText>
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                  {/* <Controller
                                    name={`founders.${index}.designation`}
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: true }}
                                    render={({
                                      field,
                                      fieldState: { error },
                                    }) => (
                                      <Autocomplete
                                        disableClearable
                                        {...field}
                                        options={designation.map(
                                          (option) => option.name
                                        )}
                                        onChange={(event, newValue) =>
                                          handleSelectDesignation(newValue, index)
                                        }
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label="Designation *"
                                            placeholder="Select Designation"
                                            error={!!error}
                                            helperText={
                                              error
                                                ? "Designation is required"
                                                : ""
                                            }
                                          />
                                        )}
                                        fullWidth
                                        margin="normal"
                                      />
                                    )}
                                  />
                                  <FormHelperText className="text-danger">
                                    {errors.founders?.[index]?.designation &&
                                      errors.founders?.[index]?.designation
                                        .message}
                                  </FormHelperText> */}
                                  <Controller
                                    name={`founders.${index}.designation`}
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: true }}
                                    render={({
                                      field,
                                      fieldState: { error },
                                    }) => (
                                      <Autocomplete
                                        disableClearable
                                        {...field}
                                        options={designation}
                                        onChange={(event, newValue) =>
                                          handleSelectDesignation(newValue, index)
                                        }
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label="Designation *"
                                            placeholder="Select Designation"
                                            error={!!error}
                                            helperText={
                                              error
                                                ? "Designation is required"
                                                : ""
                                            }
                                          />
                                        )}
                                        fullWidth
                                        margin="normal"
                                        ListboxProps={{
                                          style: { textAlign: 'left' }
                                        }}
                                      />
                                    )}
                                  />
                                  <FormHelperText className="text-danger">
                                    {errors.founders?.[index]?.designation &&
                                      errors.founders?.[index]?.designation
                                        .message}
                                  </FormHelperText>
                                </Grid>
                                <Grid
                                  item
                                  lg={6}
                                  md={6}
                                  sm={12}
                                  xs={12}
                                  className="mb-3"
                                >
                                  {/* <FormLabel shrink="true" className='input_Lable'>Gender <span className='text-blue'>*</span></FormLabel>
                                  <Controller name={`founder.${index}.gender`} control={control} defaultValue="" rules={{ required: true }}
                                    render={({ field }) =>
                                      <div className='p-relative gender_input'>
                                        <span className={getValues(`founder.${index}.gender`) === 'Male' ? 'genderspan genderAct' : 'genderspan'} onClick={() => { handleGender('Male', index) }}>
                                          <img src='/images/male.png' className='sml_img' alt='' />
                                        </span>
                                        <span className={getValues(`founder.${index}.gender`) === 'Female' ? 'genderspan genderAct' : 'genderspan'} onClick={() => { handleGender('Female', index) }}>
                                          <img src='/images/female.png' className='sml_img' alt='' />
                                        </span>
                                        <span className={getValues(`founder.${index}.gender`) === 'Trans' ? 'genderspan genderAct' : 'genderspan'} onClick={() => { handleGender('Trans', index) }}>
                                          <img src='/images/trang.png' className='sml_img' alt='' />
                                        </span>
                                      </div>
                                    } />
                                  <FormHelperText className="text-danger">
                                    {errors.founder?.[index]?.gender && `Gender is required`}
                                  </FormHelperText> */}
                                  <Controller
                                    name={`founders.${index}.gender`}
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: true }}
                                    render={({
                                      field: { onChange, value },
                                      fieldState: { error },
                                    }) => (
                                      <Autocomplete
                                        disableClearable
                                        options={gender}
                                        getOptionLabel={(option) => option}
                                        value={value || []}
                                        onChange={(event, newValue) =>
                                          onChange(newValue)
                                        }
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label="Gender *"
                                            placeholder="Select Gender"
                                            error={!!error}
                                            helperText={
                                              error ? "Gender is required" : ""
                                            }
                                          />
                                        )}
                                        fullWidth
                                        margin="normal"
                                      />
                                    )}
                                  />
                                  <FormHelperText className="text-danger">
                                    {errors.founders?.[index]?.gender &&
                                      errors.founders?.[index]?.message}
                                  </FormHelperText>
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                  <div className="d-flex align-items-center">
                                    <Controller
                                      name={`founders.${index}.physicallyChallenged`}
                                      control={control}
                                      defaultValue=""
                                      rules={{ required: false }}
                                      render={({ field }) => (
                                        <FormControlLabel
                                          {...field}
                                          control={
                                            <Checkbox
                                              value={field.value}
                                              checked={field.value}
                                            />
                                          }
                                          label="Physically Challenged"
                                        />
                                      )}
                                    />
                                  </div>
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                                  <div className="form-group file-name mt-2" key={index}>
                                    {getValues(`founders.${index}.profileUrl`) ? (
                                      <>
                                        <label >Upload Profile Pic<span className="text-danger ml-1">*</span></label>
                                        <Grid container>

                                          <Grid item lg={7} md={7} sm={12} xs={12} className="mb-3">
                                            <Controller
                                              name={`founders.${index}.profileName`}
                                              control={control}
                                              defaultValue=""
                                              rules={{ required: false }}
                                              render={({ field }) => (
                                                <Button
                                                  component="label"
                                                  variant="contained"
                                                  {...field}
                                                  fullWidth
                                                  onChange={(e) =>
                                                    handleFileUpload(
                                                      e,
                                                      "profile",
                                                      "profileName",
                                                      "founders",
                                                      index,
                                                    )
                                                  }
                                                  startIcon={<CloudUploadIcon />}
                                                  href="#file-upload"
                                                >
                                                  Change
                                                  <VisuallyHiddenInput type="file" />
                                                </Button>
                                              )}
                                            />
                                          </Grid>
                                          <Grid item lg={5} md={5} sm={12} xs={12} className="mb-3">
                                            <Button
                                              className="view_doc_btn_document c-pointer"

                                              size="medium"
                                              variant="outlined"
                                              onClick={() =>
                                                handleClickOpen(
                                                  getValues(`founders.${index}.profile`),
                                                  getValues(`founders.${index}.profileUrl`)
                                                )
                                              }
                                            >
                                              Preview
                                            </Button>
                                          </Grid>
                                          <div>
                                            <span className="font-sm   mt-1 ">
                                              Maximum 5 mb allowed doc (Accepted Format: jpeg, jpg, png)
                                            </span>
                                          </div>
                                        </Grid>
                                      </>

                                    ) : (
                                      <div className="form-group mt-2">
                                        <Controller
                                          name={`founders.${index}.profileName`}
                                          control={control}
                                          defaultValue=""
                                          rules={{ required: false }}
                                          render={({ field }) => (
                                            <TextField
                                              variant="outlined"
                                              label="Upload Profile Pic"
                                              placeholder="Upload Profile Pic"
                                              fullWidth
                                              type="text"
                                              {...field}
                                              disabled
                                            />
                                          )}
                                        />
                                        <FormHelperText className="text-danger">
                                          {errors.founders?.[index]?.profileName && errors.founders?.[index]?.profileName.message}
                                        </FormHelperText>
                                        <div className='d-flex align-items-center w-100 my-3'>
                                          <p className='font-sm'>
                                            Recommended aspect ratio 4:3 <br />
                                            Max size upto 5mb <br />
                                            Accepted Format: jpeg, jpg, png</p>
                                          <label className='ml-auto lbl_btn'
                                            onChange={(e) =>
                                              handleFileUpload(
                                                e,
                                                "profile",
                                                "profileName",
                                                "founders",
                                                index,
                                              )
                                            }
                                            href="#file-upload">
                                            <img src='/images/admin/upload.png' className='mr-3' alt='' />
                                            Upload Image
                                            <VisuallyHiddenInput type="file" />
                                          </label>
                                        </div>
                                      </div>
                                    )
                                    }
                                  </div>
                                </Grid>
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                        ))}
                      </>
                    ) : (
                      <div className="p-5 text-center">
                        <Button
                          variant="outlined"
                          onClick={() => addFounderBtn()}
                        >
                          + Team Member
                        </Button>
                      </div>
                    )}
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} className='mb-3'>
                    <p><b>Select your Team Size</b></p>
                    <Grid container spacing={1}>
                      <Grid item lg={4} md={4} sm={4} xs={4} className='my-1'>
                        <button type="button" className={teamSize === '1-5' ? ('team_size_btn teamActBtn') : ('team_size_btn')} onClick={() => handleTeamSize('1-5')}>1-5</button>
                      </Grid>
                      <Grid item lg={4} md={4} sm={4} xs={4} className='my-1'>
                        <button type="button" className={teamSize === '5-10' ? ('team_size_btn teamActBtn') : ('team_size_btn')} onClick={() => handleTeamSize('5-10')}>5-10</button>
                      </Grid>
                      <Grid item lg={4} md={4} sm={4} xs={4} className='my-1'>
                        <button type="button" className={teamSize === '10-25' ? ('team_size_btn teamActBtn') : ('team_size_btn')} onClick={() => handleTeamSize('10-25')}>10-25</button>
                      </Grid>
                      <Grid item lg={4} md={4} sm={4} xs={4} className='my-1'>
                        <button type="button" className={teamSize === '25-50' ? ('team_size_btn teamActBtn') : ('team_size_btn')} onClick={() => handleTeamSize('25-50')}>25-50</button>
                      </Grid>
                      <Grid item lg={4} md={4} sm={4} xs={4} className='my-1'>
                        <button type="button" className={teamSize === '>50' ? ('team_size_btn teamActBtn') : ('team_size_btn')} onClick={() => handleTeamSize('>50')}>{`>`}50</button>
                      </Grid>
                      <Grid item lg={4} md={4} sm={4} xs={4} className='my-1'>
                        <button type="button" className={teamSize === '>100' ? ('team_size_btn teamActBtn') : ('team_size_btn')} onClick={() => handleTeamSize('>100')}>{`>`}100</button>
                      </Grid>
                    </Grid>
                    {/* <FormHelperText className='text-danger'>{teamValiation === true && 'Team Size is required'}</FormHelperText> */}
                  </Grid>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="text-center"
                  >
                    <button className="next-btn" type="submit">
                      Next
                    </button>
                  </Grid>
                </Grid>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
      <Viewimage
        viewImage={viewImage}
        viewImageName={viewImageName}
        open={open}
        handleClose={handleClose}
      />
    </div>
  );
};

export default Foundedetails;
