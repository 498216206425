import React, { useEffect, useState } from 'react'
import { Autoplay, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react'

const TnEventSpeakers = () => {
  const [screenSize, setScreenSize] = useState(5);
  useEffect(() => {
    size();
  }, []);

  const size = () => {
    if (window.innerWidth > 1440) {
      setScreenSize(4);
    } else if (window.innerWidth <= 1440 && window.innerWidth > 1024) {
      setScreenSize(4);
    } else if (window.innerWidth <= 1024 && window.innerWidth > 992) {
      setScreenSize(4);
    } else if (window.innerWidth <= 992 && window.innerWidth > 768) {
      setScreenSize(4);
    } else if (window.innerWidth <= 768 && window.innerWidth > 575) {
      setScreenSize(4);
    } else {
      setScreenSize(1);
    }
  };

  const row1 = [
    "/images/Speakers/row1/udayanithi.png",
    "/images/Speakers/row1/Anbarasu.png",
    "/images/Speakers/row1/p-Moorthy.png",
    "/images/Speakers/row1/palanivel.png",
    "/images/Speakers/row1/archana.png",
    "/images/Speakers/row1/Anand R.png",
    "/images/Speakers/row1/sangeetha.png",
    "/images/Speakers/row1/Dineshkumar.png",
    "/images/Speakers/row1/sivaraj_1.png",
    "/images/Speakers/row1/Ambalavanan.png",
    "/images/Speakers/row1/krishna C.png",
    "/images/Speakers/row1/Krishnamoorthy.png",
    "/images/Speakers/row1/srivats.png",
    "/images/Speakers/row1/vanitha.png",

  ]
  const row2 = [
    "/images/Speakers/row2/Vedha_arun.png",
    "/images/Speakers/row2/Arun_that.png",
    "/images/Speakers/row2/chandran.png",
    "/images/Speakers/row2/Vignesh_annamalai.png",
    "/images/Speakers/row2/uma_mahes.png",
    "/images/Speakers/row2/Faizl.png",
    "/images/Speakers/row2/ps_kannan.png",
    "/images/Speakers/row2/muthu jk.png",
    "/images/Speakers/row2/Balachandar m.png",
    "/images/Speakers/row2/Sivaprakash R.png",
    "/images/Speakers/row2/suhani.png",
    "/images/Speakers/row2/panneerselvam.png",
    "/images/Speakers/row2/vasanth_selvan.png",
    "/images/Speakers/row2/suresh_kumar.png",
    "/images/Speakers/row2/Mohan.png",
    "/images/Speakers/row2/sujith.png",
    "/images/Speakers/row2/Aazhi.png",
    "/images/Speakers/row2/Kru palani.png",
    "/images/Speakers/row2/Gopinath.png",
    "/images/Speakers/row2/vignesh_sivan.png",
    "/images/Speakers/row2/Aravind.png",
    "/images/Speakers/row2/Nagaraj Praksas.png",
    "/images/Speakers/row2/Thulasiraj.png",
    "/images/Speakers/row2/kumaravel.png",
    "/images/Speakers/row2/kumar_vembu.png",
    "/images/Speakers/row2/Suresh.png",
    "/images/Speakers/row2/velumani.png",

  ]
  const row3 = [
    "/images/Speakers/Row3_new/Ganesh_sathyamurthy.png",
    "/images/Speakers/Row3_new/rohan_chat.png",
    "/images/Speakers/Row3_new/utkarsh_mishra.png",
    "/images/Speakers/Row3_new/Thirumurugan.png",
    "/images/Speakers/Row3_new/kathir.png",
    "/images/Speakers/Row3_new/indhuja.png",
    "/images/Speakers/Row3_new/nanusamy.png",
    "/images/Speakers/Row3_new/Amith_kumar.png",
    "/images/Speakers/Row3_new/Naren.png",
    "/images/Speakers/Row3_new/Nellai_sollaiapn.png",
    "/images/Speakers/Row3_new/saranya.png",
    "/images/Speakers/Row3_new/sebin.png",
    "/images/Speakers/Row3_new/VinothG.png",
    "/images/Speakers/Row3_new/prabhakaran.png",
    "/images/Speakers/Row3_new/chitraLekha.png",
    "/images/Speakers/Row3_new/Elango.png",
    "/images/Speakers/Row3_new/Vanathi_sounder.png",
    "/images/Speakers/Row3_new/Sharmila.png",
    "/images/Speakers/Row3_new/SenthilKumar M.png",
    "/images/Speakers/Row3_new/Ronikka_Mary.png",
    "/images/Speakers/Row3_new/Priiya_babu'.png",
    "/images/Speakers/Row3_new/Aadhan_yogi.png",
    "/images/Speakers/Row3_new/Tamizh Inian.png",
    "/images/Speakers/Row3_new/siruvadivel.png",
    "/images/Speakers/Row3_new/krishanan.png",
    "/images/Speakers/Row3_new/cholin_selvan.png",
    "/images/Speakers/Row3_new/Vasanth_kumar.png",
    "/images/Speakers/Row3_new/JayaShakthi.png",
    "/images/Speakers/Row3_new/Sundare_raju.png",
    "/images/Speakers/Row3_new/Appass.png",
    "/images/Speakers/Row3_new/Abey George.png",
    "/images/Speakers/Row3_new/Thaman.png",
    "/images/Speakers/Row3_new/Dhanesh.png",
    "/images/Speakers/Row3_new/suresh_c.png",
    "/images/Speakers/Row3_new/Sanya_arora.png",
    "/images/Speakers/Row3_new/Gowri.png",
    "/images/Speakers/Row3_new/Sounder_kannan.png"
  ]
  return (
    <div>
      <div className='video-bg'>
        <div className='startups-fests'>
          <Swiper
            spaceBetween={30}
            slidesPerView={screenSize}
            navigation
            loop
            autoplay={{ delay: 3000, disableOnInteraction: false }}
            modules={[Autoplay, Navigation]}
            style={{ paddingBottom: "50px" }}
          >
            <SwiperSlide>
              <div className="swiper-item">
                <img src="/images/Banner/meeting-consider-deliberate-about-meet_svgrepo.com.png" alt="Startup Pitches" />
                <h3>FIRESIDE CHAT</h3>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-item">
                <img src="/images/Banner/network-1_svgrepo.com.png" alt="Cultural Extravaganzas" />
                <h3>NETWORKING SPACES</h3>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-item">
                <img src="/images/Banner/Group.png" alt="Inaugurations" />
                <h3>BUYER-SELLER MEET</h3>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-item">
                <img src="/images/Banner/Group (1).png" alt="Immersive Journeys" />
                <h3>STARTUP PITCHES</h3>
              </div>
            </SwiperSlide>
            {/* <SwiperSlide>
              <div className="swiper-item">
                <img src="/images/Banner/meeting-consider-deliberate-about-meet_svgrepo.com.png" alt="Startup Pitches" />
                <h3>STARTUP PITCHES</h3>
              </div>
            </SwiperSlide> */}
            <SwiperSlide>
              <div className="swiper-item">
                <img src="/images/Banner/network-1_svgrepo.com.png" alt="Cultural Extravaganzas" />
                <h3>CULTURAL EXTRAVAGANZAS</h3>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-item">
                <img src="/images/Banner/Group.png" alt="Inaugurations" />
                <h3>INAUGURATIONS</h3>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-item">
                <img src="/images/Banner/Frame 1707480228.png" alt="Immersive Journeys" />
                <h3>CULTURAL EXTRAVAGANZAS</h3>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-item">
                <img src="/images/Banner/Frame 1707480228 (1).png" alt="Immersive Journeys" />
                <h3>INAUGURATIONS</h3>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-item">
                <img src="/images/Banner/Frame 1707480228 (2).png " alt="Immersive Journeys" />
                <h3>IMMERSIVE JOURNEYS</h3>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-item">
                <img src="/images/Banner/Group (1).png" alt="Immersive Journeys" />
                <h3>IMMERSIVE JOURNEYS</h3>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        {/* <div className="Speakers-head mt-5">
          <h2>Speakers</h2>
          <h6>Coming Soon..</h6>
        </div> */}
      </div>
      <div className="Speakers-head">
        <div className='event-speakers-lists text-center'>
          <h1 style={{ color: '#fff' }}><span style={{ color: '#FFBB00' }}>Voices of Startup </span>Thiruvizha 2024</h1>
          <p style={{ color: '#fff' }}>Voices of Startup Thiruvizha brings together visionary leaders and innovators to share their insights, experiences, and strategies for success. Join us to discover the ideas shaping the future of entrepreneurship and innovation.</p>
        </div>
        <marquee>
          <div className='row--banners mt-5'>
            {
              row1 && row1.map((item, i) => (
                <div className='images-speak' key={i}>
                  <img src={item} alt="images" className='mans-imags' />
                </div>
              ))
            }
          </div>
        </marquee>
        <marquee direction="right">
          <div className='row--banners mt-5 mb-5'>
            {
              row2 && row2.map((item, i) => (
                <div className='images-speak' key={i}>
                  <img src={item} alt="images" className='mans-imags' />
                </div>
              ))
            }
          </div>
        </marquee>
        <marquee>
          <div className='row--banners my-5'>
            {
              row3 && row3.map((item, i) => (
                <div className='images-speak' key={i}>
                  <img src={item} alt="images" className='mans-imags' />
                </div>
              ))
            }
          </div>
        </marquee>
      </div>
      <div>
        {/* <img src={'/images/Speakers/Overall Speakers 82 Final.jpg'} alt="banner" className='bg-speakers'/> */}
      </div>
      {/* <div className="Speakers-head ">
  <img src="https://startuptn-public-files.s3.ap-south-1.amazonaws.com/Thiruvizha_2024_SpeakersList.gif" alt="Thiruvizha 2024 Speakers List" className='img-fluid'/>
</div> */}

      <img src={'/images/Banner/strip_banner.svg'} alt="strip" className='img-fluid' />
    </div>
  )
}

export default TnEventSpeakers