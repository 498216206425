// import Navbar from "./Navbar";
// import { Helmet } from "react-helmet";
import React, { useState, useEffect, useRef, useContext } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Aos from "aos";
import axios from "axios";
import DataContext from "../../../context/DataContext";
// import { useParams } from 'react-router-dom';
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import { IoIosArrowDown } from "react-icons/io";
import Typewriter from "typewriter-effect";
import { Autocomplete, TextField } from "@mui/material";
import apiService from "../../../api/apiService";
const BannerFilter = () => {
  const { navigator, t } = useContext(DataContext);
  const [isParsonaVisible, setIsParsonaVisible] = useState(false);
  const [isProfileTypeVisible, setIsProfileTypeVisible] = useState(false);
  useDidMountEffect(() => {
    Aos.init();
    profileGet();
  }, []);
  const toggleSearchVisibility = () => {
    setIsParsonaVisible(!isParsonaVisible);
  };
  const [data, setData] = useState([]);
  const profileGet = () => {
    // axios
    //   .get("./json/Explore.json", "")
    apiService("home/explore/list", "", "unauthget")
      .then((result) => {
        if (result.data) {
          setData(result.data);
        }
      })
      .catch((err) => {});
  };

  const [selectedValue, setSelectedValue] = useState("");
  const [submenuValues, setSubMenuValues] = useState([]);
  const [submenuAllValues, setSubMenuAllValues] = useState([]);
  const [selectedSubMenuName, setSelectedSubMenuName] = useState("");
  const toggleParsonaSelect = (type) => {
    setSelectedValue(type);
    data.forEach((element) => {
      if (element.persona === type) {
        setSubMenuValues(element.services);
        setSubMenuAllValues(element.services);
        setIsProfileTypeVisible(true);
      }
    });
    setSelectedSubMenuName("");
  };

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(e.target.value);
    filterResults(query);
  };
  const filterResults = (query) => {
    if (!query.trim()) {
      setSubMenuValues(submenuAllValues);
    } else {
      const filtered = submenuValues.filter((item) =>
        item.name.toLowerCase().includes(query.toLowerCase())
      );
      setSubMenuValues(filtered);
    }
  };

  const [path, setPath] = useState("");
  const [expolreId, setExpolreId] = useState(0);
  const toggleProfileTypeSelect = (value, path, id) => {
    setSelectedSubMenuName(value);
    setIsProfileTypeVisible(false);
    setIsParsonaVisible(false);
    setPath(path);
    setExpolreId(id);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const describeDropDown = () => {
    // setIsProfileTypeVisible(!isProfileTypeVisible);
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const searchRef = useRef(null);
  const handleClickOutside = (event) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setIsParsonaVisible(false);
    }
  };
  const StartNow = () => {
    if (path !== undefined && selectedValue && selectedSubMenuName) {
      navigator(`/explore/${path}/${btoa(expolreId)}`);
    }
  };

  return (
    <div className="banner-box">
      <div className="banner-content">
        <div
          className="banner-title-content ml-lg-2 set-w"
          onClick={toggleSearchVisibility}
        >
          <p className="mt-2 ">
            {/* Who Am I ?  */}
            {t("Bannerfilter.whoami")}
            <span className="ml-2 arrow">
              <IoIosArrowDown />
            </span>
          </p>
          {selectedValue === "" ? (
            <Typewriter
              options={{
                strings: data.map((item) => item.persona),
                autoStart: true,
                loop: true,
              }}
              style={{
                fontSize: "18px", // Set font size here
                color: "#ffffff !important", // Set text color here
                fontFamily: "Arial, sans-serif", // Optional: Set font family
              }}
            />
          ) : (
            <p>
              <b>{selectedValue}</b>
            </p>
          )}
        </div>
        <div className="banner-line"></div>
        <div
          className="banner-title-content ml-lg-5 ml-0"
          onClick={selectedValue === "" ? null : describeDropDown}
        >
          <p className="mt-2">
            {/* I’m looking for */}
            {t("Bannerfilter.lookingfor")}
            <span className="ml-2 arrow">
              <IoIosArrowDown />
            </span>
          </p>
          {selectedValue === "" ? (
            <Typewriter
              options={{
                strings: [
                  "FUNDING",
                  "LITERACY",
                  "SUPPORT",
                  "INNOVATION",
                  "MARKET",
                  "ECOSYSTEM STAKEHOLDERS",
                  "EQUITABLE GROWTH",
                ],
                autoStart: true,
                loop: true,
              }}
              style={{
                fontSize: "18px", // Set font size here
                color: "#ffffff !important", // Set text color here
                fontFamily: "Arial, sans-serif", // Optional: Set font family
              }}
            />
          ) : (
            <></>
          )}

          {isParsonaVisible && (
            <div
              className="banner-search"
              data-aos="fade-top"
              data-aos-delay="200"
            >
              {/* <input type="search" placeholder="Choose Your Profile Type" /> */}
              {data.map((value, index) => (
                <span
                  key={index}
                  className={selectedValue === value.persona ? "bannerAct" : ""}
                  onClick={() => toggleParsonaSelect(value.persona)}
                >
                  {value.persona}
                </span>
              ))}
            </div>
          )}

          <p>
            <b>{selectedSubMenuName}</b>
            <div className="secont-filter secont-filter-position">
              {isProfileTypeVisible && (
                <div
                  className="banner-search1"
                  data-aos="fade-top"
                  data-aos-delay="200"
                >
                  <div className="banner-input-search">
                    <input
                      type="search"
                      placeholder="Search..."
                      value={searchQuery}
                      onInput={handleSearchChange}
                      // onFocus={onFocus}
                    />

                    {submenuValues.length > 0 &&
                      submenuValues.map((value, index) => (
                        <span
                          key={index}
                          onClick={() =>
                            toggleProfileTypeSelect(
                              value.name,
                              value.redirectPath,
                              value.id
                            )
                          }
                        >
                          {value.name}
                        </span>
                      ))}
                  </div>
                  {/* <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={sam}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined"/>
                )}
              /> */}
                </div>
              )}
            </div>
          </p>
        </div>
        <div className="ml-auto">
          <button className="banner-content-btn" onClick={() => StartNow()}>
            {/* Explore */}
            {t("Bannerfilter.explore")}
          </button>
        </div>
      </div>
      <div className="search-box">
        {/* first-dropDown */}

        {/* secont-dropDown */}
      </div>
    </div>
  );
};

export default BannerFilter;
