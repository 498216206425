import { FormHelperText, Grid, TextField } from '@mui/material'
import React, { useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import useDidMountEffect from '../../../../hooks/useDidMountEffect'
import DataContext from '../../../../context/DataContext'
import apiService from '../../../../api/apiService'
import notifyService from '../../../../api/notifySerivce'
import cryptodecryptJS from '../../../../hooks/cryptodecryptJS'
const QuickbriefAspirants = () => {
    const { navigator, handleNextClick, isWhitespace, fastUrlPath } = useContext(DataContext)
    const [formData, setFormData] = useState({});
    const { handleSubmit, control, formState: { errors }, } = useForm({ values: formData });
    useDidMountEffect(() => {
        handleNextClick(98)
        getApi()
    }, [])
    const getApi = () => {
        var id = localStorage.getItem('id')
        var type = localStorage.getItem('type')
        apiService(`userprofile/get?persona=${type}&userid=${id}`, "", "get")
            .then((result) => {
                if (result && result.data) {
                    var data = result.data
                    data.state = 'Tamilnadu'
                    data.country = 'India'
                    setFormData(data)
                }
            }).catch((err) => {
            });
    }
    const onSubmit = (data) => {
        console.log('Form Data:', data);
        data.profileUpdated = true        
        apiService('userprofile/save', data, "post").then((result) => {
            if (result.data.responseStatus === 'Success') {
                localStorage.setItem('profile', true)
                localStorage.setItem('email', data.email)
                navigator('/aspirant/profile')
            }
        }).catch((err) => {
        });
    };
    const onError = (event) => {
        console.log("error Data:", event);
        notifyService("danger", "Submit Failed", "Please check the mandatory fields");
    };
    return (
        <div>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <div className='signup-data_div'>
                        <div className='forms-title d-flex'>
                            <span className='path_sapn'>Quick Brief</span>
                        </div>
                        <div className='signup_box_div mt-4'>
                            <form onSubmit={handleSubmit(onSubmit, onError)}>
                                <div>
                                    <div className='mb-3'>
                                        <p><b>Brief Description about your Startup </b></p>
                                        <div>
                                            <div className='my-3'>
                                                <Controller
                                                    name="about"
                                                    control={control}
                                                    defaultValue=""
                                                    rules={{
                                                        required: "Description is required", validate: {
                                                            noWhitespace: value => !isWhitespace(value) || 'WhiteSpace not allowed'
                                                        },
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            id="outlined-multiline-static"
                                                            label="Enter your Description *"
                                                            fullWidth
                                                            multiline
                                                            rows={4}
                                                            defaultValue=""
                                                            error={Boolean(errors.about && errors.about)}
                                                        />
                                                    )}
                                                />
                                                <FormHelperText className="text-danger">
                                                    {errors.about && errors.about.message}
                                                </FormHelperText>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='text-center mt-5'>
                                        <button className='next-btn' type="submit">SAVE</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default QuickbriefAspirants
