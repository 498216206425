import { FormHelperText } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
const ThiruvizhaTimer = ({ resent, seconds, setSeconds }) => {
    const [minutes, setMinutes] = useState(1);

    // const [animation, setAnimation] = useState(true);


    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(interval);
                } else {
                    setSeconds(59);
                    setMinutes(minutes - 1);
                }
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [seconds]);
    // const resendOTP = () => {
    //   setMinutes(0);
    //   setSeconds(15);
    //   setAnimation();
    // };

    return (
        <div className="">
            {seconds === 0 ? (
                ""
            ) : (
                // <div className="loader">
                //   <span></span>{" "}
                // </div>
                <></>
            )}

            {seconds > 0 || minutes > 0 ? (
                <FormHelperText>
                    Time Remaining :{" "}
                    <span style={{ fontWeight: 600 }}>
                        {minutes < 10 ? `0${minutes}` : minutes}:
                        {seconds < 10 ? `0${seconds}` : seconds}
                    </span>
                </FormHelperText>
            ) : (
                // Display if countdown timer reaches 0
                <FormHelperText>Didn’t receive the OTP? <span className='resend_span' onClick={resent}>Resend OTP</span></FormHelperText>
            )}
        </div>
    );
}


export default ThiruvizhaTimer