import { Button, Container } from '@mui/material'
import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import DataContext from '../../../../context/DataContext';
import Navbar from '../../../home-module/navbar-page/Navbar';

const SmartThankyou = () => {
  const { id } = useParams()
  const { navigator} = useContext(DataContext);
  let userperid = localStorage.getItem("userpersonaId");
  let userName =  localStorage.getItem("userProfName");
  let ids
  if (id !== null) {
    ids = atob(id)
  }

const handleClose = ()=>{
   navigator(`/smartcard/service`)
}
  return (
    <>
    <Navbar/>
    <Container>
    <div className='joinus-design'>
        <div className='title-joinus text-center'>
            <div className='text-center '>
                <img src={'/images/join-us/prof-Thank.png'} alt="images" className=' my-5' />
                <div >
                    <h6><b>Thank You for Availing Our Services!</b></h6>
                    <div className='Nothing1 my-4'>
                        <p className='mb-0'>{`Your request for the [${userName}] has been successfully submitted. A service partner will be assigned shortly to coordinate and guide you through the next steps.`}</p>
                        {/* <p> Start by adding your first offering to showcase what you can provide.</p>
                    </div> */}
                </div>
                <div className='join-prod-act pb-5'>
                    <Button variant='contained' size='large' sx={{ background: "#0B40AA", textTransform: 'none' }} onClick={()=>handleClose()}>Close</Button>
                </div>
            </div>
        </div>

    </div>
</div>
    </Container>
    </>
  )
}

export default SmartThankyou