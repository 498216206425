import React from 'react'
import Footer from '../../footer-page/Footer'

const TnfestForm = () => {
  return (
    <div>
      <div className='strip-img-div'>
       <img src={'/images/Banner/strip_banner.svg'} alt="strip" className='strip-img' />
     </div>
    </div>
  )
}

export default TnfestForm