import React, { useContext, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { IoIosList } from "react-icons/io";
import { FaRegCalendar } from "react-icons/fa6";
import DataContext from "../../../context/DataContext";
import { FormControl, InputBase, InputLabel, MenuItem, Select, ToggleButton, styled, Button, Typography, Menu, Autocomplete, TextField, } from "@mui/material";
import CustomDateRangePicker from "../../common-pages/CustomDateRangePicker";
import { NavLink } from "react-router-dom";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import apiService from "../../../api/apiService";
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "24px",
  backgroundColor: "rgb(255 255 255)",
  border: "1px solid #00000017",
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#d9d9d9",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "19ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const MeetingFilter = ({ setSearchInputValue, setSelectedPeriod, selectedPeriod, setSortBy, sortBy, setDepartmentId, setActiveMainTabs, activemainTabs }) => {
  const { navigator, setStartDate, setEndDate, hasPrivilege } = useContext(DataContext);
  const handleMeatingAdd = () => {
    navigator("/admin/meeting/add");
  };

  const handleSearch = (e) => {
    setSearchInputValue(e.target.value);
  };
  const handleSelectPeriod = (value) => {
    setSelectedPeriod(value);
  };
  const [anchorElUser, setAnchorElUser] = useState(null);
  const handleOpenSortMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleSortBy = (value) => {
    setSortBy(value);
    handleCloseUserMenu();
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  useDidMountEffect(() => {
    departmentListApi()
  }, [])
  const [departmentList, setDepartmentList] = useState([])
  const departmentListApi = () => {
    apiService(`department/list`, {}, 'post').then((result) => {
      if (result && result.data && result.data.department) {
        let data = []
        const val = {
          "id": 0,
          "name": 'All',
        }
        data.push(val)
        result.data.department.forEach(element => {
          data.push(element)
        });
        setDepartmentList(data)
      } else {
        let data = []
        const val = {
          "id": 0,
          "name": 'All',
        }
        data.push(val)
        setDepartmentList(data)
      }
    })
  }
  const [departmentName, setDepartmentName] = useState('All')
  const handleDepartment = (val) => {
    setDepartmentName(val)
    var depValue = departmentList.filter((list) => list.name === val)
    setDepartmentId(depValue[0].id)
  }

  const handleTabClick = (newValue) => {
    setActiveMainTabs(newValue);
  };

  return (
    <section>
      <div className="double-clr">
        <div className="frt-gry">
          <div className="main-tab-adminpage">
            <NavLink className="startup-sup-btn" to={"/admin/meeting/list"}>
              Meetings
            </NavLink>
            {
              hasPrivilege('20') && (
                <NavLink className="startup-sup-btn1" to={"/admin/meeting/report"}>
                  Reports
                </NavLink>
              )
            }
          </div>
          {
            hasPrivilege('17') && (
              <div>
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  sx={{
                    textTransform: "none",
                    background: "#0061D4",
                    borderRadius: "10px",
                  }}
                  onClick={handleMeatingAdd}
                >
                  Create Meetings
                </Button>
              </div>
            )
          }

        </div>
        <div className="sec-clrk">
          <div className="sec-btn-bg">
            <Autocomplete
              size="small"
              disableClearable
              disablePortal
              id="combo-box-demo"
              value={departmentName}
              options={departmentList.map((val) => val.name)}
              onChange={(e, selectedOptions) =>
                handleDepartment(selectedOptions)
              }
              className="meetingListDropdown"
              renderInput={(params) => <TextField {...params} label="Choose Department" />}
            />
          </div>
          <div className="sec-filtersk">
            <div className="search-div">
              <Search>
                <SearchIconWrapper>
                  <img src="/images/admin/search-black.png" alt="" />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search Meetings "
                  inputProps={{ "aria-label": "search" }}
                  onChange={handleSearch}
                  type="search"
                />
              </Search>
            </div>
            <div>
              <CustomDateRangePicker
                setStartDate={setStartDate}
                setEndDate={setEndDate}
              />
            </div>
            <div>
              <FormControl variant="outlined" size="small" sx={{ width: 120 }}>
                <InputLabel id="dropdown-label">Duration</InputLabel>
                <Select
                  labelId="dropdown-label"
                  label="Select Option"
                  value={selectedPeriod}
                  variant="outlined"
                  style={{ borderRadius: 10 }}
                  onChange={(e) => handleSelectPeriod(e.target.value)}
                >
                  <MenuItem value={"All"}>All</MenuItem>
                  <MenuItem value={"This Week"}>This Week</MenuItem>
                  <MenuItem value={"This Month"}>This Month</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="frt-fil">
              <div className="all-btn-bg">
                <button
                  className={`secs-btn-1 ${activemainTabs === 0 ? "active" : ""
                    }`}
                  onClick={() => handleTabClick(0)}
                >
                  <span>
                    <IoIosList className="lists-icons" />
                  </span>
                  List
                </button>
                <button
                  className={`secs-btn-2 ${activemainTabs === 1 ? "active" : ""
                    }`}
                  onClick={() => handleTabClick(1)}
                >
                  <span>
                    <FaRegCalendar className="calander-icons" />
                  </span>
                  Calendar View
                </button>
              </div>
            </div>

            <div>
              <ToggleButton className="toggle-fil" onClick={handleOpenSortMenu}>
                {<img src="/images/filter icon.svg" alt="" />}
              </ToggleButton>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem onClick={() => handleSortBy(true)}>
                  <Typography
                    textAlign="center"
                    sx={{ color: sortBy === true ? "#0061D4" : "" }}
                  >
                    StartDate Ascending
                  </Typography>
                </MenuItem>
                <MenuItem onClick={() => handleSortBy(false)}>
                  <Typography
                    textAlign="center"
                    sx={{ color: sortBy === false ? "#0061D4" : "" }}
                  >
                    StartDate Descending
                  </Typography>
                </MenuItem>
              </Menu>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MeetingFilter;
