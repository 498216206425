import React, { useContext, useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  TextField,
} from "@mui/material";
import "swiper/swiper-bundle.css";
import DataContext from "../../../../context/DataContext";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const MentorSessions = ({ name,url }) => {
  const { navigator, setPageLoading, validateEmail } = useContext(DataContext);
  const dep = ["Mentors"];
  const handleDepartment = (val) => {};
  const navigate = useNavigate();

  const sessionItems = [
    {
      sessionImage: "/images/sessionimage.png",
      sessionProgram: "Cohort Program",
      sessionTitle: "Basic strategy for effective sustainable energy",
    },
    {
      sessionImage: "/images/sessionimage.png",
      sessionProgram: "Cohort Program",
      sessionTitle: "Basic strategy for effective sustainable energy",
    },
    {
      sessionImage: "/images/sessionimage.png",
      sessionProgram: "Cohort Program",
      sessionTitle: "Basic strategy for effective sustainable energy",
    },
    {
      sessionImage: "/images/sessionimage.png",
      sessionProgram: "Cohort Program",
      sessionTitle: "Basic strategy for effective sustainable energy",
    },
    {
      sessionImage: "/images/sessionimage.png",
      sessionProgram: "Cohort Program",
      sessionTitle: "Basic strategy for effective sustainable energy",
    },
    {
      sessionImage: "/images/sessionimage.png",
      sessionProgram: "Cohort Program",
      sessionTitle: "Basic strategy for effective sustainable energy",
    },
    {
      sessionImage: "/images/sessionimage.png",
      sessionProgram: "Cohort Program",
      sessionTitle: "Basic strategy for effective sustainable energy",
    },
    {
      sessionImage: "/images/sessionimage.png",
      sessionProgram: "Cohort Program",
      sessionTitle: "Basic strategy for effective sustainable energy",
    },
  ];

  const handlebacked = () => {
    navigate(`/initiative/${url}/mentortn/home`)
  }

  const handleMentor = () => {
    window.open('https://startuptnmentors.com/', '_blank', 'noopener,noreferrer');
  }

  return (
    <div className="smartCard-page">
      <div className="radial-back-layer">
        <div className="round-layers"></div>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={12}>
            <div className="banner-text">
              <div className="sm-banner-chip-text">
                <span>{`${name}  >  Sessions`}</span>
                <h1 className="banner-text-gradient">MentorTN</h1>
                <h6>Right Mentor for the right founder!</h6>
                <div className="banner-content-actions">
                  <button className="banner-viewbtn" onClick={handleMentor}>Join Us</button>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>

      <div className="join-us-now-sec1">
        <Container>
          <div>
            <Button className="about-backed" onClick={handlebacked}>
              <FaArrowLeft />
              Back
            </Button>
          </div>
          <div className="sessions-title">
            <div>
              <h4 className="ongoing">Ongoing Sessions</h4>
            </div>
            <div className="sec-btn-bg">
              <Autocomplete
              className="automate"
                disablePortal
                id="combo-box-demo"
                options={dep}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Ongoing Session" />
                )}
                onChange={(e, selectedOptions) =>
                  handleDepartment(selectedOptions)
                }
              />
            </div>
          </div>
          <div className="service-crd mt-4">
            <Container>
              <Grid container spacing={3}>
                {sessionItems.map((item, i) => (
                  <Grid item xs={12} sm={6} md={3} key={i} className="cardingss">
                    <Card className="session-crds1">
                      <div>
                        <img
                          className="session-crd-back"
                          src={item.sessionImage}
                          alt=""
                        />
                      </div>
                      <CardContent className="session-contents">
                        <div className="mt-2">
                          <span className="ses-pro">{item.sessionProgram}</span>
                        </div>
                        <div>
                          <span className="ses-tit">{item.sessionTitle}</span>
                        </div>
                        <div className="mt-2">
                          <Button className="ses-now">Join Now</Button>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Container>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default MentorSessions;
