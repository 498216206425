import { Grid } from '@mui/material'
import React from 'react'

const TnFestPartner = () => {
    return (
        <>
            <section>
                <div className='partner-bg'>
                    <div className='container'>
                        <center>
                            <div className="partner-banner">
                                <div className="partner-banner-text">
                                    <h5 className='m-0'>
                                        Industries Ecosystem Partners
                                    </h5>
                                </div>
                                <div className="partner-banner-logo-first">
                                    <img src="/images/PartnerSection/cii.png" alt="CII Logo" />
                                </div>
                            </div>
                        </center>

                        <div className='partner-margin'>
                            <div className="partner-banner-1">
                                <center>
                                    <div className="partner-banner-text">
                                        <h5 className='m-0'>
                                            Ecosystem Partners
                                        </h5>
                                    </div>
                                </center>
                                <div className="partner-banner-logo-dflex">
                                    <img src="/images/PartnerSection/pt-2.png" className="partner-banner-logo" alt="CII Logo" />
                                    <img src="/images/PartnerSection/pt-8.png" className="partner-banner-logo" alt="CII Logo" />
                                    <img src="/images/PartnerSection/pt-12.png" className="partner-banner-logo" alt="CII Logo" />
                                    <img src="/images/PartnerSection/pt-7.png" className="partner-banner-logo" alt="CII Logo" />
                                    <img src="/images/PartnerSection/pt-11.png" className="partner-banner-logo" alt="CII Logo" />
                                    <img src="/images/PartnerSection/pt-9.png" className="partner-banner-logo" alt="CII Logo" />
                                </div>

                                <div className="partner-banner-logo-dflex mt-3">
                                    <img src="/images/PartnerSection/pt-1.png" className="partner-banner-logo" alt="CII Logo" />
                                    <img src="/images/PartnerSection/pt-3.png" className="partner-banner-logo" alt="CII Logo" />
                                    <img src="/images/PartnerSection/pt-10.png" className="partner-banner-logo" alt="CII Logo" />
                                    <img src="/images/PartnerSection/pt-6.png" className="partner-banner-logo" alt="CII Logo" />
                                    <img src="/images/PartnerSection/pt-5.png" className="partner-banner-logo" alt="CII Logo" />
                                    <img src="/images/PartnerSection/pt-4.png" className="partner-banner-logo" alt="CII Logo" />
                                </div>
                            </div>
                        </div>

                        <div className='partner-margin'>
                            <div className="partner-banner-1">
                                <center>
                                    <div className="partner-banner-text">
                                        <h5 className='m-0'>
                                            Outreach Partners
                                        </h5>
                                    </div>
                                </center>
                                <center>
                                    <div className="partner-banner-logo-dflex">
                                        <img src="/images/PartnerSection/pt-16.png" className="partner-banner-logo" alt="CII Logo" />
                                        <img src="/images/PartnerSection/pt-15.png" className="partner-banner-logo" alt="CII Logo" />
                                        <img src="/images/PartnerSection/pt-14.png" className="partner-banner-logo" alt="CII Logo" />
                                        <img src="/images/PartnerSection/pt-13.png" className="partner-banner-logo" alt="CII Logo" />
                                        <img src="/images/PartnerSection/Renaisance.png" className="partner-banner-logo" alt="CII Logo" />
                                    </div>
                                </center>
                            </div>
                        </div>

                        <Grid container direction="row" alignItems="flex-start" spacing={3} >
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <div className='partner-margin'>
                                    <div className="partner-banner-1">
                                        <center>
                                            <div className="partner-banner-text-1">
                                                <h5 className='m-0'>
                                                    Event Tech Partners
                                                </h5>
                                            </div>
                                        </center>
                                        <center>
                                            <div className="partner-banner-logo-dflex">
                                                <img src="/images/PartnerSection/pt-22.png" className="partner-banner-logo-1" alt="CII Logo" />
                                                <img src="/images/PartnerSection/pt-21.png" className="partner-banner-logo-1" alt="CII Logo" />
                                            </div>
                                        </center>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <div className='partner-margin'>
                                    <div className="partner-banner-1">
                                        <center>
                                            <div className="partner-banner-text-1">
                                                <h5 className='m-0'>
                                                    Cloud Partners
                                                </h5>
                                            </div>
                                        </center>
                                        <center>
                                            <div className="partner-banner-logo-dflex">
                                                <img src="/images/PartnerSection/pt-20.png" className="partner-banner-logo-1" alt="CII Logo" />
                                            </div>
                                        </center>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>



                        <Grid container direction="row" alignItems="flex-start" spacing={3} >
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <div className='partner-margin'>
                                    <div className="partner-banner-1">
                                        <center>
                                            <div className="partner-banner-text-1">
                                                <h5 className='m-0'>
                                                    Logistics Partners
                                                </h5>
                                            </div>
                                        </center>
                                        <center>
                                            <div className="partner-banner-logo-dflex">
                                                <img src="/images/PartnerSection/pt-18.png" className="partner-banner-logo-1" alt="CII Logo" />
                                                <img src="/images/PartnerSection/pt-17.png" className="partner-banner-logo-1" alt="CII Logo" />
                                            </div>
                                        </center>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <div className='partner-margin'>
                                    <div className="partner-banner-1">
                                        <center>
                                            <div className="partner-banner-text-1">
                                                <h5 className='m-0'>
                                                    Beverage Partners
                                                </h5>
                                            </div>
                                        </center>
                                        <center>
                                            <div className="partner-banner-logo-dflex">
                                                <img src="/images/PartnerSection/pt-19.png" className="partner-banner-logo-1" alt="CII Logo" />
                                            </div>
                                        </center>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>

                        <div className='partner-margin'>
                            <div className="partner-banner-1">
                                <center>
                                    <div className="partner-banner-text">
                                        <h5 className='m-0'>
                                            Academic Partners
                                        </h5>
                                    </div>
                                </center>
                                <div className="partner-banner-logo-dflex">
                                    <img src="/images/PartnerSection/pt-39.png" className="partner-banner-logo" alt="CII Logo" />
                                    <img src="/images/PartnerSection/pt-38.png" className="partner-banner-logo" alt="CII Logo" />
                                    <img src="/images/PartnerSection/pt-37.png" className="partner-banner-logo" alt="CII Logo" />
                                    <img src="/images/PartnerSection/pt-36.png" className="partner-banner-logo" alt="CII Logo" />
                                    <img src="/images/PartnerSection/tce.png" className="partner-banner-logo" alt="CII Logo" />

                                    <img src="/images/PartnerSection/pt-34.png" className="partner-banner-logo" alt="CII Logo" />

                                </div>

                                <div className="partner-banner-logo-dflex mt-3">
                                    <img src="/images/PartnerSection/pt-45.png" className="partner-banner-logo" alt="CII Logo" />
                                    <img src="/images/PartnerSection/pt-44.png" className="partner-banner-logo" alt="CII Logo" />
                                    <img src="/images/PartnerSection/pt-43.png" className="partner-banner-logo" alt="CII Logo" />
                                    <img src="/images/PartnerSection/pt-42.png" className="partner-banner-logo" alt="CII Logo" />
                                    <img src="/images/PartnerSection/pt-41.png" className="partner-banner-logo" alt="CII Logo" />
                                    <img src="/images/PartnerSection/pt-40.png" className="partner-banner-logo" alt="CII Logo" />
                                </div>

                                <div className="partner-banner-logo-dflex mt-3">
                                    <img src="/images/PartnerSection/pt-51.png" className="partner-banner-logo" alt="CII Logo" />
                                    <img src="/images/PartnerSection/pt-50.png" className="partner-banner-logo" alt="CII Logo" />
                                    <img src="/images/PartnerSection/pt-49.png" className="partner-banner-logo" alt="CII Logo" />
                                    <img src="/images/PartnerSection/pt-48.png" className="partner-banner-logo" alt="CII Logo" />
                                    <img src="/images/PartnerSection/pt-47.png" className="partner-banner-logo" alt="CII Logo" />
                                    <img src="/images/PartnerSection/pt-46.png" className="partner-banner-logo" alt="CII Logo" />
                                </div>

                                <div className="partner-banner-logo-dflex mt-3">
                                    <img src="/images/PartnerSection/pt-57.png" className="partner-banner-logo" alt="CII Logo" />
                                    <img src="/images/PartnerSection/pt-56.png" className="partner-banner-logo" alt="CII Logo" />
                                    <img src="/images/PartnerSection/pt-55.png" className="partner-banner-logo" alt="CII Logo" />
                                    <img src="/images/PartnerSection/pt-54.png" className="partner-banner-logo" alt="CII Logo" />
                                    <img src="/images/PartnerSection/pt-53.png" className="partner-banner-logo" alt="CII Logo" />
                                    <img src="/images/PartnerSection/pt-52.png" className="partner-banner-logo" alt="CII Logo" />


                                </div>

                                <div className="partner-banner-logo-dflex mt-3">
                                    <img src="/images/PartnerSection/pt-62.png" className="partner-banner-logo" alt="CII Logo" />
                                    <img src="/images/PartnerSection/NPR.png" className="partner-banner-logo" alt="CII Logo" />
                                    <img src="/images/PartnerSection/pt-61.png" className="partner-banner-logo" alt="CII Logo" />
                                    <img src="/images/PartnerSection/pt-60.png" className="partner-banner-logo" alt="CII Logo" />
                                    <img src="/images/PartnerSection/pt-59.png" className="partner-banner-logo" alt="CII Logo" />
                                    <img src="/images/PartnerSection/pt-58.png" className="partner-banner-logo" alt="CII Logo" />
                                </div>

                                <div className="partner-banner-logo-dflex mt-3">
                                    <img src="/images/PartnerSection/st_joseph.png" className="partner-banner-logo" alt="CII Logo" />
                                    <img src="/images/PartnerSection/pt-32.png" className="partner-banner-logo" alt="CII Logo" />
                                    <img src="/images/PartnerSection/senthamarai.png" className="partner-banner-logo" alt="CII Logo" />
                                </div>
                            </div>
                        </div>

                        <div className='partner-margin'>
                            <div className="partner-banner-1">
                                <center>
                                    <div className="partner-banner-text">
                                        <h5 className='m-0'>
                                            Incubation Partners
                                        </h5>
                                    </div>
                                </center>
                                <div className="partner-banner-logo-dflex">
                                    <img src="/images/PartnerSection/Tce-tBi.png" className="partner-banner-logo" alt="CII Logo" />
                                    <img src="/images/PartnerSection/pt-30.png" className="partner-banner-logo" alt="CII Logo" />
                                    <img src="/images/PartnerSection/pt-29.png" className="partner-banner-logo" alt="CII Logo" />
                                    <img src="/images/PartnerSection/pt-28.png" className="partner-banner-logo" alt="CII Logo" />
                                    <img src="/images/PartnerSection/pt-27.png" className="partner-banner-logo" alt="CII Logo" />
                                    <img src="/images/PartnerSection/pt-26.png" className="partner-banner-logo" alt="CII Logo" />
                                </div>

                                <div className="partner-banner-logo-dflex mt-3">
                                    <img src="/images/PartnerSection/pt-25.png" className="partner-banner-logo" alt="CII Logo" />
                                    <img src="/images/PartnerSection/pt-24.png" className="partner-banner-logo" alt="CII Logo" />
                                    <img src="/images/PartnerSection/pt-23.png" className="partner-banner-logo" alt="CII Logo" />
                                </div>
                            </div>
                        </div>

                        <Grid container direction="row" alignItems="flex-start" spacing={3} >
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <div className='partner-margin'>
                                    <div className="partner-banner-1">
                                        <center>
                                            <div className="partner-banner-text-1">
                                                <h5 className='m-0'>
                                                    Hydration Partners
                                                </h5>
                                            </div>
                                        </center>
                                        <center>
                                            <div className="partner-banner-logo-dflex">
                                                <img src="/images/PartnerSection/pt-63.png" className="partner-banner-logo-1" alt="CII Logo" />
                                            </div>
                                        </center>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <div className='partner-margin'>
                                    <div className="partner-banner-1">
                                        <center>
                                            <div className="partner-banner-text-1">
                                                <h5 className='m-0'>
                                                    Media Partners
                                                </h5>
                                            </div>
                                        </center>
                                        <center>
                                            <div className="partner-banner-logo-dflex">
                                                <img src="/images/PartnerSection/pt-64.png" className="partner-banner-logo-1" alt="CII Logo" />
                                            </div>
                                        </center>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>

                        <div className='mt-5'>
                        </div>
                    </div>
                </div>
                <div className='strip-img-div'>
                    <img src={'/images/Banner/strip_banner.svg'} alt="strip" className='strip-img' />
                </div>
            </section>
        </>
    )
}

export default TnFestPartner