import { Dialog, FormHelperText, Grid, TextField } from '@mui/material'
import React, { useContext, useState } from 'react'
import DataContext from '../../../context/DataContext';
import { Controller, useForm } from 'react-hook-form'
import IconButton from '@mui/material/IconButton';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import apiService from '../../../api/apiService'
import cryptoJS from '../../../hooks/cryptoJS'
import { Link } from 'react-router-dom'
import { auth, googleAuthProvider } from '../Firebase';
// import LinkedInLogin from '../continue-with/LinkedInLogin';
import StartupIndia from '../continue-with/StartupIndia';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
// import UpdatePersona from './UpdatePersona';
import ForgotPassword from './ForgotPassword';
import notifyService from '../../../api/notifySerivce';
import Captchatext from '../../common-pages/Captchatext';
// import cryptodecryptJS from '../../../hooks/cryptodecryptJS';
// import ProfileCheckDialog from '../../home-module/home-page/Tn_fest/Ticketbookdialog/ProfileCheckDialog';
// import Delegatefreepass from '../../home-module/home-page/Tn_fest/PassDialog/Delegatefreepass';
// import Thiruvizha24Modal from '../../home-module/home-page/thiruvizha24-page/Thiruvizha24Modal';
const Login = () => {
    const { navigator, validateEmail, setPageLoading, handleHomePage, setProfileDialog, fullWidth, captchaValue, setCaptchaValue, setInputValue, fastUrlPath, handleParsonaClose } = useContext(DataContext)
    const { handleSubmit, control, watch, setError, reset, formState: { errors }, } = useForm({ values: {}, mode: 'onBlur' });
    const [formValid, setFormValid] = useState(false);
    const watchFields = watch(['userName', 'password']);
    useDidMountEffect(() => {
        const isFormValid = Object.values(watchFields).every(field => !!field);
        setFormValid(isFormValid);
        setPageLoading(false)
    }, [watchFields]);

    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    var conference = localStorage.getItem('way');
    const onSubmit = (data) => {
        if (!captchaValue) {
            notifyService('danger', 'Enter Captcha', 'Please Verify  Captcha')
            return;
        }
        var getform = data
        getform.type = cryptoJS('EMAIL')
        getform.userName = cryptoJS(data.userName.toLowerCase())
        getform.password = cryptoJS(data.password)
        setPageLoading(true);
        apiService('login', getform, 'post').then((res) => {
            localStorage.setItem('continueWith', false)
            setPageLoading(false);
            responseCode(res)



        }).catch((err) => {

        })
    };
    const onError = (event) => {
        console.log('error Data:', event);
    }
    // continue With linked in
    const callBack = `${process.env.REACT_APP_LOGINCALLBACK}`;
    useDidMountEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        if (code) {
            getToken(code);
        }
    }, [])
    var personaName = localStorage.getItem("type");
    const getToken = async (code) => {
        var req = {
            "grantType": "authorization_code",
            "code": code,
            "redirectUri": callBack,
            "clientId": "865drszkyuas84",
            "clientSecret": "mr8Bf0DzY3XElto2"
        }
        apiService('user/linkedin/token', req, 'unauthpost').then((result) => {
            if (result && result.data && result.data.accessToken) {
                console.log('Access token:', result.data.accessToken);
                var getform = {
                    "type": cryptoJS('LINKEDIN'),
                    "token": cryptoJS(result.data.accessToken)
                }
                continueWith(getform)
            }

        }).catch((err) => { });
    };
    // continue With startup india
    useDidMountEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const auth_token = urlParams.get('auth_token');
        if (auth_token) {
            // console.log('startup india token', auth_token)
            var getform = {
                "type": cryptoJS('STARTUPINDIA'),
                "token": cryptoJS(auth_token)
            }
            continueWith(getform)
        }
    }, [])
    // continue With google
    const handleGoogleSignIn = async () => {
        try {
            await auth.signOut();
            googleAuthProvider.setCustomParameters({ prompt: 'select_account' });
            await auth.signInWithPopup(googleAuthProvider).then((result) => {
                console.log('Redirect successful:', result);
                const user = result.user;
                if (user) {
                    var getform = {
                        "type": cryptoJS('GOOGLE'),
                        "uid": cryptoJS(user.uid)
                    }
                    const googletyp = cryptoJS('GOOGLE')
                    const uids = cryptoJS(user.uid)
                    sessionStorage.setItem('GoogleType', googletyp)
                    sessionStorage.setItem('Uid', uids)
                    continueWith(getform);
                }
            }).catch((error) => {
                console.error('Error during redirect:', error);
            });
        } catch (error) {
            setError(error.message);
        }
    };
    const continueWith = (getform) => {
        apiService('login', getform, 'unauthpost').then((res) => {
            localStorage.setItem('continueWith', true)
            responseCode(res)
        })
    }
    // commen response Code
    const [openModal, setOpenModal] = useState(false);
    const [idValue, setIdValue] = useState(0)
    const responseCode = (res) => {
        if (res) {
            if (res.data) {
                if (res.data.response.responseStatus === 'Success') {
                    var name = res.data.firstName + `${res.data.lastName ? res.data.lastName : ''}`
                    localStorage.setItem('jwttoken', res.data.token)
                    localStorage.setItem('id', res.data.id)
                    // localStorage.setItem('userpersonaId', res.data.id)
                    localStorage.setItem('profileId', res.data.id)
                    localStorage.setItem('profile', res.data.profileUpdated)
                    localStorage.setItem('role', res.data.role)
                    localStorage.setItem('privileges', JSON.stringify(res.data.privileges))
                    localStorage.setItem('name', name)
                    localStorage.setItem('type', res.data.persona)
                    localStorage.setItem('logo', res.data.imageName)
                    if (res.data.persona === 'NA') {
                        setOpenModal(true)
                        setIdValue(res.data.id)
                    } else {
                        var currentPath = localStorage.getItem('currentPath')
                        if (currentPath) {
                            navigator(currentPath)
                        } else {
                            if (res.data.profileUpdated === true) {
                                if (res.data.persona === 'STARTUP') {
                                    navigator('/startup/profile')
                                } else if (res.data.persona === 'ASPIRANT') {
                                    navigator('/aspirant/profile')
                                } else if (res.data.persona === 'MENTOR') {
                                    navigator('/mentor/profile')
                                } else if (res.data.persona === 'INNOVATOR') {
                                    navigator('/innovator/profile')
                                } else if (res.data.persona === 'INVESTOR') {
                                    navigator('/investor/profile')
                                } else if (res.data.persona === 'STARTUP-ECO') {
                                    navigator('/ecosystem/profile')
                                } else if (res.data.persona === 'STARTUPS-SERVPROV') {
                                    navigator('/service-provider/profile')
                                } else if (res.data.persona === 'CORE-TEAM') {
                                    navigator('/admin')
                                }
                            } else {
                                if (res.data.persona === 'STARTUP') {
                                    navigator('/startups/basicinfo')
                                } else if (res.data.persona === 'ASPIRANT') {
                                    navigator('/aspirants/basicinfo')
                                } else if (res.data.persona === 'MENTOR') {
                                    navigator('/mentors/basicinfo')
                                } else if (res.data.persona === 'INNOVATOR') {
                                    navigator('/innovators/basicinfo')
                                } else if (res.data.persona === 'INVESTOR') {
                                    navigator('/investor/basicinfo')
                                } else if (res.data.persona === 'STARTUP-ECO') {
                                    navigator('/ecosystem/basicinfo')
                                } else if (res.data.persona === 'STARTUPS-SERVPROV') {
                                    navigator('/service-provider/basicinfo')
                                } else if (res.data.persona === 'CORE-TEAM') {
                                    navigator('/admin')
                                }

                            }
                            if (res.data.role === 'COADMIN' || res.data.role === 'ASSOCIATE' || res.data.role === 'ADMIN' || res.data.role === 'CHIEF-SECRETARY') {
                                navigator('/admin')
                            }
                            else if (res.data.role === "SUPER-ADMIN") {
                                navigator('/admin/smartcard/list')
                            }
                        }
                    }

                }
            }
        }
    }
    const [profileDialogBox, setProfileDialogBox] = useState(false)
    const handleProfileClose = () => {
        setProfileDialogBox(false)
    }

    // const responseCode = (res) => {
    //     if (res) {
    //         if (res.data) {
    //             if (res.data.response.responseStatus === 'Success') {
    //                 var name = res.data.firstName + `${res.data.lastName ? res.data.lastName : ''}`
    //                 localStorage.setItem('jwttoken', res.data.token)
    //                 localStorage.setItem('id', res.data.id)
    //                 localStorage.setItem('userId', res.data.id)
    //                 localStorage.setItem('profileId', res.data.id)
    //                 // localStorage.setItem('profile', res.data.profileUpdated)
    //                 localStorage.setItem('profile', res.data.profileUpdated ? res.data.profileUpdated : false)
    //                 localStorage.setItem('role', res.data.role)
    //                 localStorage.setItem('privileges', JSON.stringify(res.data.privileges))
    //                 localStorage.setItem('name', name)
    //                 localStorage.setItem('type', res.data.persona)
    //                 localStorage.setItem('logo', res.data.imageName)
    //                 localStorage.setItem('email', res.data.email)
    //                 if (res.data.persona === 'NA') {
    //                     setIdValue(res.data.id)
    //                     navigator(`letsstart/${btoa(res.data.id)}`)
    //                     localStorage.setItem('idValue', res.data.id)
    //                 } else {
    //                     const currentPath = localStorage.getItem('currentPath');
    //                     const path = localStorage.getItem('way')
    //                     if (currentPath) {
    //                         if (path !== 'Conference') {
    //                             setProfileDialog(true)
    //                             fastUrlPath()
    //                         } else {
    //                             if (res.data.profileUpdated === true) {
    //                                 localStorage.setItem("profile", true)
    //                                 fastUrlPath()
    //                             } else {
    //                                 navigator(`/fest`)
    //                                 // setProfileDialogBox(true)
    //                             }

    //                         }
    //                     } else {
    //                         if (res.data.profileUpdated === true) {
    //                             if (res.data.persona === 'STARTUP') {
    //                                 navigator('/startup/profile')
    //                             } else if (res.data.persona === 'ASPIRANT') {
    //                                 navigator('/aspirant/profile')
    //                             } else if (res.data.persona === 'MENTOR') {
    //                                 navigator('/mentor/profile')
    //                             } else if (res.data.persona === 'INNOVATOR') {
    //                                 navigator('/innovator/profile')
    //                             } else if (res.data.persona === 'INVESTOR') {
    //                                 navigator('/investor/profile')
    //                             } else if (res.data.persona === 'STARTUP-ECO') {
    //                                 navigator('/ecosystem/profile')
    //                             } else if (res.data.persona === 'STARTUPS-SERVPROV') {
    //                                 navigator('/service-provider/profile')
    //                             } else if (res.data.persona === 'CORE-TEAM') {
    //                                 navigator('/admin')
    //                             }
    //                         }  else {
    //                             if (res.data.persona === 'STARTUP') {
    //                                 navigator('/startups/basicinfo')
    //                             } else if (res.data.persona === 'ASPIRANT') {
    //                                 navigator('/aspirants/basicinfo')
    //                             } else if (res.data.persona === 'MENTOR') {
    //                                 navigator('/mentors/basicinfo')
    //                             } else if (res.data.persona === 'INNOVATOR') {
    //                                 navigator('/innovators/basicinfo')
    //                             } else if (res.data.persona === 'INVESTOR') {
    //                                 navigator('/investor/basicinfo')
    //                                 // navigator('/investor/profile')
    //                             } else if (res.data.persona === 'STARTUP-ECO') {
    //                                 navigator('/ecosystem/basicinfo')
    //                             } else if (res.data.persona === 'STARTUPS-SERVPROV') {
    //                                 navigator('/service-provider/basicinfo')
    //                             } else if (res.data.persona === 'CORE-TEAM') {
    //                                 navigator('/admin')
    //                             }

    //                         }
    //                         if (res.data.role === 'COADMIN' || res.data.role === 'ASSOCIATE' || res.data.role === 'ADMIN' || res.data.role === 'CHIEF-SECRETARY') {
    //                             navigator('/admin')
    //                         }
    //                         else if (res.data.role === "SUPER-ADMIN") {
    //                             navigator('/admin/smartcard/list')
    //                         }
    //                         // else {
    //                         //     navigator('/fest')
    //                         // }

    //                     }
    //                 }
    //                 // Check if there's an intended path stored and navigate to it
    //                 // const currentPath = localStorage.getItem('currentPath');
    //                 // const path = localStorage.getItem('way')
    //                 // if (currentPath) {
    //                 //     // 
    //                 //     if (path !== 'Conference') {
    //                 //         setProfileDialog(true)
    //                 //         fastUrlPath()
    //                 //     } else {
    //                 //         setProfileDialogBox(true)
    //                 //     }

    //                 // } else {
    //                 //     // Handle navigation based on persona and profile update status

    //                 // }
    //                 reset()
    //                 setInputValue('')
    //             }
    //         }
    //     }
    // }
    // const handleParsonaClose = (value) => {
    //     if (value === 'STARTUP') {
    //         navigator('/startups')
    //     } else if (value === 'ASPIRANT') {
    //         navigator('/aspirants')
    //     } else if (value === 'MENTOR') {
    //         navigator('/mentors')
    //     } else if (value === 'INNOVATOR') {
    //         navigator('/innovators')
    //     } else if (value === 'INVESTOR') {
    //         navigator('/investor')
    //     } else if (value === 'STARTUP-ECO') {
    //         navigator('/ecosystem')
    //     } else if (value === 'STARTUPS-SERVPROV') {
    //         navigator('/service-provider')
    //     }

    // }
    const [openPasswordModal, setOpenPasswordModal] = useState(false);
    const forgotPassword = () => {
        setOpenPasswordModal(true)
    }
    const forgotPasswordClose = () => {
        setCaptchaValue('')
        setOpenPasswordModal(false)
    }

    return (
        <div>
            <div className='signup-details-bg-dev'>
                {/* <img src="/images/TamilNadu_Logo.svg" alt="" className="img-fluid startup_img2" onClick={handleHomePage}/> */}
                <img src='/images/startup-white.png' alt='' className='startup_img img-fluid' onClick={handleHomePage} />
            </div>
            <Grid container spacing={2} className='p-4'>
                <Grid item xs={12}>
                    <div className='pt-5'>
                        {/* <Button startIcon={<ArrowBackIcon />} onClick={backBtn}>Back</Button> */}
                    </div>
                </Grid>
                <Grid item lg={4} md={4} sx={{ display: { lg: 'block', md: 'block', sm: 'none', xs: 'none' } }}>
                </Grid>
                <Grid item lg={4} md={6} sm={12} xs={12}>
                    <Grid container spacing={1}>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <StartupIndia />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <button className='continue_btn2' onClick={handleGoogleSignIn}>Continue with <img src='/images/logo/Google_logo.png' alt='' /></button>
                        </Grid>
                        {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                            <LinkedInLogin />
                        </Grid> */}
                        <Grid item xs={12}>
                            <div className='signup-data_div'>
                                <div className='signup-title'>
                                    <p>Login</p>
                                </div>
                                <div className='signup_box_div'>
                                    <form onSubmit={handleSubmit(onSubmit, onError)}>
                                        <Grid container spacing={1}>
                                            <Grid item lg={12} md={12} sm={12} xs={12} className='mb-3'>
                                                <Controller
                                                    name="userName"
                                                    control={control}
                                                    defaultValue=""
                                                    rules={{ required: "Email Address is required", validate: validateEmail }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            variant="outlined"
                                                            label="Email Address"
                                                            placeholder="Enter Email Address"
                                                            fullWidth
                                                            type="text"
                                                            {...field}
                                                        />
                                                    )}
                                                />
                                                <FormHelperText className="text-danger">
                                                    {errors.userName && errors.userName.message}
                                                </FormHelperText>
                                            </Grid>
                                            <Grid item lg={12} md={12} sm={12} xs={12} className='mb-3'>
                                                <Controller
                                                    name="password"
                                                    control={control}
                                                    defaultValue=""
                                                    rules={{ required: "Password is required" }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            variant="outlined"
                                                            label="Password"
                                                            placeholder="Enter Password"
                                                            fullWidth
                                                            {...field}
                                                            type={showPassword ? "text" : "password"}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <IconButton
                                                                        onClick={handleClickShowPassword}
                                                                        onMouseDown={handleMouseDownPassword}
                                                                    >
                                                                        {showPassword ? (
                                                                            <Visibility />
                                                                        ) : (
                                                                            <VisibilityOff />
                                                                        )}
                                                                    </IconButton>
                                                                ),
                                                            }}
                                                        />
                                                    )}
                                                />
                                                <FormHelperText className="text-danger">
                                                    {errors.password && errors.password.message}
                                                </FormHelperText>
                                                <FormHelperText className="c-blue c-pointer" onClick={forgotPassword}>
                                                    {"Forgot Password"}
                                                </FormHelperText>
                                            </Grid>
                                            <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                                                <Captchatext />
                                            </Grid>
                                            <Grid item lg={12} md={12} sm={12} xs={12} className='d-flex align-item-center'>
                                                <p className='mb-0'>Create an Account - <Link className='c-blue' to={'/profile/type'}>Signup</Link></p>
                                                <button className={`ml-auto ${formValid ? 'register-btn' : 'register-btn-disabled'}`} type="submit"
                                                    disabled={!formValid}
                                                >Login</button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item lg={4} md={2} sx={{ display: { lg: 'block', md: 'block', sm: 'none', xs: 'none' } }}>
                </Grid>
            </Grid>
            <Dialog fullWidth={fullWidth} open={openPasswordModal} maxWidth="lg">
                <ForgotPassword forgotPasswordClose={forgotPasswordClose} />
            </Dialog>            
        </div>
    )
}

export default Login