import React from 'react'
import { Button, Dialog, DialogContent, Grid } from '@mui/material'
import { useContext } from 'react'
import DataContext from '../../../../../context/DataContext'
import DoneIcon from '@mui/icons-material/Done';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DelegateModal from './DelegateModal';
import { useState } from 'react';
import useDidMountEffect from '../../../../../hooks/useDidMountEffect';
import apiService from '../../../../../api/apiService';
import cryptodecryptJS from '../../../../../hooks/cryptodecryptJS';
import { useForm } from 'react-hook-form';

const Delegatefreepass = () => {
    const { navigator, ProfileDialogBox, fastUrlPath, handleProfileDialogCloseBox, setPageLoading } = useContext(DataContext)

    // const { handleSubmit, control, setValue, getValues, clearErrors, formState: { errors } } = useForm({ values: formData })

    var id = localStorage.getItem("id");
    var type = localStorage.getItem('type')
    // useDidMountEffect(() => {
    //     if (id !== null && type !== 'NA') {
    //         getApi();
    //     }
    // }, [])
    // const [formData, setFormData] = useState({});
    const getApi = () => {

        if (id !== null && type !== 'NA') {
            setPageLoading(true)

            apiService(`userprofile/get?persona=${type}&userid=${id}`, "", "get")
                .then((result) => {
                    setPageLoading(false)
                    if (result && result.data) {
                        var data = result.data;
                        if (data.email) {
                            handleFreepass(data.email)
                        }
                    }
                })
                .catch((err) => {
                });
        } else {

        }

    };
    const [delegateModalOpen, setDelegateModalOpen] = useState(false);
    const handleDelegateModalOpen = () => {
        setDelegateModalOpen(true);
    };
    const handleDelegateModalClose = () => {
        setDelegateModalOpen(false);
    };

    const handleFreepass = (email) => {
        localStorage.removeItem('currentPath');
        // var email = localStorage.getItem('email')
        // 
        var conferenceurl = `https://event.startuptn.in/conference?email=${cryptodecryptJS(email)}`
        localStorage.setItem("conferencePath", conferenceurl)
        localStorage.removeItem('way')
        window.location.href = conferenceurl;
        navigator("/fest");
    }
    const [openType, setOpenType] = useState(false)
    const [openTypeValue, setOpenTypeValue] = useState('')
    const openTypeModal = (value) => {
        setDelegateModalOpen(true)
        setOpenTypeValue(value)
    }
    const closeTypeModal = () => {
        setDelegateModalOpen(false)
        setOpenTypeValue('')
    }
    const myself = () => {
        if (openTypeValue === 'Delegate') {
            handleDelegateModalOpen()
        } else {
            getApi()
        }
    }
    const myTeam = () => {
        if (type === 'STARTUP') {
            navigator('/startups/basicinfo')
        } else if (type === 'ASPIRANT') {
            navigator('/aspirants/basicinfo')
        } else if (type === 'MENTOR') {
            navigator('/mentors/basicinfo')
        } else if (type === 'INNOVATOR') {
            navigator('/innovators/basicinfo')
        } else if (type === 'INVESTOR') {
            navigator('/investor/basicinfo')
            // navigator('/investor/profile')
        } else if (type === 'STARTUP-ECO') {
            navigator('/ecosystem/basicinfo')
        } else if (type === 'STARTUPS-SERVPROV') {
            navigator('/service-provider/basicinfo')
        } else if (type === 'CORE-TEAM') {
            navigator('/admin')
        }
    }
    return (
        <>
            <div>
                <Dialog
                    fullWidth={true}
                    maxWidth={"md"}
                    open={ProfileDialogBox}
                >
                    <DialogContent sx={{ padding: '30px 47px' }}>
                        <div className='text-center mb-3'>
                            <img src='/images/thiruzila_logo.png' className='thiruzila_logo' />
                        </div>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="flex-start"
                            spacing={2}
                        >
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <div className='dele-box'>
                                    <div className='dele-head' onClick={() => openTypeModal('Delegate')} >
                                        <h5 className='m-0'>Delegate Pass</h5>
                                    </div>
                                    <div>
                                        <p className='tick-clr'> <DoneIcon className='tick-clr mr-3' /> Access to Conference </p>
                                        <p className='tick-clr'> <DoneIcon className='tick-clr mr-3' /> Access to Exhibition Area </p>
                                        <p className='close-clr'> <CloseRoundedIcon className='close-clr mr-3' /> No Food will be provided </p>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <div className='dele-box'>
                                    <div className='dele-head' onClick={() => openTypeModal('Free')}>
                                        <h5 className='m-0'>Visitor Pass</h5>
                                    </div>
                                    <div>
                                        <p className='tick-clr'> <DoneIcon className='tick-clr mr-3' /> Access to Exhibition Area </p>
                                        <p className='close-clr'> <CloseRoundedIcon className='close-clr mr-3' /> No Access to Conference </p>
                                        <p className='close-clr'> <CloseRoundedIcon className='close-clr mr-3' /> No Food will be provided </p>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </DialogContent>

                    <div className='text-center pb-3'>
                        <Button
                            variant="contained"
                            className="edit-btn-profile"
                            sx={{
                                textTransform: "none",
                                border: "1px solid",
                                borderColor: "#020671",
                                color: "#fff",
                                padding: "6px 20px",
                                background: "#00023A",
                                marginRight: "10px"
                            }}
                            onClick={handleProfileDialogCloseBox}
                        >
                            Close
                        </Button>
                    </div>
                </Dialog>
            </div>
            <Dialog fullWidth maxWidth="md" open={openType} onClose={closeTypeModal}>
                <DialogContent sx={{ padding: '30px 47px' }}>
                    <div className='text-center mb-3'>
                        <img src='/images/thiruzila_logo.png' className='thiruzila_logo' />
                    </div>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                        spacing={2}
                    >
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <div className='dele-head' onClick={myself} >
                                <h5 className='m-0'>For Myself</h5>
                            </div>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <div className='dele-head' onClick={myTeam}>
                                <h5 className='m-0'>For My Team</h5>
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            <Dialog fullWidth maxWidth="lg" open={delegateModalOpen}>
                <DelegateModal onClose={handleDelegateModalClose} openTypeValue={openTypeValue}/>
            </Dialog>
        </>
    )
}

export default Delegatefreepass
