import React, { useContext, useState } from "react";
import {
  Autocomplete,
  Container,
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
  TextField,
} from "@mui/material";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import DataContext from "../../../context/DataContext";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Eventregister from "../../Media-module/Media-layout-page/Event-Page/Eventregister";
import apiService from "../../../api/apiService";
import { MdArrowDropDownCircle } from "react-icons/md";
const HomeEventSection = () => {
  const { navigator, setPageLoading, fullScreen } = useContext(DataContext);
  useDidMountEffect(() => {
    categorylistApi();
  }, []);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [opens, setOpens] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [categoryId, setCategoryId] = useState("");
  const [week, setWeek] = useState(0);
  useDidMountEffect(() => {
    listApi();
  }, [page, categoryId, week]);
  const [eventList, setEventList] = useState([]);

  const listApi = () => {
    setEventList([]);
    setPageLoading(true);
    const today = new Date();
    const nextYear = new Date();
    nextYear.setFullYear(today.getFullYear() + 1);

    // Format the dates as YYYY-MM-DD
    const fromDate = today.toISOString().split("T")[0];
    const toDate = nextYear.toISOString().split("T")[0];
    var req = {
      categoryId: categoryId,
      listSize: 3,
      pageNumber: page,
      week: week,
      fromDate: fromDate,
      toDate: toDate,
    };
    apiService(`event/home/list`, req, "unauthpost")
      .then((result) => {
        setPageLoading(false);
        if (result && result.data && result.data.event) {
          setEventList(result.data.event);
          setTotalPage(result.data.totalPages);
        }
      })
      .catch((err) => {});
  };
  const handleWeekChange = (value) => {
    setWeek(value);
  };
  const [eventId, setEventId] = useState(0);
  const handleRegister = (val) => {
    var token = localStorage.getItem("jwttoken");
    if (token === "") {
      navigator("/login");
    } else if (token === null) {
      navigator("/login");
    } else {
      setEventId(val);
      setOpens(true);
    }
  };
  const handleReportClose = () => {
    setOpens(false);
  };
  const handleChange = (value) => {
    setPage(value);
  };
  const [store, setStore] = useState([]);
  const categorylistApi = () => {
    apiService(`home/category/list?type=event`, "", "unauthget")
      .then((result) => {
        if (result && result.data && result.data.categories) {
          setStore(result.data.categories);
        }
      })
      .catch((err) => {});
  };
  const [eventValue, setEventValue] = useState("");

  const handleEventFilter = (id, name) => {
    setEventValue(name);

    if (name !== null) {
      var value = store.filter((option) => option.category === name);
      setCategoryId(value[0].id);
    } else {
      setCategoryId("");
    }
  };
  return (
    <section className="event-section">
      <Container>
        <div className="event-header mb-5">
          <h4>
            <b>Upcoming Events</b>
          </h4>
        </div>
        <div className="home-event-title">
          {/* <h4 className="home-coming">
            <b>Events</b>
          </h4> */}
          <div className="event-filters">
            {/* <div className="filter-btns c-pointer">
              <span>
                <img
                  src={"/images/filtericn.png"}
                  alt="filtericn"
                  onClick={() => setShowForm(!showForm)}
                />
              </span>
              <p className="filter-text" onClick={() => setShowForm(!showForm)}>
                Filters
              </p>
            </div> */}
            <div>
              {showForm && (
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="end"
                  spacing={2}
                >
                  <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <Autocomplete
                      // disableClearable
                      disablePortal
                      id="combo-box-demo"
                      value={eventValue}
                      options={store.map((option) => option.category)}
                      popupIcon={<MdArrowDropDownCircle />}
                      onChange={(e, selectedOption) =>
                        handleEventFilter(e, selectedOption)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Category"
                          placeholder="Select Category"
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              )}
            </div>
          </div>
          <Grid container spacing={2} className="mb-2">
            {/* <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                            <div className="event-filter">

                                <div>
                                    <button className="weekbtn" onClick={() => handleWeekChange(0)}>This Week</button>
                                    <button className="event_round_btn" onClick={() => handleWeekChange(week - 1)}>{`<`}</button>
                                    <button className="event_round_btn" onClick={() => handleWeekChange(week + 1)}>{`>`}</button>
                                </div>
                                <div className="event-list-div">
                                    <p className={categoryId === '' && 'actCategory'} onClick={() => handleEventFilter('')}>All</p>
                                    {
                                        store.length > 0 && store.map((value, index) => (
                                            <p className={categoryId === value.id && 'actCategory'} onClick={() => handleEventFilter(value.id)}>{value.category}</p>
                                        ))
                                    }
                                </div>
                            </div>
                        </Grid> */}

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="mt-3">
              {eventList.length > 0 ? (
                <Grid container spacing={2}>
                  {eventList.map((item, index) => (
                    <Grid item sm={12} md={4} lg={4}>
                      <div className="event-list">
                        <div
                          class="card"
                          style={{
                            width: "18rem",
                            minHeight: "380px",
                            borderRadius: "10px",
                          }}
                        >
                          <div className="event-img">
                            <img
                              src={item.imageName}
                              class="event-home-img-top"
                              alt=""
                            />
                            <div className="img-top">
                              <span>{item.category}</span>
                            </div>
                            <div className="event-time-top">
                              <div className="home-event-days">
                                {item.startDay && (
                                  <>
                                    <div className="time-bg">
                                      <h6 className="time-color">
                                        {item.startDay}
                                      </h6>
                                      <span>{item.startMonth}</span>
                                    </div>
                                  </>
                                )}
                                {item.endDay && (
                                  <>
                                    <div className="time-bg ml-2">
                                      <h6 className="time-color">
                                        {item.endDay}
                                      </h6>
                                      <span>{item.endMonth}</span>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                          <div
                            class="card-body"
                            style={{ padding: "10px", position: "relative" }}
                          >
                            <div className="event-details">
                              <div>
                                <h5>{item.title}</h5>
                                {/* <p>{item.description}</p> */}
                                <h6 className="event-time mt-2">
                                  <img
                                    src="/images/clock-icon.png"
                                    alt=""
                                    className="mr-2"
                                  />
                                  {item.formattedTime}
                                  {/* - <span className="event-free">({item.meetSpace})</span> */}
                                </h6>
                                {item.location ? (
                                  <span className="event-location">
                                    <img
                                      src="/images/location-icon.png"
                                      alt=""
                                      className="mr-2"
                                    />
                                    {item.location}
                                  </span>
                                ) : (
                                  // <span className="event-location">&nbsp;</span>
                                  <span className="event-location">
                                    <img
                                      src="/images/online-symbal.png"
                                      alt=""
                                      className="mr-2"
                                    />
                                    Online
                                  </span>
                                )}
                              </div>
                              <div className="reg-fees2 mt-2">
                                {item.paid === false ? (
                                  <span className="event-free">Free</span>
                                ) : (
                                  <span className="event-free">
                                    ₹{item.amount}
                                  </span>
                                )}
                                {item.showRegisterButton === true && (
                                  <div>
                                    <button
                                      className="reg-btn"
                                      onClick={() => handleRegister(item.id)}
                                    >
                                      Register
                                    </button>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <div className="text-center">
                  <h3>Events coming soon</h3>
                </div>
              )}
            </Grid>
            {eventList.length > 0 && totalPage > 1 && (
              <div className="event-pagination text-center w-100">
                <button
                  disabled={page === 1}
                  className="event_round_btn"
                  onClick={() => handleChange(page - 1)}
                >{`<`}</button>
                <button
                  disabled={page === totalPage}
                  className="event_round_btn"
                  onClick={() => handleChange(page + 1)}
                >{`>`}</button>
              </div>
            )}
          </Grid>
        </div>
      </Container>
      <Dialog fullScreen={fullScreen} maxWidth={"lg"} open={opens}>
        <DialogContent className="dialog-modal">
          <DialogContentText id="alert-dialog-description">
            <div>
              <span
                style={{ color: "#2260FF", cursor: "pointer" }}
                onClick={handleReportClose}
              >
                <ArrowBackIcon /> Back
              </span>
            </div>
            <Eventregister
              categoryId={eventId}
              setOpens={setOpens}
              via={"home"}
            />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </section>
  );
};

export default HomeEventSection;
