import React, { useContext, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { Avatar, Box, IconButton, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import DataContext from "../../../context/DataContext";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import apiService from "../../../api/apiService";
import { Margin } from "@mui/icons-material";
import SideMenu from "../../home-module/navbar-page/SideMenu";




const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const UndermaintainNavbar = () => {
  const { navigator } = useContext(DataContext);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [isScrolled, setIsScrolled] = useState(false);
  let loignType
  useDidMountEffect(() => {
    loignType = localStorage.getItem('type')
    const handleScroll = () => {
      if (window.scrollY > 50) {  // Adjust the scroll position value as needed
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  let logo = localStorage.getItem('logo')
  let token = localStorage.getItem("jwttoken")
//   useDidMountEffect(() => {
//     if (token !== null && logo === null) {
//       getApi()
//     }
//   }, [])
  const [startupName, setStartupName] = useState('')
//   const getApi = () => {
//     apiService(`user/get`, '', 'get').then((result) => {
//       if (result && result.data) {
//         var data = result.data
//         if (data.companyLogoURL) {
//           localStorage.setItem('logo', data.companyLogoURL)
//         } else {
//           setStartupName(data.startupName)
//         }

//       }

//     }).catch((err) => {

//     });
//   }
//   const logoutBtn = () => {
//     handleCloseUserMenu()
//     localStorage.clear()
//     navigator('/')
//   }
//   const handleProfileType = () => {
//     navigator('/profile')
//   }
//   const profilePage = () => {
//     // navigator('/user/profile')
//     var name=localStorage.getItem('type')
//     if (name === 'STARTUP') {
//       navigator('/startup/profile')
//     } else if (name === 'ASPIRANT') {
//       navigator('/aspirants/profile')
//     } else if (name === 'MENTOR') {
//       navigator('/mentors/profile')
//     } else if (name === 'INNOVATOR') {
//       navigator('/innovators/profile')
//     } else if (name === 'INVESTOR') {
//       navigator('/investor/profile')
//     } else if (name === 'STARTUP-ECO') {
//       navigator('/ecosystem/profile')
//     } else if (name === 'STARTUPS-SERVPROV') {
//       navigator('/service-provider/profile')
//     }
//   }
//   const adminDashboardPage = () => {
//     navigator('/admin')
//   }
//   const [openStartupMenu, setOpenStartupMenu] = useState(false)
//   const handleStartupMenu = () => {
//     setOpenStartupMenu(!openStartupMenu)
//   }

  return (
    <>

      <div className={`${isScrolled ? 'scrolled fixed-tops' : 'fixed-top'}`}>
        <div className={token !== null ? 'container' : 'container-fluid margin-css'}>
          <nav className="navbar navbar-expand-lg navbar-light bg-lights nav-pads">
            <Link className="navbar-brand nav-log-res1" to="/">
              <img src="/images/tamilnadu_new_logo.png" alt="" />
            </Link>

            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              {/* {
                token === null && (
                  <SideMenu/>
                )
              } */}

              <Link to="/">
                <img
                  className="img-fluid nav-logo-profile"
                  src="/images/tamilnadu_new_logo.png"
                  alt=""
                />
              </Link>

              <ul className="navbar-nav nav-items m-auto">
                {/* Dropdown 1 */}
                <li className="nav-item dropdown mr-1">
                  <Link
                    className="nav-link menues dropdown-toggle"
                    role="button"
                    // onClick={handleStartupMenu}
                  >
                    For Startups
                  </Link>

                </li>
                {/* Dropdown 2 */}
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link menues dropdown-toggle"

                    role="button"
                    data-toggle="dropdown"
                    aria-expanded="false"
                  >
                    For Enablers
                  </Link>
                  {/* <div className="dropdown-menu">
                    <Link className="dropdown-item" >Action</Link>
                    <Link className="dropdown-item" >Another action</Link>
                    <div className="dropdown-divider"></div>
                    <Link className="dropdown-item" >Something else here</Link>
                  </div> */}
                </li>
                <li className="nav-item mr-1">
                  <NavLink className="nav-link menuess" 
                //   to={'/events/list'} 
                  >Events & Media</NavLink>
                </li>
                <li className="nav-item mr-1">
                  <NavLink className="nav-link menuess"
                //    to={'/ecosystem-info'}
                   >Ecosystem</NavLink>
                </li>
              </ul>

              {/* More Navbar Links */}
              <ul className="navbar-nav">
                <div className="nav-align">
                  <button className="search-buttons" ><img src="/images/search-icon.png" alt="" className="" /></button>
                  {
                    token === null ? (
                      <>
                        <Link
                        //  to={'/login'} 
                         className="nav-buttons" >Login</Link>
                        <button className="nav-button" 
                        // onClick={handleProfileType}
                        >Get Started</button>
                      </>
                    ) : (
                      <Box sx={{ flexGrow: 0 }}>
                        {/* onClick={handleOpenUserMenu} */}
                        <Tooltip 
                        // onClick={handleOpenUserMenu}
                        >
                          <IconButton sx={{ p: 0 }}>
                            <Avatar alt={startupName} src={logo} />
                          </IconButton>
                        </Tooltip>
                        <Menu
                          sx={{ mt: '45px' }}
                          id="menu-appbar"
                          anchorEl={anchorElUser}
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          keepMounted
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          open={Boolean(anchorElUser)}
                          onClose={handleCloseUserMenu}
                        >
                          {
                            loignType === 'ADMIN' ? (
                              <MenuItem
                            //    onClick={adminDashboardPage} 
                               >
                                <Typography textAlign="center">Dashboard</Typography>
                              </MenuItem>
                            ) : (
                              <MenuItem 
                            //   onClick={profilePage}
                               >
                                <Typography textAlign="center">Profile</Typography>
                              </MenuItem>
                            )
                          }


                          <MenuItem 
                        //   onClick={logoutBtn} 
                          >
                            <Typography textAlign="center">Logout</Typography>
                          </MenuItem>
                        </Menu>
                      </Box>
                    )
                  }
                </div>


              </ul>
            </div>
          </nav>
        </div>

      </div>
      <div className="startupMenu">
        {/* {
          openStartupMenu === true && (
            <StartupMenu setOpenStartupMenu={setOpenStartupMenu} />
          )
        } */}
      </div>
    </>
  )
}

export default UndermaintainNavbar

