import React, { useContext, useState } from "react";
import {
  Autocomplete,
  Button,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  ToggleButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import apiService from "../../../../api/apiService";
import DataContext from "../../../../context/DataContext";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import DeleteIcon from '@mui/icons-material/Delete';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
const tomorrow = dayjs().add(0, 'day');
const SmartproductAdd = ({ onClose, getSmartCardApi }) => {
  const [showAdditionalCharges, setShowAdditionalCharges] = useState(false);
  const { navigator, setPageLoading, validateNumberonly } = useContext(DataContext)
  const [formData, setFormData] = useState({
    "offers": [
      {
        "id": 0,
        "smartCardAssocId": 0,
        "offer": "",
        "descripition": ""
      }
    ]
  });
  const { handleSubmit, control, getValues, setValue, reset, clearErrors, formState: { errors } } = useForm({ values: formData });
  const {
    fields: offersFields,
    append: offersAppend,
    remove: offersRemove,
  } = useFieldArray({
    control,
    name: "offers",
  });
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  useDidMountEffect(() => {
    categoryApi();
  }, [])
  const categoryApi = () => {
    apiService("smartcard/subcategory/list", "", "get")
      .then((result) => {
        if (result && result.data) {
          setCategories(result.data);
        }
      })
      .catch((err) => {
      });
  };

  const handlePriceMode = (value) => {
    setValue("period", value);
    clearErrors("period");
  };

  const handleTypeMode = (value) => {
    setValue("offerType", value);
    clearErrors("offerType");
  };

  const handleToggleAdditionalCharges = () => {
    setShowAdditionalCharges((prev) => !prev);
  };

  const handleSelectCategory = (event, newValue) => {
    const selectedCategory = categories.find((c) => c.category === newValue);
    setSelectedCategoryId(selectedCategory.id);
    setValue("category", newValue);
    if (selectedCategory.subcategorylist) {
      setSubcategories(selectedCategory.subcategorylist);
    } else {
      setSubcategories([]);
    }
  };

  const handleSelectSubcategory = (event, newValue) => {
    const selectedSubcategory = subcategories.find((sc) => sc.subcategory === newValue);
    if (selectedSubcategory) {
      setValue("subCategoryId", selectedSubcategory.id);
    }
  };

  const [startdate, setStartDate] = useState(null)
  const handleStartDateFormat = (newValue) => {
    setStartDate(newValue)
    setValue('startDate', newValue)
    clearErrors('startDate');
    // const datePart = newValue.format('YYYY-MM-DD');
  }
  const [enddate, setEndDate] = useState(null)
  const handleEndDateFormat = (newValue) => {
    setEndDate(newValue)
    setValue('endDate', newValue)
    clearErrors('endDate');
    // const datePart = newValue.format('YYYY-MM-DD');
  }
  const addManagementBtn = () => {
    offersAppend({
      "id": 0,
      "smartCardAssocId": 0,
      "offer": "",
      "descripition": ""
    });
  };
  const handleOffersDelete = (index) => {
    var id = getValues(`offers.${index}.id`)
    if (id !== 0) {
      apiService(`event/management/remove?id=${id}`, '', 'delete').then((result) => {
        if (result && result.data) {
          offersRemove(index)
        }
      }).catch((err) => {

      });
    } else {
      offersRemove(index)
    }
  }
  const onsubmit = (data) => {
    // data.startDate = data.startDate.format('YYYY-MM-DD');
    // data.endDate = data.endDate.format('YYYY-MM-DD');
    apiService("smartcard/privilege/create", data, "post")
      .then((result) => {
        setPageLoading(false)
        if (result && result.data && result.data.responseStatus === "Success") {
          reset()
          onClose()
          getSmartCardApi()
        }
      })
      .catch((err) => { });
  }
  const [selectedDuration, setSelectedDuration] = useState("")
  const Duration = [
    { Durations: "Day" },
    { Durations: "Month" },
    { Durations: "Year" },
  ];
  const handleDuration = (value) => {
    setSelectedDuration(value);
    setValue('offerTime', value ? value.Durations : '');
  };
  return (
    <div>
      <DialogTitle>
        Add Product / Services
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 44,
            top: 30,
            backgroundColor: "red",
            borderRadius: "50%",
            color: "white",
            padding: "1px",
            '&:hover':{
              backgroundColor: "red",
              color: "white",
            }
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className="m-0">
        <form onSubmit={handleSubmit(onsubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <div className="input_background mb-3">
                <Controller
                  name="name"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Product / Service Name is required" }}
                  render={({ field }) => (
                    <TextField
                      variant="outlined"
                      label="Product / Service Name"
                      placeholder="Please Enter Product / Service Name"
                      fullWidth
                      type="text"
                      {...field}
                    />
                  )}
                />
                <FormHelperText className="text-danger">
                  {errors.name && errors.name.message}
                </FormHelperText>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="input_background mb-3">
                <Controller
                  name="period"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Price / Period is Required" }}
                  render={({ field }) => (
                    <FormControl>
                      <FormLabel> Price / Period </FormLabel>
                      <RadioGroup
                        row
                        {...field}
                        onChange={(e) => handlePriceMode(e.target.value)}
                      >
                        <FormControlLabel
                          value={"Annually"}
                          control={<Radio />}
                          label="Annually"
                        />
                        <FormControlLabel
                          value={"Monthly"}
                          control={<Radio />}
                          label="Monthly"
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                />
                <FormHelperText className="text-danger">
                  {errors.period && errors.period.message}
                </FormHelperText>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="input_background mb-3">
                <Controller
                  name="description"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Product Description / Feature is required",
                  }}
                  render={({ field }) => (
                    <TextField
                      variant="outlined"
                      label="Product Description / Feature"
                      placeholder="Please Enter Product Description / Feature"
                      fullWidth
                      type="text"
                      {...field}
                    />
                  )}
                />
                <FormHelperText className="text-danger">
                  {errors.description &&
                    errors.description.message}
                </FormHelperText>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="input_background mb-3">
                <Controller
                  name="marketPrice"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Market Price is required" }}
                  render={({ field }) => (
                    <TextField
                      variant="outlined"
                      label="Market Price"
                      placeholder="Please Enter Market Price"
                      fullWidth
                      type="text"
                      {...field}
                      onKeyDown={(e) => validateNumberonly(e)}
                    />
                  )}
                />
                <FormHelperText className="text-danger">
                  {errors.marketPrice && errors.marketPrice.message}
                </FormHelperText>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="input_background mb-3">
                <Controller
                  name="offerType"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Type of Offer is Required" }}
                  render={({ field }) => (
                    <FormControl>
                      <FormLabel> Type of Offer </FormLabel>
                      <RadioGroup
                        row
                        {...field}
                        onChange={(e) => handleTypeMode(e.target.value)}
                      >
                        <FormControlLabel
                          value={"Discount"}
                          control={<Radio />}
                          label="Discount"
                        />
                        <FormControlLabel
                          value={"Price Cut"}
                          control={<Radio />}
                          label="Price Cut"
                        />
                        <FormControlLabel
                          value={"Free"}
                          control={<Radio />}
                          label="Free"
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                />
                <FormHelperText className="text-danger">
                  {errors.offerType && errors.offerType.message}
                </FormHelperText>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="input_background mb-3">
                <Controller
                  name="smartCardPrice"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Smart Card Price / Discount is required",
                  }}
                  render={({ field }) => (
                    <TextField
                      variant="outlined"
                      label="Smart Card Price / Discount "
                      placeholder="Please Enter Price / Discount Percentage"
                      fullWidth
                      type="text"
                      {...field}
                      onKeyDown={(e) => validateNumberonly(e)}
                    />
                  )}
                />
                <FormHelperText className="text-danger">
                  {errors.smartCardPrice && errors.smartCardPrice.message}
                </FormHelperText>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={2} >
                <Grid item md={6} lg={6}>
                  <div className="input_background mb-3">
                    <FormControl sx={{ width: "100%" }}>
                      <Controller
                        name="category"
                        control={control}
                        defaultValue=""
                        rules={{ required: true }}
                        render={({ field, fieldState: { error } }) => (
                          <Autocomplete
                            disableClearable
                            disablePortal
                            {...field}
                            options={categories.map((option) => option.category)}
                            value={field.value}
                            onChange={(event, newValue) => {
                              field.onChange(newValue);
                              handleSelectCategory(event, newValue);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Category *"
                                placeholder="Select Category"
                                error={!!error}
                                helperText={error ? 'Category is required' : ''}
                              />
                            )}
                            fullWidth
                            margin="normal"
                          />
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.category && errors.category.errorMessage}
                      </FormHelperText>
                    </FormControl>
                  </div>
                </Grid>
                {
                  subcategories.length > 0 && (
                    <Grid item md={6} lg={6}>
                      <div className="input_background mb-3">
                        <FormControl sx={{ width: "100%" }}>
                          <Controller
                            name="subCategoryId"
                            control={control}
                            defaultValue=""
                            rules={{ required: true }}
                            render={({ field, fieldState: { error } }) => (
                              <Autocomplete
                                disableClearable
                                disablePortal
                                options={subcategories.map((option) => option.subcategory)}
                                value={subcategories.find(sc => sc.id === field.value)?.subcategory || ""} // Match selected id with its name for display
                                onChange={(event, newValue) => {
                                  const selectedSubcategory = subcategories.find(sc => sc.subcategory === newValue);
                                  field.onChange(selectedSubcategory ? selectedSubcategory.id : ""); // Update form state with subcategory id
                                  handleSelectSubcategory(event, newValue); // Custom handler
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Subcategory *"
                                    placeholder="Select Subcategory"
                                    error={!!error}
                                    helperText={error ? 'Subcategory is required' : ''}
                                  />
                                )}
                                fullWidth
                                margin="normal"
                              />
                            )}
                          />
                          <FormHelperText className="text-danger">
                            {errors.subCategoryId && errors.subCategoryId.errorMessage}
                          </FormHelperText>
                        </FormControl>
                      </div>
                    </Grid>
                  )
                }
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={6} xs={6}>
                  <div className='input_background mb-3'>
                    {/* <Controller
                      name={`startDate`}
                      control={control}
                      rules={{ required: 'Start Date is required' }}
                      render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={['DatePicker']} fullWidth>
                            <DatePicker label="Start Date" format="DD/MM/YYYY"
                              minDate={tomorrow}
                              value={startdate}
                              {...field}
                              sx={{ width: "100%" }}
                              inputFormat="DD/MM/YYYY"
                              onChange={(newValue) => handleStartDateFormat(newValue)}
                              renderInput={(params) => <TextField {...params} />}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.startDate &&
                        errors.startDate.message}
                    </FormHelperText> */}
                    <Controller
                      name="offerDuration"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "Offer Valid Duration is required",
                      }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="Offer Valid Duration"
                          placeholder="Please Enter Offer Valid Duration"
                          fullWidth
                          type="text"
                          {...field}
                          onKeyDown={(e) => validateNumberonly(e)}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.offerDuration && errors.offerDuration.message}
                    </FormHelperText>
                  </div>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={6}>
                  <div className='input_background mb-3'>
                    {/* <Controller
                      name={`endDate`}
                      control={control}
                      rules={{ required: 'End Date is required' }}
                      render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={['DatePicker']} fullWidth>
                            <DatePicker label="End Date" format="DD/MM/YYYY"
                              minDate={startdate ? startdate : tomorrow}
                              value={enddate}
                              {...field}
                              sx={{ width: "100%" }}
                              inputFormat="DD/MM/YYYY"
                              onChange={(newValue) => handleEndDateFormat(newValue)}
                              renderInput={(params) => <TextField {...params} />}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.endDate &&
                        errors.endDate.message}
                    </FormHelperText> */}
                    {/* <FormControl sx={{ width: "50%" }}>
                      <Controller
                        name="offerTime"
                        control={control}
                        defaultValue=""
                        rules={{ required: true }}
                        render={({ field, fieldState: { error } }) => (
                          <Autocomplete
                            disableClearable
                            disablePortal
                         
                            options={Duration}
                            getOptionLabel={(option) => option.Durations}
                            className="meetingListDropdown"
                            onChange={(event, value) => {
                              handleDuration(value ? value.Durations : "");
                            }}
                            renderInput={(params) => <TextField {...params} label="Select Duration" />}
                          />
                        )} />
                    </FormControl> */}
                    <FormControl sx={{ width: '100%' }}>
                      <Controller
                        name="offerTime"
                        control={control}
                        defaultValue=""
                        rules={{ required: true }}
                        render={({ field, fieldState: { error } }) => (
                          <Autocomplete
                            disableClearable
                            disablePortal
                            options={Duration}
                            getOptionLabel={(option) => option.Durations}
                            value={selectedDuration || null}
                            onChange={(event, newValue) => {
                              handleDuration(newValue);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Duration *"
                                placeholder="Select Duration"
                                error={!!error}
                                helperText={error ? 'This field is required' : ''}
                              />
                            )}
                            fullWidth
                            margin="normal"
                          />
                        )}
                      />
                      {errors.offerTime && (
                        <FormHelperText className="text-danger">
                          {errors.offerTime.message || 'This field is required'}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} >
              <div className='special_title_header d-flex align-items-center mb-3'>
                <p className='font-color-black'>Offer Details</p>
                <div className='ml-auto'>
                  <Button className='add_icon_btn' onClick={addManagementBtn}>+ Add</Button>
                </div>
              </div>
              <div className='event_add_details_div'>
                {offersFields.map((field, index) => (
                  <Grid key={field.id} container spacing={3} alignItems={'center'} className='mb-3'>
                    <Grid item lg={3} md={3} sm={6} xs={12}>
                      <div className='input_background'>
                        <Controller
                          name={`offers.${index}.offer`}
                          control={control}
                          defaultValue=""
                          rules={{ required: "Offer is required" }}
                          render={({ field }) => (
                            <TextField
                              variant="outlined"
                              label="Offer *"
                              placeholder="Enter Offer "
                              fullWidth
                              type="text"
                              {...field}
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.offers?.[index]?.offer && errors.offers?.[index]?.offer.message}
                        </FormHelperText>
                      </div>
                    </Grid>
                    <Grid item lg={8} md={8} sm={6} xs={12}>
                      <div className='input_background'>
                        <Controller
                          name={`offers.${index}.descripition`}
                          control={control}
                          defaultValue=""
                          rules={{ required: "Descripition is required" }}
                          render={({ field }) => (
                            <TextField
                              variant="outlined"
                              label="Descripition"
                              placeholder="Enter Descripition"
                              fullWidth
                              type="text"
                              {...field}
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.offers?.[index]?.descripition && errors.offers?.[index]?.descripition.message}
                        </FormHelperText>
                      </div>
                    </Grid>
                    <Grid item lg={1} md={1} sm={6} xs={12}>
                      <div className='text-center'>
                        <DeleteIcon onClick={() => handleOffersDelete(index)} sx={{ color: 'red' }} />
                      </div>
                    </Grid>
                  </Grid>
                ))
                }
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="input_background mb-3">
                <ToggleButton
                  value="color"
                  aria-label="color"
                  className="charges"
                  onClick={handleToggleAdditionalCharges}
                >
                  Additional Charges
                  <ArrowDropDownIcon className="down-drop" />
                </ToggleButton>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}></Grid>
            {
              showAdditionalCharges && (
                <>
                  <Grid item xs={12} sm={6} md={4}>
                    <div className="input_background mb-3">
                      <Controller
                        name="addChargeDesc"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Description is required" }}
                        render={({ field }) => (
                          <TextField
                            variant="outlined"
                            label="Description"
                            placeholder="Deployment Charges"
                            fullWidth
                            type="text"
                            {...field}
                          />
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.addChargeDesc &&
                          errors.addChargeDesc.message}
                      </FormHelperText>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <div className="input_background mb-3">
                      <Controller
                        name="addChargeMarketPrice"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Market Price is required" }}
                        render={({ field }) => (
                          <TextField
                            variant="outlined"
                            label="Market Price"
                            placeholder="Please Enter Market Price"
                            fullWidth
                            type="text"
                            {...field}
                            onKeyDown={(e) => validateNumberonly(e)}
                          />
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.addChargeMarketPrice && errors.addChargeMarketPrice.message}
                      </FormHelperText>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <div className="input_background mb-3">
                      <Controller
                        name="addChargeSmartCardPrice"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: "Smart Card Price / Discount is required",
                        }}
                        render={({ field }) => (
                          <TextField
                            variant="outlined"
                            label="Smart Card Price / Discount "
                            placeholder="Please Enter Price / Discount Percentage"
                            fullWidth
                            type="text"
                            {...field}
                            onKeyDown={(e) => validateNumberonly(e)}
                          />
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.addChargeSmartCardPrice && errors.addChargeSmartCardPrice.message}
                      </FormHelperText>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <div className="input_background mb-3">
                      <Controller
                        name="termsAndCondition"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: "Additional Terms & Conditions is required",
                        }}
                        render={({ field }) => (
                          <TextField
                            variant="outlined"
                            label="Additional Terms & Conditions "
                            placeholder="Please Enter ATC"
                            fullWidth
                            type="text"
                            {...field}
                            multiline
                            rows={3}
                          />
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.termsAndCondition && errors.termsAndCondition.message}
                      </FormHelperText>
                    </div>
                  </Grid>
                </>
              )}
          </Grid>
          <div className="modal-smcrd-service text-center">
            <button
              className="product-button"
              sx={{ textTransform: "none" }}
            >
              Submit
            </button>
          </div>
        </form>
      </DialogContent>
    </div>
  );
};

export default SmartproductAdd;
