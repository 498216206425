import React from 'react'
import TnFestFooter from './TnFestFooter'
import FastNavbar from './FastNavbar'

const Tnfestrefundandcancellation = () => {
    return (
        <>

            <section className="termscond-page">
                <FastNavbar/>
                <div className="back-color">
                    <div className="back-layer"></div>
                    <div className="back-layer1"></div>

                    <h1 className='terms-heading mb-3 '>Refunds and Cancellations</h1>

                    <div className='term-box'>
                        <div className='container'>
                            <h4 className='blck-clr pt-5'>For Visitors:</h4>
                            <p className='privacypadding'>Refunds are not available for visitor passes. However, you may change the attendee details by contacting our support team. This transfer of right allows a different individual to attend the event in your place, subject to our approval.</p>

                            <h4 className='blck-clr pt-5'>For Exhibitors:</h4>
                            <p className='privacypadding'>Exhibitors may apply for a refund within the specified time frame. The refund request must be submitted [specified time frame] before the event.</p>

                            <h4 className='blck-clr pt-5'>Conditions for Refunds:</h4>
                            <p className='privacypadding'>Refunds are issued under specific conditions, such as event cancellation, significant changes in event details, or non-delivery of promised services. Refunds are not provided for personal reasons, such as change of mind or scheduling conflicts.</p>

                            <h4 className='blck-clr pt-5'>Non-Refundable Fees:</h4>
                            <p className='privacypadding'>Any non-refundable fees, such as processing or administrative fees, will be clearly stated at the time of booking.</p>

                            <h4 className='blck-clr pt-5'>Refund Process:</h4>
                            <p className='privacypadding'>To request a refund, contact us within [specified time frame] of the event date with your booking details. Refunds will be processed within [specified time frame] after verifying the eligibility criteria. Refunds will be credited to the original payment method used during booking.</p>
                            <p className='privacypadding'>To request a refund, contact us within [specified time frame] of the event date with your booking details. Refunds will be processed within [specified time frame] after verifying the eligibility criteria. Refunds will be credited to the original payment method used during booking.</p>

                            <h4 className='blck-clr pt-5'>Cancellation Policy:</h4>
                            <p className='privacypadding'>Bookings can be canceled within [specified time frame] of making the reservation. Cancellation requests made after this period may not be accepted, and no refunds will be issued. Please contact our support team for assistance with cancellations.</p>

                            <h4 className='blck-clr pt-5'>Partial Cancellations:</h4>
                            <p className='privacypadding'>In cases where partial cancellations are permitted, the refund amount will be prorated based on the unused portion of the booking.</p>

                            <h4 className='blck-clr pt-5'>Non-Transferable:</h4>
                            <p className='privacypadding mb-3'>Bookings and tickets are non-transferable. Changes to attendee details can be made by contacting our support team, subject to our approval.
                                <br />
                                If you cannot attend an event, you may request a substitution for another attendee. Substitutions are subject to our approval and any applicable fees.</p>
                        </div>
                    </div>
                </div>
            </section>
            <TnFestFooter />
        </>
    )
}

export default Tnfestrefundandcancellation