import React from 'react'

const Norecordsfound = ({ name }) => {
    return (
        <div className='text-center w-100 m-5 nodatacss'>
            <h5 className='mb-4'>{name}</h5>
            <div>
                <img src="/images/blue horizontal.png" alt="blue horizontal.png" className='logo_dash img-fluid' />
            </div>
            <div className='mt-5'>
                <h3 className='title_p opa'>
                    StartupTN 3.0
                </h3>
            </div>
        </div>
    )
}

export default Norecordsfound