import { Autocomplete, FormHelperText, Grid, TextField, Button, debounce } from "@mui/material";
import React, { useContext, useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Controller, useForm } from "react-hook-form";
import DataContext from "../../../../context/DataContext";
import apiService from "../../../../api/apiService";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import { MuiFileInput } from "mui-file-input";
import notifyService from "../../../../api/notifySerivce";
import Viewimage from "../../../../api/Viewimage";
import cryptoJS from "../../../../hooks/cryptoJS";
import cryptodecryptJS from "../../../../hooks/cryptodecryptJS";
import InputAdornment from '@mui/material/InputAdornment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import { useCallback } from "react";
const Basicinfo = () => {
  const role = localStorage.getItem("role");
  const { isWhitespace, navigator, validateEmail, yearListApi, yearListValue, setPageLoading, checkUppernumeric, validateNumberonly, validatePhoneNumber, handleNextClick, VisuallyHiddenInput, validateAadharNumber, validateCinOrPanNo, validatePANNumber, checkAlphanumeric, checkAlphanumericDpiit } = useContext(DataContext);
  const [formData, setFormData] = useState({});
  const { handleSubmit, control, setValue, clearErrors, getValues, setError, formState: { errors } } = useForm({ values: formData });
  var dpiival = sessionStorage.getItem('dpiitval')
  useDidMountEffect(() => {
    handleNextClick(50);
    yearListApi();
    dropdownApi();
    entitydropdownApi();
    businessModelsdropdownApi()
  }, []);
  const [dippTickIcon, setDippTickIcon] = useState(0);
  useDidMountEffect(() => {
   if(dpiival === "true"){
    setDippTickIcon(1)
    
   }
  }, []);


  const [sectors, setSectors] = useState([]);
  const dropdownApi = () => {
    apiService(`home/sector/list`, "", "unauthget")
      .then((result) => {
        if (result) {
          if (result.data) {
            if (result.data.sectors) {
              setSectors(result.data.sectors);
            }
          }
        }
      })
      .catch((err) => { });
  };
  const [natureEntities, setNatureEntities] = useState([]);

  const entitydropdownApi = () => {
    apiService(`admin/dropdownvalues/get?key=${"entities"}`, "", "unauthget")
      .then((result) => {
        if (result) {
          if (result.data) {
            if (result.data.dropDownValues) {
              var dropDown = result.data.dropDownValues;
              setNatureEntities(dropDown.values);
            }
          }
        }
      })
      .catch((err) => { });
  };
  const [businessModelList, setBusinessModelList] = useState([])
  const businessModelsdropdownApi = () => {
    apiService(`admin/dropdownvalues/get?key=${"businessmodel"}`, "", "unauthget")
      .then((result) => {
        if (result) {
          if (result.data) {
            if (result.data.dropDownValues) {
              var dropDown = result.data.dropDownValues;
              setBusinessModelList(dropDown.values);
            }
          }
        }
      })
      .catch((err) => { });
  };
  var id = localStorage.getItem("id");
  var type = localStorage.getItem('type')
  useDidMountEffect(() => {
    // console.log(cryptodecryptJS('DpaUGHzGnNURUzQrwZX30g=='))
    // console.log(cryptodecryptJS('OOPr3Mbpmk1FdF7dsU18nQ=='))
    if (id !== null) {
      getApi();
      setStartupNameShow(false)
      setStartupTickIcon(0)
      setCinTickIcon(0)
      setPanTickIcon(0)
      setDippTickIcon(0)
    }
  }, [])
  
  // var dpiitval = sessionStorage.getItem('dpiitval')
  const getApi = () => {
    setPageLoading(true)
    apiService(`userprofile/get?persona=${type}&userid=${id}`, "", "get")
      .then((result) => {
        setPageLoading(false)
        if (result && result.data) {
          var data = result.data;
          if (data.yearOfReg === 0) {
            data.yearOfReg = ''
          }
          if (data.imageName) {
            data.imageUrl = data.imageName
          }
          if (data.dpiitCertificateName) {
            data.dpiitCertificateUrl = data.dpiitCertificateName
            
            if (dpiival === null) {
              
              setDippTickIcon(2)
            } else {
              setDippTickIcon(0)
            }
          }

          if (data.email) {
            data.email = cryptodecryptJS(data.email);
          }
          if (data.phone) {
            data.phone = cryptodecryptJS(data.phone);
          }
          // if (data.name) {
          //   setStartupTickIcon(1)
          // }
          if (data.pan) {
            data.pan = cryptodecryptJS(data.pan)
            setPanTickIcon(1)
          }
          if (data.cin) {
            data.cin = cryptodecryptJS(data.cin)
            // setCinTickIcon(1)
          }
          if (data.dpiitNo) {
            data.dpiitNo = cryptodecryptJS(data.dpiitNo)
            handleDippNumber(data.dpiitNo)
            // setDippNumber(data.dpiitNo)
            // if (dpiival === 'true') {
            //   setDippTickIcon(1)
            // } else {
            //   setDippTickIcon(2)
            // }
            // console.log(data.dpiitNo);
          }
          if (startupnames) {
            if (data.name) {
              setStartupTickIcon(1)
            }
          }

          if (data.yearOfReg === 0) {
            data.yearOfReg = ''
          }
          console.log(data)
          setFormData(data)
        }

      })
      .catch((err) => { });

  };
  const handleSelectSector = (value) => {
    setValue("sector", value);
    clearErrors("sector")
  };
  const [startupName, setStartupName] = useState('')
  const [startupNameShow, setStartupNameShow] = useState(false)
  const [startupTickIcon, setStartupTickIcon] = useState(0);
  // let startupNamevalue
  const handleStartupName = (e) => {
    var startupNames = e.target.value
    
    // startupNamevalue = startupNames
    // console.log(startupNamevalue);
    setStartupName(startupNames)
    if (startupNames === startupNameView) {
      setStartupNameShow(false);
      setStartupTickIcon(1);
    } else if (startupNameView === undefined) {
      setStartupNameShow(false)
    } else if (getValues('pan') !== '' || getValues('cin') !== '') {
      setStartupTickIcon(2)
      setStartupNameShow(true)
    }
  }

  const [checkStartupName, setCheckStartupName] = useState(false)
  const handleNatureOfEntity = (value) => {
    if (value !== 'Sole Proprietorship') {
      setCheckStartupName(true)
    } else {
      setCheckStartupName(false)

    }
    setValue("natureOfEntity", value);
    clearErrors("natureOfEntity")
    setValue("cin", '');
    clearErrors("cin", '')
    setValue("pan", '');
    clearErrors("pan", '')
    setValue("yearOfReg", '');
    clearErrors("yearOfReg", '')
    setStartupNameView('')
    setStartupNameShow(false)
    setStartupTickIcon(0)
    setStartupName('')
    setCinTickIcon(0)
    setPanTickIcon(0)
  };

  const [panTickIcon, setPanTickIcon] = useState(0);
  const [startupNameView, setStartupNameView] = useState();
  const handlePanChange = (e) => {
    var panNumber = e.target.value
    if (panNumber.length === 10) {
      var req = {
        pan: cryptoJS(panNumber)
      }
      apiService('pan/validate', req, 'post').then((result) => {
        console.log(result.data.yearOfReg);
        if (result && result.data && result.data.response.responseStatus === 'Success') {
          setPanTickIcon(1)
          var names = getValues('name')
          if (result.data.yearOfReg !== 0) {
            setValue('yearOfReg', result.data.yearOfReg)
            clearErrors('yearOfReg', '')
          }
          if (result.data.name !== undefined) {
            setStartupNameView(result.data.name)
            localStorage.setItem('startupName', result.data.name)
            // localStorage.setItem('panNumber',result.data.pan)
            if (names === result.data.name) {
              setStartupNameShow(false)
              setPanTickIcon(1)
              setStartupTickIcon(1)
            }
            else {
              setStartupNameShow(true)
            }
          } else {
            localStorage.removeItem('startupName')
            setStartupNameView('')
            setStartupNameShow(null)
            setPanTickIcon(0)
            setStartupTickIcon(0)
          }
        } else {
          setPanTickIcon(2)
        }
      }).catch(() => {
        setPanTickIcon(0)
        setStartupNameShow(false)
      });
    } else {
      localStorage.removeItem('startupName')
      setStartupNameView('')
      setStartupNameShow(null)
      setPanTickIcon(0)
      setStartupTickIcon(0)
    }
    // if (len.length === 8) {
    //   
    //   setAdornmentState(0); // No adornment
    // } else if (len.length === 9) { // Assuming validatePANNumber is your validation function
    //   setAdornmentState(1); // Show green checkmark
    // } else if (len.length === 10) {
    //   setAdornmentState(2); // Show red cross
    // }
    // if (value.length === 0) {
    //   setAdornmentState(0); // No adornment
    // } else if (validatePANNumber(value)) { // Assuming validatePANNumber is your validation function
    //   setAdornmentState(1); // Show green checkmark
    // } else {
    //   setAdornmentState(2); // Show red cross
    // }
  };

  const [cinTickIcon, setCinTickIcon] = useState(0);
  const handleCinChange = (e) => {
    var cinNumber = e.target.value
    if (cinNumber.length === 21) {
      var req = {
        cin: cryptoJS(cinNumber)
      }
      apiService('cin/validate', req, 'post').then((result) => {
        if (result.data.yearOfReg !== 0) {
          
          setValue('yearOfReg', result.data.yearOfReg)
          clearErrors('yearOfReg')
        }
        if (result && result.data && result.data.response.responseStatus === 'Success') {
          setCinTickIcon(1)
          // var names = getValues('name')
          // setStartupNameView(result.data.name)
          // localStorage.setItem('startupName',result.data.name)
          // if (names === result.data.name) {
          //   setStartupNameShow(false)
          //   setCinTickIcon(1)
          //   setStartupTickIcon(1)
          // }
          // else {
          //   setStartupNameShow(true)
          // }
        } else {
          setCinTickIcon(2)
        }

      }).catch((

      ) => {
        setCinTickIcon(2)
      });
    } else {
      // setCinTickIcon(0)
      // setStartupTickIcon(2)
    }
  }

 
  // const [dippError, setDippError] = useState(false)
  const handleDippNumber = (value) => {
    var dippNumber = value.toUpperCase()
    
    // setValue('dpiitCertificateUrl', '')
    // setValue('dpiitCertificateName', '')
    var values = getValues()
    values.dpiitNo = dippNumber
    values.dpiitCertificateUrl = ''
    values.dpiitCertificateName = ''
    values.dpiitCertificate = ''
    setFormData(values)
    console.log(values);

    if (dippNumber.length > 4 && dippNumber.length < 12) {
      var req = {
        dpiitNo: cryptoJS(dippNumber)
      }
      apiService('dpiit/validate', req, 'post').then((result) => {
        // 
        if (result && result.data && result.data.response.responseStatus === 'Success') {
          setDippTickIcon(1)
          notifyService("success", "Success", "DPIIT is Valid");
          sessionStorage.setItem('dpiitval', true)
          
        } else {
          setDippTickIcon(2)
          // setDippError(true)
          sessionStorage.removeItem('dpiitval')
          // notifyService("warning", "Warning", "DPIIT is Invalid, still you can continue to complete the profile. We will get back to you once your account is verified.");
          // notifyService("warning", "Warning", "DPIIT is Invalid, still you can continue to complete the profile.");
        }

      }).catch((err) => {
        setDippTickIcon(2)
      });
    } else {
      setDippTickIcon(0)
    }
  }

  // const [dippTickIcon, setDippTickIcon] = useState(0);
  // const [dpiCheck, setDpiCheck] = useState(false)
  // const [dippVal, setDippVal] = useState(false);

  // // Debounced API call
  // const validateDippNumber = useCallback(
  //   debounce((number) => {
  //     if (number.length >= 1) {
  //       const req = {
  //         dpiitNo: cryptoJS(number)
  //       };
  //       apiService('dpiit/validate', req, 'post')
  //         .then((result) => {
  //           if (result && result.data && result.data.response.responseStatus === 'Success') {
  //             setDippTickIcon(1);
  //             setDippVal(true)
  //             sessionStorage.setItem('dpiitval', true)
  //             setDpiCheck(true)
  //             notifyService('success', 'Success', 'DPIIT is Valid');
  //           } else {
  //             setDippTickIcon(2);
  //             notifyService('warning', 'Warning', 'DPIIT is Invalid, still you can continue to complete the profile. We will get back to you once your account is verified.');
  //           }
  //         })
  //         .catch(() => {
  //           setDippTickIcon(2);
  //           notifyService('danger', 'Error', 'An error occurred while validating DPIIT');
  //         });
  //     } else {
  //       setDippTickIcon(0);
  //     }
  //   }, 1000),
  //   []
  // );

  // const handleInputChange = (event) => {
  //   setValue('dpiitNo', event.target.value)
  //   clearErrors('dpiitNo')
  //   sessionStorage.removeItem('dpiitval')

  //   const { value } = event.target;
  //   event.target.value = value.replace(/[^a-zA-Z0-9]/g, '');
  // };

  // const handleBlur = () => {
  //   var dippNumber = getValues('dpiitNo')
  //   validateDippNumber(dippNumber);
  // };



  let logoselecetdFile = "";
  const handleFileUpload = (event, filekey, fileName,format) => {
    if (event !== null) {
      if (event.target === undefined) {
        logoselecetdFile = event
      } else {
        logoselecetdFile = event.target.files[0]
      }
      const maxFileSize = 5 * 1024 * 1024; // 5MB in bytes
      if (logoselecetdFile) {
        if (logoselecetdFile.size < maxFileSize) {
          var reader = new FileReader();
          var imagetype = logoselecetdFile.type;
          var imagedatatype = imagetype.split("/");
          var img_crt_type = imagedatatype[1];
          if (
            ((format === 'file') && (img_crt_type === "jpeg" || img_crt_type === "jpg" || img_crt_type === "png" || img_crt_type === "pdf")) ||
            ((format === 'image') && (img_crt_type === "jpeg" || img_crt_type === "jpg" || img_crt_type === "png"))
        ) {
            setValue(filekey, "");
            setValue(fileName, "");
            // setValue(fileUrl, '')
            var fileValue = logoselecetdFile;
            reader.readAsDataURL(logoselecetdFile);
            reader.onload = () => {
              var logourl1 = reader.result;
              var spl = logourl1.split(",");
              var ImageValue = spl[1];
              var img_name = fileValue.name;
              // setUrl(logourl1)
              setValue(filekey, ImageValue);
              setValue(fileName, img_name);
              // setValue(fileUrl, logourl1)
              clearErrors(filekey);
              clearErrors(fileName);
              // clearErrors(fileUrl);
              // 
              // clearErrors('dpiitCertificateName')
            };
          } else {
            notifyService(
              "danger",
              "File Format Invalided",
              "Please check your file format"
            );
          }
        } else {
          notifyService(
            "danger",
            "File Size Exceeded",
            `Maximum file size allowed is 5 MB`
          );
        }
      }
    }
  };
  const [open, setOpen] = useState(false);
  const [viewImage, setViewImage] = useState("");
  const [viewImageName, setViewImageName] = useState("");
  const handleClickOpen = (value, name) => {
    setViewImage(value);
    setViewImageName(name);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const formatUdyamNumber = (value) => {
    value = value.replace(/[^a-zA-Z0-9]/g, '');
    if (value.length > 5 && value.length <= 7) {
      value = `${value.slice(0, 5)}-${value.slice(5)}`;
    } else if (value.length > 7 && value.length <= 9) {
      value = `${value.slice(0, 5)}-${value.slice(5, 7)}-${value.slice(7)}`;
    } else if (value.length > 9) {
      value = `${value.slice(0, 5)}-${value.slice(5, 7)}-${value.slice(7, 9)}-${value.slice(9, 16)}`;
    }
    return value;
  };
  var startupnames = localStorage.getItem('startupName')
  // var panNumbers =   localStorage.getItem('pannumber')


  const onSubmit = (data) => {
    
    // if (dippTickIcon === 2) {
    //   if (data.dpiitCertificateName === '') {
    //     setError('dpiitCertificateName', {
    //       message: "DPIIT Certificate is required"
    //     })
    //     // return
    //   }
    //   // notifyService('danger', 'Submit Failed', 'DPIIT Certificate is required')
    //   // return
    // }
    if (panTickIcon === 2) {
      setPanTickIcon(2)
      notifyService('danger', 'Submit Failed', 'Enter a Valid PAN')
      return
    }
    // if (dippVal !== true) {
    //   setError('dpiitCertificateName', {
    //     message: "DPIIT Certificate is required"
    //   })
    //   return
    // }

    // if (cinTickIcon === 2) {
    //   setCinTickIcon(2)
    //   notifyService('danger', 'Submit Failed', 'Enter a Valid Cin Number')
    //   return
    // }
    if (startupTickIcon === 2) {
      setStartupTickIcon(2)
      notifyService('danger', 'Submit Failed', 'Startup Name does not match')
      return
    }
    // if (role === "DPIIT" && dippTickIcon !== 1) {
    //   notifyService('danger', 'Submit Failed', 'Invalid DPIIT Number')
    //   return
    // }
    if (startupnames) {
      if (data.name !== startupnames) {
        setStartupNameShow(true)
        setStartupTickIcon(0)
        notifyService('danger', 'Submit Failed', 'Startup Name does not match')
        return
      }
    }

    const sector = sectors.filter(val => val.name === data.sector);
    data.sectorId = sector[0].id.toString()
    data.persona = type
    data.userid = id
    data.phone = cryptoJS(data.phone);
    data.email = cryptoJS(data.email.toLowerCase());
    if (data.pan) {
      data.pan = cryptoJS(data.pan.toUpperCase());
    }
    if (data.cin !== '') {
      data.cin = cryptoJS(data.cin);
    }
    if (data.dpiitNo !== '') {
      data.dpiitNo = cryptoJS(data.dpiitNo);
    }
    

    setPageLoading(true)
    apiService("userprofile/save", data, "post")
      .then((result) => {
        setPageLoading(false)
        if (result && result.data && result.data.responseStatus === "Success") {
          navigator("/startups/address");
        }
      })
      .catch((err) => { });
  };

  const onError = (event) => {
    
    console.log("error Data:", event);
    notifyService('danger', 'Submit Failed', 'Please check the mandatory fields')
  };




  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <div className="signup-data_div">
            <div className="forms-title d-flex">
              <p>
                Organization Info {`>`}{" "}
                <span className="path_sapn">Basic Info</span>
              </p>
              <p className="ml-auto">
                <span className="count_num">1</span>/5
              </p>
            </div>
            <div className="signup_box_div mt-3">
              <form onSubmit={handleSubmit(onSubmit, onError)}>
                <Grid container spacing={1}>
                  <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                    <Controller
                      name="name"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: 'Startup Name is required', validate: {
                          noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                        },
                      }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="Startup Name *"
                          placeholder="Enter Startup Name"
                          fullWidth
                          type="text"
                          onInput={(e) => {
                            startupNameView !== '' &&
                              handleStartupName(e);
                          }}
                          InputProps={{
                            endAdornment: startupTickIcon === 1 ? (
                              <InputAdornment position="end">
                                <CheckCircleIcon color="success" />
                              </InputAdornment>
                            ) : startupTickIcon === 2 ? (
                              <InputAdornment position="end">
                                <CloseIcon color="error" />
                              </InputAdornment>
                            ) : null,
                          }}
                          {...field}
                          error={Boolean(errors.name && errors.name)}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.name && errors.name.message}
                    </FormHelperText>
                    {startupNameShow === true && (
                      <span class='text-danger m-0 p-0'>Registered Startup Name : {startupnames} </span>
                    )}

                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                    <Controller
                      name="natureOfEntity"
                      control={control}
                      defaultValue=""
                      rules={{ required: true }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <Autocomplete
                          disableClearable
                          disablePortal
                          options={natureEntities}
                          getOptionLabel={(option) => option}
                          defaultValue={[]}
                          value={value || []}
                          onChange={(event, newValue) => handleNatureOfEntity(newValue)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Nature of Entity *"
                              placeholder="Seletct Nature of Entity"
                              error={!!error}
                              helperText={
                                error ? "Nature of Entity is required" : ""
                              }
                            />
                          )}
                          fullWidth
                          margin="normal"
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.natureOfEntity && errors.natureOfEntity.message}
                    </FormHelperText>
                  </Grid>
                  {
                    (getValues('natureOfEntity') === "Private Limited" || getValues('natureOfEntity') === "LLP") && (
                      <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                        <Controller
                          name="cin"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: "CIN No. is required",
                            // validate: validateCinOrPanNo
                          }}
                          render={({ field }) => (
                            <TextField
                              variant="outlined"
                              label="CIN No. *"
                              placeholder="Enter CIN  No."
                              fullWidth
                              type="text"
                              // inputProps={{ maxLength: 21 }}
                              // onKeyDown={(e) => checkUppernumeric(e)}
                              // onInput={(e) => {
                              //   handleCinChange(e);
                              // }}
                              {...field}
                              error={Boolean(errors.cin && errors.cin)}
                            // InputProps={{
                            //   endAdornment: cinTickIcon === 1 ? (
                            //     <InputAdornment position="end">
                            //       <CheckCircleIcon color="success" />
                            //     </InputAdornment>
                            //   ) : cinTickIcon === 2 ? (
                            //     <InputAdornment position="end">
                            //       <CloseIcon color="error" />
                            //     </InputAdornment>
                            //   ) : null,
                            // }}
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.cin && errors.cin.message}
                        </FormHelperText>
                      </Grid>)}
                  {
                    getValues('natureOfEntity') === "Sole Proprietorship" && (
                      <Grid item lg={12} md={12} sm={12} xs={12} className='mb-3'>
                        <Controller
                          name="udyamNumber"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: false,
                            // pattern: {
                            //   value: /^[a-zA-Z]{5}-[a-zA-Z]{2}-\d{2}-\d{7}$/,
                            //   message: "Udyam Number must be in the format UDYAM-XY-07-1234567"
                            // }

                            // validate: (value) =>
                            //   /^[a-zA-Z]{5}-[a-zA-Z]{2}-\d{2}-\d{7}$/.test(value)
                            //     ? true
                            //     : "Udyam Number must be in the format UDYAM-XY-07-1234567",
                          }}
                          render={({ field: { onChange, value, ...field } }) => (
                            <TextField
                              variant="outlined"
                              label="Udyam Number"
                              placeholder="Enter Udyam Number"
                              fullWidth
                              type="text"
                              inputProps={{ maxLength: 19 }}  // Max length of the Udyam Number format
                              value={formatUdyamNumber(value)}
                              onChange={(e) => {
                                const formattedValue = formatUdyamNumber(e.target.value);
                                onChange(formattedValue);
                              }}
                              error={Boolean(errors.udyamNumber)}
                              helperText={errors.udyamNumber?.message || ""}
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                    )}
                  {
                    getValues('natureOfEntity') === "Registered Partnership" && (
                      <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                        <Controller
                          name="formc"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: "Form C No. is required",
                            // validate: validatePANNumber
                            validate: {
                              noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                            },
                          }}
                          render={({ field }) => (
                            <TextField
                              variant="outlined"
                              label="Form C No. *"
                              placeholder="Enter Form C No."
                              fullWidth
                              type="text"
                              // inputProps={{ maxLength: 10 }}
                              // onKeyDown={(e) => checkAlphanumeric(e)}
                              {...field}
                            // error={Boolean(errors.formC && formC.pan)}
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.formc && errors.formc.message}
                        </FormHelperText>
                      </Grid>
                    )}
                  {
                    getValues('natureOfEntity') !== "Registered Partnership" && (
                      <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                        <Controller
                          name="pan"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: "PAN is required",
                            // validate: validatePANNumber
                          }}
                          render={({ field }) => (
                            <TextField
                              variant="outlined"
                              label="PAN *"
                              placeholder="Enter PAN"
                              fullWidth
                              type="text"
                              // inputProps={{ maxLength: 10 }}
                              // onKeyDown={(e) => checkUppernumeric(e)}
                              // onInput={(e) => {
                              //   handlePanChange(e);
                              // }}
                              {...field}
                              error={Boolean(errors.pan && errors.pan)}
                            // InputProps={{
                            //   endAdornment: panTickIcon === 1 ? (
                            //     <InputAdornment position="end">
                            //       <CheckCircleIcon color="success" />
                            //     </InputAdornment>
                            //   ) : panTickIcon === 2 ? (
                            //     <InputAdornment position="end">
                            //       <CloseIcon color="error" />
                            //     </InputAdornment>
                            //   ) : null,
                            // }}
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.pan && errors.pan.message}
                        </FormHelperText>
                      </Grid>
                    )}
                  {
                    role === "DPIIT" && (

                      <>
                        {/* <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                          <Controller
                            name="dpiitNo"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: 'DPIIT No is required',
                              validate: {
                                noWhitespace: value => !/\s/.test(value) || 'Whitespace not allowed',
                                checkAlphanumeric: value => /^[a-zA-Z0-9]+$/.test(value)
                              }
                            }}
                            render={({ field }) => (
                              <TextField
                                variant="outlined"
                                label="DPIIT Registration No. *"
                                placeholder="Enter DPIIT Registration No."
                                fullWidth
                                type="text"
                                {...field}
                                // onChange={handleInputChange}
                                // onBlur={handleBlur}
                                onChange={(event) => {
                                  handleInputChange(event);
                                  field.onChange(event);
                                }}
                                onBlur={handleBlur}
                                // value={dippNumber}
                                // error={dippTickIcon === 2}
                                InputProps={{
                                  endAdornment: dippTickIcon === 1 ? (
                                    <InputAdornment position="end">
                                      <CheckCircleIcon color="success" />
                                    </InputAdornment>
                                  ) : dippTickIcon === 2 ? (
                                    <InputAdornment position="end">
                                      <CloseIcon color="error" />
                                    </InputAdornment>
                                  ) : null,
                                }}
                              />
                            )}
                          />
                          <FormHelperText className="text-danger">
                            {errors.dpiitNo && errors.dpiitNo.message}
                          </FormHelperText>
                          <FormHelperText className="text-danger">
                            {dippTickIcon === 2 && 'DPIIT Registration No is Invalid'}
                          </FormHelperText>
                        </Grid> */}
                        <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                          <Controller
                            name="dpiitNo"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: 'DPIIT Registration No is required',
                              // validate: {
                              //   format: value =>
                              //     /^DIPP\d{4,6}$/i.test(value) ||
                              //     'DPIIT Registration No must be in the format "DIPPdddd" where d is a digit (4 to 6 digits)',
                              //   noWhitespace: value => !/\s/.test(value) || 'Whitespace not allowed',
                              // }
                            }}
                            render={({ field }) => (
                              <TextField
                                variant="outlined"
                                label="DPIIT Registration No. *"
                                placeholder="Enter DPIIT Registration No."
                                fullWidth
                                type="text"
                                onInput={(e) => {
                                  handleDippNumber(e.target.value);
                                }}
                                onKeyDown={(e) => checkAlphanumeric(e)}
                                inputProps={{ maxLength: 11 }}
                                {...field}
                                error={Boolean(errors.dpiitNo)}
                                InputProps={{
                                  endAdornment: dippTickIcon === 1 ? (
                                    <InputAdornment position="end">
                                      <CheckCircleIcon color="success" />
                                    </InputAdornment>
                                  ) : dippTickIcon === 2 ? (
                                    <InputAdornment position="end">
                                      <CloseIcon color="error" />
                                    </InputAdornment>
                                  ) : null,
                                }}
                              />
                            )}
                          />
                          <FormHelperText className="text-danger">
                            {errors.dpiitNo && errors.dpiitNo.message}
                          </FormHelperText>
                          <FormHelperText className="text-danger">
                            {
                              dippTickIcon === 2 ? 'DPIIT is Invalid, still you can continue to complete the profile. We will get back to you once your account is verified' : ''
                            }
                          </FormHelperText>
                        </Grid>
                        {(dippTickIcon === 2) && (
                          <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                            {getValues('dpiitCertificateUrl') !== '' ? (
                              <>
                                <label>
                                  Upload DPIIT Certificate<span className="text-danger ml-1">*</span>
                                </label>
                                <div className="row m-0 mr-5">
                                  <Grid item lg={7} md={7} sm={12} xs={12} className="mb-3">
                                    <Controller
                                      name="dpiitCertificateName"
                                      control={control}
                                      defaultValue=""
                                      rules={{ required: 'DPIIT Certificate is required' }}
                                      render={({ field }) => (
                                        <Button
                                          component="label"
                                          variant="contained"
                                          {...field}
                                          fullWidth
                                          onChange={(e) => handleFileUpload(e, 'dpiitCertificate', 'dpiitCertificateName','file')}
                                          startIcon={<CloudUploadIcon />}
                                          href="#file-upload"
                                        >
                                          Change
                                          <VisuallyHiddenInput type="file" />
                                        </Button>
                                      )}
                                    />
                                  </Grid>
                                  <Grid item lg={5} md={5} sm={12} xs={12} className="mb-3 logo-btn-margin">
                                    <Button
                                      className="view_doc_btn_document c-pointer"
                                      size="medium"
                                      variant="outlined"
                                      onClick={() =>
                                        handleClickOpen(getValues('dpiitCertificate'), getValues('dpiitCertificateUrl'))
                                      }
                                    >
                                      Preview
                                    </Button>
                                  </Grid>
                                  <div>
                                    <span className="fn-12 mt-1">
                                      Maximum 5 mb allowed doc (Accepted Format: jpeg, jpg, png, pdf)
                                    </span>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <div className="form-group mt-2">
                                <Controller
                                  name="dpiitCertificateName"
                                  control={control}
                                  defaultValue=""
                                  rules={{ required: 'DPIIT Certificate is required' }}
                                  render={({ field }) => (
                                    <TextField
                                      variant="outlined"
                                      label="Upload DPIIT Certificate *"
                                      placeholder="Upload Image "
                                      fullWidth
                                      type="text"
                                      {...field}
                                      disabled
                                    />
                                  )}
                                />
                                <FormHelperText className="text-danger">
                                  {errors.dpiitCertificateName && errors.dpiitCertificateName.message}
                                </FormHelperText>
                                {/* <FormHelperText className="text-danger">
                                  {dippVal !== true && 'DPIIT Certificate is required'}
                                </FormHelperText> */}
                                <div className='d-flex align-items-center w-100 my-3'>
                                  <p className='font-sm'>
                                    Recommended aspect ratio 4:3 <br />
                                    Max size upto 5mb <br />
                                    Accepted Format: jpeg, jpg, png, pdf
                                  </p>
                                  <label className='ml-auto lbl_btn1'
                                    onChange={(e) => handleFileUpload(e, "dpiitCertificate", "dpiitCertificateName",'file')}
                                    href="#file-upload">
                                    <img src='/images/admin/upload.png' className='mr-3' alt='' />
                                    Upload
                                    <VisuallyHiddenInput type="file" />
                                  </label>
                                </div>
                              </div>
                            )}
                          </Grid>
                        )}
                      </>
                    )}


                  <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                    <Controller
                      name="email"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "Company Email Address is required",
                        validate: validateEmail,
                      }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="Company Email Address *"
                          placeholder="Enter Company Email Address"
                          fullWidth
                          type="text"
                          {...field}
                          error={Boolean(errors.email && errors.email)}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.email && errors.email.message}
                    </FormHelperText>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                    <Controller
                      name="phone"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "Phone Number is required",
                        validate: validatePhoneNumber,
                      }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="Phone Number *"
                          placeholder="Enter Phone Number"
                          fullWidth
                          type="text"
                          {...field}
                          onKeyDown={(e) => validateNumberonly(e)}
                          inputProps={{ maxLength: 10 }}
                          error={Boolean(errors.phone && errors.phone)}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.phone && errors.phone.message}
                    </FormHelperText>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                    <Controller
                      name="sector"
                      control={control}
                      defaultValue=""
                      rules={{ required: true }}
                      render={({ field, fieldState: { error } }) => (
                        <Autocomplete
                          disableClearable
                          disablePortal
                          {...field}
                          options={sectors.map((option) => option.name)}
                          onChange={(event, newValue) => handleSelectSector(newValue)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Sector *"
                              placeholder="Select Sector"
                              error={!!error}
                              helperText={error ? 'Sector is required' : ''}
                            />
                          )}
                          fullWidth
                          margin="normal"
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.sector && errors.sector.errorMessage}
                    </FormHelperText>

                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                    <Controller
                      name="businessModel"
                      control={control}
                      defaultValue=""
                      rules={{ required: true }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <Autocomplete
                          disableClearable
                          disablePortal
                          options={businessModelList}
                          getOptionLabel={(option) => option}
                          defaultValue={[]}
                          value={value || []}
                          onChange={(event, newValue) => onChange(newValue)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Business Model *"
                              placeholder="Seletct Business Model"
                              error={!!error}
                              helperText={
                                error ? "Business Model is required" : ""
                              }
                            />
                          )}
                          fullWidth
                          margin="normal"
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.businessModel && errors.businessModel.message}
                    </FormHelperText>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                    <Controller
                      name="yearOfReg"
                      control={control}
                      defaultValue=""
                      rules={{ required: true }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <Autocomplete
                          disableClearable
                          disablePortal
                          options={yearListValue}
                          getOptionLabel={(option) => option}
                          defaultValue={[]}
                          value={value || []}
                          onChange={(event, newValue) => onChange(newValue)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Year Of registration *"
                              placeholder="Seletct Year Of registration"
                              error={!!error}
                              helperText={
                                error ? "Year Of registration is required" : ""
                              }
                            />
                          )}
                          fullWidth
                          margin="normal"
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.yearOfReg && errors.yearOfReg.message}
                    </FormHelperText>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                    {
                      getValues('imageUrl') ? (
                        <>
                          <label >Upload Company Logo<span className="text-danger ml-1">*</span></label>
                          <div className="row">
                            <Grid item lg={7} md={7} sm={12} xs={12} className="mb-3">

                              <Controller
                                name="imageName"
                                control={control}
                                defaultValue=""
                                rules={{ required: "Company Logo is required" }}
                                render={({ field }) => (
                                  <Button
                                    component="label"
                                    variant="contained"
                                    {...field}
                                    fullWidth
                                    onChange={(e) =>
                                      handleFileUpload(e, "image", "imageName",'image')
                                    }
                                    startIcon={<CloudUploadIcon />}
                                    href="#file-upload"
                                  >
                                    Change
                                    <VisuallyHiddenInput type="file" />
                                  </Button>
                                )}
                              />

                            </Grid>
                            <Grid item lg={5} md={5} sm={12} xs={12} className="mb-3 logo-btn-margin">
                              <Button
                                className="view_doc_btn_document c-pointer"

                                size="medium"
                                variant="outlined"
                                onClick={() =>
                                  handleClickOpen(
                                    getValues("image"),
                                    getValues("imageUrl")
                                  )
                                }
                              >
                                Preview
                              </Button>
                            </Grid>
                            <div>
                              <span className="fn-12   mt-1 ">
                                Maximum 5 mb allowed doc (Accepted Format: jpeg, jpg, png)

                              </span>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="form-group mt-2">
                          <Controller
                            name="imageName"
                            control={control}
                            defaultValue=""
                            rules={{ required: false }}
                            render={({ field }) => (
                              <TextField
                                variant="outlined"
                                label="Upload Company Logo "
                                placeholder="Upload Image "
                                fullWidth
                                type="text"
                                {...field}
                                disabled
                              />
                            )}
                          />
                          <FormHelperText className="text-danger">
                            {errors.imageName && errors.imageName.message}
                          </FormHelperText>
                          <div className='d-flex align-items-center w-100 my-3'>
                            <p className='font-sm'>
                              Recommended aspect ratio 4:3 <br />
                              Max size upto 5mb <br />
                              Accepted Format: jpeg, jpg, png</p>
                            <label className='ml-auto lbl_btn1'
                              onChange={(e) => handleFileUpload(e, "image", "imageName",'image')}
                              href="#file-upload">
                              <img src='/images/admin/upload.png' className='mr-3' alt='' />
                              Upload
                              <VisuallyHiddenInput type="file" />
                            </label>
                          </div>
                        </div>
                      )
                    }
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} className="text-center"                  >
                    <button className="next-btn" type="submit">
                      Next
                    </button>
                  </Grid>
                </Grid>

              </form>
              <Viewimage
                viewImage={viewImage}
                viewImageName={viewImageName}
                open={open}
                handleClose={handleClose}
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
export default Basicinfo;
