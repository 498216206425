import React, { useContext, useState, useEffect } from 'react';
import AdminSupportFilters from './AdminSupportFilters';
import { Autocomplete, Box, FormHelperText, Grid, Table, TableBody, TableCell, TableContainer, Paper, TableHead, TableRow, Typography, Stack, Pagination } from '@mui/material';
import { BarChart, Bar, XAxis, Tooltip, ResponsiveContainer, Cell } from 'recharts';
import { Controller, useForm } from 'react-hook-form';
import ReactApexChart from 'react-apexcharts';
import DataContext from '../../../context/DataContext';
import apiService from '../../../api/apiService';

const data = [
    { name: 'Startuptn', uv: 20 },
    { name: 'ITINT', uv: 22 },
    { name: 'TANCAM', uv: 35 },
    { name: 'TANCOE', uv: 30 },
    { name: 'Fort', uv: 25 },
    { name: 'SIDCO', uv: 28 },
    { name: 'TNGCC', uv: 24 },
    { name: 'EDII', uv: 35 },
    { name: 'TANSOE', uv: 27 },
    { name: 'TNFMTC', uv: 26 },
    { name: 'InnovateTN', uv: 23 },
    { name: 'TICEL', uv: 29 },
];

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip" style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: '10px' }}>
                <p className="label">{`${label} : ${payload[0].value}`}</p>
            </div>
        );
    }
    return null;
};

const choose = ['Option 1', 'Option 2'];

const AdminSupportDashboard = () => {
    const {
        supportType, setSupportType, readStatus, setReadStatus, setPageLoading,
        searchSupportInputValue, selectedSupportPeriod, startDate, setStartDate, endDate, setEndDate,
        sortBy, setSortBy, setCategoryId, categoryId, getSupportStatus
    } = useContext(DataContext);

    const [formData, setFormData] = useState('');
    const { control, handleSubmit, setValue, reset, setError, clearErrors, getValues, formState: { errors } } = useForm({ values: formData });

    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [supportList, setSupportList] = useState([]);
    const [dashTicket, setDashTicket] = useState({});
    const [chartData, setChartData] = useState({
        series: [0, 0],
        options: {
            chart: {
                width: 380,
                type: 'donut',
            },
            plotOptions: {
                pie: {
                    startAngle: -90,
                    endAngle: 270,
                },
            },
            dataLabels: {
                enabled: false,
            },
            fill: {
                type: 'gradient',
            },
            legend: {
                show: false,
            },
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200,
                        },
                        legend: {
                            position: 'bottom',
                            show: false,
                        },
                    },
                },
            ],
            colors: ['#23e958', '#ff0000'],
            tooltip: {
                y: {
                    formatter: (value, { seriesIndex }) => {
                        const labels = ['Closed Tickets', 'Unresolved Tickets'];
                        return `${labels[seriesIndex] || 'Unknown'}: ${value}`;
                    },
                },
            },
        },
    });

    useEffect(() => {
        setSupportType('All Support');
        setReadStatus(0);
        setStartDate('');
        setEndDate('');
        setSortBy(true);
    }, [setSupportType, setReadStatus, setStartDate, setEndDate, setSortBy]);

    useEffect(() => {
        const fetchDashboardData = async () => {
            try {
                const result = await apiService('support/admin/dashboard', "", "get");
                if (result?.data) {
                    setDashTicket(result.data);
                    setChartData(prevState => ({
                        ...prevState,
                        series: [result.data.closedTickets, result.data.unresolvedTickets]
                    }));
                }
            } catch (error) {
                console.error('Error fetching dashboard data:', error);
            }
        };

        const fetchSupportList = async () => {
            setPageLoading(true);
            try {
                const req = {
                    listSize: 12,
                    pageNumber: page,
                    status: readStatus,
                    searchString: searchSupportInputValue,
                    period: selectedSupportPeriod,
                    type: 1,
                    categoryId,
                    startDate: startDate ? `${startDate}T00:00:00.174Z` : '',
                    endDate: endDate ? `${endDate}T00:00:00.174Z` : '',
                    sort: sortBy ? 'asc' : 'desc'
                };
                const result = await apiService('support/ticket/admin/list', req, 'post');
                if (result?.data) {
                    setTotalPage(result.data.totalPages);
                    setSupportList(result.data.responseDTOList || []);
                }
            } catch (error) {
                console.error('Error fetching support list:', error);
            } finally {
                setPageLoading(false);
            }
        };

        fetchDashboardData();
        fetchSupportList();
    }, [page, supportType, readStatus, categoryId, searchSupportInputValue, selectedSupportPeriod, startDate, endDate, sortBy, setPageLoading]);

    const handleChange = (event, value) => {
        setPage(value);
    };

    return (
        <>
            <AdminSupportFilters />
            <section>
                <Grid container direction="row" justifyContent="center" alignItems="flex-start">
                    <Grid item sm={12} md={6} lg={8}>
                        <Grid container direction="row" justifyContent="center" alignItems="flex-start" spacing={2}>
                            <Grid item sm={12} md={4} lg={4}>
                                <Box className="widget-box-new">
                                    <p>Total Tickets</p>
                                    <h3 className="bluee-clrr">{dashTicket.totalTickets || 0}</h3>
                                </Box>
                            </Grid>
                            <Grid item sm={12} md={4} lg={4}>
                                <Box className="widget-box-new">
                                    <p>Closed Tickets</p>
                                    <h3 className="bluee-clrr">{dashTicket.closedTickets || 0}</h3>
                                </Box>
                            </Grid>
                            <Grid item sm={12} md={4} lg={4}>
                                <Box className="widget-box-new">
                                    <p>Unresolved Tickets</p>
                                    <h3 className="bluee-clrr">{dashTicket.unresolvedTickets || 0}</h3>
                                </Box>
                            </Grid>
                        </Grid>

                        {/* {(adminrole !== "COADMIN") && (adminrole !== "ASSOCIATE") ? (
                            <div className='bar-graph'>
                                <div className='bar-head'>
                                    <span>
                                        <h5>Total Ticket Raised across departments</h5>
                                        <span className='bar-chart'>Monthly</span>
                                    </span>
                                    <div className='support-dashboard-close mb-5'>
                                        <Controller
                                            sx={{ width: '200' }}
                                            name="category"
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: "Required" }}
                                            render={({ field }) => (
                                                <Autocomplete
                                                    disableClearable
                                                    disablePortal
                                                    {...field}
                                                    options={choose}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="Choose " />
                                                    )}
                                                />
                                            )}
                                        />
                                        <FormHelperText className="text-danger">
                                            {errors.category && errors.category.message}
                                        </FormHelperText>
                                    </div>
                                </div>
                                <ResponsiveContainer width="100%" height={300}>
                                    <BarChart data={data}>
                                        <XAxis dataKey="name" axisLine={false} tickLine={false} tick={{ fontSize: 10 }} />
                                        <Tooltip content={<CustomTooltip />} />
                                        <Bar dataKey="uv" fill="#8884d8" radius={[10, 10, 0, 0]}>
                                            {data.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={entry.name === 'EDII' ? '#0088FE' : '#C4C4C4'} />
                                            ))}
                                        </Bar>
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                        ) : (<></>)} */}

                        <div>
                            <p className='my-4'>Support Ticket</p>
                            {supportList.length > 0 ? (
                                <TableContainer className='table-bg' component={Paper}>
                                    <Table sx={{ borderCollapse: 'separate', borderSpacing: '0 10px' }}>
                                        <TableHead>
                                            <TableRow className='bar'>
                                                <TableCell>Ticket No.</TableCell>
                                                <TableCell>Organization Name</TableCell>
                                                <TableCell>Category</TableCell>
                                                <TableCell>Due Date</TableCell>
                                                <TableCell align='center'>Status</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {supportList.map((list, i) => (
                                                <TableRow
                                                    key={i}
                                                    sx={{
                                                        '&:after': {
                                                            content: '""',
                                                            position: 'absolute',
                                                            top: 0,
                                                            bottom: 0,
                                                            left: 0,
                                                            right: 0,
                                                            border: '1px solid rgba(224, 224, 224, 1)',
                                                            borderRadius: '20px',
                                                            pointerEvents: 'none',
                                                            marginBottom: '-1px',
                                                        },
                                                        marginBottom: '10px',
                                                    }}
                                                    className="Sup-list"
                                                >
                                                    <TableCell className='list-1'>
                                                        <span className='list-1-clr'>{list.ticketNo}</span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="h6" className="list-2-clr">{list.serviceName}</Typography>
                                                        <div className="list-2-dflex">
                                                            <span className="list-2-grey">User Profile:</span>
                                                            <span className="list-2-black">{list.persona}</span>
                                                        </div>
                                                        <div>
                                                            <span className="list-2-grey">Smartcard ID:</span>
                                                            <span className="list-2-black">{list.smartCardNumber}</span>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <span className='list-4-clr'>{list.categoryName}</span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <span className={`list-5-clr set-${list.dueDateColor}`}>{list.dueDate}</span>
                                                    </TableCell>
                                                    <TableCell align='center'>
                                                        <span className='list-6-clr'>{getSupportStatus(list.status)}</span>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                    {totalPage > 1 && (
                                        <div className="event-pagination">
                                            <Stack spacing={2}>
                                                <Pagination
                                                    count={totalPage}
                                                    page={page}
                                                    onChange={handleChange}
                                                />
                                            </Stack>
                                        </div>
                                    )}
                                </TableContainer>
                            ) : (
                                <div className='text-center nodatacss_admin'>
                                    <h5 className='mb-4'>Startup support dashboard data not found</h5>
                                    <div>
                                        <img src="/images/tamilnadu_logo_high.svg" alt="tamilnadu_logo_high.svg" className='logo_dash_support img-fluid' />
                                    </div>
                                    <div className='mt-5'>
                                        <h3 className='title_p opa'></h3>
                                    </div>
                                </div>
                            )}
                        </div>
                    </Grid>
                    <Grid item sm={12} md={6} lg={4}>
                        <div className='donut-chart'>
                            <h5 className='mb-5'>Tickets</h5>
                            <div id="chart">
                                <ReactApexChart
                                    options={chartData.options}
                                    series={chartData.series}
                                    type="donut"
                                    width={300}
                                />
                            </div>
                        </div>
                        {/* <div className='unsolved-tic'>
                            <h5 className='my-3'>Unsolved Tickets</h5>
                            <Box className="widget-red-box">
                                <h3 className="red-clrr">84</h3>
                                <p className="red-clrr-1">Currently</p>
                            </Box>
                            <p>By Category</p>
                            <div className='dept-css'>
                                <p>Department 1</p>
                                <p>54</p>
                            </div>
                            <div className='dept-css'>
                                <p>Department 2</p>
                                <p>24</p>
                            </div>
                            <div className='dept-css'>
                                <p>Department 3</p>
                                <p>08</p>
                            </div>
                            <div className='dept-css'>
                                <p>Department 4</p>
                                <p>08</p>
                            </div>
                        </div> */}
                    </Grid>
                </Grid>
            </section>
        </>
    );
};

export default AdminSupportDashboard;
