import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Tab,
  Tabs,
} from "@mui/material";

import useDidMountEffect from "../../../hooks/useDidMountEffect";
import axios from "axios";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import DataContext from "../../../context/DataContext";
import apiService from "../../../api/apiService";

const SupportSmartCardService = ({ name }) => {
  const { navigator, setPageLoading, setUserProfileId, setUserProfileParsona, setShowViewbtn, setshowHighlights } = useContext(DataContext);
  const [categoryList, setCategoryList] = useState([]);
  const [categoryValue, setCategoryValue] = useState(1)

  useDidMountEffect(() => {
    getCategoryData()
  }, [])
  const getCategoryData = () => {
    apiService('smartcard/category/list', {}, 'unauthpost').then((result) => {
      if (result && result.data && result.data.categories) {
        setCategoryList(result.data.categories);
        setCategoryValue(result.data.categories[0].id)
      }
    })
  }
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(null);
  useDidMountEffect(() => {
    serviceListApi()

  }, [categoryValue,page])

  const [serviceData, setServiceData] = useState([])
  const serviceListApi = () => {
    setPageLoading(true)
    setServiceData([])
    var req = {
      "listSize": 12,
      "pageNumber":page,
      "categoryId": categoryValue
    }
    apiService('smartcard/list', req, 'unauthpost').then((result) => {
      setPageLoading(false)
      if (result && result.data) {
        setTotalPage(result.data.totalPages)
        if (result.data.responsemodelList) {
          setServiceData(result.data.responsemodelList);
        }
      }
    }).catch((err) => {
      console.log(err);
    });
  }
  const handleChangeCategory = (newValue) => {
    setCategoryValue(newValue);
  };
  const handleChange = (value) => {
    setPage(value);
  };
  let userid = localStorage.getItem('id')

  const handleKnowMoreClick = (service) => {
    const { userId, persona, smartCardId } = service
    setUserProfileId(userId)
    setUserProfileParsona(persona)
    setShowViewbtn(1)
    if (userId == userid) {
      localStorage.setItem('smartCard', 'join')
    } else {
      localStorage.setItem('smartCard', '')
      setshowHighlights(true)
    }
    localStorage.setItem('joinusId', smartCardId)
    localStorage.setItem("userpersonaId", userId);
    localStorage.setItem("userpersona", persona);
    // if (name === 'initiative') {
    //   navigator(`/initiative/aboutCompany/${btoa(serviceId)}`);
    // } else {
    //   navigator(`/smartcard/aboutCompany/${btoa(serviceId)}`);
    // }
    navigator(`/smartcard/${btoa(userId)}/profile`);
  };
  let token = localStorage.getItem("jwttoken");
  let pers = localStorage.getItem('type')
  const handleJoinPartner = () => {
    setPageLoading(true)
    if (token) {
      apiService(`smartcard/status/get?userid=${userid}`, "", "get")
        .then((result) => {
          setPageLoading(false)
          localStorage.setItem("userpersonaId", userid);
          localStorage.setItem("userpersona", pers);
          if (result && result.data) {
            if (result.data.smartcardId) {
              localStorage.setItem('joinusId', result.data.smartcardId)
            }
            if(result.data.status === 0) {
              navigator(`/smartcard/joinuspartner/${btoa(userid)}/info`);
            }
            else {
              localStorage.setItem('smartCard', 'join')
              localStorage.setItem('joinasstatus', result.data.status)
              setPageLoading(true)
              setUserProfileId(userid)
              setUserProfileParsona(pers)
              navigator(`/smartcard/${btoa(userid)}/profile`);
            }
          }
        })

        .catch((err) => {
          console.log(err);

        });
    } else {
      localStorage.setItem('smartCard', 'join')
      const currentPath = window.location.pathname;
      localStorage.setItem('currentPath', currentPath);
      navigator('/login')
    }
    // navigator(`/smartcard/${btoa(serviceId)}`);
    // navigator(`/smartcard/${btoa(serviceId)}/profile`);
  }


  return (
    <div className="smartCard-page">
      <div className="radial-back-layer">
        <div className="round-layers"></div>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="banner-text">
              <div className="sm-banner-chip-text">
                <span>{name === 'initiative' && (`Support >`)} Smart Card </span>
                <h1 className="banner-text-gradient">
                  Smart Card Service Partner
                </h1>
                <h6>
                  Smart Savings for Startups: The Ultimate StartupTN Smart Card
                </h6>
                <div className="banner-content-actions">
                  <button className="banner-viewbtn">Apply Services</button>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className="smart-card-info">
        <Container>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={5}
          >
            <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
              <div className="left-side-smartcardPages mt-5">
                <h3>Service Partners</h3>
                <p className="mt-3">
                  To enhance the value of the Smart Card and support our startup
                  community, we are seeking service partners to provide
                  discounts on their products and services.
                </p>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <div className="smart-content-actions mt-5">
                <Button
                  variant="contained"
                  className="sm-right-btn"
                  size="medium"
                  onClick={handleJoinPartner}
                >
                  Join as Partner
                </Button>
              </div>
            </Grid>
          </Grid>
          <div className="tab-btn-details mt-5">
            <TabContext className="tab-div">
              <Box className="smart-tabs-boxs">
                <Tabs variant="scrollable" scrollButtons="auto" >
                  {categoryList &&
                    categoryList.map((category) => (
                      <Tab
                        key={category.id}
                        label={category.category}
                        onClick={() => handleChangeCategory(category.id)}
                        style={{
                          backgroundColor: category.id === categoryValue ? "#083fb2" : "",
                          color: category.id === categoryValue ? "white" : "#000",
                          borderRadius: '25px',
                          padding: '7px 15px'
                        }}
                        className="setFont"
                      />
                      // <Button
                      //   key={category.id}
                      //   style={{
                      //     backgroundColor:
                      //       category.id === categoryValue
                      //         ? "#083fb2"
                      //         : "",
                      //     color:
                      //       category.id === categoryValue
                      //         ? "white"
                      //         : "#000",
                      //     borderRadius: '25px',
                      //     padding: '7px 15px'
                      //   }}
                      //   className="setFont"
                      //   onClick={() => handleChangeCategory(category.id)}
                      // >{category.category}
                      // </Button>
                    ))}
                </Tabs>
              </Box>

              <TabPanel>
                {
                  serviceData.length > 0 ? (
                    <>
                      <Grid container spacing={5}>
                        {
                          serviceData.map((service, index) => (
                            <Grid item xs={12} sm={6} md={3} key={index}>
                              <Card className="sup-sm-crds">
                                <div className="service-card-back-layers">
                                  <div
                                    // component="img"
                                    // height="200"
                                    // image="/images/crd-backlayer.png"
                                    // alt="service image"
                                    className="layer-crd-back-img"
                                  >
                                    <p className="category-title-para">
                                      {service.subCategory}
                                    </p>
                                    <div className="service-card-back-profss">
                                      {/* <img
                                        src={service.companyLogoName}
                                        alt={service.subCategory}
                                        className="smcrd-card-back-image"
                                      /> */}
                                      <img
                                        // className="user-brandlogo img-fluid"
                                        className="smcrd-card-back-image"
                                        src={
                                          service.companyLogoName
                                            ? service.companyLogoName
                                            : "/images/user.png"
                                        }
                                        alt="logo"
                                      />
                                    </div>
                                    <div className="mt-2 mb-0">
                                    <h4 style={{wordBreak:'break-word',fontSize:'18px',fontWeight:'600'}}>{service.companyName ? service.companyName  : ""}</h4>

                                    </div>
                                    <div className="card-content-offers">
                                      {/* <ul className="discount_smart">
                                        {service.offers && service.offers.map((value, index) => (
                                          <li key={index}>
                                            <div
                                              className="imag-cont-smarnew"
                                            >
                                              <span className="colr_chane">{value.offer}</span>{value.descripition}
                                            </div>
                                          </li>
                                        ))}
                                      </ul> */}
                                      <p style={{fontSize:'12px',wordBreak:'break-word'}}>{service.privilegeOffers ? service.privilegeOffers  : ""}</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="text-center mt-4">
                                  <Button
                                    variant="contained"
                                    className="smnew-crd-actions"
                                    onClick={() =>
                                      handleKnowMoreClick(service)
                                    }
                                    
                                  >
                                    Know More
                                  </Button>
                                </div>
                              </Card>
                            </Grid>
                          ))
                        }
                      </Grid>
                      {serviceData.length > 0 && totalPage > 1 && (
                        <div className="event-pagination text-center w-100">
                          <button disabled={page === 1} className="event_round_btn" onClick={() => handleChange(page - 1)}>{`<`}</button>
                          <button disabled={page === totalPage} className="event_round_btn" onClick={() => handleChange(page + 1)}>{`>`}</button>
                        </div>
                      )}
                    </>

                  ) : (
                    <>
                      <div className='text-center nodatacss_admin'>
                        <h5 className='mb-4'>Smart Card data not found</h5>
                        <div>
                          <img src="/images/blue horizontal.png" alt="blue horizontal.png" className='logo_dash img-fluid' />
                        </div>
                        <div className='mt-5'>
                          <h3 className='title_p opa'>
                            StartupTN 3.0
                          </h3>
                        </div>
                      </div>
                    </>
                  )
                }

              </TabPanel>
            </TabContext>
          </div>
        </Container>
        <div className="container-fluid">
          <div className="join-us-now-sect">
            <Container>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div className="join-us-now-boxs">
                    <div className="join-us-now-contentes">
                      <h4>Disclaimer</h4>
                      <p>
                        ``The empaneled list, stating that StartupTN should not
                        be held accountable for the lapses in services of the
                        empaneled service provider, exemplifies a 'hands-off'
                        approach. Furthermore, it emphasizes that StartupTN has
                        no role in endorsing any specific empaneled service
                        providers, leaving the selection entirely to the
                        startups themselves.``
                      </p>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Container>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportSmartCardService;
