import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Container,
  Divider,
  Grid,
  Tab,
} from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { Link, useNavigate } from "react-router-dom";
import { FaCircleArrowRight } from "react-icons/fa6";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IoIosAddCircle } from "react-icons/io";
import Navbar from "../../home-module/navbar-page/Navbar";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import apiService from "../../../api/apiService";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import DataContext from "../../../context/DataContext";
import Footer from "../../home-module/footer-page/Footer";

const ScStFund = ({name}) => {
  const { navigator, setPageLoading, validateEmail } = useContext(DataContext);
  const [expanded, setExpanded] = useState("panel1");
  const [screenSize, setScreenSize] = useState(4);
  const navigate = useNavigate();

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [activeTab, setActiveTab] = useState("0");

  const [value, setValue] = useState("0");
  const handleChangetab = (event, newValue) => {
    setValue(newValue);
    setActiveTab(newValue);
  };

  useEffect(() => {
    size();
  }, []);

  const size = () => {
    if (window.innerWidth > 1440) {
      setScreenSize(4);
    } else if (window.innerWidth <= 1440 && window.innerWidth > 1024) {
      setScreenSize(4);
    } else if (window.innerWidth <= 1024 && window.innerWidth > 992) {
      setScreenSize(4);
    } else if (window.innerWidth <= 992 && window.innerWidth > 768) {
      setScreenSize(4);
    } else if (window.innerWidth <= 768 && window.innerWidth > 575) {
      setScreenSize(4);
    } else {
      setScreenSize(1);
    }
  };

  useDidMountEffect(() => {
    getServiceApi();
  }, []);

  const [serviceData, setServiceData] = useState([]);
  const getServiceApi = () => {
    var req = {
      listSize: 25,
      pageNumber: 1,
    };
    apiService("smartcard/list", req, "unauthpost")
      .then((result) => {
        setPageLoading(false);
        if (result && result.data) {
          if (result.data.responsemodelList) {
            setServiceData(result.data.responsemodelList);
          }
        }
      })
      .catch((err) => {});
  };

  const handlenow = () => {
    navigate('/login')
  }
  const handleApplyFunding = () => {
    window.open("https://tanfund.in/", "_blank", 'noopener noreferrer');
  }

  const handleBrochure = () => {
    const pdfUrl =
    "https://startuptn-public-files.s3.ap-south-1.amazonaws.com/SCST_Brochure.pdf";
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.target = '_blank';
    link.download = "Startup_Admin_Template.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  return (
    <div className="smartCard-page">
      <Navbar />
      <div className="radial-back-layer">
        <div className="round-layers"></div>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="banner-text">
              <div className="sm-banner-chip-text">
                <span>{`${name}  >  TN `}</span>
                <h1 className="banner-text-gradient">TN SC/ST Startup fund</h1>
                <h6>
                  Propelling Socioeconomic Growth! Investing in Inclusive
                  Progress!
                </h6>
                <div className="banner-content-actions">
                  <button className="banner-viewbtn" onClick={()=>handleApplyFunding()}>Apply Funding</button>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className="smart-card-info">
        <Container>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={2}
            className="tanseed-info"
          >
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="left-side-smartcardPage">
                <h4>What is TN SC/ST Startup Funds ?</h4>
                <p>
                  Tamil Nadu Scheduled Caste / Scheduled Tribe Startup Fund is
                  an exclusive initiative of the Government of Tamil Nadu to
                  address the issue of financial exclusion of entrepreneurs
                  belonging to marginalised communities. The objective of this
                  fund is to invest in startups founded by entrepreneurs
                  exclusively from the Scheduled Caste / Scheduled Tribe
                  communities.
                </p>
                <div className="left-content-actions">
                  <button className="ser-viewbtn"  onClick={()=>handleApplyFunding()}>Apply for Funding</button>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="smart-crd-img">
                <img
                  className="smartcrdimag img-fluid"
                  src={"/images/startupLogo.png"}
                  alt="smart-card"
                />
              </div>
            </Grid>
          </Grid>
          <div className="smartcrd-details-div mt-5">
            <div className="service-partner-overlay"></div>
            <div className="det-lines">
              <div className="smcrd-services">
                <h3>
                  Our <span> Startups</span>
                </h3>
                <p>
                  Over 100+ Startups funded Successfully – Join the Revolution!"
                </p>
                <div className="partners-company-logos-scroll">
                  <div className="partners-company-logos">
                    {serviceData.map((logo, i) => (
                      <div className="partners-company-sec" key={i}>
                        <img
                          src={logo.companyLogoName}
                          alt={logo.subCategory}
                          className="part-logos"
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="how-works-divs">
            <Grid
              container
              direction="row"
              alignItems="flex-start"
              justifyContent="center"
              spacing={2}
            >
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div className="works-section mt-5">
                  <h4>How it Works?</h4>
                  <p>
                    Here are the detailed steps of the TN SC/ST Startup Funds
                    process for providing Support to startups.
                  </p>
                </div>
                <div className="track"></div>
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                <div className="layer-bg-smcrd">
                  <div className="works-mesh-layer1"></div>
                  <div className="how-works-detailss">
                    <div className="1">
                      <div className="sm-crd-process-steps1">
                        <div className="process-div1">
                          <div className="rec-img1">
                            <img
                              src={"/images/zoomglass.png"}
                              alt="registration"
                              className="process-img"
                            />
                          </div>
                          <div className="process-text">
                            <h3>
                              01 <span>Submission of EOI</span>
                            </h3>
                            <p>
                              Assessing initial eligibility and potential of
                              business ideas.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="sm-crd-process-steps1">
                        <div className="process-div1">
                          <div className="rec-img2">
                            <img
                              src={"/images/chart.png"}
                              alt="registration"
                              className="process-img"
                            />
                          </div>
                          <div className="process-text">
                            <h3>
                              02 <span>Business Validation</span>
                            </h3>
                            <p>
                              Confirming the feasibility and market fit of the
                              proposed business model.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="sm-crd-process-steps1">
                        <div className="process-div1">
                          <div className="rec-img2">
                            <img
                              src={"/images/increase.png"}
                              alt="registration"
                              className="process-img"
                            />
                          </div>
                          <div className="process-text">
                            <h3>
                              03 <span>Investment Committee</span>
                            </h3>
                            <p>Reviewing and approving investment proposals.</p>
                          </div>
                        </div>
                      </div>

                      <div className="sm-crd-process-steps3">
                        <div className="process-div1">
                          <div className="rec-img2">
                            <img
                              src={"/images/form.png"}
                              alt="registration"
                              className="process-img"
                            />
                          </div>
                          <div className="process-text">
                            <h3>
                              04 <span>Project Sanction Committee</span>
                            </h3>
                            <p>
                              Authorizing the commencement of selected
                              projects. 
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Container>
        <div className="why-smartcrd-sec1">
          <div className="container">
            <div className="tabed pb-5">
              <h1 className="criteria">Eligibility Criteria</h1>
              <div className="tab-3">
                {" "}
                <ol className="listed">
                  <li>
                    The applicant, at the time of application, should conform to
                    the definition of Startup under the Tamil Nadu Startup and
                    Innovation Policy.
                  </li>
                  <li>
                    More than 50% of equity of the company/enterprise must
                    belong to one or more founders who belong to the SC/ST
                    community.
                  </li>
                  <li>
                    The control of the company/enterprise should vest with SC/ST
                    shareholders.
                  </li>
                  <li>
                    The company/enterprise should be domiciled in Tamil Nadu and
                    its principal base of operation should be Tamil Nadu.
                  </li>
                  <li>
                    The SC/ST entrepreneur(s) who have applied should be
                    native(s) of Tamil Nadu or of Tamil origin.
                  </li>
                  <li>
                    Preference will be given if such entrepreneur(s) have their
                    principal base of operations within Tamil Nadu.
                  </li>
                </ol>
              </div>
            </div>
            <div className="broch">
              <h3 className="more">
                For More Details, Please Download the Brochure below
              </h3>
              {/* <Button className="download" onClick={handleBrochure}>Download Brochure</Button> */}
              <Button className="download">Download Brochure</Button>
            </div>
          </div>
        </div>
        <div className="join-us-now-sec1">
          <Container>
            <div className="term-condition-title">
              <h4 className="trem">Terms and Condition</h4>
            </div>
            <div className="trem-lis">
              <ol>
                <li>
                  The applications will be processed batch-wise, on a First in
                  First out basis.
                </li>
                <li>
                  The application should come through the prescribed transparent
                  process.
                </li>
                <li>
                  Any form of influence would lead to disqualification of the
                  application.
                </li>
                <li>
                  The decision of the Investment Committee will be the final.
                </li>
                <li>
                  The process of the application may take 4 to 6 months from the
                  date of acceptance of the application (acceptance of the
                  application does not guarantee sanction of funds).
                </li>
                <li>
                  The receipt of applications may be closed once the threshold
                  level is reached.
                </li>
                <li>
                  Equity investment in startups involves purchasing shares or
                  ownership stakes in a startup company in exchange for capital.{" "}
                </li>
                <li>
                  Investors provide funding to the startup in return for a
                  percentage of ownership, which means they become shareholders
                  in the company.{" "}
                </li>
              </ol>
            </div>
          </Container>
        </div>
        <div className="faq-section">
          <div className="faq-content">
            <div className="wel">
              <div>
                <h3 className="inss">
                  TN SC/ST Startup Fund in welcoming applications
                </h3>
              </div>
              <div>
                <Button className="applied"  onClick={()=>handleApplyFunding()}>Apply Now</Button>
              </div>
            </div>
          </div>
        </div>
        <div className="join-us-now-sec">
          <Container>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="join-us-now-box">
                  <div className="join-us-now-content">
                    <h4>Let’s Get Started</h4>
                    <p>Tamil Nadu's investment Gateway for scaling Startups!</p>
                    <Button
                      variant="contained"
                      size="large"
                      className="join-now-btn"
                      onClick={()=>handleApplyFunding()} 
                    >
                      Join us Now
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default ScStFund;
