import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
} from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { Autoplay, Pagination } from "swiper/modules";

import DataContext from "../../../../context/DataContext";
import { useNavigate } from "react-router-dom";

const MentorHome = ({ name,url }) => {
  const { navigator, setPageLoading, validateEmail } = useContext(DataContext);
  const [expanded, setExpanded] = useState("panel1");
  const [screenSize, setScreenSize] = useState(4);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  useEffect(() => {
    size();
  }, []);

  const size = () => {
    if (window.innerWidth > 1440) {
      setScreenSize(4);
    } else if (window.innerWidth <= 1440 && window.innerWidth > 1024) {
      setScreenSize(4);
    } else if (window.innerWidth <= 1024 && window.innerWidth > 992) {
      setScreenSize(4);
    } else if (window.innerWidth <= 992 && window.innerWidth > 768) {
      setScreenSize(4);
    } else if (window.innerWidth <= 768 && window.innerWidth > 575) {
      setScreenSize(4);
    } else {
      setScreenSize(1);
    }
  };

  const mentorItems = [
    {
      mentorImage: "/images/subu.png",
      mentorName: "Balasubramanian AJ  ",
    },
    {
      mentorImage: "/images/mentor2.png",
      mentorName: "k Suresh Kumar",
    },
    {
      mentorImage: "/images/mentor3.png",
      mentorName: "Jitendra Jain ",
    },
    {
      mentorImage: "/images/mentor4.png",
      mentorName: "Priya Raghavan",
    },
    {
      mentorImage: "/images/mentor5.png",
      mentorName: "Chandran Krishnan",
    },
  ];

  const sessionItems = [
    {
      sessionImage: "/images/sessionimage.png",
      sessionProgram: "Cohort Program ",
      sessionTitle: "Basic strategy for effective sustainable energy",
    },
    {
      sessionImage: "/images/sessionimage.png",
      sessionProgram: "Cohort Program ",
      sessionTitle: "Basic strategy for effective sustainable energy",
    },
    {
      sessionImage: "/images/sessionimage.png",
      sessionProgram: "Cohort Program ",
      sessionTitle: "Basic strategy for effective sustainable energy",
    },
    {
      sessionImage: "/images/sessionimage.png",
      sessionProgram: "Cohort Program ",
      sessionTitle: "Basic strategy for effective sustainable energy",
    },
    
  ];

  const navigate = useNavigate();

  const handlejoined = () => {
    navigate(`/initiative/${url}/mentortn/about`)
  };

  const handleExplored = () => {
    navigate(`/initiative/${url}/mentortn/team`)
  };

  const handleMored = () => {
    navigate(`/initiative/${url}/mentortn/sessions`)
  };

  const handlenow = () => {
    navigate('/login')
  }

  const handleMentor = () => {
    window.open('https://startuptnmentors.com/', '_blank', 'noopener,noreferrer');
  }

  return (
    <div className="smartCard-page">
      <div className="radial-back-layer">
        <div className="round-layers"></div>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="banner-text">
              <div className="sm-banner-chip-text">
                <span>{`${name} >  MentorTN`}</span>
                <h1 className="banner-text-gradient">MentorTN</h1>
                <h6>Right Mentor for the right founder!</h6>
                <div className="banner-content-actions">
                  <button className="banner-viewbtn" onClick={()=>handleMentor()}>Join Us</button>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className="smart-card-info">
        <Container>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={2}
          >
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="left-side-smartcardPage mt-5">
                <h4>What is MentorTN ?</h4>
                <p>
                  A Mentorship focused platform to bridge the gap between
                  aspiring entrepreneurs and seasoned industry experts,
                  fostering meaningful connections that drive success. Whether
                  you're navigating the early stages of your venture or seeking
                  guidance to overcome challenges and seize new opportunities,
                  MentorTN is here to empower you every step of the way.
                </p>

                <div className="left-content-actions">
                  <button className="ser-viewbtn" onClick={handlejoined}>
                    Join Us
                  </button>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="session-crd-img">
                <img
                  className="sessioncrdimag img-fluid"
                  src={"/images/startup-mentor.png"}
                  alt="smart-card"
                />
              </div>
            </Grid>
          </Grid>
          <Container>
            <div className="details-divided">
              <div className="deteled-lines">
                <div className="mentored-info">
                  <div>
                    <img src={"/images/green-energy (1) 1.svg"} alt="" />
                  </div>
                  <div className="det-font">
                    <h3>659+</h3>
                    <p>Happy members</p>
                  </div>
                </div>
                <div className="mentored-info">
                  <div>
                    <img src={"/images/Group 1707479617.svg"} alt="" />
                  </div>
                  <div className="det-font">
                    <h3>257+</h3>
                    <p>Expert Mentors</p>
                  </div>
                </div>
                <div className="mentored-info">
                  <div>
                    <img src={"/images/startup 1.svg"} alt="" />
                  </div>
                  <div className="det-font">
                    <h3>900+</h3>
                    <p> Connections</p>
                  </div>
                </div>
                <div className="mentored-info">
                  <div>
                    <img src={"/images/diagram 1.svg"} alt="" />
                  </div>
                  <div className="det-font">
                    <h3>10+</h3>
                    <p>Cohorts</p>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </Container>
        <div className="join-us-now-sec1">
          <div className="ment">
            <img src="/images/mentor meter.png" alt="" />
          </div>
          <div className="plat mt-2">
            <p className="lev-para">
              Level Up Your Journey: One Platform, Endless Mentoring
              Possibilities
            </p>
          </div>
          <div className="mentor-lists">
            <div>
              <span>1:1 Mentoring</span>
            </div>
            <Divider
              className="did"
              orientation="vertical"
              variant="middle"
              flexItem
            />
            <div>
              <span>Group Mentoring</span>
            </div>
            <Divider
              className="did"
              orientation="vertical"
              variant="middle"
              flexItem
            />
            <div>
              <span>Cohorts</span>
            </div>
          </div>
          <div className="mentor-lists">
            <div>
              <span>Peer Learning</span>
            </div>
            <Divider
              className="did"
              orientation="vertical"
              variant="middle"
              flexItem
            />
            <div>
              <span>Ask Me Anything(AMA)</span>
            </div>
            <Divider
              className="did"
              orientation="vertical"
              variant="middle"
              flexItem
            />
            <div>
              <span>Reverse Mentoring</span>
            </div>
          </div>
          <div className="text-center mt-3">
            <Button className="explore" onClick={handleExplored}>Explore More</Button>
          </div>
        </div>
        <div className="why-smartcrd-sec mt-5">
          <div className="why-sm-title">
            <h3>
              Why <span>MentorTN?</span>
            </h3>
          </div>
          <div className="why-sm-paras">
            <Container>
              <Grid
                container
                direction="row"
                alignItems="flex-start"
                justifyContent="center"
                spacing={2}
              >
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <div className="why-sm-texts text-center">
                    <div className="why-sm-icns">
                      <img src={"/images/Mask.png"} alt="Acess Key" />
                    </div>
                    <div className="why-sm-det">
                      <h6>Connects and Create Interactions</h6>
                      <p className="text-left">
                        StartupTN Mentor Portal connects and creates impactful
                        interactions between Startups and Experts.
                      </p>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <div className="why-sm-texts text-center">
                    <div className="why-sm-icns">
                      <img
                        src={"/images/comprehensive.png"}
                        alt="comprehensive data"
                      />
                    </div>
                    <div className="why-sm-det">
                      <h6>Mature Mentoring Network</h6>
                      <p className="text-left">
                        Tamil Nadu’s Startup Ecosystem has a mature Mentoring
                        Network that is confined within the community comprising
                        ecosystem enablers and business incubators, without
                        making much noise.
                      </p>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <div className="why-sm-texts1 text-center">
                    <div className="why-sm-icns">
                      <img src={" /images/block.png"} alt="exclusive events" />
                    </div>
                    <div className="why-sm-det">
                      <h6>Bridges the Gap</h6>
                      <p className="text-left">
                        This platform is to bridge the gap between these mentors
                        and Startup founders and aspirants.
                      </p>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Container>
          </div>
        </div>
        <div className="join-us-now-sec1">
          <Container>
            <Grid
              container
              direction="row"
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="service-partners-title">
                  <h4>Discover Our Mentors</h4>
                </div>
              </Grid>
            </Grid>
            <div className="service-crd mt-4">
              <Swiper
                slidesPerView={screenSize}
                spaceBetween={30}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay,Pagination]}
                pagination={{
                  clickable: true,
                  renderBullet: function (index, className) {
                    return `<span class="${className}" style="background-color: orange;"></span>`;
                  },
                }}
                className="mySwiper"
                style={{ paddingBottom: "50px" }}
              >
                {mentorItems.map((item, i) => (
                  <SwiperSlide key={i}>
                    <Card className="sup-sm-crds1">
                      <div className="service-card-back-layers1">
                        <div className="layer-crd-back-img1">
                          <div className="service-card-back-profss1">
                            <img
                              src={item.mentorImage}
                              alt="crd-img1"
                              className="mentor-carded-back-image1"
                            />
                          </div>
                        </div>
                      </div>
                      <CardContent
                        sx={{
                          textAlign: "center",
                          padding: "24px 0px 0px 0px",
                        }}
                      >
                        <p className="ser-crd-title-text" style={{fontWeight:600}}>{item.mentorName}</p>
                      </CardContent>
                    </Card>
                  </SwiperSlide>
                ))}
                
              </Swiper>
              {/* <div className="ser-content-actions">
                  <button className="ser-viewbtn">
                    Explore all services partners
                  </button>
                </div> */}
            </div>
          </Container>
        </div>
        <div className="faq-section ">
          <div className="thiru-content">
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="center"
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={4}
                xl={4}
                className="thiru-image"
              >
                <img
                  src="/images/thiruvalluvar.png"
                  alt=""
                  className="img-fluid"
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={8}
                xl={8}
                className="thiru-grid"
              >
                <h3 className="thiru-title">
                  <span className="thiru">
                    அறனறிந்து மூத்த அறிவுடையார் கேண்மை திறனறிந்து தேர்ந்து
                    கொளல்.
                  </span>
                </h3>
                <p className="thiru-para">
                  This couplet in the chapter titled 'Seeking the Aid of Great
                  Men' narrates the Mentor-Mentee relationship. It states that
                  Mentees secure the trust and friendship of their Mentors and
                  by doing so Knowledge and Wisdom of Mentors is passed on to
                  the younger generations. This age old couplet like many others
                  proves to be true to this day and new age Startups are no
                  exemption to this.
                </p>
                <p className="thiru-cou">Thirukkural Couplet : 441</p>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="join-us-now-sec">
          <Container>
            <Grid
              container
              direction="row"
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="ongoinged">
                  <h4>Ongoing Sessions</h4>
                </div>
              </Grid>
            </Grid>
            <div className="service-crd mt-4">
              <Container>
                <Grid container spacing={3}>
                  {sessionItems.map((item, i) => (
                    <Grid item xs={12} sm={6} md={3} key={i} className="cardingss">
                      <Card className="session-crds1">
                        <div>
                          <img
                            className="session-crd-back"
                            src={item.sessionImage}
                            alt=""
                          />
                        </div>
                        <CardContent className="session-contents">
                          <div className="mt-2">
                            <span className="ses-pro">
                              {item.sessionProgram}
                            </span>
                          </div>
                          <div>
                            <span className="ses-tit">{item.sessionTitle}</span>
                          </div>
                          <div className="mt-2">
                            <Button className="ses-now">Join Now</Button>
                          </div>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                  <Grid item xs={12}>
                    <div className="text-center mt-3">
                      <Button className="explore" onClick={handleMored}>Explore More</Button>
                    </div>
                  </Grid>
                </Grid>
              </Container>
            </div>
          </Container>
        </div>
        <div className="join-us-now-sec">
          <Container>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="join-us-now-box">
                  <div className="join-us-now-content">
                    <h3>Let’s Get Started</h3>
                    <p>MentorTN</p>
                    <p>Right Mentor for the right founder!</p>
                    <Button
                      variant="contained"
                      size="large"
                      className="join-now-btn"
                      onClick={handlenow}
                    >
                      Join us Now
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default MentorHome;
