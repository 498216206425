import { Autocomplete, FormHelperText, Grid, TextField, } from "@mui/material";
import React, { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import DataContext from "../../../../context/DataContext";
import apiService from "../../../../api/apiService";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import notifyService from "../../../../api/notifySerivce";
import cryptodecryptJS from "../../../../hooks/cryptodecryptJS";
const MoreinfoMenteors = () => {
  const { navigator, handleNextClick, isWhitespace ,fastUrlPath} = useContext(DataContext);
  const [formData, setFormData] = useState({});
  const { handleSubmit, control, formState: { errors }, } = useForm({ values: formData });
  useDidMountEffect(() => {
    handleNextClick(98);
    stagesdropdownApi()
  }, []);
  useDidMountEffect(() => {
    getApi()
  }, []);
  const getApi = () => {
    var id = localStorage.getItem('id')
    var type = localStorage.getItem('type')
    apiService(`userprofile/get?persona=${type}&userid=${id}`, "", "get").then((result) => {
      if (result && result.data) {
        var data = result.data
        setFormData(data)
      }
    }).catch((err) => {
    });
  }
  const [startupStages, setStartupStages] = useState([])
  const stagesdropdownApi = () => {
    apiService(`admin/dropdownvalues/get?key=${'stages'}`, '', 'unauthget').then((result) => {
      if (result && result.data && result.data.dropDownValues) {
        var dropDown = result.data.dropDownValues
        setStartupStages(dropDown.values)
      }
    }).catch((err) => {
    });
  }
  const onSubmit = (data) => {
    data.profileUpdated = true
    apiService("userprofile/save", data, 'post').then((result) => {
      if (result.data.responseStatus === 'Success') {
        localStorage.setItem('profile', true)
        localStorage.setItem('email', data.email)
        navigator('/mentor/profile')
      }
    }).catch((err) => {
    });
  };
  const onError = (event) => {
    notifyService("danger", "Submit Failed", "Please check the mandatory fields");
  };
  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <div className="signup-data_div">
            <div className="forms-title d-flex">
              <p>
                Organization Info {`>`}{" "}
                <span className="path_sapn">More Info</span>
              </p>
              <p className="ml-auto">
                <span className="count_num">4</span>/4
              </p>
            </div>
            <div className="signup_box_div mt-4">
              <form onSubmit={handleSubmit(onSubmit, onError)}>
                <Grid container spacing={1}>
                  <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                    <Controller
                      name="preferredStage"
                      control={control}
                      defaultValue=""
                      rules={{ required: true }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <Autocomplete
                          multiple={true}
                          options={startupStages}
                          getOptionLabel={(option) => option}
                          defaultValue={[]}
                          value={value || []}
                          disableClearable
                          onChange={(event, newValue) => onChange(newValue)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Preferred Stage Of Startups *"
                              placeholder="Seletct Preferred Stage Of Startups"
                              error={!!error}
                              helperText={
                                error
                                  ? "Preferred Stage Of Startups is required"
                                  : ""
                              }
                            />
                          )}
                          fullWidth
                          margin="normal"
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.preferredStage &&
                        errors.preferredStage.message}
                    </FormHelperText>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                    <Controller
                      name="about"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "Brief About Your Expertise   is required",
                        validate: {
                          noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                        },
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          id="outlined-multiline-static"
                          label="Brief About Your Expertise *"
                          fullWidth
                          multiline
                          rows={4}
                          defaultValue=""
                          inputProps={
                            { maxLength: 500 }
                          }
                          error={Boolean(errors.about && errors.about)}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.about && errors.about.message}
                    </FormHelperText>
                  </Grid>

                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="text-center"
                  >
                    <button className="next-btn" type="submit">
                      Save
                    </button>
                  </Grid>
                </Grid>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default MoreinfoMenteors;
