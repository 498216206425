import { Container, Grid } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

const Tnfestblog = () => {
  return (
    <div>
      <div className='cm-bg-img-tiru'>
        <div className='whole-blog-div'>
          <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2}>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <img src={'/images/Banner/cm-stalin.webp'} alt="cm-photo" className='img-cm-tiru' />
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <div className='blog-para-cm'>
            <p>Recognizing the potential of startups and aiming to nurture them by creating a conducive environment, the Hon’ble Chief Minister of Tamil Nadu, Thiru M.K. Stalin, is demonstrating a strong commitment to supporting startups. His keen interest is evident through a series of announcements, the launch of special initiatives, and efforts to directly monitor the progress of StartupTN and other startups.</p>
                <p>In 2022-23, the Chief Minister allocated Rs. 30 crore to establish the ‘Tamil Nadu SC/ST Startup Fund’. This initiative, the first of its kind by a state government, involves equity investments in startups founded and operated by entrepreneurs from the Scheduled Caste and Scheduled Tribe communities. Due to its overwhelming success in promoting social justice, the fund was increased to Rs. 50 crore in 2023-24.</p>
                <p>To foster job creation in Tier II and Tier III cities and towns, the Government of Tamil Nadu launched StartupTN Regional Hubs in Erode, Madurai, and Tirunelveli in 2022-23. To further extend its reach in rural areas, four additional Regional Hubs in Hosur, Salem, Thanjavur, and Cuddalore were announced in 2023-24.</p>
                <p>A Global Startup Summit will be organised in February, 2025 in Chennai to turn the spotlight on the Startup Ecosystem in the state. Startups from Tamil Nadu will get exposure and networking opportunities by showcasing their products and services to the world.</p>
                <p>Establishing Global Coordination Centres in foreign countries marks another significant step towards creating a globally competitive startup ecosystem. These centres will support Tamil Nadu startups in expanding their businesses internationally, offering co-working spaces, business consultations, legal and regulatory guidance, and access to capital and partnerships. The first facility will come up in Dubai, following which centres will be opened in Singapore and the USA</p>
                <p>More information about these efforts to position Tamil Nadu as a global startup leader can be found at <span><Link className="animate-pulse" href={'https://startuptn.in/'} target="_blank">https://startuptn.in/.</Link></span></p>
                </div>
            </Grid>
          </Grid>
        </div>
      </div>
        <img src={'/images/Banner/strip_banner.svg'} alt="strip" class='img-fluid' />
    </div>
  )
}

export default Tnfestblog
