import { Autocomplete, Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, InputAdornment, Radio, RadioGroup, TextField } from '@mui/material'
import React, { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import DataContext from "../../../../context/DataContext";
import apiService from "../../../../api/apiService";
import cryptoJS from "../../../../hooks/cryptoJS";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import FormLabel from "@mui/material/FormLabel";
import notifyService from '../../../../api/notifySerivce';
import cryptodecryptJS from '../../../../hooks/cryptodecryptJS';
const MoreinfoInvestment = () => {
  const { navigator, validateEmail, validateNumberonly, setPageLoading, handleNextClick, isWhitespace,fastUrlPath } = useContext(DataContext);
  const [formData, setFormData] = useState({});
  const { handleSubmit, control, setValue, watch, setError, clearErrors, formState: { errors }, } = useForm({ values: formData });
  var id = localStorage.getItem('id')
  useDidMountEffect(() => {
    handleNextClick(100);
    dropdownApi()
    stagesdropdownApi()
    sectorsdropdownApi()
    investmentticketsize()
  }, []);
  const [support, setSupport] = useState([])
  const dropdownApi = () => {
    apiService(`admin/dropdownvalues/get?key=${'support'}`, '', 'unauthget').then((result) => {

      if (result) {
        if (result.data) {
          if (result.data.dropDownValues) {
            var dropDown = result.data.dropDownValues
            setSupport(dropDown.values)

          }
        }
      }
    }).catch((err) => {
    });
  }
  const [sectors, setSectors] = useState([])
  const sectorsdropdownApi = () => {
    apiService(`home/sector/list`, "", "unauthget").then((result) => {
      if (result) {
        if (result.data) {
          if (result.data.sectors) {
            var values = result.data.sectors
            setSectors(values)
          }
        }
      }
    }).catch((err) => {
    });
  }
  const [startupStages, setStartupStages] = useState([])
  const stagesdropdownApi = () => {
    apiService(`admin/dropdownvalues/get?key=${'stages'}`, '', 'unauthget').then((result) => {
      if (result) {
        if (result.data) {
          if (result.data.dropDownValues) {
            var dropDown = result.data.dropDownValues
            setStartupStages(dropDown.values)
          }
        }
      }
    }).catch((err) => {
    });
  }
  let roleval = localStorage.getItem("role");
  useDidMountEffect(() => {
    if (id !== null) {
      getApi();
    }
  }, []);
  const getApi = () => {
    var id = localStorage.getItem("id");
    var type = localStorage.getItem('type')
    apiService(`userprofile/get?persona=${type}&userid=${id}`, "", "get").then((result) => {
      if (result && result.data) {
        var data = result.data       
        setFormData(data)
      }

    }).catch((err) => {

    });
  }
  const onSubmit = (data) => {

    data.profileUpdated = true
    apiService("userprofile/save", data, "post").then((result) => {
      if (result.data.responseStatus === 'Success') {
        localStorage.setItem('profile', true)
        localStorage.setItem('email', data.email)
        navigator('/investor/profile')
        // const currentPath = localStorage.getItem('currentPath');
        // if (currentPath) {          
        //   fastUrlPath()
        // } else {
        //   navigator('/investor/profile')
        // }
      }
    }).catch((err) => {

    });

  };
  const onError = (event) => {
    notifyService("danger", "Submit Failed", "Please check the mandatory fields");
  };
  const [ticketsize, setticketsize] = useState([]);
  const investmentticketsize = () => {
    apiService(`admin/dropdownvalues/get?key=${'investmentticketsize'}`, '', 'unauthget')
      .then((result) => {
        if (result && result.data.dropDownValues.values) {
          setticketsize(result.data.dropDownValues.values);
        }
      })
      .catch((err) => { });
  };
  const [selectedOption, setSelectedOption] = useState("");
  const handleTicket = (value) => {
    setValue("ticketSize", value);
    clearErrors("ticketSize");
    setSelectedOption(value);
  };
  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <div className="signup-data_div">
            <div className="forms-title d-flex">
              <p>
                Organization Info {`>`}{" "}
                <span className="path_sapn">More Info</span>
              </p>
              <p className="ml-auto">
                <span className="count_num">4</span>/4
              </p>
            </div>
            <div className="signup_box_div mt-4">
              <form onSubmit={handleSubmit(onSubmit, onError)}>
                <Grid container spacing={1}>
                  {
                    (roleval === 'ANGEL-INVESTOR') ? (
                      <>
                        <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                          <Controller
                            name="preferredStage"
                            control={control}
                            defaultValue=""
                            rules={{ required: true }}
                            render={({
                              field: { onChange, value },
                              fieldState: { error },
                            }) => (
                              <Autocomplete
                                multiple={true}
                                options={startupStages}
                                disableClearable
                                getOptionLabel={(option) => option}
                                defaultValue={[]}
                                value={value || []}
                                onChange={(event, newValue) => onChange(newValue)}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Preferred Stage Of Startups *"
                                    placeholder="Select Preferred Stage Of Startups"
                                    error={!!error}
                                    helperText={
                                      error
                                        ? "Preferred Stage Of Startups is required"
                                        : ""
                                    }
                                  />
                                )}
                                fullWidth
                                margin="normal"
                              />
                            )}
                          />
                          <FormHelperText className="text-danger">
                            {errors.preferredStage &&
                              errors.preferredStage.message}
                          </FormHelperText>
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12} className='mb-3'>
                          {/* <Controller
                            name="ticketSize"
                            control={control}
                            defaultValue=""
                            rules={{ required: "Ticket Size is required" }}
                            render={({ field }) => (
                              <TextField
                                variant="outlined"
                                label="Investment Ticket Size *"
                                placeholder="Enter Ticket Size"
                                fullWidth
                                type="text"
                                {...field}
                                error={Boolean(errors.ticketSize && errors.ticketSize)}
                                onKeyDown={(e) => validateNumberonly(e)}
                                inputProps={
                                  { maxLength: 6, minLength: 1 }
                                }
                              />
                            )}
                          /> */}
                          <Controller
                            name="ticketSize"
                            control={control}
                            defaultValue=""
                            rules={{ required: "Ticket Size is required" }}
                            render={({ field }) => (
                              <Autocomplete
                                {...field}
                                disableClearable
                                options={ticketsize}
                                // value={selectedOption}
                                onChange={(e, selectedOption) =>
                                  handleTicket(selectedOption)
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Investment Ticket Size *"
                                    placeholder="Enter Ticket Size"
                                    error={Boolean(errors.ticketSize && errors.ticketSize)}
                                  />
                                )}
                                fullWidth
                                margin="normal"
                              />
                            )}
                          />
                          <FormHelperText className="text-danger ml-0">
                            {errors.ticketSize && errors.ticketSize.message}
                          </FormHelperText>
                        </Grid></>) : (<></>)}
                  <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                    <Controller
                      name="about"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "Your Expertise is required",
                        validate: {
                          noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                        }
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          id="outlined-multiline-static"
                          label="Brief About Your Expertise *"
                          fullWidth
                          multiline
                          rows={4}
                          defaultValue=""
                          inputProps={
                            { maxLength: 500 }
                          }
                          error={Boolean(errors.about && errors.about)}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.about && errors.about.message}
                    </FormHelperText>
                  </Grid>
                  {
                    (roleval === 'ANGEL-INVESTOR') ? (
                      <>
                        <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                          <Controller
                            name="networkorCoInvest"
                            control={control}
                            defaultValue=""
                            rules={{ required: false }}
                            render={({ field }) => (
                              <FormControl>
                                <FormLabel id="demo-row-radio-buttons-group-label">
                                  Do you want to be a part of a network or co-invest?
                                </FormLabel>
                                <RadioGroup
                                  row
                                  aria-labelledby="demo-row-radio-buttons-group-label"
                                  name="row-radio-buttons-group"
                                  {...field}
                                >
                                  <FormControlLabel
                                    value={true}
                                    control={<Radio />}
                                    label="Yes"
                                  />
                                  <FormControlLabel
                                    value={false}
                                    control={<Radio />}
                                    label="No"
                                  />
                                </RadioGroup>
                              </FormControl>
                            )}
                          />
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                          <Controller
                            name="angelInvesting"
                            control={control}
                            defaultValue=""
                            rules={{ required: false }}
                            render={({ field }) => (
                              <FormControl>
                                <FormLabel id="demo-row-radio-buttons-group-label">
                                  If you're in your early stage as an Investor, do you want to learn the basics of Angel Investing?
                                </FormLabel>
                                <RadioGroup
                                  row
                                  aria-labelledby="demo-row-radio-buttons-group-label"
                                  name="row-radio-buttons-group"
                                  {...field}
                                >
                                  <FormControlLabel
                                    value={true}
                                    control={<Radio />}
                                    label="Yes"
                                  />
                                  <FormControlLabel
                                    value={false}
                                    control={<Radio />}
                                    label="No"
                                  />
                                </RadioGroup>
                              </FormControl>
                            )}
                          />
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                          <Controller
                            name="invested"
                            control={control}
                            defaultValue=""
                            rules={{ required: false }}
                            render={({ field }) => (
                              <FormControl>
                                <FormLabel id="demo-row-radio-buttons-group-label">
                                  Have you ever invested in startups before?
                                </FormLabel>
                                <RadioGroup
                                  row
                                  aria-labelledby="demo-row-radio-buttons-group-label"
                                  name="row-radio-buttons-group"
                                  {...field}
                                >
                                  <FormControlLabel
                                    value={true}
                                    control={<Radio />}
                                    label="Yes"
                                  />
                                  <FormControlLabel
                                    value={false}
                                    control={<Radio />}
                                    label="No"
                                  />
                                </RadioGroup>
                              </FormControl>
                            )}
                          />
                        </Grid></>) : (<></>)}
                  {
                    (roleval !== 'ANGEL-INVESTOR') ? (
                      <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                        <Controller
                          name="onBoarded"
                          control={control}
                          defaultValue=""
                          rules={{ required: false }}
                          render={({ field }) => (
                            <FormControl>
                              <FormLabel id="demo-row-radio-buttons-group-label">
                                Have you onboarded yourself to TANFUND?
                              </FormLabel>
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                {...field}
                              >
                                <FormControlLabel
                                  value={true}
                                  control={<Radio />}
                                  label="Yes"
                                />
                                <FormControlLabel
                                  value={false}
                                  control={<Radio />}
                                  label="No"
                                />
                              </RadioGroup>
                            </FormControl>
                          )}
                        />
                      </Grid>) : (<></>)}
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="text-center"
                  >
                    <button className="next-btn" type="submit">
                      Save
                    </button>
                  </Grid>
                </Grid>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default MoreinfoInvestment
