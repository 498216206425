import React, { useContext } from 'react'
import DataContext from '../../../../../context/DataContext'
import { Button, Dialog, DialogContent } from '@mui/material'

const ProfileCheckDialog = ({ personaName }) => {
    const { navigator, ProfileDialog, setProfileDialog, handleProfileDialogOpen, handleProfileDialogClose } = useContext(DataContext)
    const handleProfileSubmit = () => {
        setProfileDialog(false); // Close the dialog

        // Redirect to respective basic info page based on personaName
        switch (personaName) {
            case 'STARTUP':
                navigator('/startups/basicinfo');
                break;
            case 'ASPIRANT':
                navigator('/aspirants/basicinfo');
                break;
            case 'MENTOR':
                navigator('/mentors/basicinfo');
                break;
            case 'INNOVATOR':
                navigator('/innovators/basicinfo');
                break;
            case 'INVESTOR':
                navigator('/investor/profile');
                break;
            case 'STARTUP-ECO':
                navigator('/ecosystem/basicinfo');
                break;
            case 'STARTUPS-SERVPROV':
                navigator('/service-provider/basicinfo');
                break;
            case 'CORE-TEAM':
                navigator('/admin');
                break;
            default:
                navigator('/login');
        }
    };

    return (
        <div>
            <div>
                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={ProfileDialog}
                // onClose={handleProfileDialogClose}
                >
                    <DialogContent>
                        <div className='text-center'>
                            <img src={'/images/Banner/attention.gif'} alt="warning" className='gif-warn' />
                            {/* <img src={'/images/Banner/attention -man.gif'} alt="warning" className='gif-warn' /> */}
                            <h5 className='my-4'>
                                Please update your profile to proceed booking..!!
                            </h5>
                        </div>
                    </DialogContent>

                    <div className='text-center pb-3'>
                        <Button
                            variant="outlined"
                            className="edit-btn-profile"
                            sx={{
                                textTransform: "none",
                                border: "1px solid",
                                borderColor: "#020671",
                                color: "#020671",
                                padding: "6px 20px",
                                marginRight: "10px"
                            }}
                            onClick={handleProfileDialogClose}
                        >
                            Close
                        </Button>
                        <Button
                            variant="contained"
                            className="edit-btn-profile"
                            sx={{
                                textTransform: "none",
                                border: "1px solid",
                                borderColor: "#020671",
                                color: "#fff",
                                padding: "6px 20px",
                                background: "#00023A",
                                
                            }}
                            onClick={handleProfileSubmit}
                        >
                            Continue
                        </Button>
                    </div>
                </Dialog>
            </div>
        </div>
    )
}

export default ProfileCheckDialog