import React, { useEffect, useRef, useState } from "react";
import {
    Button,
    Container,
    Grid,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const LearningDevelopmentPage = ({ name }) => {
    const navigate = useNavigate();

    const handlenow = () => {
        navigate('/login')
    }

    const handleLearn = () => {
        window.open('https://lnd.startuptn.in/#/', '_blank', 'noopener,noreferrer');
    }

    return (
        <div className="smartCard-page">
            {/* <Navbar /> */}
            <div className="radial-back-layer">
                <div className="round-layers"></div>
                <Container>
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className="banner-text">
                                <div className="sm-banner-chip-text1">
                                    <span>{`${name}  >  Learning and Development`}</span>
                                    <h1 className="banner-text-gradient">Learning and Development</h1>
                                    <h6 className="learn-title">
                                        Empower Your Startup Journey with Knowledge & Growth!
                                    </h6>
                                    <div className="banner-content-actions">
                                        <button className="banner-viewbtn" onClick={handleLearn}>Get in Touch</button>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <div className="smart-card-info5">
                <div className="layer-aadu">
                    <Container>
                        <Grid
                            container
                            direction="row"
                            alignItems="center"
                            justifyContent="center"
                            spacing={2}
                            className="tanseed-info1"
                        >
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <div className="left-side-smartcardPage1">
                                    <h4 className="learningdevelop">What is the Learning and Development program?</h4>
                                    <p className="para-all">
                                        Our Learning & Development Program (LnD) program equips you with the essential knowledge and skills to navigate the startup landscape and achieve sustainable growth.Join us today turn your startup dreams into reality.
                                    </p>
                                </div>
                                <div className="left-content-actions pb-5">
                                    <button className="ser-viewbtn" aria-label="Enroll Now" onClick={handleLearn}>Enroll Now</button>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <div className="smart-crd-img">
                                    <img
                                        className="smartcrdimag img-fluid"
                                        src={"/images/startupLogo.png"}
                                        alt="smart-card"
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
                <div className="join-us-now-sec1">
                    <Container>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <div className="join-us-now-box">
                                    <div className="join-us-now-content">
                                        <h4>Let’s Get Started</h4>
                                        <p>
                                            Empower Your Startup Journey with Knowledge & Growth!
                                        </p>
                                        <Button
                                            variant="contained"
                                            size="large"
                                            className="join-now-btn"
                                            onClick={handlenow}
                                        >
                                            Join us Now
                                        </Button>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </div>
        </div>
    );
};

export default LearningDevelopmentPage;
