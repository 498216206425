import { Grid, Chip, Button } from "@mui/material";
import React, { useContext, useState } from "react";
import { Outlet, useOutletContext } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa6";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import DataContext from "../../../../context/DataContext";
// import apiService from "../../../api/apiService";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";

const SupportTicketList = () => {
  // const searchInputValue = useOutletContext();
  const {
    navigator,
    searchInputValue,
    supportList,
    page,
    setPage,
    totalPage,
    supportistApi,
  } = useContext(DataContext);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [showAll, setShowAll] = useState(false);

  useDidMountEffect(() => {
    supportistApi();
  }, [page, searchInputValue]);

  const visibleOptions = showAll ? supportList : supportList.slice(0, 4);

  const handleViewMore = () => {
    setShowAll(!showAll);

    if (!showAll) {
      setSelectedTicket(null);
    }
  };
  let name = localStorage.getItem("name");
  const handleDetails = (id, i) => {
    setSelectedTicket(i);
    navigator(`startup/support/${name}/${btoa(id)}`);
    // navigator(`user/support/query/${btoa(ids)}`)
  };
  const handleBackBtn = () => {
    ;

    var local = window.location.pathname;

    if (local === `/startup/support/${name}/add`) {
      navigator(`/startup/profile`);
    } else {
      navigator(`startup/support/${name}/add`);
    }
    ;
  };

  const statusMap = {
    0: "Not Assigned",
    1: "Assigned",
    2: "Responded",
    3: "Resolved",
    4: "Reopened",
  };

  const handleChange = (value) => {
    setPage(value);
  };
  return (
    <div>
      <div className="container-fluid main-lists-support">
        <div className="list-divs">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            // spacing={2}
            sx={{ position: "relative" }}
          >
            <Grid item xs={12} sm={12} md={6} lg={6}>
              {supportList.length > 0 ? (
                <>
                  <div className="allticket-list">
                    <Button className="back-btns" onClick={handleBackBtn}>
                      <FaArrowLeft />
                      <span style={{ marginLeft: "5px" }}>Back</span>
                    </Button>
                    <div className="support-history-title">
                      <p>Support History</p>
                    </div>
                  </div>
                  {supportList.map((list, i) => (
                    <div key={i}>
                      <div
                        key={i}
                        className={`msg-boxs ${
                          selectedTicket === i ? "selected" : ""
                        }`}
                        onClick={() => handleDetails(list.id, i)}
                      >
                        <div className="display-data">
                          <div className="ticketNo">
                            <span className="ticket-span">Ticket No</span>
                            <p className="ticket-no-para">{list.ticketNo}</p>
                          </div>
                          <div className="pars-divs">
                            <p className="ticket-para">{list.queryTitle}</p>
                            <span className="funding-span">
                              {list.categoryName}
                            </span>
                          </div>
                          <div className="status-crd">
                            <div className="mb-2">
                              <Chip
                                // label={list.status}
                                label={statusMap[list.status]}
                                sx={{
                                  color:
                                    list.status === 3
                                      ? "#269949"
                                      : list.status === 1
                                      ? "#DFA700"
                                      : list.status === 0
                                      ? "#EB3F3F"
                                      : list.status === 4
                                      ? "#0000ff"
                                      : list.status === 2
                                      ? "#71408a"
                                      : "",
                                  background:
                                    list.status === 3
                                      ? "#E5FFED"
                                      : list.status === 1
                                      ? "#FFF6DC"
                                      : list.status === 0
                                      ? "#FFDADA"
                                      : list.status === 4
                                      ? "#add8e6"
                                      : list.status === 2
                                      ? "#d8bfd8"
                                      : "",
                                  fontSize: "11px",
                                  fontWeight: "600",
                                }}
                              />
                            </div>
                            <div>
                              <span className="date-span">
                                {list.creationTime}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* {
                          supportList.length > 4 && (
                            <div className="viewmore-btns">
                              <Button
                                variant="contained"
                                size="small"
                                sx={{
                                  textTransform: "none",
                                  borderRadius: "15px",
                                  background: "#fff",
                                  color: " #000",
                                  "&:hover": {
                                    background: "#fff",
                                    color: " #000",
                                  },
                                }}
                                onClick={handleViewMore}
                                endIcon={
                                  showAll ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />
                                }
                              >
                                {showAll ? "Show Less" : "View More"}
                              </Button>
                            </div>
                          )
                        } */}
                    </div>
                  ))}
                  {totalPage > 1 && (
                    <div className="event-pagination text-center w-100">
                      <button
                        disabled={page === 1}
                        className="event_round_btn"
                        onClick={() => handleChange(page - 1)}
                      >{`<`}</button>
                      <button
                        disabled={page === totalPage}
                        className="event_round_btn"
                        onClick={() => handleChange(page + 1)}
                      >{`>`}</button>
                    </div>
                  )}
                </>
              ) : (
                <div className="nodata-div">
                  <div className="">
                    <p className="back-btns" onClick={handleBackBtn}>
                      <FaArrowLeft />
                      <span style={{ marginLeft: "5px" }}>Back</span>
                    </p>
                  </div>
                  <div className="nodata-msg">
                    <h1>No Support Ticket Found</h1>
                  </div>
                </div>
              )}
              {/* <div className="nodata-div">
                <div>
                  <p className="back-btns">
                    <FaArrowLeft />
                    <span style={{ marginLeft: "5px" }}>Back</span>
                  </p>
                </div>
                <div className="nodata-msg">
                  <h1>No Support Ticket Found</h1>
                </div>
              </div> */}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} className="pl-3">
              <Outlet></Outlet>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default SupportTicketList;
