import React from 'react'
import { Link } from 'react-router-dom'
import { Grid } from '@mui/material'
import TnFestFooter from './TnFestFooter'
import FastNavbar from './FastNavbar'

const TnfestPrivacypolicy = () => {
    return (
        <>

            <section className="termscond-page">
                <FastNavbar />
                <div className="back-color">
                    <div className="back-layer"></div>
                    <div className="back-layer1"></div>

                    <h1 className='terms-heading mb-3'>Privacy Policy</h1>
                    <div className='container'>
                        <p className='privacypadding'>We value your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and protect your data </p>
                    </div>

                    <div className='term-box'>
                        <div className='container'>
                            <h4 className='blck-clr pt-5'>Data Collection:</h4>
                            <p className='privacypadding'>
                                We collect personal information such as your name, email address, phone number, and payment details when you make a booking or contact us. We also collect information through cookies and similar technologies to enhance your user experience.
                            </p>
                            <h4 className='blck-clr pt-5'>Information Collected Automatically:</h4>
                            <p className='privacypadding'>
                                When you visit our website, we automatically collect information about your device and your use of our site, including IP address, browser type, and pages visited.
                            </p>
                            <h4 className='blck-clr pt-5'>Data Usage:</h4>
                            <p className='privacypadding'>
                                Your data is used to process bookings, communicate with you, improve our services, and personalize your experience. We may use your information to send you promotional offers and updates about upcoming events.
                            </p>
                            <h4 className='blck-clr pt-5'>Marketing Communications:</h4>
                            <p className='privacypadding'>
                              You can opt-out of receiving marketing communications from us by following the unsubscribe link in our emails or contacting us directly.
                            </p>
                            <h4 className='blck-clr pt-5'>Data Protection:</h4>
                            <p className='privacypadding'>
                            We implement robust security measures to protect your personal information from unauthorized access, alteration, or disclosure. This includes encryption, secure servers, and regular security audits.
                            </p>
                            <h4 className='blck-clr pt-5'>Cookies:</h4>
                            <p className='privacypadding'>
                            We use cookies to collect information about your browsing activities to enhance your experience on our site. You can control cookie settings through your browser preferences. Types of Cookies Used: Session cookies, persistent cookies, and third-party cookies. Managing Cookies: Instructions on how to disable cookies through your browser settings.
                            </p>
                            <h4 className='blck-clr pt-5'>Third-Party Services:</h4>
                            <p className='privacypadding'>
                            We may share your data with trusted third-party service providers to facilitate our services, such as payment processing and event logistics. These providers are obligated to protect your information and use it only for the intended purposes.
                            </p>
                            <h4 className='blck-clr pt-5'>Third-Party Links:</h4>
                            <p className='privacypadding'>
                            Our site may contain links to third-party websites. We are not responsible for the privacy practices or content of these sites.
                            </p>
                            <h4 className='blck-clr pt-5'>User Rights:</h4>
                            <p className='privacypadding'>
                            You have the right to access, correct, or delete your personal information held by us. You can also opt-out of receiving marketing communications from us at any time.
                            </p>
                            <h4 className='blck-clr pt-5'>Accessing Your Information:</h4>
                            <p className='privacypadding mb-3'>
                            Instructions on how to access your account information and request corrections or deletions.
                            </p>
                        </div>

                    </div>
                </div>
            </section>
            <TnFestFooter/>
        </>
    )
}

export default TnfestPrivacypolicy