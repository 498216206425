import React from 'react'
import TnFestFooter from './TnFestFooter'
import FastNavbar from './FastNavbar'

const Tnfestticketcollectionpolicy = () => {
    return (
        <>

            <section className="termscond-page">
                <FastNavbar/>
                <div className="back-color">
                    <div className="back-layer"></div>
                    <div className="back-layer1"></div>

                    <h1 className='terms-heading mb-3'>Event Ticket Collection Policy</h1>

                    <div className='term-box'>
                        <div className='container'>
                            <h4 className='blck-clr pt-5'>Ticket Collection at Event:</h4>
                            <p className='privacypadding'>All tickets purchased through event.startuptn.in must be collected at the event venue on the day of the event. Please bring a copy of your booking confirmation email and a valid photo ID for verification.</p>
                            <p className='privacypadding'>Designated ticket collection points will be clearly marked at the venue. Our staff will be available to assist you.</p>

                            <h4 className='blck-clr pt-5'>Identification Requirements:</h4>
                            <p className='privacypadding'>To collect your ticket, you must present a valid photo ID matching the name on the booking confirmation. Acceptable forms of ID include a passport, driver's license, or government-issued ID card.</p>

                            <h4 className='blck-clr pt-5'>Early Collection:</h4>
                            <p className='privacypadding'>If you wish to collect your ticket before the event day, please contact our support team to arrange an early collection at a specified location and time.</p>

                            <h4 className='blck-clr pt-5'>Group Bookings:</h4>
                            <p className='privacypadding'>For group bookings, the primary booking holder must be present with a valid photo ID to collect all tickets. The primary holder is responsible for distributing tickets to group members.</p>

                            <h4 className='blck-clr pt-5'>Lost or Stolen Tickets:</h4>
                            <p className='privacypadding mb-3'>If your ticket is lost or stolen, please contact our support team immediately. Replacement tickets may be issued at our discretion and may incur an additional fee.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <TnFestFooter />
        </>
    )
}

export default Tnfestticketcollectionpolicy