import React, { useContext, useEffect, useRef } from 'react';
import JsBarcode from 'jsbarcode';
import QRCode from 'qrcode.react';
import DataContext from '../../../../context/DataContext';
import { Grid } from '@mui/material';

const Qrcode = ({path}) => {
    // const { showUidNumber } = useContext(DataContext); 
    const barcodeRef = useRef(null);
    const qrRef = useRef(null);

    // Generate the base URL with query parameters
    const baseURL = `${process.env.REACT_APP_REDIRECT_URL}`;
    const userpersonaId = localStorage.getItem('id');
    const userpersona = localStorage.getItem('type');
    const url = `${baseURL}${path}${btoa(userpersona)}/${btoa(userpersonaId)}`;
console.log(url,url)
    useEffect(() => {
        if (barcodeRef.current) {
            JsBarcode(barcodeRef.current, url, {
                format: "CODE128",
                lineColor: "#000",
                width: 2,
                height: 100,
                displayValue: false
            });
        }
    }, [url]);

    const downloadQRCode = () => {
        if (qrRef.current) {
            const canvas = qrRef.current.querySelector('canvas');
            if (canvas) {
                const dataURL = canvas.toDataURL('image/png');
                const link = document.createElement('a');
                link.href = dataURL;
                link.download = 'qrcode.png';
                link.click();
            }
        }
    };

    const downloadBarcode = () => {
        if (barcodeRef.current) {
            const svgElement = barcodeRef.current;
            const serializer = new XMLSerializer();
            const svgString = serializer.serializeToString(svgElement);
            const svgBlob = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' });
            const url = URL.createObjectURL(svgBlob);

            // Create a link element
            const link = document.createElement('a');
            link.href = url;
            link.download = 'barcode.svg';

            // Append the link to the body
            document.body.appendChild(link);
            link.click();

            // Clean up
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        }
    };

    return (
        <div>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                spacing={1}
            >
                <Grid item xs={12} sm={12} md={4} lg={6} xl={6}>
                    <div className="code-container" ref={qrRef}>
                        <h3 className="text-center">Your QR</h3>
                        <QRCode value={url} size={150} level="H" />
                        {url ? <span className="download-button" onClick={downloadQRCode}>Download</span> : null}
                    </div>
                </Grid>
                {/* <Grid item xs={12} sm={12} md={4} lg={6} xl={6}>
                    <div className="code-container">
                        <h3 className="text-center">Your Barcode</h3>
                        <div>
                            <svg ref={barcodeRef}></svg>
                        </div>
                        {url ? <span className="download-button2" onClick={downloadBarcode}>Download</span> : null}
                    </div>
                </Grid> */}
            </Grid>
        </div>
    );
};

export default Qrcode;
