import { Button, Container } from '@mui/material'
import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import DataContext from '../../../context/DataContext'

const Joinuspartnerstatus = () => {
  const { id ,smartcardid} = useParams()
  const { navigator} = useContext(DataContext);
  let ids
  if (id !== null) {
    ids = atob(id)
  }
const handleClose = ()=>{
    localStorage.setItem('smartCard', 'join')
    navigator(`/smartcard/${btoa(ids)}/profile`)
}
  return (
    <Container>
    <div className='joinus-design'>
        <div className='title-joinus text-center'>
            <h2 className='join-h3 mb-2'>Join as a Service Partner</h2>
            <p>Join our network of trusted service partners and grow together.</p>
            <div className='my-3'>
                <img src="/images/join-us/third.png" alt="tab"/>
            </div>
            <h6><b>Thank You for Choosing to Partner with Us!</b></h6>
            <div className='text-center '>
                <img src={' /images/join-us/Thankyou.png'} alt="images" className=' my-5' />
                <div >
                    <h6><b>Your Application is Under Review!</b></h6>
                    <div className='Nothing my-4'>
                        <p className='mb-0'>Our team is currently reviewing your submission. We’ll update you with the next steps shortly.</p>
                        {/* <p> Start by adding your first offering to showcase what you can provide.</p>
                    </div> */}
                </div>
                <div className='join-prod-act pb-5'>
                    <Button variant='contained' size='large' sx={{ background: "#0B40AA", textTransform: 'none' }} onClick={()=>handleClose()}>Close</Button>
                </div>
            </div>
        </div>

    </div>
</div>
    </Container>
  )
}

export default Joinuspartnerstatus