import React from 'react'
import FastNavbar from './FastNavbar'
import TnFestFooter from './TnFestFooter'
import { useParams } from 'react-router-dom'

const FestAgenda = () => {
    const { name } = useParams()
    return (
        <>
            <FastNavbar />
            <section>
                {
                    name === 'day1' && (
                        <img src='https://startuptn-public-files.s3.ap-south-1.amazonaws.com/AgendaDay1.png' className='img-fluid w-100' />
                    )
                }
                {
                    name === 'day2' && (
                        <img src='https://startuptn-public-files.s3.ap-south-1.amazonaws.com/AgendaDay2.png' className='img-fluid w-100' />
                    )
                }
            </section>
            <TnFestFooter />
        </>
    )
}

export default FestAgenda