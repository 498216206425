import React, { useContext, useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import MeetingFilter from './MeetingFilter'
import { Container, Grid, Pagination, Stack, Tabs } from "@mui/material";
import DataContext from "../../../context/DataContext";
import CustomCalendar from "../../common-pages/CustomCalendar";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import apiService from "../../../api/apiService";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
const MeetingList = () => {
  const { navigator, fullScreen, CustomTabPanel, setPageLoading, startDate, setStartDate, endDate, setEndDate, hasPrivilege } = useContext(DataContext);

  const [tabValue, setTabValue] = useState("1");
  // const [disabled, setDisabled] = useState(true);
  // const [tabName, setTabName] = useState("Upcoming Meetings");
  const [totalPage, setTotalPage] = useState(1);
  const [page, setPage] = useState(1);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [meetingsData, setMeetingsData] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState("All")
  const [sortBy, setSortBy] = useState(true)
  const [activemainTabs, setActiveMainTabs] = useState(0);
  const [departmentId, setDepartmentId] = useState(0);
  useDidMountEffect(() => {
    listApi();
  }, [searchInputValue, page, selectedPeriod, startDate, endDate, sortBy, departmentId, tabValue]);
  const listApi = () => {
    setPageLoading(true)
    setMeetingsData([])
    var data = {
      listSize: 10,
      pageNumber: page,
      searchString: searchInputValue,
      period: selectedPeriod,
      startDate: startDate ? (startDate + 'T00:00:00.174Z') : '',
      endDate: endDate ? (endDate + 'T00:00:00.174Z') : '',
      sort: sortBy ? 'asc' : 'desc',
      upComingEvent: tabValue === '1' ? true : false,
      departmentId: departmentId
    };
    apiService("meeting/list", data, "post")
      .then((result) => {
        setPageLoading(false)
        if (result && result.data) {
          setTotalPage(result.data.totalPages);
          if (result.data.responseDTOList) {
            setMeetingsData(result.data.responseDTOList);
          }
        }
      })
      .catch((err) => {
        console.error("Error fetching events:", err);
      });
  }
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const handleChange = (event, value) => {
    setPage(value);
  };
  const handleEditBtn = (id) => {
    navigator(`admin/meeting/update/${btoa(id)}`)
  }
  const handleDeleteBtn = (id) => {
    apiService(`meeting/remove?id=${id}`, '', "delete").then((result) => {
      if (result && result.data && result.data.responseStatus === "Success") {
        listApi();
      }
    }).catch((err) => {

    });
  }
  return (
    <div>
      <MeetingFilter
        setSearchInputValue={setSearchInputValue}
        setSelectedPeriod={setSelectedPeriod} selectedPeriod={selectedPeriod}
        setSortBy={setSortBy} sortBy={sortBy}
        setDepartmentId={setDepartmentId}
        setActiveMainTabs={setActiveMainTabs} activemainTabs={activemainTabs}
      />
      {/* <TabContext value={tabValue} className="tab-div"> */}
      <div className="meeting-filter-bg">
        <Box className="ml-3">
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab
              label="Upcoming Meetings"
              value="1"
              style={{
                backgroundColor: tabValue === "1" ? "transparent" : "",
                color: tabValue === "1" ? "#0061D4" : "#000",
                borderBottom: tabValue === "1" ? "3px solid #0061D4" : '',
                borderRight: "1px solid #ccc",
                borderRadius: '0px'
              }}
              className="meeting-tabs"
            />
            <Tab
              label="Past Meetings"
              value="2"
              style={{
                backgroundColor: tabValue === "2" ? "transparent" : "",
                color: tabValue === "2" ? "#0061D4" : "#000",
                borderBottom: tabValue === "2" ? "3px solid #0061D4" : '',
                borderRadius: '0px'
              }}
              className="setFont"
            />
          </Tabs>
        </Box>
      </div>
      <CustomTabPanel value={activemainTabs} index={0}>
        <div>
          {
            meetingsData.length > 0 ? (
              <>
                <Grid
                  container
                  direction="row"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  spacing={2}
                >
                  {
                    meetingsData.map((list, index) => (
                      <Grid item lg={5} md={6} xs={12} sm={12}>
                        <div className="meeting-pending-details">
                          <h5 className="mb-4">{list.title}</h5>
                          <Grid
                            container
                            direction="row"
                            alignItems="flex-start"
                            justifyContent="flex-start"
                            spacing={2}
                          >
                            <Grid item lg={6} md={6} xs={12} sm={12}>
                              <div>
                                <span>Start Time</span>
                                <p>{list.startDate} , {list.startTime}</p>
                              </div>
                            </Grid>
                            <Grid item lg={6} md={6} xs={12} sm={12}>
                              <div>
                                <span>End Time</span>
                                <p>{list.endDate} , {list.endTime}</p>
                              </div>
                            </Grid>
                            <Grid item lg={6} md={6} xs={12} sm={12}>
                              <div>
                                <span>Venue</span>
                                <p>{list.venue}</p>
                              </div>
                            </Grid>
                            <Grid item lg={6} md={6} xs={12} sm={12}>
                              <div>
                                <span>Department</span>
                                <p>{list.department}</p>
                              </div>
                            </Grid>
                          </Grid>

                          <div className="d-flex align-items-center">
                            <div>
                              <button className="add-calender-btn ">Add to Calender</button>
                              <button className="meeting-join-btn ml-3">Join</button>
                            </div>

                            <div className="ml-auto">
                              {
                                hasPrivilege('18') && (
                                  <EditIcon
                                    sx={{
                                      cursor: "pointer",
                                      marginLeft: "5px",
                                      color: 'green'
                                    }}
                                    onClick={() => handleEditBtn(list.id)}
                                  />
                                )
                              }
                              {
                                hasPrivilege('19') && (
                                  <DeleteForeverIcon
                                    sx={{
                                      cursor: "pointer",
                                      marginLeft: "5px",
                                      color: 'red'
                                    }}
                                    onClick={() => handleDeleteBtn(list.id)}
                                  />
                                )
                              }
                            </div>
                          </div>
                        </div>
                      </Grid>
                    ))
                  }
                </Grid>
                {
                  totalPage > 1 && (
                    <div className="event-pagination">
                      <Stack spacing={2}>
                        <Pagination
                          count={totalPage}
                          page={page}
                          onChange={handleChange}
                        />
                      </Stack>
                    </div>
                  )
                }
              </>

            ) : (
              <>
                <div className='text-center nodatacss_admin'>
                  <h5 className='mb-4'>Meeting data not found</h5>
                  <div>
                    <img src="/images/blue horizontal.png" alt="blue horizontal.png" className='logo_dash img-fluid' />
                  </div>
                  <div className='mt-5'>
                    <h3 className='title_p opa'>
                      StartupTN 3.0
                    </h3>
                  </div>
                </div>
              </>
            )
          }
        </div>
      </CustomTabPanel>
      <CustomTabPanel value={activemainTabs} index={1}>
        <CustomCalendar />
      </CustomTabPanel>
      {/* </TabContext> */}

    </div >
  )
}

export default MeetingList