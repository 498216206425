import { Pagination, Stack, Grid } from "@mui/material";
import React, { useContext, useState } from "react";
import apiService from "../../../../api/apiService";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import DataContext from "../../../../context/DataContext";
import Mediafilter from "../Mediafilter";
import Norecordsfound from "../../../common-pages/Norecordsfound";

const Galleryvideo = () => {
  const { setPageLoading } = useContext(DataContext)
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [categoryId, setCategoryId] = useState("");
  useDidMountEffect(() => {
    listApi();
  }, [page, searchInputValue, categoryId]);
  const [eventList, setEventList] = useState([]);

  const listApi = () => {
    setEventList([])
    setPageLoading(true)
    var req = {
      searchString: searchInputValue,
      categoryId: categoryId,
      listSize: 12,
      pageNumber: page,
      mediaType: 'video'
    };
    apiService(`media/gallery/home/list`, req, "unauthpost")
      .then((result) => {
        setPageLoading(false)
        if (result && result.data) {
          if (result.data.media) {
            setEventList(result.data.media);
            setTotalPage(result.data.totalPages)
          }
        }
      })
      .catch((err) => { });
  };
  const handleChange = (event, value) => {
    setPage(value);
  };

  function getYoutubeVideoId(url) {
    const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regex);
    return match ? match[1] : '';
  }

  return (
    <>
      <div className="event-title">
        <h2 className="event-titles">Gallery Videos</h2>
        <div className="event-line">
          <img src="/images/event-line.png" alt="" />
        </div>
      </div>
      <Grid container direction="row" alignItems="flex-start" justifyContent="center" >
        {/* <Grid item sm={12} md={3} lg={3}><Mediafilter setSearchInputValue={setSearchInputValue} setCategoryId={setCategoryId} type={'media'} seachLableName={'Enter Keywords'} /></Grid> */}
        <Grid item sm={12} md={12} lg={12} >
          <div className="container">
            <Grid container direction="row" alignItems="flex-start" justifyContent="flex-start" spacing={2}>
              {eventList.length > 0 ?
                eventList.map((item, index) => (
                  <Grid item sm={12} md={6} lg={6} key={index}>
                    <div className="gallery-list">
                      <div className="p-relative">
                        {/* <img src={item.fileName} className="gallery-img img-fluid" alt="" /> */}
                        <iframe
                          className="gallery-img img-fluid"
                          src={`https://www.youtube.com/embed/${getYoutubeVideoId(item.fileName)}`}
                        ></iframe>
                        <div className="gallery-title">
                          <p>{item.title}</p>
                        </div>
                      </div>
                    </div>
                  </Grid>
                )) : (
                  <Norecordsfound name="Gallery Videos Not Found" />
                )
              }

            </Grid>
            {totalPage > 1 ? (
              <div className="event-pagination">
                <Stack spacing={2}>
                  <Pagination
                    count={totalPage}
                    page={page}
                    onChange={handleChange}
                  />
                </Stack>
              </div>
            ) : (<></>)}
          </div>
        </Grid>
      </Grid>
    </>

  );
};


export default Galleryvideo
