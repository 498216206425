import React from 'react'
import Navbar from '../navbar-page/Navbar'
import Footer from '../footer-page/Footer'

const TermsAndCondition = () => {
    return (
        <>
            <section className="termscond-page">
                <Navbar />
                <div className="back-color">
                <div className="back-layer"></div>
                <div className="back-layer1"></div>
                <h3 className='terms-heading mb-3'>Terms and Conditions</h3>

                <div className='term-box'>
                    <div className='container'>
                        <p className='pt-5'>StartupTN portal has been created to provide information to the general public. The documents and information displayed in this
                            portal are for reference purposes only and does not purport to be legal document.</p>
                        <p className='pt-3'>
                            StartupTN or Government of Tamil Nadu accepts no responsibility with regard to the accuracy or completeness of the
                            information, text, graphics, links or other items contained within the StartupTN  portal. As a result of updates
                            and corrections, the web contents are subject to change regularly. In no event, StartupTN or Government
                            of Tamil Nadu will be liable for any loss, damage, liability or expenses incurred or suffered that is
                            claimed to have resulted from use of this portal, including, without limitation, any fault, virus, error,
                            omission, interruption or delay, with respect thereto, direct or indirect.
                        </p>
                        <p className='pt-3'>
                            The information posted on this portal could include hypertext links or pointers to information created and
                            maintained by non-Government/private organisations. StartupTN is providing these links and pointers solely for
                            user information and convenience. When users select a link to an outside website, they are leaving the StartupTN
                            portal and are subject to the privacy and security policies of the owners/sponsors of the outside website/ portal.
                            StartupTN or Government of Tamil Nadu does not guarantee the availability of such linked pages at all times
                            nor does necessarily endorse the views expressed therein.
                        </p>
                        <p className='py-3'>
                            These terms and conditions shall be governed by and construed in accordance with the Indian Laws. Any dispute 
                            arising under these terms and conditions shall be subject to the jurisdiction of the courts of India.
                        </p>
                    </div>
                </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default TermsAndCondition