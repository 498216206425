import React, { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import { Autocomplete, InputBase, Menu, MenuItem, TextField, ToggleButton, Typography, styled } from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import CustomDateRangePicker from "../../common-pages/CustomDateRangePicker";
import DataContext from "../../../context/DataContext";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import apiService from "../../../api/apiService";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "24px",
  backgroundColor: "rgb(255 255 255)",
  border: "1px solid #00000017",
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#d9d9d9",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "19ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const ReportFilter = ({
  setSearchInputValue,
  setSortBy, sortBy,
  setDepartmentId
}) => {
  const { setStartDate, setEndDate, hasPrivilege } = useContext(DataContext);
  useDidMountEffect(() => {
    departmentListApi()
  }, [])
  const [departmentList, setDepartmentList] = useState([])
  const departmentListApi = () => {
    apiService(`department/list`, {}, 'post').then((result) => {
      if (result && result.data && result.data.department) {
        let data = []
        const val = {
          "id": 0,
          "name": 'All',
        }
        data.push(val)
        result.data.department.forEach(element => {
          data.push(element)
        });
        setDepartmentList(data)
      } else {
        let data = []
        const val = {
          "id": 0,
          "name": 'All',
        }
        data.push(val)
        setDepartmentList(data)
      }
    })
  }
  const [departmentName, setDepartmentName] = useState('All')
  const handleDepartment = (val) => {
    setDepartmentName(val)
    var depValue = departmentList.filter((list) => list.name === val)
    setDepartmentId(depValue[0].id)
  }

  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleSearch = (e) => {
    setSearchInputValue(e.target.value);
  };

  const handleOpenSortMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleSortBy = (value) => {
    setSortBy(value);
    handleCloseUserMenu();
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  return (
    <div>
      <div className="double-clr">
        <div className="frt-gry">
          <div className="main-tab-adminpage">
            <NavLink className="report-sup-btn" >
              Documents & Reports
            </NavLink>
          </div>
          <div></div>
        </div>
        <div className="sec-clrk">
          {
            hasPrivilege('40') && (
              <div className="sec-btn-bg">
                <Autocomplete
                  size="small"
                  disableClearable
                  disablePortal
                  id="combo-box-demo"
                  value={departmentName}
                  options={departmentList.map((val) => val.name)}
                  onChange={(e, selectedOptions) =>
                    handleDepartment(selectedOptions)
                  }
                  className="meetingListDropdown"
                  renderInput={(params) => <TextField {...params} label="Choose Department" />}
                />
              </div>

            )
          }

          <div className="sec-filtersk ml-auto">
            <div className="search-div">
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search Reports"
                  inputProps={{ "aria-label": "search" }}
                  onChange={handleSearch}
                  type="search"
                />
              </Search>
            </div>
            <div>
              <CustomDateRangePicker
                setStartDate={setStartDate}
                setEndDate={setEndDate}
              />
            </div>
            <div>
              <ToggleButton className="toggle-fil" onClick={handleOpenSortMenu}>
                <img src="/images/filter icon.svg" alt="Filter" />
              </ToggleButton>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem onClick={() => handleSortBy(true)}>
                  <Typography
                    textAlign="center"
                    sx={{ color: sortBy === true ? "#0061D4" : "" }}
                  >
                    Start Date Ascending
                  </Typography>
                </MenuItem>
                <MenuItem onClick={() => handleSortBy(false)}>
                  <Typography
                    textAlign="center"
                    sx={{ color: sortBy === false ? "#0061D4" : "" }}
                  >
                    Start Date Descending
                  </Typography>
                </MenuItem>
              </Menu>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportFilter;
