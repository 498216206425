import { Autocomplete, Box, Button, Divider, FormControl, FormHelperText, InputBase, InputLabel, Menu, MenuItem, Select, TextField, ToggleButton, Typography, styled } from '@mui/material'
import React, { useContext, useState } from 'react'
import AddIcon from '@mui/icons-material/Add';
import { NavLink, useLocation } from 'react-router-dom';
import DataContext from '../../../context/DataContext';
import CustomDateRangePicker from '../../common-pages/CustomDateRangePicker';
import { Controller, useForm } from 'react-hook-form';
import apiService from '../../../api/apiService';
import useDidMountEffect from '../../../hooks/useDidMountEffect';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: '24px',
  backgroundColor: 'rgb(255 255 255)',
  border: '1px solid #00000017',
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#d9d9d9',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('sm')]: {
      width: '19ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

const BudgetFilter = ({
  setSearchInputValue,
  setSelectedPeriod, selectedPeriod,
  setSortBy, sortBy,
  setDepartmentId,
  name
}) => {

  const { navigator, hasPrivilege } = useContext(DataContext);

  const handleReadStatus = (value) => {
    setSelectedPeriod(value)
  }

  const handleSearch = (e) => {
    setSearchInputValue(e.target.value);
  };

  const [anchorElUser, setAnchorElUser] = useState(null);
  const handleOpenSortMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleSortBy = (value) => {
    setSortBy(value)
    handleCloseUserMenu();
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleAddBudget = () => {
    navigator('/admin/budget/add')
  }

  useDidMountEffect(() => {
    departmentListApi()
  }, [])

  const [departmentList, setDepartmentList] = useState([])
  const departmentListApi = () => {
    apiService(`department/list`, {}, 'post').then((result) => {
      if (result && result.data && result.data.department) {
        let data = []
        const val = {
          "id": 0,
          "name": 'All',
        }
        data.push(val)
        result.data.department.forEach(element => {
          data.push(element)
        });
        setDepartmentList(data)
      } else {
        let data = []
        const val = {
          "id": 0,
          "name": 'All',
        }
        data.push(val)
        setDepartmentList(data)
      }
    })
  }

  const handleDepartment = (val) => {
    var depValue = departmentList.filter((list) => list.name === val)
    setDepartmentId(depValue[0].id)
  }

  return (
    <>
      <section>
        <div className='container-fluid p-0'>
          <div className='double-clr'>
            <div className='frt-gry'>
              <div>
                {
                  hasPrivilege('34') && (
                    <NavLink className="startup-sup-btn1" to={'/admin/budget/allocation'}>Budget Allocation</NavLink>
                  )
                }

                {/* {
                  hasPrivilege('38') && (
                    <NavLink className="startup-sup-btn1" to={'/admin/budget/report'}>Budget Reports</NavLink>
                  )
                } */}

              </div>
              {
                hasPrivilege('35') && (
                  <div>
                    <Button
                      variant="contained"
                      startIcon={<AddIcon />}
                      onClick={handleAddBudget}
                      sx={{
                        textTransform: "none",
                        background: "#0061D4",
                        borderRadius: "10px",
                      }}>Add Budget</Button>
                  </div>
                )
              }

            </div>
            <div className='sec-clr'>
              {/* <div className='sec-btn-bg'>
                {name === 'startup' && (
                  <div className='all-btn-bg' >
                    <button className={supportType === 'All Support' ? 'sec-btn-1 active' : 'sec-btn-1'} onClick={() => handleSupportType('All Support')}>All Support</button>
                    <button className={supportType === 'Project Support' ? 'sec-btn-1 active' : 'sec-btn-1'} onClick={() => handleSupportType('Project Support')}>Project Support</button>
                    <button className={supportType === 'Events Support' ? 'sec-btn-1 active' : 'sec-btn-1'} onClick={() => handleSupportType('Events Support')}>Events Support</button>
                  </div>
                )}
                {name === 'team' && (
                  <div className='all-btn-bg' >
                    <button className={supportType === 'Team StartupTN' ? 'sec-btn-1 active' : 'sec-btn-1'} onClick={() => handleSupportType('Team StartupTN')}>Team StartupTN</button>
                    <button className={supportType === 'PF2' ? 'sec-btn-1 active' : 'sec-btn-1'} onClick={() => handleSupportType('PF2')}>PF2</button>
                    <button className={supportType === 'Mafia' ? 'sec-btn-1 active' : 'sec-btn-1'} onClick={() => handleSupportType('Mafia')}>Mafia</button>
                  </div>
                )}
              </div> */}
              {
                hasPrivilege('44') && (
                  <div className="sec-btn-bg">
                    <Autocomplete
                      size="small"
                      disableClearable
                      disablePortal
                      id="combo-box-demo"
                      options={departmentList.map((val) => val.name)}
                      onChange={(e, selectedOptions) =>
                        handleDepartment(selectedOptions)
                      }
                      className="meetingListDropdown"
                      renderInput={(params) => <TextField {...params} label="Choose Department" />}
                    />
                  </div>
                )
              }
              <div className='sec-filters-sur ml-auto'>
                <div className='frt-fil'>
                  <div className='all-btn-bg' >
                    <button className={selectedPeriod === 0 ? 'btn-fil active' : 'btn-fil'} onClick={() => handleReadStatus(0)}>All</button>
                    <button className={selectedPeriod === 1 ? 'btn-fil active' : 'btn-fil'} onClick={() => handleReadStatus(1)}>Unread</button>
                    <button className={selectedPeriod === 2 ? 'btn-fil active' : 'btn-fil'} onClick={() => handleReadStatus(2)}>Closed</button>
                  </div>
                </div>
                <Search>
                  <SearchIconWrapper>
                    <img src="/images/admin/search-black.png" alt="" />
                  </SearchIconWrapper>
                  <StyledInputBase
                    placeholder="Search budget"
                    inputProps={{ 'aria-label': 'search' }}
                    onChange={handleSearch}
                    type="search"
                  />
                </Search>
                {/* <div >
                  <CustomDateRangePicker setStartDate={setStartDate} setEndDate={setEndDate} />
                </div>
                <div>
                  <FormControl
                    variant="outlined"
                    size="small"
                    sx={{ width: 120 }}
                  >
                    <InputLabel id="dropdown-label">Duration</InputLabel>
                    <Select
                      labelId="dropdown-label"
                      label="Select Option"
                      value={selectedSupportPeriod}
                      variant="outlined"
                      style={{ borderRadius: 10 }}
                      onChange={(e) => handleSelectPeriod(e.target.value)}
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      <MenuItem value={"This Week"}>This Week</MenuItem>
                      <MenuItem value={"This Month"}>This Month</MenuItem>
                    </Select>
                  </FormControl>
                </div> */}
                <div>
                  <ToggleButton className="toggle-fil" onClick={handleOpenSortMenu} >
                    {<img src="/images/filter icon.svg" alt="" />}
                  </ToggleButton>
                  <Menu
                    sx={{ mt: '45px' }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    <MenuItem onClick={() => handleSortBy(true)}>
                      <Typography textAlign="center" sx={{ color: sortBy === true ? '#0061D4' : '' }}>StartDate Ascending</Typography>
                    </MenuItem>
                    <MenuItem onClick={() => handleSortBy(false)}>
                      <Typography textAlign="center" sx={{ color: sortBy === false ? '#0061D4' : '' }}>StartDate Descending</Typography>
                    </MenuItem>
                  </Menu>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default BudgetFilter