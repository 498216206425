import { Divider } from "@mui/material";
import React from "react";

const DeptAboutUs = ({ departmentValue }) => {
  return (
    <div>

      {/* <p className="d-tabf1-p mt-5"> 
          Inclusivity, Innovation, and Entrepreneurship define the core
          principles of Our Ethos.
        </p> */}
      <div dangerouslySetInnerHTML={{ __html: departmentValue.about }}></div>
      {/* <p>
        {}
      </p> */}
      {/* <p>
          <span>Entrepreneurship :</span> Entrepreneurship is the engine of
          economic growth and social change. We propel individuals to pursue
          their passions, take calculated risks, and turn their ideas into
          viable ventures and provide the support, resources, and guidance
          needed to help entrepreneurs navigate the startup journey and scale.
        </p>
        <p>
          <span>Innovation :</span> Innovation is at the heart of everything we
          do. We encourage experimentation, risk-taking, creative thinking,
          empowering individuals and teams to explore, iterate, and iterate
          again until they achieve breakthrough innovations that have a positive
          impact on society.
        </p>
        <p className="bold-para-text">
          StartupTN envisions Tamil Nadu to be an all inclusive - Global
          Innovation hub and stand as an exemplar of Startup Ecosystem for other
          nations to emulate
        </p> */}
      <Divider />
      <p className="dep-branch-name"> <span className="mt-3 mr-5">Branch / Hubs</span>{departmentValue.branchCities ? departmentValue.branchCities.toLocaleString() : '-'} </p>
    </div>

  );
};

export default DeptAboutUs;
