import React, { useContext, useState } from 'react';
import { Checkbox, Container, FormControlLabel, FormHelperText, styled } from '@mui/material'
import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Controller, useForm } from 'react-hook-form';
import apiService from '../../api/apiService';
import notifyService from '../../api/notifySerivce';
import cryptoJS from '../../hooks/cryptoJS';
import DataContext from '../../context/DataContext';

const GreenCheckbox = styled(Checkbox)(({ theme }) => ({
    '&.Mui-checked': {
      color: 'green',
    },
  }));
  
const SmallFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
    '& .MuiFormControlLabel-label': {
      fontSize: '0.67rem',
    },
  }));

const Changepassword = ({ handlePasswordClose }) => {
    const { setPageLoading, validatePassword } = useContext(DataContext)
    const { handleSubmit, control, reset, formState: { errors } } = useForm({});
    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const [showRePassword, setShowRePassword] = React.useState(false);
    const handleClickShowRePassword = () => setShowRePassword((show) => !show);
    const [checkSamePassword, setCheckSamePassword] = useState(false)
    const [password, setPassword] = useState('');
    const isPasswordValid = () => {
        return (
            password.length >= 8 &&
            /[A-Z]/.test(password) &&
            /\d/.test(password) &&
            /[!@#$%^&*(),.?":{}|<>]/.test(password)
        );
    };
    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };
    const onSubmit = (data) => {
        if (data.oldPassword !== data.password) {
            if (data.password === data.reenterPassword) {
                setPageLoading(true);
                setCheckSamePassword(false)
                var getform = data
                getform.oldPassword = cryptoJS(data.oldPassword)
                getform.password = cryptoJS(data.password)
                apiService(`password/change`, getform, 'post').then((res) => {
                    setPageLoading(false);
                    if (res) {
                        if (res.data.responseStatus === "Success") {
                            notifyService('success', 'Success', '')
                            handleCancel()
                        }
                    }
                })
            } else {
                setCheckSamePassword(true)
            }
        } else {
            notifyService('danger', 'Invaild', 'New password can not be same as old password')
        }
    }
    const handleCancel = () => {
        reset({})
        handlePasswordClose()
    }
    return (
        <section className='p-4'>
            <Container>
                <div className='header_tag text-center'>
                    <h5 className='c-black'>Change Password</h5>
                </div>
                <div className=''>
                    <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
                        <div className='row'>
                            <div className='col-lg-12 col-12 mb-3'>
                                <div className="form-group mb-3">
                                    <Controller name="oldPassword" control={control} defaultValue="" rules={{ required: 'Old Password is required' }}
                                        render={({ field }) =>
                                            <TextField
                                                variant='outlined'
                                                label="Old Password"
                                                placeholder="Enter Old Password"
                                                autoFocus
                                                fullWidth
                                                type='password'
                                                {...field}
                                            />} />
                                    <FormHelperText className='text-danger'>{errors.oldPassword && errors.oldPassword.message}</FormHelperText>
                                </div>
                            </div>
                            <div className='col-lg-6 col-12 mb-3'>
                                <div className="form-group mb-3">
                                    <Controller name="password" control={control} defaultValue="" rules={{ required: 'New Password is required', validate: validatePassword }}
                                        render={({ field }) =>
                                            <TextField
                                                variant='outlined'
                                                label="New Password"
                                                placeholder='Enter New Password'
                                                fullWidth
                                                autoComplete='password'
                                                {...field}
                                                onChange={(e) => {
                                                    field.onChange(e);
                                                    handlePasswordChange(e);
                                                  }}
                                                type={showPassword ? 'text' : 'password'}
                                                inputProps={{ maxLength: 15 }}
                                                InputProps={{
                                                    endAdornment:
                                                        <IconButton
                                                            onClick={handleClickShowPassword}
                                                        >{showPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                }}
                                            />
                                        }
                                    />
                                    <FormHelperText className='text-danger'>{errors.password && errors.password.message}</FormHelperText>
                                </div>
                            </div>
                            <div className='col-lg-6 col-12 mb-3'>
                                <div className="form-group mb-3">
                                    <Controller name="reenterPassword" control={control} defaultValue="" rules={{ required: 'Re-Enter Password is required', validate: validatePassword }}
                                        render={({ field }) =>
                                            <TextField
                                                variant='outlined'
                                                label="Re-Enter Password"
                                                placeholder='Enter Re-Enter Password'
                                                fullWidth
                                                autoComplete='password'
                                                {...field}
                                                type={showRePassword ? 'text' : 'password'}
                                                inputProps={{ maxLength: 15 }}
                                                InputProps={{
                                                    endAdornment:
                                                        <IconButton
                                                            onClick={handleClickShowRePassword}
                                                        >{showRePassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                }}
                                            />
                                        }
                                    />
                                    <FormHelperText className='text-danger'>{errors.reenterPassword && errors.reenterPassword.message}</FormHelperText>
                                    <FormHelperText className='text-danger'>{(checkSamePassword === true ? ('Password Mismatch') : (<></>))}</FormHelperText>
                                </div>
                            </div>
                        </div>
                        <table>
                            <tr>
                                <td>
                                    <SmallFormControlLabel
                                        control={
                                            <GreenCheckbox
                                                size="small"
                                                checked={password.length >= 8}
                                                disabled
                                            />
                                        }
                                        label="Should be 8 Characters Long"
                                    />
                                </td>
                                <td>
                                    <SmallFormControlLabel
                                        control={
                                            <GreenCheckbox
                                                size="small"
                                                checked={/[A-Z]/.test(password)}
                                                disabled
                                            />
                                        }
                                        label="Atleast One UpperCase Letter"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <SmallFormControlLabel
                                        control={
                                            <GreenCheckbox
                                                size="small"
                                                checked={/\d/.test(password)}
                                                disabled
                                            />
                                        }
                                        label="Atleast One Number"
                                    />
                                </td>
                                <td>
                                    <SmallFormControlLabel
                                        control={
                                            <GreenCheckbox
                                                size="small"
                                                checked={/[!@#$%^&*(),.?":{}|<>]/.test(password)}
                                                disabled
                                            />
                                        }
                                        label="Atleast One Special Character ( - , _ , @ , * )"
                                    />
                                </td>
                            </tr>
                        </table>
                        <div className="form-group text-center">
                            <Button onClick={handlePasswordClose} variant="outlined" className='form-btn'>Cancel</Button>
                            <Button
                                type="submit"
                                variant="contained"
                                className='form-btn b-green-btn ml-3'
                                disabled={!isPasswordValid()}
                            >
                                <span>Change</span>
                            </Button>
                        </div>
                    </form>
                </div>
            </Container>
        </section>
    )
}

export default Changepassword