import React, { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import { Autocomplete, Button, Dialog, DialogContent, DialogTitle, FormHelperText, InputBase, TextField, styled } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import DataContext from "../../../context/DataContext";
import apiService from "../../../api/apiService";
import { LoadingButton } from "@mui/lab";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { Controller, useForm } from "react-hook-form";
import notifyService from "../../../api/notifySerivce";
import cryptoJS from "../../../hooks/cryptoJS";
import ExcelToMail from "../common-page/ExcelToMail";

const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: "24px",
    backgroundColor: "rgb(255 255 255)",
    border: "1px solid #00000017",
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
    },
}));
const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#d9d9d9",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    width: "100%",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create("width"),
        [theme.breakpoints.up("sm")]: {
            width: "19ch",
            "&:focus": {
                width: "20ch",
            },
        },
    },
}));
const Servicepartnersfilter = ({ servicePartnerList, setSearchInputValue, rowsPerPage, selectedPersonas, setSelectedPersonas, setSortBy, setFilterVal, filterVal, page, searchInputValue }) => {
    const { setStartDate, setEndDate, hasPrivilege, setPageLoading } = useContext(DataContext);
    const { handleSubmit, control, reset, formState: { errors } } = useForm({});

    const [loading, setLoading] = useState(false);
    const [emailModal, setEmailModal] = useState(false);
    const [fullWidth, setFullWidth] = useState(true);
    const [maxWidth, setMaxWidth] = useState('sm');

    const handleSearch = (e) => {
        setSearchInputValue(e.target.value);
    };


    // const servicePartnerDatas = [
    //     { id: 0, status: "All" },
    //     { id: 1, status: "Requested" },
    //     { id: 2, status: "Approved" },
    //     { id: 3, status: "Rejected" }
    // ];

    const servicePartnerDatas = [
        { id: 1, status: "Pending" },
        { id: 2, status: "Approved" },
        { id: 3, status: "Rejected" }
    ];

    const handleservice = (id) => {
        setFilterVal(id);
    };

    const handleEmailClose = () => {
        setEmailModal(false);
        reset()
    };

    const handleopenemail = () => {
        setEmailModal(true);
    };

    const emailSubmit = (value) => {
        setPageLoading(true);
        let emails
        emails = value.email.split(',');
        let emailvar = []
        emails.forEach(element => {
            element = cryptoJS(element)
            emailvar.push(element)
        });

        const req = {
            listSize: rowsPerPage,
            pageNumber: page,
            searchString: searchInputValue,
            smartcardPrivilegeStatus: filterVal,
            emails: emailvar,
            "download": 'yes',
            persona: selectedPersonas
        };

        apiService("smartcard/admin/servicepartner/list", req, "downloadpost").then((res) => {

            if (res) {
                if (res.data) {
                    setPageLoading(false);
                    setEmailModal(false);
                    reset()
                    notifyService("success", "Success", "Excel Send To Mail");

                    // downloadExcel(res.data, 'SmartCard_Status_List')
                    // setDownloadValue('')
                }
            }
        })
    };

    const personas = [
        { personaName: "STARTUP" },
        { personaName: "ASPIRANT" },
        { personaName: "MENTOR" },
        { personaName: "STARTUP-ECO" },
        { personaName: "INVESTOR" },
        { personaName: "INNOVATOR" },
        { personaName: "STARTUPS-SERVPROV" }
    ];

    const handlePersoans = (value) => {
        setSelectedPersonas(value);
    };
    const regex = /^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(,\s*[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})*$/;
    return (
        <div>
            <div className="double-clr">
                <div className="frt-gry">
                    <div className="main-tab-adminpage">
                        <NavLink className="report-sup-btn">
                            Service Partners List
                        </NavLink>
                    </div>
                    <div></div>
                </div>
                <div className="sec-clrk">
                    {
                        hasPrivilege('52') && (
                            <>
                                <div className="sec-btn-bg">
                                    <Autocomplete
                                        size="small"
                                        disableClearable
                                        disablePortal
                                        id="combo-box-demo"
                                        value={servicePartnerDatas.find(option => option.id === filterVal) || null}
                                        options={servicePartnerDatas}
                                        getOptionLabel={(option) => option.status}
                                        onChange={(e, selectedOption) => {
                                            handleservice(selectedOption ? selectedOption.id : null);
                                        }}
                                        className="meetingListDropdown"
                                        renderInput={(params) => <TextField {...params} label="Select Status" />}
                                    />
                                </div>
                                {/* <div className="sec-btn-bg">
                                    <Autocomplete
                                        size="small"
                                        // disableClearable
                                        disablePortal
                                        options={personas}
                                        getOptionLabel={(option) => option.personaName}
                                        className="meetingListDropdown"
                                        onChange={(event, value) => {
                                            handlePersoans(value ? value.personaName : "");
                                        }}
                                        renderInput={(params) => <TextField {...params} label="Select Persona" />}
                                    />
                                </div> */}
                            </>
                        )
                    }
                    <div className="sec-filtersk ml-auto">
                        <div className="search-div">
                            <Search>
                                <SearchIconWrapper>
                                    <SearchIcon />
                                </SearchIconWrapper>
                                <StyledInputBase
                                    placeholder="Search Startup Name"
                                    inputProps={{ "aria-label": "search" }}
                                    onChange={handleSearch}
                                    type="search"
                                />
                            </Search>
                        </div>
                    </div>
                    {
                        servicePartnerList.length > 0 && (
                            // <div className='mr-3'>
                            //     <LoadingButton className='export-btn'
                            //         loading={loading}
                            //         loadingPosition="start"
                            //         startIcon={<CloudDownloadIcon />}
                            //         variant="outlined"
                            //         onClick={handleopenemail}
                            //     >
                            //         <span>EXPORT EXCEL</span>
                            //     </LoadingButton>
                            // </div>
                            // <div className='mr-3'>
                            //     <ExcelToMail name="servicePartner" rowsPerPage={rowsPerPage} page={page} searchInputValue={searchInputValue} filterVal={filterVal} selectedProfileType={selectedPersonas} />
                            // </div>
                            <></>
                        )
                    }
                </div>
            </div>
            {/* <Dialog
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={emailModal}
                onClose={handleEmailClose}
            >
                <DialogTitle>Send Excel To Mail</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleSubmit(emailSubmit)}>
                        <div>
                            <div className='my-3'>
                                <Controller
                                    name="email"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        required: "Email Id's is required",
                                        pattern: {
                                            value: regex,
                                            message: "Invalid email format. Ensure emails are separated by commas."
                                        }
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            variant="outlined"
                                            label="Email Id's *"
                                            placeholder="Enter Email Id's"
                                            fullWidth
                                            {...field}
                                        />
                                    )}
                                />
                                <FormHelperText>
                                    For multiple emails use , (comma) inbetween Ex. test@startuptn.in,support@startuptn.in
                                </FormHelperText>
                                <FormHelperText className="text-danger">
                                    {errors.email && errors.email.message}
                                </FormHelperText>
                            </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-center">
                            <div className='text-center pb-3'>
                                <Button
                                    variant="outlined"
                                    className="edit-btn-profile"
                                    sx={{
                                        textTransform: "none",
                                        color: "#fff",
                                        padding: "6px 20px",
                                        background: "#020671",
                                        marginRight: '5px',
                                        '&:hover': {
                                            color: "#fff",
                                            background: "#020671",
                                        }
                                    }}
                                    onClick={handleEmailClose}
                                >
                                    Close
                                </Button>
                            </div>
                            <div className='text-center pb-3'>
                                <Button
                                    variant="outlined"
                                    className="edit-btn-profile"
                                    sx={{
                                        textTransform: "none",
                                        border: "1px solid",
                                        borderColor: "#020671",
                                        color: "#020671",
                                        padding: "6px 20px",
                                    }}
                                    type="submit"
                                >
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </form>
                </DialogContent>
            </Dialog> */}
        </div>
    );
};

export default Servicepartnersfilter;
