import React, { useState, useEffect, useContext } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import {
  Button,
  CardActionArea,
  CardActions,
  Container,
  Dialog,
  Paper,
  DialogContent,
  DialogContentText,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Menu,
  Rating,
  Box,
} from "@mui/material";
import { Grid } from "@mui/material";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import DataContext from "../../../context/DataContext";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import apiService from "../../../api/apiService";
import Norecordsfound from "../../common-pages/Norecordsfound";
import Eventregister from "../../Media-module/Media-layout-page/Event-Page/Eventregister";
import AdminEventFilter from "./AdminEventFilter";
import ReactApexChart from "react-apexcharts";

const AdminEventHistory = () => {
  const {
    navigator,
    fullScreen,
    setPageLoading,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
  } = useContext(DataContext);
  useDidMountEffect(() => {
    setStartDate("");
    setEndDate("");
  }, []);
  const [totalPage, setTotalPage] = useState(1);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const [selectedEventIndex, setSelectedEventIndex] = useState(0);
  const [page, setPage] = useState(1);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [eventsData, setEventsData] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState("All");
  const [selectedType, setSelectedType] = useState("All");
  const [sortBy, setSortBy] = useState(true);
  const [activemainTabs, setActiveMainTabs] = useState(0);
  const [departmentId, setDepartmentId] = useState(0);
  useDidMountEffect(() => {
    // listApi();
  }, [
    searchInputValue,
    page,
    selectedPeriod,
    selectedType,
    startDate,
    endDate,
    sortBy,
    departmentId
  ]);

  const listApi = () => {
    setPageLoading(true);
    setEventsData([]);
    var data = {
      listSize: 10,
      pageNumber: page,
      searchString: searchInputValue,
      period: selectedPeriod,
      type: selectedType,
      startDate: startDate ? startDate + "T00:00:00.174Z" : "",
      endDate: endDate ? endDate + "T00:00:00.174Z" : "",
      sort: sortBy ? "asc" : "desc",
      departmentId: departmentId
    };
    apiService("event/list", data, "post")
      .then((result) => {
        setPageLoading(false);
        if (result && result.data) {
          setTotalPage(result.data.totalPages);
          if (result.data.responseModelList) {
            setEventsData(result.data.responseModelList);
          }
        }
      })
      .catch((err) => {
        console.error("Error fetching events:", err);
      });
  };
  const handleChange = (event, value) => {
    setPage(value);
  };

  useDidMountEffect(() => {
    if (eventsData.length > 0) {
      setSelectedEvent(eventsData[selectedEventIndex]);
    } else {
      setSelectedEvent(null);
      setSelectedEventIndex(0);
    }
  }, [eventsData]);
  const handleEventClick = (event, index) => {
    setSelectedEvent(event);
    setSelectedEventIndex(index);
  };

  const fundList = [
    {
      fundTitle: "TN SEED FUND WEBINAR",
      fundCategory: "Investor Connect",
      fundDepartment: "TNIFMC",
      fundDate: "09",
      fundMonth: "May",
    },
    {
      fundTitle: "Investor Connect",
      fundCategory: "Investor Connect",
      fundDepartment: "StartupTN",
      fundDate: "09",
      fundMonth: "May",
    },
    {
      fundTitle: "TN SEED FUND WEBINAR",
      fundCategory: "Investor Connect",
      fundDepartment: "TNIFMC",
      fundDate: "09",
      fundMonth: "May",
    },
    {
      fundTitle: "Investor Connect",
      fundCategory: "Investor Connect",
      fundDepartment: "StartupTN",
      fundDate: "09",
      fundMonth: "May",
    },
  ];

  const [series, setSeries] = useState([42, 47, 52, 58, 65, 40]);

  const [options, setOptions] = useState({
    chart: {
      width: "100%",
      height: 500,
      type: "polarArea",
    },
    labels: ["SIDCO", "TNGCC", "StartupTN", "iTNT", "EDII", "TNIFMC"],
    fill: {
      opacity: 1,
    },
    stroke: {
      width: 1,
      colors: undefined,
    },
    yaxis: {
      show: false,
    },
    legend: {
      position: "bottom",
      show: false,
    },
    dataLabels: {
      enabled: true,
    },
    plotOptions: {
      polarArea: {
        rings: {
          strokeWidth: 0,
        },
        spokes: {
          strokeWidth: 0,
        },
      },
    },
    colors: ["#FFD700", "#1E90FF", "#4B0082", "#00FFFF", "#FF69B4", "#FFA500"],
    theme: {
      monochrome: {
        enabled: false,
      },
    },
  });

  return (
    <div className="admin-event-page">
      <AdminEventFilter
        setSearchInputValue={setSearchInputValue}
        setSelectedPeriod={setSelectedPeriod} selectedPeriod={selectedPeriod}
        setSelectedType={setSelectedType} selectedType={selectedType}
        setSortBy={setSortBy} sortBy={sortBy}
        setDepartmentId={setDepartmentId}
        setActiveMainTabs={setActiveMainTabs} activemainTabs={activemainTabs}
        name={'report'}
      />
      <div className="event-datas-list py-5">
        <Grid
          container
          direction="row"
          alignItems="flex-start"
          justifyContent="center"
          spacing={5}
        >
          <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
            <div className="report-boxs">
              <div className="sess-1">
                <Box className="widget-boxes">
                  <p>Total Events</p>
                  <h3 className="bluee-clrr">26</h3>
                </Box>
              </div>
              <div className="sess-1">
                <Box className="widget-boxes">
                  <p>Upcoming Events</p>
                  <h3 className="bluee-clrr">08</h3>
                </Box>
              </div>
              <div className="sess-1">
                <Box className="widget-boxes">
                  <p>Total Participants</p>
                  <h3 className="bluee-clrr">520</h3>
                </Box>
              </div>
            </div>
            <div className="table_main_div">
              <div className="past mt-4 mb-3">
                <span>Past Events</span>
              </div>

              <TableContainer component={Paper}>
                <Table
                  sx={{
                    borderCollapse: "separate",
                    borderSpacing: "0 10px",
                  }}
                >
                  <TableBody sx={{ position: "relative" }}>
                    {fundList &&
                      fundList.map((a, i) => (
                        <TableRow
                          sx={{
                            position: "relative",
                            "&:after": {
                              content: '""',
                              position: "absolute",
                              top: 0,
                              bottom: 0,
                              left: 0,
                              right: 0,
                              border: "1px solid rgba(224, 224, 224, 1)",
                              borderRadius: "20px",
                              pointerEvents: "none",
                              marginBottom: "-1px",
                            },
                            "& > *": {
                              padding: "10px",
                              backgroundColor: "#fff",
                              "&:last-of-type": {
                                borderTopRightRadius: "20px",
                                borderBottomRightRadius: "20px",
                              },
                            },
                            marginBottom: "10px",
                          }}
                          className="Sup-list"
                          key={i}
                        >
                          <TableCell>
                            <div className="px-3">
                              <Typography variant="h6" className="list-2-clr">
                                {a.fundTitle}
                              </Typography>
                              <div className="listing mt-1">
                                <div>
                                  <span className="list-2-grey">
                                    Category :{" "}
                                  </span>
                                  <span className="list-2-black">
                                    {a.fundCategory}
                                  </span>
                                </div>
                                <div>
                                  <span className="list-2-grey">
                                    Department :{" "}
                                  </span>
                                  <span className="list-2-black">
                                    {a.fundDepartment}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="events-date-box">
                              <div className="date-blue-box mr-2">
                                <p>{a.fundDate}</p>
                                <p>{a.fundMonth}</p>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="events-time-box mr-3">
                              <div className="time-gray-boxed">
                                <div className="rateds">
                                  <div>
                                    <span className="four">4.0</span>
                                  </div>
                                  <div>
                                    <Rating
                                      name="size-medium"
                                      defaultValue={4}
                                    />
                                  </div>
                                </div>

                                <div className="text-center">
                                  <span>52 participants</span>
                                </div>
                              </div>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <div>
              <div className="chart-container">
                <p className="text-center">Events by Departments</p>
                <ReactApexChart
                  options={options}
                  series={series}
                  type="polarArea"
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default AdminEventHistory;
