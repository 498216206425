import React, { useContext, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormHelperText,
  TextField,
} from "@mui/material";
import apiService from "../../../api/apiService";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { Controller, useForm } from "react-hook-form";
import cryptoJS from "../../../hooks/cryptoJS";
import notifyService from "../../../api/notifySerivce";
import DataContext from "../../../context/DataContext";
const ExcelToMail = ({
  name,
  rowsPerPage,
  page,
  searchInputValue,
  filterVal,
  selectedProfileType,
}) => {
  const { setPageLoading } = useContext(DataContext);
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({});
  const regex =
    /^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(,\s*[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})*$/;
  const [emailModal, setEmailModal] = useState(false);
  const handleEmailClose = () => {
    setEmailModal(false);
    reset();
  };

  const handleopenemail = () => {
    setEmailModal(true);
  };

  


    const emailSubmit = (value) => {
        setPageLoading(true);
        let emails
        emails = value.email.split(',');
        let emailvar = []
        emails.forEach(element => {
            element = cryptoJS(element)
            emailvar.push(element)
        });
        console.log(emailvar)
        var req
        var url
        if (name === 'smartcard') {
            req = {
                listSize: rowsPerPage,
                pageNumber: page+1,
                searchString: searchInputValue,
                smartcardstatus: filterVal,
                emails: emailvar,
                download: "yes",
                profileType: selectedProfileType
            };
            url = "smartcard/admin/list"
        } else if (name === 'servicePartner') {
            req = {
                listSize: rowsPerPage,
                pageNumber: page+1,
                searchString: searchInputValue,
                smartcardPrivilegeStatus: filterVal,
                emails: emailvar,
                download: 'yes',
                persona: selectedProfileType
            };
            url = "smartcard/admin/servicepartner/list"
        }
    apiService(url, req, "downloadpost")
      .then((res) => {
        if (res) {
          if (res.data) {
            setPageLoading(false);
            setEmailModal(false);
            reset();
            notifyService("success", "Success", "Excel Send To Mail");
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div>
      <Button
        className="export-btn"
        startIcon={<CloudDownloadIcon />}
        variant="outlined"
        onClick={handleopenemail}
      >
        <span>EXPORT EXCEL</span>
      </Button>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={emailModal}
        onClose={handleEmailClose}
      >
        <DialogTitle>Send Excel To Mail</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(emailSubmit)}>
            <div>
              <div className="my-3">
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Email Id's is required",
                    pattern: {
                      value: regex,
                      message:
                        "Invalid email format. Ensure emails are separated by commas.",
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      variant="outlined"
                      label="Email Id's *"
                      placeholder="Enter Email Id's"
                      fullWidth
                      {...field}
                    />
                  )}
                />
                <FormHelperText>
                  {/* For multiple emails use , (comma) inbetween Ex. test@startuptn.in,support@startuptn.in */}
                  For multiple emails use a comma between addresses.
                  E.g.test@startuptn.in,support@startuptn.in
                </FormHelperText>
                <FormHelperText className="text-danger">
                  {errors.email && errors.email.message}
                </FormHelperText>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <div className="text-center pb-3">
                <Button
                  variant="outlined"
                  className="edit-btn-profile"
                  sx={{
                    textTransform: "none",
                    border: "1px solid",
                    borderColor: "#020671",
                    color: "#020671",
                    padding: "6px 20px",
                    marginRight: "5px",
                  }}
                  onClick={handleEmailClose}
                >
                  Close
                </Button>
              </div>
              <div className="text-center pb-3">
                <Button
                  variant="outlined"
                  className="edit-btn-profile"
                  sx={{
                    textTransform: "none",
                    color: "#fff",
                    padding: "6px 20px",
                    background: "#020671",
                    "&:hover": {
                      color: "#fff",
                      background: "#020671",
                    },
                  }}
                  type="submit"
                >
                  Submit
                </Button>
              </div>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ExcelToMail;
