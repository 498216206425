import React from 'react'
import TnFestFooter from './TnFestFooter'
import FastNavbar from './FastNavbar'

const TnfestProductpricing = () => {
    return (
        <>

            <section className="termscond-page">
                <FastNavbar/>
                <div className="back-color">
                    <div className="back-layer"></div>
                    <div className="back-layer1"></div>

                    <h1 className='terms-heading mb-3 '>Product Pricing</h1>
                    <div className='term-box'>
                        <div className='container'>
                            <h4 className='blck-clr pt-5'>Displayed Prices:</h4>
                            <p className='privacypadding'>
                                All event prices are clearly displayed on our website, inclusive of applicable taxes. Prices are subject to change without notice.
                            </p>
                            <h4 className='blck-clr pt-5'>Price Changes:</h4>
                            <p className='privacypadding'>
                                If a price change occurs after you have made a booking but before the event, we will honor the original price at which you booked.
                            </p>
                            <h4 className='blck-clr pt-5'>Additional Fees:</h4>
                            <p className='privacypadding'>
                                Additional fees, such as service charges or administrative fees, will be specified at checkout. There are no hidden charges.
                                <br />
                                Any applicable service fees will be clearly listed and explained during the booking process.
                            </p>
                            <h4 className='blck-clr pt-5'>Currency:</h4>
                            <p className='privacypadding'>
                                Prices are listed in Indian Rupees (INR). All transactions will be processed in INR.
                                <br />
                                For international payments, the final amount may vary based on exchange rates and fees applied by your payment provider.
                            </p>
                            <h4 className='blck-clr pt-5'>Discounts and Promotions:</h4>
                            <p className='privacypadding'>
                                Information about discounts and promotions will be displayed on the event pages and the homepage. Promotional offers are subject to specific terms and conditions, and may not be combined with other offers.
                                <br />
                                To use a promo code, enter it during checkout. Promo codes cannot be applied retroactively.
                            </p>
                            <h4 className='blck-clr pt-5'>Cookies:</h4>
                            <p className='privacypadding'>
                                We use cookies to collect information about your browsing activities to enhance your experience on our site. You can control cookie settings through your browser preferences. Types of Cookies Used: Session cookies, persistent cookies, and third-party cookies. Managing Cookies: Instructions on how to disable cookies through your browser settings.
                            </p>
                            <h4 className='blck-clr pt-5'>Payment Methods:</h4>
                            <p className='privacypadding'>
                            We accept various payment methods, including credit/debit cards, net banking, and digital wallets.
                            </p>
                            <h4 className='blck-clr pt-5'>Third-Party Links:</h4>
                            <p className='privacypadding'>
                                Our site may contain links to third-party websites. We are not responsible for the privacy practices or content of these sites.
                            </p>
                            <h4 className='blck-clr pt-5'>User Rights:</h4>
                            <p className='privacypadding'>
                                You have the right to access, correct, or delete your personal information held by us. You can also opt-out of receiving marketing communications from us at any time.
                            </p>
                            <h4 className='blck-clr pt-5'>Accessing Your Information:</h4>
                            <p className='privacypadding mb-3'>
                                Instructions on how to access your account information and request corrections or deletions.
                            </p>
                        </div>

                    </div>
                </div>
            </section>
            <TnFestFooter />
        </>
    )
}

export default TnfestProductpricing