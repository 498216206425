import React from 'react'
import TnfestBanner from './TnfestBanner'
import TnfestVideo from './TnfestVideo'
import Tnfestblog from './Tnfestblog'
import TnEventSpeakers from './TnEventSpeakers'
import Tnfesteventagenda from './Tnfesteventagenda'
import TnfestMilestones from './TnfestMilestones'
import TnfestThiruvizhabanner from './TnfestThiruvizhabanner'
import TnfestBookTicket from './TnfestBookTicket'
import TnfestForm from './TnfestForm'
import Fest from './Fest'
import TnFestFooter from './TnFestFooter'
import TnFestPartner from './TnFestPartner'

const Festlayout = () => {
  return (
    <div>
      
        <Fest/>
        <TnfestVideo/>
        <Tnfestblog/>
        <TnEventSpeakers/>
        {/* <Tnfesteventagenda/> */}
        <TnfestMilestones/>
        <TnfestThiruvizhabanner/>
        {/* <TnfestBookTicket/> */}
        <TnfestForm/>
        <TnFestPartner />
        <TnFestFooter/>
    </div>
  )
}

export default Festlayout