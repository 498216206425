import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Container,
  Divider,
  Grid,
  Tab,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const ScaleupIncubationsupport = ({name}) => {
  const [expanded, setExpanded] = useState("panel1");
  const [screenSize, setScreenSize] = useState(4);
  const navigate = useNavigate();

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [activeTab, setActiveTab] = useState("0");

  const [value, setValue] = useState("0");
  const handleChangetab = (event, newValue) => {
    setValue(newValue);
    setActiveTab(newValue);
  };

  useEffect(() => {
    size();
  }, []);

  const size = () => {
    if (window.innerWidth > 1440) {
      setScreenSize(4);
    } else if (window.innerWidth <= 1440 && window.innerWidth > 1024) {
      setScreenSize(4);
    } else if (window.innerWidth <= 1024 && window.innerWidth > 992) {
      setScreenSize(4);
    } else if (window.innerWidth <= 992 && window.innerWidth > 768) {
      setScreenSize(4);
    } else if (window.innerWidth <= 768 && window.innerWidth > 575) {
      setScreenSize(4);
    } else {
      setScreenSize(1);
    }
  };

  const handlenow = () => {
    navigate('/login')
  }

  return (
    <div className="smartCard-page">
      {/* <Navbar /> */}
      <div className="radial-back-layer">
        <div className="round-layers"></div>
        <Container>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="banner-text">
                <div className="sm-banner-chip-text1">
                  <span>{`${name}  >  ScaleUp - Incubation Support`}</span>
                  <h1 className="banner-text-gradient">
                    ScaleUp - Incubation Support
                  </h1>
                  <h6 className="fund-title">Incubating Incubators</h6>
                  <div className="banner-content-actions">
                    <button className="banner-viewbtn">Get in Touch</button>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
      <div className="smart-card-info5">
        <div className="layer-aadu">
          <Container>
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={2}
              className="tanseed-info1"
            >
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="left-side-smartcardPage1">
                  <h4>What is ScaleUp - Incubation Support ?</h4>
                  <p className="para-all">
                    ScaleUp - Incubation support provides curriculum-based
                    support through structured workshops and an opportunity to
                    get grants from StartupTN, to implement their learnings &
                    action plan. The aim of the program is to enable the
                    incubators to gain insights, learn about global best
                    practices, and gain access to networks & tools to close the
                    existing gaps in scaling an incubation centre and march
                    towards a truly sustainable model of running the incubator
                    successfully.
                  </p>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="smart-crd-img">
                  <img
                    className="smartcrdimag img-fluid"
                    src={"/images/startupLogo.png"}
                    alt="smart-card"
                  />
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
        <div className="join-us-now-sec1">
          <Container>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="join-us-now-box">
                  <div className="join-us-now-content">
                    <h4>Let’s Get Started</h4>
                    <p>ScaleUp - Incubation Support Incubating Incubators</p>
                    <Button
                      variant="contained"
                      size="large"
                      className="join-now-btn"
                      onClick={handlenow}
                    >
                      Join us Now
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default ScaleupIncubationsupport;
