import { Autocomplete, Button, FormHelperText, Grid, TextField } from "@mui/material";
import React, { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import apiService from "../../../api/apiService";
import DataContext from "../../../context/DataContext";
import notifyService from "../../../api/notifySerivce";
import useDidMountEffect from "../../../hooks/useDidMountEffect";

const DepartmentAddCS = () => {
    const { setPageLoading, navigator, validateEmail } = useContext(DataContext);
    const { handleSubmit, control, setValue, reset, clearErrors, formState: { errors } } = useForm({});
    useDidMountEffect(() => {
        DesignationApi()
    }, [])
    const [designationList, setDesignationList] = useState([]);
    const DesignationApi = () => {
        apiService(`home/designation/list`, "", "get")
            .then((result) => {
                if (result) {
                    if (result.data) {
                        setDesignationList(result.data.designations);
                    }
                }
            })
            .catch((err) => { });
    };
    
    const handleSelectDesignation = (value) => {
        setValue(`designation`, value);
        clearErrors(`designation`);
    };
    const onSubmit = (data) => {
        setPageLoading(true)
        var designationVal = designationList.filter((value) => value.name === data.designation);
        data.authorityDesignationId = designationVal[0].id
        console.log(data)
        apiService("department/save", data, "post")
            .then((result) => {
                setPageLoading(false)
                if (result.data.responseStatus === "Success") {
                    notifyService("success", "Success", "");
                    navigator("/admin/department/list");
                }
            })
            .catch((err) => { });
    };
    const onError = (event) => {
        console.log("error Data:", event);
        notifyService(
            "danger",
            "Submit Failed",
            "Please check the mandatory fields"
        );
    };
    const handleReset = () => {
        reset();
        navigator("/admin/department/list");
    };

    return (
        <div className="deap-Add-form">
            <Grid container direction="row" alignItems="flex-start" justifyContent="start" spacing={3}>
                <Grid item lg={3} xl={3}></Grid>
                <Grid item lg={6} xl={6}>
                    <div className="eventdepart-title mx-3">
                        <h4 className="even-ead">Add Departments</h4>
                        <p className="font-color-gray">See and add your department information</p>
                    </div>
                    <div className="admin-department-box input_background pt-5">
                        <form onSubmit={handleSubmit(onSubmit, onError)}>
                            <div className="mb-4">
                                <Controller
                                    name="name"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: "Department Name is required" }}
                                    render={({ field }) => (
                                        <TextField
                                            variant="outlined"
                                            label="Department Name"
                                            placeholder="Please Enter Department Name"
                                            fullWidth
                                            type="text"
                                            {...field}
                                            error={Boolean(
                                                errors.name && errors.name
                                            )}                                       
                                        />
                                    )}
                                />
                                <FormHelperText className="text-danger">
                                    {errors.name && errors.name.message}
                                </FormHelperText>
                            </div>
                            <div className="mb-4">
                                <Controller
                                    name="authorityName"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: "Name of Authority is required" }}
                                    render={({ field }) => (
                                        <TextField
                                            variant="outlined"
                                            label="Name of Authority"
                                            placeholder="Please Enter Authority Name"
                                            fullWidth
                                            type="text"
                                            {...field}
                                            error={Boolean(
                                                errors.authorityName && errors.authorityName
                                            )}
                                        />
                                    )}
                                />                                
                                <FormHelperText className="text-danger ml-0">
                                    {errors.authorityName &&
                                        errors.authorityName.message}
                                </FormHelperText>
                            </div>
                            <div className="mb-4">
                                <Controller
                                    name="designation"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: "Designation is required" }}
                                    render={({ field }) => (
                                        <Autocomplete
                                            disableClearable
                                            disablePortal
                                            {...field}
                                            options={designationList.map(
                                                (option) => option.name
                                            )}
                                            onChange={(event, newValue) =>
                                                handleSelectDesignation(newValue)
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Designation"
                                                    placeholder="Select Designation"
                                                />
                                            )}
                                            fullWidth
                                            margin="normal"
                                        />
                                    )}
                                />
                                <FormHelperText className="text-danger">
                                    {errors.designation && errors.designation.message}
                                </FormHelperText>
                            </div>
                            <div className="mb-4">
                                <Controller
                                    name="authorityEmail"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        required: "Organization Mail ID is required",
                                        validate: validateEmail,
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            variant="outlined"
                                            label="Organization Mail ID"
                                            placeholder="Please Enter Organization Mail ID"
                                            fullWidth
                                            type="text"
                                            {...field}
                                            error={Boolean(errors.authorityEmail && errors.authorityEmail)}
                                        />
                                    )}
                                />
                                <FormHelperText className="text-danger">
                                    {errors.authorityEmail && errors.authorityEmail.message}
                                </FormHelperText>
                            </div>
                            <div className="text-center mb-3 mt-4 w-100">
                                <Button onClick={handleReset} className="discard-btn">
                                    Clear
                                </Button>
                                <Button type="submit" className="publish-button">
                                    <span>Add Department</span>
                                </Button>
                            </div>
                        </form>
                    </div>
                </Grid>
                <Grid item lg={3} xl={3}></Grid>
            </Grid>
        </div>
    );
};
export default DepartmentAddCS