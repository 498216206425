import React, { useContext, useState } from "react";
import DeptAboutUs from "./DeptAboutUs";
import DeptReport from "./DeptReport";
import DeptService from "./DeptService";
import DeptTeam from "./DeptTeam";
import { Divider, Grid } from "@mui/material";
import { Link, useParams } from "react-router-dom";

import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import apiService from "../../../../api/apiService";
import DataContext from "../../../../context/DataContext";
const DepartmentDetails = () => {
  const { id } = useParams()
  let departmentId
  if (id != null) {
    departmentId = atob(id)
  }
  const { navigator, setPageLoading, hasPrivilege } = useContext(DataContext);
  const [activeTab, setActiveTab] = useState("About Us");
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  useDidMountEffect(() => {
    getApi()
  }, [])
  const [departmentValue, setDepartmentValue] = useState({})
  const [servicesList, setServicesList] = useState([]);
  const getApi = () => {
    setPageLoading(true)
    apiService(`department/get?id=${departmentId}`, "", "get")
      .then((result) => {
        setPageLoading(false)
        if (result && result.data) {
          setDepartmentValue(result.data)
          if (result.data.services) {
            setServicesList(result.data.services)
          }
        }

      })
      .catch((err) => { });
  };
  const handleEditDetails = (id) => {
    navigator(`admin/department/update/${btoa(id)}`)
  }
  return (
    <div>
      <div className="depAdd-title mx-3">
        <h3>StartupTN</h3>
        <p>See your department information</p>
      </div>
      <div className="dep-tab-grid">
        <Grid
          container
          direction="row"
          alignItems="flex-start"
          justifyContent="space-between"
          spacing={4}
        >
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <div className="dept-leftbox">
              <div className="card-comp-dep-details">
                <div className="imag-border">
                  <img
                    className="comp-logo"
                    src={departmentValue.logoName? departmentValue.logoName : '/images/racket-img.png'}
                    alt="company-logo"
                  />
                </div>
                <div>
                  <div className="first-para-dept mb-3">
                    <p className="admcrd-para-color">{departmentValue.name}</p>
                    <span>{departmentValue.tagline}</span>
                  </div>
                  <Divider />

                  <div className="departinfo-details my-3">
                    <p>
                      HeadQuarters <span className="sub-p1">{departmentValue.district}</span>
                    </p>
                    <p>
                      Department Size{" "}
                      <span className="sub-p2">{departmentValue.departmentSize}+ Employees</span>
                    </p>
                  </div>
                  <Divider />
                  <div className="dept-crd-contactinfo mt-3">
                    <p className="con-text-dep">Contact Information</p>
                    <p className="blac-color">
                      {departmentValue.address},
                      {departmentValue.state}, {departmentValue.district} - {departmentValue.pincode}
                    </p>

                    {/* <Link to="https://www.google.com/search?q=startuptn&oq=startuptn&gs_lcrp=EgZjaHJvbWUyBggAEEUYOTIGCAEQRRhBMgYIAhBFGD0yBggDEEUYPDIGCAQQRRg8MgYIBRBFGDwyBggGEEUYQTIGCAcQLhhA0gEIMjQ2N2owajGoAgCwAgA&sourceid=chrome&ie=UTF-8" target="_blank"> */}
                    <span>{departmentValue.tagLine}</span>
                    {/* </Link> */}
                  </div>
                  <div className="soc-media-dep-icn mt-2 mb-4">
                    {departmentValue.facebook ? (
                      <div>
                        <Link to={departmentValue.facebook} target='_blank'>
                          <img
                            src={"/images/admin-logo/facebook.png "}
                            alt="social-media-logo"
                            className="soc-dep-imag"
                          />
                        </Link>
                      </div>
                    ) : (
                      <></>
                    )}
                    {departmentValue.youtube ? (
                      <div>
                        <Link to={departmentValue.youtube} target='_blank'>
                          <img
                            src={"/images/admin-logo/play.png "}
                            alt="social-media-logo"
                            className="soc-dep-imag"
                          />
                        </Link>
                      </div>
                    ) : (
                      <></>
                    )}
                    {departmentValue.instagram ? (
                      <div>
                        <Link to={departmentValue.instagram} target='_blank'>
                          <img
                            src={"/images/admin-logo/instagram.png"}
                            alt="social-media-logo"
                            className="soc-dep-imag"
                          />
                        </Link>
                      </div>
                    ) : (
                      <></>
                    )}
                    {departmentValue.twitter ? (
                      <div>
                        <Link to={departmentValue.twitter} target='_blank'>
                          {/* <img
                            src={"/images/admin-logo/twitter.png"}
                            alt="social-media-logo"
                            className="soc-dep-imag"
                          /> */}
                          <img src='/images/logo/twitter -white.svg' className='img-fluid' alt="" style={{width:'25px',padding:'5px',borderRadius:'50%',background:'#000',}}/>
                        </Link>
                      </div>
                    ) : (
                      <></>
                    )}
                    {departmentValue.linkedin ? (
                      <div>
                        <Link to={departmentValue.linkedin} target='_blank'>
                          <img
                            src={"/images/admin-logo/linkedin.png"}
                            alt="social-media-logo"
                            className="soc-dep-imag"
                          />
                        </Link>
                      </div>
                    ) : (
                      <></>
                    )}
                    {departmentValue.whatsapp ? (
                      <div>
                        <Link to={departmentValue.whatsapp} target='_blank'>
                          <img
                            src={"/images/admin-logo/whatsapp.png "}
                            alt="social-media-logo"
                            className="soc-dep-imag"
                          />
                        </Link>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  {
                    hasPrivilege('13') && (
                      <div className="text-center">
                        <button
                          className="depart-view-btn"
                          onClick={() => handleEditDetails(departmentValue.id)}
                        >
                          Edit Details
                        </button>
                      </div>
                    )
                  }

                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
            <div className="dept-left-contents">
              <div className="frt-grys">
                <div className="main-tab-adminpage">
                  <div className="tab-buttons">
                    <button
                      className={`startup-sup-btns ${activeTab === "About Us" ? "active" : ""
                        }`}
                      onClick={() => handleTabClick("About Us")}
                    >
                      About Us
                    </button>
                    <button
                      className={`startup-sup-btns ${activeTab === "Services" ? "active" : ""
                        }`}
                      onClick={() => handleTabClick("Services")}
                    >
                      Services
                    </button>
                    <button
                      className={`startup-sup-btns ${activeTab === "Teams" ? "active" : ""
                        }`}
                      onClick={() => handleTabClick("Teams")}
                    >
                      Teams
                    </button>
                    <button
                      className={`startup-sup-btns ${activeTab === "Reports" ? "active" : ""
                        }`}
                      onClick={() => handleTabClick("Reports")}
                    >
                      Reports
                    </button>
                  </div>
                </div>
              </div>
              <div className="tab-1-content">
                {activeTab === "About Us" && (
                  <>
                    <DeptAboutUs departmentValue={departmentValue} />
                  </>
                )}
                {activeTab === "Services" && (
                  <>
                    <DeptService servicesList={servicesList} />
                  </>
                )}
                {activeTab === "Teams" && (
                  <>
                    <DeptTeam departmentId={departmentId} />
                  </>
                )}
                {activeTab === "Reports" && (
                  <>
                    <DeptReport />
                  </>
                )}
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default DepartmentDetails;
