import React from "react";
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import UserProfileTab from "./startup-profile/user-Profile-Details/UserProfileTab";
import UserSchemeservice from "./startup-profile/user-Profile-Details/UserSchemeservice";
import SupportTicketLayout from "./startup-profile/support-ticket-details/SupportTicketLayout";
import SupportTicketList from "./startup-profile/support-ticket-details/SupportTicketList";
import SupportTicketAdd from "./startup-profile/support-ticket-details/SupportTicketAdd";
import SupportTicketDetails from "./startup-profile/support-ticket-details/SupportTicketDetails";
import SupportTicketQuery from "./startup-profile/support-ticket-details/SupportTicketQuery";
import InvestorProfileLayout from "./investor-profile/InvestorProfileLayout";
import StartupProfileLayout from "./startup-profile/StartupProfileLayout";
import InvestorStartupLayout from "./investor-profile/investor-startup-details/InvestorStartupLayout";
import AssignedStartup from "./investor-profile/investor-startup-details/startup-details/AssignedStartup";
import PortfolioStartups from "./investor-profile/investor-startup-details/startup-details/PortfolioStartups";
import DDPhase from "./investor-profile/investor-startup-details/startup-details/DDPhase";
import ICCohorts from "./investor-profile/investor-startup-details/startup-details/ICCohorts";
import MyFavourites from "./investor-profile/investor-startup-details/startup-details/MyFavourites";
import InvestorTeamList from "./investor-profile/investor-team-details/InvestorTeamList";
import InvestorEventLayout from "./investor-profile/investor-event-details/InvestorEventLayout";
import InvEventNotification from "./investor-profile/investor-event-details/event-details/InvEventNotification";
import InvAppointments from "./investor-profile/investor-event-details/event-details/InvAppointments";
import InvMentoringSessions from "./investor-profile/investor-event-details/event-details/inv-mentoring-sessions-details/InvMentoringSessions";
import InvMyEvents from "./investor-profile/investor-event-details/event-details/InvMyEvents";
import InvSupportDeckList from "./investor-profile/Investor-supportdeck-details/InvSupportDeckList";
import InvNewsMediaList from "./investor-profile/investor-news-media/InvNewsMediaList";
import InvMentoringAll from "./investor-profile/investor-event-details/event-details/inv-mentoring-sessions-details/InvMentoringAll";
import InvMentoringRequested from "./investor-profile/investor-event-details/event-details/inv-mentoring-sessions-details/InvMentoringRequested";
import InvestorProfilePage from "./investor-profile/investor-profile-details/InvestorProfilePage";
import ICCohortslist from "./investor-profile/investor-startup-details/startup-details/ICCohortslist";
import MentorProfileLayout from "./mentor-profile/MentorProfileLayout";
import MentorProfilePage from "./mentor-profile/mentor-profile-details/MentorProfilePage";
import MenSessionList from "./mentor-profile/mentor-sesstion-details/sesstion-details/MenSessionList";
import MenRequestList from "./mentor-profile/mentor-sesstion-details/request-details/MenRequestList";
import MentorSessionLayout from "./mentor-profile/mentor-sesstion-details/MentorSessionLayout";
import MentorRateReviewPage from "./mentor-profile/mentor-rate-review-details/MentorRateReviewPage";
import WelcomeUserProfile from "./WelcomeUserProfile";
import EcosystemEnablersLayout from "./ecosystem-enablers-profile/EcosystemEnablersLayout";
import EnablersProfilePage from "./ecosystem-enablers-profile/enablers-profile-details/EnablersProfilePage";
import InnovatorProfilePage from "./innovator-profile/innovator-profile-details/InnovatorProfilePage";
import InnovatorProfilelayout from "./innovator-profile/InnovatorProfilelayout";

const Userroutes = () => {
  return (
    <main>
      <Routes>
        <Route path="/startup" element={<StartupProfileLayout />}>
          <Route path="/startup" element={<Navigate replace to="/startup/profile" />} />
          <Route path="/startup/profile" element={<UserProfileTab name={''} path="startup/profile/"/>} />
          <Route path="/startup/profile/:name/:id" element={<UserProfileTab name={''} path="startup/profile/"/>} />
          <Route path="/startup/profile/add" element={<UserProfileTab names={'add'}/>} />
          <Route path="/startup/Schemes" element={<UserSchemeservice />} />
          <Route path="/startup/support" element={<SupportTicketLayout />}>
            <Route path="/startup/support" element={<Navigate replace to="/startup/support/:name" />} />
            <Route path="/startup/support/:name" element={<SupportTicketList />} >
              <Route path="/startup/support/:name" element={<Navigate replace to="/startup/support/:name/add" />} />
              <Route path="/startup/support/:name/add" element={<SupportTicketAdd />} />
              <Route path="/startup/support/:name/:id" element={<SupportTicketDetails />} />
            </Route>
            <Route path="/startup/support/query/:id" element={<SupportTicketQuery />} />
          </Route>
        </Route>
        <Route path="/investor" element={<InvestorProfileLayout path="investor/profile/"/>}>
          <Route path="/investor" element={<Navigate replace to="/investor/profile" />} />
          <Route path="/investor/profile" element={<InvestorProfilePage path="investor/profile/"/>} />
          <Route path="/investor/profile/:name/:id" element={<InvestorProfilePage path="investor/profile/"/>} />
          <Route path="/investor/startup" element={<InvestorStartupLayout />}>
            <Route path="/investor/startup" element={<Navigate replace to="/investor/startup/assigned" />} />
            <Route path="/investor/startup/assigned" element={<AssignedStartup name={"assignedStartups"} />} />
            <Route path="/investor/startup/portfolio" element={<PortfolioStartups name={"portfoliostartups"} />} />
            <Route path="/investor/startup/ddPhase" element={<DDPhase name={"DDphase"} />} />
            <Route path="/investor/startup/iccohorts" element={<ICCohorts name={"Iccohorts"} />} />
            <Route path="/investor/startup/iccohorts/list" element={<ICCohortslist name={"Iccohortslist"} />} />
            <Route path="/investor/startup/my-favourites" element={<MyFavourites name={"myfavourites"} />} />
          </Route>
          <Route path="/investor/team" element={<InvestorTeamList />} />
          <Route path="/investor/event-session" element={<InvestorEventLayout />}>
            <Route path="/investor/event-session" element={<Navigate replace to="/investor/event-session/notification" />} />
            <Route path="/investor/event-session/notification" element={<InvEventNotification />} />
            <Route path="/investor/event-session/appointments" element={<InvAppointments />} />
            <Route path="/investor/event-session/mentoring" element={<InvMentoringSessions />}>
              <Route path="/investor/event-session/mentoring" element={<Navigate replace to="/investor/event-session/mentoring/all" />} />
              <Route path="/investor/event-session/mentoring/all" element={<InvMentoringAll />} />
              <Route path="/investor/event-session/mentoring/requested" element={<InvMentoringRequested />} />
            </Route>
            <Route path="/investor/event-session/my-events" element={<InvMyEvents />} />
          </Route>
          <Route path="/investor/support-deck" element={<InvSupportDeckList />} />
          <Route path="/investor/news-media" element={<InvNewsMediaList />} />
        </Route>
        <Route path="/mentor" element={<MentorProfileLayout path="mentor/profile/"/>}>
          <Route path="/mentor" element={<Navigate replace to="/mentor/profile" />} />
          <Route path="/mentor/profile" element={<MentorProfilePage path="mentor/profile/"/>} />
          <Route path="/mentor/profile/:name/:id" element={<MentorProfilePage  path="mentor/profile/"/>} />
          <Route path="/mentor/session" element={<MentorSessionLayout />}>
            <Route path="/mentor/session" element={<Navigate replace to="/mentor/session/list" />} />
            <Route path="/mentor/session/list" element={<MenSessionList />} />
            <Route path="/mentor/session/request" element={<MenRequestList />} />
          </Route>
          <Route path="/mentor/rate-review" element={<MentorRateReviewPage />} />
        </Route>
        <Route path="/ecosystem" element={<EcosystemEnablersLayout />} >
          <Route path="/ecosystem" element={<Navigate replace to="/ecosystem/profile" />} />
          <Route path="/ecosystem/profile" element={<EnablersProfilePage path="ecosystem/profile/"/>} />
          <Route path="/ecosystem/profile/:name/:id" element={<EnablersProfilePage path="ecosystem/profile/"/>} />
        </Route>
        <Route path="/innovator" element={<InnovatorProfilelayout  />} >
          <Route path="/innovator" element={<Navigate replace to="/innovator/profile" />} />
          <Route path="/innovator/profile" element={<InnovatorProfilePage  path="innovator/profile/"/>} />
          <Route path="/innovator/profile/:name/:id" element={<InnovatorProfilePage path="innovator/profile/"/>} />
        </Route>
        <Route path="/aspirant" element={<InnovatorProfilelayout />} >
          <Route path="/aspirant" element={<Navigate replace to="/aspirant/profile" />} />
          <Route path="/aspirant/profile" element={<InnovatorProfilePage path="aspirant/profile/"/>} />
          <Route path="/aspirant/profile/:name/:id" element={<InnovatorProfilePage path="aspirant/profile/"/>} />
        </Route>
        <Route path="/service-provider" element={<InnovatorProfilelayout />} >
          <Route path="/service-provider" element={<Navigate replace to="/service-provider/profile" />} />
          <Route path="/service-provider/profile" element={<InnovatorProfilePage path="service-provider/profile/"/>} />
          <Route path="/service-provider/profile/:name/:id" element={<InnovatorProfilePage path="service-provider/profile/"/>} />
        </Route>
        {/* <Route path="/aspirant/profile" element={<WelcomeUserProfile />} />
        <Route path="/innovator/profile" element={<WelcomeUserProfile />} />
        <Route path="/service-provider/profile" element={<WelcomeUserProfile />} /> */}
      </Routes>
    </main>
  );
};

export default Userroutes;
