import React, { useContext, useState } from "react";
import { Container, Grid, TextField, Autocomplete, FormControl, FormHelperText, Pagination, } from "@mui/material";
import { Typewriter } from "react-simple-typewriter";
import { Controller, useForm } from "react-hook-form";
import { MdArrowDropDownCircle } from "react-icons/md";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import { MdMailOutline } from "react-icons/md";
import { FaCircleArrowRight } from "react-icons/fa6";
import { RiCloseCircleFill } from "react-icons/ri";
import { BiSearchAlt } from "react-icons/bi";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
// import apiService from "../../api/apiService";
// import { useEffect } from "react";
// import Norecordsfound from "../common-pages/Norecordsfound";
import Navbar from "../navbar-page/Navbar";
import apiService from "../../../api/apiService";
import DataContext from "../../../context/DataContext";
import { useNavigate } from "react-router-dom";
import Footer from "../footer-page/Footer";
import cryptodecryptJS from "../../../hooks/cryptodecryptJS";

const EcoSystemPage = () => {
  const { setPageLoading, navigator, setUserProfileId, setUserProfileParsona, setShowViewbtn, showViewbtn } = useContext(DataContext);
  const [totalPage, setTotalPage] = useState(1);
  const [showForm, setShowForm] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState();
  const [sectorId, setSectorId] = useState();
  const [districtId, setDistrictId] = useState();
  const [hubDistrictId, setHubDistrictId] = useState(0);
  const [selectedOption, setSelectedOption] = useState("DPIIT Startup");
  const [page, setPage] = useState(1);
  const [inchargeSectorId, setInchargeSectorId] = useState()
  useDidMountEffect(() => {
    userlistApi();
  }, [
    searchInputValue,
    sectorId,
    districtId,
    hubDistrictId,
    selectedOption,
    page,
  ]);
  const [sectorList, setSectorList] = useState([]);
  const [sectorval, setSectorval] = useState({});
  const [inchargemail, setInchargemail] = useState("")

  const userlistApi = () => {
    setSectorList([]);
    setPageLoading(true);
    var data = {
      listSize: 12,
      pageNumber: page,
      searchString: searchInputValue,
      sectorId: sectorId,
      districtId: districtId,
      hubId: hubDistrictId,
      role: selectedOption,
    };
    apiService("ecosystem/list", data, "unauthpost")
      .then((result) => {
        setPageLoading(false);
        if (result) {
          if (result.data) {
            setInchargeSectorId(result.data.sectorId)
            if (result.data.sector) {
              setSectorval(result.data.sector);
              if (result.data.sector.inchargEmail) {
                var emailincharge = result.data.sector.inchargEmail
                setInchargemail(cryptodecryptJS(emailincharge))
              }
            }
            setTotalPage(result.data.totalPages);
            if (result.data.userProfiles) {
              setSectorList(result.data.userProfiles);

            }
          }
        }
      })
      .catch((err) => { });
  };
  useDidMountEffect(() => {
    // inchargeApi();
  }, []);
  const [incharge, setIncharge] = useState([]);
  const inchargeApi = () => {
    apiService(`sector/get?id=${inchargeSectorId}`, "", "get")
      .then((result) => {
        if (result) {

        }
      })
      .catch((err) => { });
  };
  useDidMountEffect(() => {
    districtsdropdownApi();
  }, [hubDistrictId]);
  const [district, setDistrict] = useState([]);
  const districtsdropdownApi = () => {
    apiService(`home/district/list?hubId=${hubDistrictId}`, "", "get")
      .then((result) => {
        if (result && result.data && result.data.districts) {
          setDistrict(result.data.districts);
        }
      })
      .catch((err) => { });
  };

  useDidMountEffect(() => {
    hubDistrictsdropdownApi();
    sectorsdropdownApi();
    personadropdownApi();
  }, []);

  const [hubDistrict, setHubDistrict] = useState([]);
  const hubDistrictsdropdownApi = () => {
    var req = {}
    apiService('hub/list', req, "unauthpost")
      .then((result) => {
        if (result && result.data && result.data.hubs) {
          setHubDistrict(result.data.hubs);
        }
      })
      .catch((err) => { });
  };
  const [persona, setPersona] = useState([]);
  const personadropdownApi = () => {

    apiService('home/role/list', "", "get")
      .then((result) => {
        if (result && result.data) {
          setPersona(result.data);
        }
      })
      .catch((err) => { });
  };
  const [sectors, setSectors] = useState([]);
  const sectorsdropdownApi = () => {
    apiService('home/sector/list', "", "get")
      .then((result) => {
        if (result && result.data && result.data.sectors) {
          setSectors(result.data.sectors);
        }
      })
      .catch((err) => { });
  };
  const [nodataname, setNodataname] = useState("")

  const handleSelectPersona = (value) => {
    setSelectedOption(value);
    setNodataname(value)

  };
  const handleDeletePersona = () => {
    setSelectedOption("DPIIT Startup");
  };

  const [sectorValue, setSectorValue] = useState("");
  const [selectSect, setSelectSect] = useState(false);
  const handleSelectSector = (e, name) => {
    setSectorValue(name);
    var value = sectors.filter((option) => option.name === name);
    setSectorId(value[0].id);
    setSelectSect(true);
  };
  const handleDeleteSector = () => {
    setSectorValue("");
    setSectorId(0);
    setSelectSect(false);
  };

  const [hubDistrictValue, setHubDistrictValue] = useState("");
  const handleHubDistrict = (e, name) => {
    setHubDistrictValue(name);
    var value = hubDistrict.filter((option) => option.name === name);
    setHubDistrictId(value[0].id);
    setDistrictValue("");
    setDistrictId(0);
  };
  const handleDeleteHub = () => {
    setHubDistrictValue("");
    setHubDistrictId(0);
  };

  const [districtValue, setDistrictValue] = useState("");
  const handleSelectDistrict = (e, name) => {
    setDistrictValue(name);
    var value = district.filter((option) => option.name === name);
    setDistrictId(value[0].id);
    // setHubDistrictValue("");
    setHubDistrictId(0);
  };
  const handleDeleteDistrict = () => {
    setDistrictValue("");
    setDistrictId(0);
  };

  const handleSearch = (e) => {
    setSearchInputValue(e.target.value);
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleUserPersonna = (id) => {
    // navigate(/user/profile/${btoa(id)})
    //
  };

  let jwttoken = localStorage.getItem("jwttoken");

  const handleCardClick = (id, userpersona) => {
    // console.log(id, "id");
    setUserProfileId(id)
    setUserProfileParsona(userpersona)
    setShowViewbtn(1)
    localStorage.setItem("userpersonaId", id);
    localStorage.setItem("userpersona", userpersona);
    if (userpersona === "INVESTOR") {
      navigator("/investor/profile");
    } else if (userpersona === "STARTUP") {
      navigator("/startup/profile");
    } else if (userpersona === "MENTOR") {
      navigator("/mentor/profile");
    } else if (userpersona === "ASPIRANT") {
      navigator("/aspirant");
    } else if (userpersona === "STARTUPS-SERVPROV") {
      navigator("/service-provider");
    } else if (userpersona === "INNOVATOR") {
      navigator("/innovator");
    } else if (userpersona === "STARTUP-ECO") {
      navigator("/ecosystem");
    }

  };
  const handleinitiatives = (name) => {
    if (sectorValue) {
      if (sectorValue === "Aerospace, Defence & SpaceTech") {
        navigator(`ecosystem-info/aerospace/${name}`);
      }
      else if (sectorValue === "Agriculture & FoodTech") {
        navigator(`ecosystem-info/foodtech/${name}`);
      }
      else if (sectorValue === "AI, ML & IoT") {
        navigator(`ecosystem-info/al-ml/${name}`);
      }
      else if (sectorValue === "Art, Culture & Architecture") {
        navigator(`ecosystem-info/artculture/${name}`);
      }
      else if (sectorValue === "Automotive, EV & Smart Mobility") {
        navigator(`ecosystem-info/automotive/${name}`);
      }
      else if (sectorValue === 'HRTech & Smart Workforce') {
        navigator(`ecosystem-info/hrtechsmartworkforce/${name}`);
      }
      else if (sectorValue === 'IT/ITES & SaaS, Software') {
        navigator(`ecosystem-info/itites/${name}`);
      }
      else if (sectorValue === 'Blue Economy') {
        navigator(`ecosystem-info/blue-economy/${name}`);
      }
      else if (sectorValue === 'Climatetech & Cleantech') {
        navigator(`ecosystem-info/climatetech/${name}`);
      }
      else if (sectorValue === "PropTech, LegalTech & RegTech") {
        navigator(`ecosystem-info/prop/${name}`);
      }
      else if (sectorValue === "Retail Tech, D2C") {
        navigator(`ecosystem-info/retailtech/${name}`);
      }
      else if (sectorValue === "Smart Cities & E-Governance") {
        navigator(`ecosystem-info/smartcities/${name}`);
      }
      else if (sectorValue === "Social Impact, Rural Livelihood & Sustainability") {
        navigator(`ecosystem-info/socialimpact/${name}`);
      }
      else if (sectorValue === "Sports Tech & Gaming") {
        navigator(`ecosystem-info/sports/${name}`);
      }
      else if (sectorValue === "Supply Chain & Logistics") {
        navigator(`ecosystem-info/supplychain/${name}`);
      }
      else if (sectorValue === "Telecom, Networking & Hardware") {
        navigator(`ecosystem-info/telecom/${name}`);
      }
      else if (sectorValue === "Web 3.0, Blockchain, VR/AR") {
        navigator(`ecosystem-info/websector/${name}`);
      }
      else if (sectorValue === "Travel & Tourism") {
        navigator(`ecosystem-info/travel/${name}`);
      }
      else if (sectorValue === "Textile Tech & Fashion") {
        navigator(`ecosystem-info/textiletech/${name}`);
      }
      else if (sectorValue === "Chemicals & Materials") {
        navigator(`ecosystem-info/chemicals/${name}`);
      }
      else if (sectorValue === "Circular Economy") {
        navigator(`ecosystem-info/circulareconomy/${name}`);
      }
      else if (sectorValue === "Marketing Tech & MICE") {
        navigator(`ecosystem-info/marketingtech/${name}`);
      }
      else if (sectorValue === "Data Mining & Analytics") {
        navigator(`ecosystem-info/datamining/${name}`);
      }
      else if (sectorValue === "Ed Tech") {
        navigator(`ecosystem-info/edtech/${name}`);
      }
      else if (sectorValue === "FemTech") {
        navigator(`ecosystem-info/femtech/${name}`);
      }
      else if (sectorValue === "Fintech & InsurTech") {
        navigator(`ecosystem-info/fintech/${name}`);
      }
      else if (sectorValue === "Healthcare & Life Sciences") {
        navigator(`ecosystem-info/healthcare/${name}`);
      }
      else if (sectorValue === "Industry 4.0 & Advanced Manufacturing") {
        navigator(`ecosystem-info/industry/${name}`);
      }
      else if (sectorValue === "Life Style, Personal Care") {
        navigator(`ecosystem-info/lifestyle/${name}`);
      }
      else if (sectorValue === "Media & Entertainment") {
        navigator(`ecosystem-info/mediaandentertainment/${name}`);
      }
    }
  }
  return (
    <div className="back-color1">
      <Navbar />
      <div className="back-layer"></div>
      <div className="back-layer1"></div>
      <div className="top-layer"></div>
      <div >
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={12}>
            <Container>
              <div className="main-title">
                <span className="small-tit">CONNECT, LEARN, SUCCEED </span>
                <p className="big-title mb-0">Become Part of </p>
                <div className="vectorimg">
                  <img src={"./images/Vector (1).png"} alt="vector" />
                </div>
                <p className="big-title2 mb-0 ">
                  the
                  <span className="big-title2 ml-2">
                    StartupTN
                    <div className="anim-div">
                      <div className="typewriter-container">
                        <div className="anim-abs">
                          <span>Explore</span>
                        </div>
                        <div className="chipText ml-1">
                          <span>E</span>
                          <Typewriter
                            words={["cosystem"]}
                            loop={false}
                            cursor
                            cursorStyle={<span className="cursor-style"></span>}
                            cursorBlinking={false}
                            typeSpeed={250}
                            deleteSpeed={60}
                            delaySpeed={1000}
                            style={{
                              background: "#B6CBFB",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </span>
                </p>
                <p className="titles-paras">
                  Here Is Our Directory Of Ecosystem Based On Our Established Policies. Search And Avail Of Them Hassle-Free.
                </p>
              </div>
            </Container>
          </Grid>
        </Grid>
        {/* <div className="search-bar">
          <div className="gray-back-search">
         
          <p><span><BiSearchAlt style={{fontSize:'20px'}}/></span>Search Keywords</p>
          </div>
        </div> */}
        {/* <div className="search-bar">
        <div className="gray-back-search">
          <span>
            <BiSearchAlt style={{ fontSize: "30px", marginLeft: "2px" }} />
            <span className="eco-search"> Search Keyword</span>
          </span>
        </div>
        <input type="text" className="search-input" onChange={handleSearch} />
      </div> */}
        <div class="search-bar">
          <BiSearchAlt className="upd-search-eco" /> <input type="text" class="search-input" onChange={handleSearch} placeholder="Search Keyword" autoFocus={true} />
        </div>

        <Container>
          <div className="eco-filters">
            <div className="filter-btns c-pointer" onClick={() => setShowForm(!showForm)}>
              <span>
                <img
                  src={"/images/filtericn.png"}
                  alt="filtericn"

                />
              </span>
              <p className="filter-text">
                Filters
              </p>
            </div>
            {showForm && (
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
                spacing={2}
              >
                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                  <Autocomplete
                    disableClearable
                    disablePortal
                    options={persona}
                    value={selectedOption}
                    popupIcon={<MdArrowDropDownCircle />}
                    onChange={(e, selectedOption) =>
                      handleSelectPersona(selectedOption)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Startups"
                        placeholder="Startups"
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                  <Autocomplete
                    disableClearable
                    disablePortal
                    id="combo-box-demo"
                    value={sectorValue}
                    options={sectors.map((option) => option.name)}
                    popupIcon={<MdArrowDropDownCircle />}
                    onChange={(e, selectedOption) =>
                      handleSelectSector(e, selectedOption)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Sector"
                        placeholder="Sector"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                  <Autocomplete
                    disableClearable
                    disablePortal
                    id="combo-box-demo"
                    value={hubDistrictValue}
                    options={hubDistrict.map((option) => option.name)}
                    // getOptionLabel={(option) => option.name}
                    popupIcon={<MdArrowDropDownCircle />}
                    onChange={(e, selectedOption) =>
                      handleHubDistrict(e, selectedOption)
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="Hub" placeholder="Hub" />
                    )}
                  />
                </Grid>

                {/* {districtValue !== '' ? <></>:<></>} */}
                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                  <Autocomplete
                    disableClearable
                    disablePortal
                    id="combo-box-demo"
                    options={district.map((option) => option.name)}
                    value={districtValue}
                    popupIcon={<MdArrowDropDownCircle />}
                    onChange={(e, selectedOption) =>
                      handleSelectDistrict(e, selectedOption)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="District"
                        placeholder="District"
                      />
                    )}
                  />
                </Grid>
              </Grid>
            )}
          </div>
          {selectSect === true && (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={8} lg={8} xl={8} className="mb-4">
                <div className="filter-datas-box">
                  <div className="filter-datas">
                    <div className="chip-rows">
                      {selectedOption ? (
                        <>
                          <Chip
                            label={selectedOption}
                            onDelete={handleDeletePersona}
                            deleteIcon={
                              <RiCloseCircleFill style={{ color: "#fff" }} />
                            }
                            sx={{ background: "#0d1232", color: "#fff" }}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                      {sectorValue ? (
                        <>
                          <Chip
                            label={sectorValue}
                            onDelete={handleDeleteSector}
                            deleteIcon={
                              <RiCloseCircleFill style={{ color: "#fff" }} />
                            }
                            sx={{ background: "#0d1232", color: "#fff" }}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                      {hubDistrictValue ? (
                        <>
                          <Chip
                            label={hubDistrictValue}
                            onDelete={handleDeleteHub}
                            deleteIcon={
                              <RiCloseCircleFill style={{ color: "#fff" }} />
                            }
                            sx={{ background: "#0d1232", color: "#fff" }}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                      {districtValue ? (
                        <>
                          <Chip
                            label={districtValue}
                            onDelete={handleDeleteDistrict}
                            deleteIcon={
                              <RiCloseCircleFill style={{ color: "#fff" }} />
                            }
                            sx={{ background: "#0d1232", color: "#fff" }}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                    {/* {selectSect && selectSect == true} */}

                    <div className="filter-datas-paras">
                      {/* <h5>Ai/ML Sector</h5> */}
                      {/* <h5>{sectorval.name}</h5> */}
                      <h5>{sectorValue}</h5>
                      <p>
                        {sectorval.brief}
                        {/* AI, with its ability to replicate human intelligence, ML,
                        with its capacity to decipher complex patterns from vast
                        datasets, and IoT, which connects billions of devices and
                        sensors, are collectively driving unprecedented
                        advancements and reshaping the way we live, work and
                        interact with the world around us. This introduction sets
                        the stage for an exploration of the AI/ML/IoT sector,
                        where we delve into the exciting developments, challenges
                        and possibilities that lie ahead in this dynamic realm of
                        technology. */}
                      </p>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div className="about-datas-box1">
                  <div className="abt-imag mr-3">
                    {/* <img
                      
                      src={sectorval.inchargeLogoName}
                      alt="profile"
                      className="eco-profilepht"
                    /> */}
                    <img
                      className="eco-profilepht"
                      src={
                        sectorval.inchargeLogoName
                          ? sectorval.inchargeLogoName
                          : "/images/user.png"
                      }
                      style={{
                        width: '100%',
                        maxWidth: '90px'}}
                    alt="logo"
                    />
                  </div>
                  <div className="abt-det">
                    {/* <span className="abt-box-tit">Ai/ML Sector Incharge</span> */}
                    <span className="abt-box-tit">{sectorval.name} Incharge</span>
                    <p className="abt-det-name">{sectorval.inchargeName}</p>
                    <p className="abt-det-mail">
                      <span>
                        <MdMailOutline style={{ marginRight: "5px" }} />
                      </span>
                      {/* gigin@statuptn.in */}
                      {inchargemail}
                    </p>
                  </div>
                </div>

                <div className="routing-boxs">
                  <div className="about-datas-box c-pointer" onClick={() => handleinitiatives('initiatives')}>
                    <span className="about-datas-span" >
                      Click to more about this
                    </span>
                    <p className="" >
                      Sector<br></br> initiatives{" "}
                      <span className="ml-1">
                        <FaCircleArrowRight />
                      </span>
                    </p>
                  </div>
                  <div className="about-datas-box c-pointer" onClick={() => handleinitiatives('resources')}>
                    <span className="about-datas-span">
                      Click to more about this
                    </span>
                    <p className="" >
                      Resources
                      <span className="ml-1">
                        <FaCircleArrowRight />
                      </span>
                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>
          )}
          <div className="side-layer"></div>
          <div className="container">
            <div className="related-search-div">
              {/* <h4>Related Searches</h4> */}
              <Grid
                container
                direction="row"
                alignItems="flex-start"
                justifyContent="flex-start"
                spacing={5}

              >
                {sectorList.length > 0 ? (
                  <>
                    {sectorList.map((item, index) => (
                      <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                        <Card
                          className="eco-card-resp"
                          key={index}
                          onClick={() => handleCardClick(item.userId, item.persona)}
                        >
                          <div className="card-back-layer">

                            <div className="layer-crd-back-img1-ecosys">
                              <div className="card-back-prof-ecosys">
                                <img
                                  src={
                                    item.imageName
                                      ? item.imageName
                                      : "/images/racket-img.png"
                                  }
                                  alt="crd-img"
                                  className="card-back-image"
                                />
                              </div>
                            </div>
                          </div>
                          <CardContent sx={{ textAlign: "center" }}>
                            <p
                              className="crd-title-text m-0"
                              onClick={() => handleUserPersonna(item.id)}
                            >
                              {item.name}
                            </p>
                            <span className="crd-span-text">{item.role}</span>
                          </CardContent>
                        </Card>
                      </Grid>
                    ))}
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      {totalPage > 1 && (
                        <div className="event-pagination">
                          <Stack spacing={2}>
                            <Pagination
                              count={totalPage}
                              page={page}
                              onChange={handleChange}
                            />
                          </Stack>
                        </div>
                      )}
                    </Grid>
                  </>
                ) : (
                  <div className="text-center w-100 my-5 nodatacss">
                    {/* <h5 className="mb-4">{nodataname} Datas Not Found</h5> */}
                    <div>
                      <img
                        src="/images/blue horizontal.png"
                        alt="blue horizontal.png"
                        className="logo_dash img-fluid"
                      />
                    </div>
                    <div className="mt-5">
                      <h3 className="title_p opa">StartupTN 3.0</h3>
                    </div>
                  </div>
                )}
              </Grid>
            </div>
          </div>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default EcoSystemPage;