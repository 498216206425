import { Button, Dialog } from '@mui/material';
import React, { useContext, useState } from 'react';
import DataContext from '../../../../context/DataContext';
// import Navbar from '../../navbar-page/Navbar';
// import Footer from '../../footer-page/Footer';
import FastNavbar from './FastNavbar';
import cryptodecryptJS from '../../../../hooks/cryptodecryptJS';
import ProfileCheckDialog from './Ticketbookdialog/ProfileCheckDialog';
import Delegatefreepass from './PassDialog/Delegatefreepass';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';
import Thiruvizha24Modal from '../thiruvizha24-page/Thiruvizha24Modal';
import FoodModal from '../thiruvizha24-page/FoodModal';
import { useEffect } from 'react';

const Fest = () => {
    var token = localStorage.getItem("jwttoken");
    var email = localStorage.getItem("email");
    var personaName = localStorage.getItem("type");
    var profileupdated = localStorage.getItem("profile")
    const { navigator, ProfileDialog, setProfileDialog, handleProfileDialogOpen, handleProfileDialogClose } = useContext(DataContext);

    // useDidMountEffect(() => {
    //     setProfileDialogBox(false)
    // }, [])
    // const handleConference = () => {

    //     if (token && email && profileupdated) {
    //         // var conferenceurl = `https://startuptn.getkameleon.com/conference?email=${cryptodecryptJS(email)}`
    //         var conferenceurl = `https://event.startuptn.in/conference?email=${cryptodecryptJS(email)}`
    //         localStorage.setItem("conferencePath", conferenceurl)
    //         window.location.href = conferenceurl;
    //     } else {
    //         localStorage.setItem('way','Conference')
    //         const currentPath = window.location.pathname;

    //         localStorage.setItem('currentPath', currentPath);
    //         setProfileDialog(true)
    //         if (personaName === 'STARTUP') {
    //             navigator('/startups/basicinfo')
    //         } else if (personaName === 'ASPIRANT') {
    //             navigator('/aspirants/basicinfo')
    //         } else if (personaName === 'MENTOR') {
    //             navigator('/mentors/basicinfo')
    //         } else if (personaName === 'INNOVATOR') {
    //             navigator('/innovators/basicinfo')
    //         } else if (personaName === 'INVESTOR') {
    //             // navigator('/investor/basicinfo')
    //             navigator('/investor/profile')
    //         } else if (personaName === 'STARTUP-ECO') {
    //             navigator('/ecosystem/basicinfo')
    //         } else if (personaName === 'STARTUPS-SERVPROV') {
    //             navigator('/service-provider/basicinfo')
    //         } else if (personaName === 'CORE-TEAM') {
    //             navigator('/admin')
    //         }else{
    //             navigator('/login')
    //         }

    //     }
    // }
    // const handleConference = () => {
    //     let type = localStorage.getItem('type')
    //     if (!token) {
    //         setProfileDialog(false);
    //         localStorage.setItem('way', 'Conference');
    //         const currentPath = window.location.pathname;
    //         localStorage.setItem('currentPath', currentPath);
    //         navigator('/login');
    //         return;
    //     } else if (token && profileupdated === "false") {
    //         if (type === 'NA') {
    //             var id = Number(localStorage.getItem('userId'))
    //             navigator(`letsstart/${btoa(id)}`)
    //             setProfileDialog(false);
    //         } else {
    //             setProfileDialogBox(true)
    //         }
    //         debugger
    //     }
    //     if (token && email) {
    //         if (type === 'NA') {
    //             var id = Number(localStorage.getItem('userId'))
    //             navigator(`letsstart/${btoa(id)}`)
    //             setProfileDialog(false);
    //         } else {

    //             setProfileDialogBox(true)
    //         }
    //     } else if (profileupdated === "true") {
    //         setProfileDialog(false);
    //         var email = localStorage.getItem('email')
    //         localStorage.removeItem('currentPath');
    //         var conferenceurl = `https://event.startuptn.in/conference?email=${cryptodecryptJS(email)}`
    //         localStorage.setItem("conferencePath", conferenceurl)
    //         localStorage.removeItem('way')
    //         window.location.href = conferenceurl;
    //         navigator("/fest");
    //     } else {
    //         if (type === 'NA') {
    //             var id = Number(localStorage.getItem('userId'))
    //             navigator(`letsstart/${btoa(id)}`)
    //             setProfileDialog(false);
    //         }
    //         else {
    //             setProfileDialogBox(true);
    //         }
    //     }
    //     debugger
    // };



    // const handleExhibitor = () => {
    //     if (token && email && profileupdated) {
    //         // var conferenceurl = `https://startuptn.getkameleon.com/authorize/login?email=${cryptodecryptJS(email)}`
    //         var conferenceurl = `https://event.startuptn.in/authorize/login?email=${cryptodecryptJS(email)}`
    //         localStorage.setItem("conferencePath", conferenceurl)
    //         window.location.href = conferenceurl;

    //     } else {
    //         localStorage.setItem('way','Exhibitor')
    //         const currentPath = window.location.pathname;
    //         localStorage.setItem('currentPath', currentPath);
    //         navigator('/login');
    //     }
    // }
    const handleExhibitor = () => {
        if (!token) {
            setProfileDialog(false);
            // const currentPath = window.location.pathname;
            // localStorage.setItem('currentPath', currentPath);
            localStorage.setItem('way', 'Exhibitor');
            const currentPath = window.location.pathname;
            localStorage.setItem('currentPath', currentPath);
            navigator('/login');
            return;
        }
        if (token && email && profileupdated === "true") {
            var conferenceurl = `https://event.startuptn.in/authorize/login?email=${cryptodecryptJS(email)}`
            localStorage.setItem("conferencePath", conferenceurl);
            window.location.href = conferenceurl;
        } else {
            var type = localStorage.getItem('type')
            if (type === 'NA') {
                var id = Number(localStorage.getItem('userId'))
                navigator(`letsstart/${btoa(id)}`)
                setProfileDialog(false);
            } else {
                setProfileDialog(true);
            }

        }
    };
    const [profileDialogBox, setProfileDialogBox] = useState(true)
    const handleConference = () => {
        localStorage.setItem('way', 'Conference');
        setProfileDialogBox(true)
    };
    // const handleExhibitor = () => {
    //     localStorage.setItem('way', 'Exhibitor');
    //     setProfileDialogBox(true)
    // };
    const handleProfileClose = () => {
        setProfileDialogBox(false)
    }

    const [showDiv, setShowDiv] = useState(true);

    useEffect(() => {
        const interval = setInterval(() => {
            setShowDiv(prevShowDiv => !prevShowDiv);
        }, 5000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div>
            <FastNavbar />
            <div>
                {
                    showDiv ? (
                        <img
                            src={'/images/Speakers/Overall Speakers 82 Final.jpg'}
                            alt="banner"
                            className='bg-speakers'
                        />

                    ) : (
                        <div className="banner-imgs" style={{ position: 'relative' }}>
                            <div className='act-btns1-food'>
                                <button onClick={() => handleConference()} className="publish-button1">
                                    Book for Visitors
                                </button>
                                <FoodModal />
                            </div>
                        </div>
                    )}
            </div>
            {/* <div>
                <div className="banner-imgs" style={{ position: 'relative' }}>
                    <div className='act-btns1-food'>

                        <h2 className='c-white'>Booking Opens at 2 PM Today!</h2>

                        <button onClick={() => handleConference()} className="publish-button1">
                            Book for Visitors
                        </button>

                        <button onClick={() => handleExhibitor()} className="publish-button2">
                            Exhibitor Login
                        </button>

                        <FoodModal />
                    </div>
                </div>
            </div> */}
            <div>
                <ProfileCheckDialog personaName={personaName} />
            </div>
            <div>
                <Delegatefreepass />
            </div>
            <Dialog fullWidth={true} maxWidth={"md"} open={profileDialogBox}>
                <Thiruvizha24Modal handleProfileClose={handleProfileClose} />
            </Dialog>
        </div>
    )
}

export default Fest;
