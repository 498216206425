import { Pagination, Stack, Grid, Divider } from "@mui/material";
import React, { useContext, useState } from "react";
import apiService from "../../../../api/apiService";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import DataContext from "../../../../context/DataContext";
import Mediafilter from "../Mediafilter";
import Norecordsfound from "../../../common-pages/Norecordsfound";

const Latestnewspage = () => {
  const { setPageLoading } = useContext(DataContext)
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [categoryId, setCategoryId] = useState("");
  useDidMountEffect(() => {
    listApi();
  }, [page, searchInputValue, categoryId]);
  const [eventList, setEventList] = useState([]);

  const listApi = () => {
    setEventList([])
    setPageLoading(true)
    var req = {
      searchString: searchInputValue,
      categoryId: categoryId,
      listSize: 12,
      pageNumber: page,
    };
    apiService(`news/home/list`, req, "unauthpost")
      .then((result) => {
        setPageLoading(false)
        if (result && result.data) {
          if (result.data.newsLetter) {
            setEventList(result.data.newsLetter);
            setTotalPage(result.data.totalPages)
          }
        }
      })
      .catch((err) => { });
  };
  const handleChange = (event, value) => {
    setPage(value);
  };
  return (
    <>
      <div className="event-title">
        <h2 className="event-titles">Latest News & Updates</h2>
        <div className="event-line">
          <img src="/images/event-line.png" alt="" />
        </div>
      </div>
      <Grid container direction="row" alignItems="flex-start" justifyContent="center" >
        {/* <Grid item sm={12} md={3} lg={3}><Mediafilter setSearchInputValue={setSearchInputValue} setCategoryId={setCategoryId} type={'newsletter'} seachLableName={'Enter Keywords'} /></Grid> */}
        <Grid item sm={12} md={12} lg={12} >
          <div className="container">
            <Grid container direction="row" alignItems="flex-start" justifyContent="flex-start" spacing={2}>
              {eventList.length > 0 ?
                eventList.map((item, index) => (
                  <>
                    <Grid item sm={12}>
                      <div className="newsletter-list">
                        <div className="letterings">
                          <img src={item.fileName} alt="" className="img-fluid news-img" />
                          <div className="news-letter-details">
                            <div className="letterings-dated">
                              <h5>{item.title}</h5>
                              <span className="ml-lg-auto ml-md-auto font-sm">{item.date}</span>
                            </div>
                            <p className="news-letter-para">{item.description} </p>
                            <p className="news-letter-source font-sm">Source : {item.source}</p>
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <div className="grid-border"></div>
                  </>
                )) : (
                  <Norecordsfound name="Latest News & Updates Not Found" />
                )
              }

            </Grid>
            {totalPage > 1 ? (
              <div className="event-pagination">
                <Stack spacing={2}>
                  <Pagination
                    count={totalPage}
                    page={page}
                    onChange={handleChange}
                  />
                </Stack>
              </div>
            ) : (<></>)}
          </div>
        </Grid>
      </Grid>
    </>

  );
};

export default Latestnewspage
