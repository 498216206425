import React, { useContext, useState } from 'react'
import Button from '@mui/material/Button';
import DataContext from '../../../context/DataContext';
import apiService from '../../../api/apiService';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText, TextField } from '@mui/material';
import notifyService from '../../../api/notifySerivce';
import { Controller, useForm } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import SearchableDropdown from '../event-details/SearchableDropdown';

const AdminSupportAssign = ({ name, supportId, getApi }) => {
    const { setPageLoading } = useContext(DataContext)
    const [openModal, setOpenModal] = useState(false);
    const assignStatusBtn = () => {
        setOpenModal(true);
    }
    const [isOpen, setIsOpen] = useState(false);
    const [assignedUserId, setAssignedUserId] = useState('')
    const handleOffParticipantDropDown = (options) => {
        setIsOpen(false)
        var val = options
        if (val !== '') {
            setAssignedUserId(val.id)
            setCheckValue(false)
        }
    }
    const [checkValue, setCheckValue] = useState(false)
    const onSubmit = () => {
        if (assignedUserId !== '') {
            setPageLoading(true)
            var req = {
                "id": Number(supportId),
                "assignedUserId": assignedUserId,
                "status": 1
            }

            
            apiService('support/ticket/status/save', req, "post").then((res) => {
                setPageLoading(false)
                if (res) {
                    if (res.data.responseStatus === "Success") {
                        getApi()
                        handleCloseModal()
                        notifyService("success", "Success", "");
                    }
                }
            });
        } else {
            setCheckValue(true)
        }

    }
    // const onError = (data) => {
    //     notifyService('danger', 'Submit Failed', 'Please check the mandatory fields')
    // }
    const handleCloseModal = () => {
        setAssignedUserId()
        setOpenModal(false);
    };
    return (
        <>
            <Button className="assign-btn" variant="outlined" onClick={() => assignStatusBtn()}>Assign</Button>
            <Dialog open={openModal} maxWidth="lg" onClose={handleCloseModal}>
                <Box sx={{ minWidth: '500px'}}>
                    <form className="signin-form">
                        <div className='d-flex justify-content-center'>
                            <DialogTitle>Assign</DialogTitle>
                            {/* <div className='ml-auto'>
                            <CloseIcon onClick={handleCloseModal} className='m-3 c-pointer' />
                        </div> */}

                        </div>
                        <DialogContent>
                            <div className='row' >
                                <div className='col-12'>
                                    <div className='input_background mb-4'>
                                        <Box sx={{maxHeight: '500px' }}>
                                        <SearchableDropdown isOpen={isOpen} setIsOpen={setIsOpen} handleOffDropDown={handleOffParticipantDropDown} lblName="Search Name" />
                                        </Box>
                                        
                                        {
                                            checkValue === true && (
                                                <FormHelperText className="text-danger">
                                                    Name is required
                                                </FormHelperText>
                                            )
                                        }

                                    </div>
                                </div>
                                {/* <div className='col-12'>
                                <div className='input_background'>
                                    <Controller
                                        name={'mail'}
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: "Mail Id is required", validate: validateEmail }}
                                        render={({ field }) => (
                                            <TextField
                                                variant="outlined"
                                                label="Mail Id"
                                                placeholder="Enter Mail ID"
                                                fullWidth
                                                type="text"
                                                {...field}
                                            />
                                        )}
                                    />
                                    <FormHelperText className="text-danger">
                                        {errors.mail && errors.mail.message}
                                    </FormHelperText>
                                </div>
                            </div> */}
                            </div>
                        </DialogContent>
                        <DialogActions >
                            <div className='text-center mb-3 w-100'>
                                <Button onClick={handleCloseModal} className="discard-btn">Close</Button>
                                <Button onClick={onSubmit} className="publish-button"> Assign</Button>
                            </div>

                        </DialogActions>
                    </form>
                </Box>

            </Dialog>
        </>
    )
}

export default AdminSupportAssign