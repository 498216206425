import { Autocomplete, FormHelperText, Grid, TextField, Button } from '@mui/material'
import React, { useContext, useState } from 'react'
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Controller, useForm } from 'react-hook-form'
import DataContext from '../../../../context/DataContext'
import apiService from '../../../../api/apiService'
import useDidMountEffect from '../../../../hooks/useDidMountEffect'
import { MuiFileInput } from 'mui-file-input'
import notifyService from '../../../../api/notifySerivce'
import Viewimage from '../../../../api/Viewimage';
import cryptodecryptJS from '../../../../hooks/cryptodecryptJS';
import cryptoJS from '../../../../hooks/cryptoJS';
const BasicinfoServiceprovider = () => {
  const { navigator, validateEmail, validateNumberonly, yearListApi, yearListValue, handleNextClick, validatePhoneNumber, 
    VisuallyHiddenInput, isWhitespace, validatePANNumber,checkUppernumeric } = useContext(DataContext)
  const [formData, setFormData] = useState({});
  const { handleSubmit, control, setValue, clearErrors, getValues, formState: { errors }, } = useForm({ values: formData });
  const [open, setOpen] = useState(false);
  const [viewImage, setViewImage] = useState("");
  const [viewImageName, setViewImageName] = useState("");
  useDidMountEffect(() => {
    handleNextClick(50)
    yearListApi()
  }, [])
  useDidMountEffect(() => {
    if (id !== null) {
      getApi()
    }
  }, [])
  var id = localStorage.getItem('id')
  var type = localStorage.getItem('type')
  const getApi = () => {
    apiService(`userprofile/get?persona=${type}&userid=${id}`, "", "get").then((result) => {
      if (result && result.data) {
        var data = result.data
        if (data.regYear === 0) {
          data.regYear = ''
        }
        if (data.phone) {
          data.phone = cryptodecryptJS(data.phone);
        }
        if (data.email) {
          data.email = cryptodecryptJS(data.email);
        }
        if (data.pan) {
          data.pan = cryptodecryptJS(data.pan)
          // setPanTickIcon(1)
        }        
        if (data.imageName) {
          data.imageUrl = data.imageName
        }
        setFormData(data)
      }
    }).catch((err) => {
    });
  }
  let logoselecetdFile = "";
  const handleFileUpload = (event, filekey, fileName) => {
    if (event !== null) {
      if (event.target === undefined) {
        logoselecetdFile = event
      } else {
        logoselecetdFile = event.target.files[0]
      }
      const maxFileSize = 5 * 1024 * 1024; 
      if (logoselecetdFile) {
        if (logoselecetdFile.size < maxFileSize) {
          var reader = new FileReader();
          var imagetype = logoselecetdFile.type;
          var imagedatatype = imagetype.split("/");
          var img_crt_type = imagedatatype[1];
          if (
            img_crt_type === "jpg" ||
            img_crt_type === "png" ||
            img_crt_type === "jpeg"
          ) {
            setValue(filekey, "");
            setValue(fileName, "");
            // setValue(fileUrl, '')
            var fileValue = logoselecetdFile;
            reader.readAsDataURL(logoselecetdFile);
            reader.onload = () => {
              var logourl1 = reader.result;
              var spl = logourl1.split(",");
              var ImageValue = spl[1];
              var img_name = fileValue.name;
              setValue(filekey, ImageValue);
              setValue(fileName, img_name);
              // setValue(fileUrl, logourl1)
              clearErrors(filekey);
              clearErrors(fileName);
            };
          } else {
            notifyService(
              "danger",
              "File Format Invalided",
              "Please check your file format"
            );
          }
        } else {
          notifyService(
            "danger",
            "File Size Exceeded",
            `Maximum file size allowed is 5 MB`
          );
        }
      }
    }
  };
  const handleClickOpen = (value, name) => {
    setViewImage(value);
    setViewImageName(name);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const onSubmit = (data) => {
    console.log('Form Data:', data);
    data.persona = type
    data.userId = id
    data.phone = cryptoJS(data.phone)
    data.email = cryptoJS(data.email.toLowerCase())
    data.type = localStorage.getItem('role')
    data.pan = cryptoJS(data.pan.toUpperCase());
    apiService('userprofile/save', data, 'post').then((result) => {
      if (result.data.responseStatus === 'Success') {
        navigator('/service-provider/address')
      }
    }).catch((err) => {

    });
  }
  const onError = (event) => {
    console.log('error Data:', event);
    notifyService('danger', 'Submit Failed', 'Please check the mandatory fields')
  }
  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <div className='signup-data_div'>
            <div className='forms-title d-flex'>
              <p>Organization Info {`>`} <span className='path_sapn'>Basic Info</span></p>
              <p className='ml-auto'><span className='count_num'>1</span>/5</p>
            </div>
            <div className='signup_box_div mt-4'>
              <form onSubmit={handleSubmit(onSubmit, onError)}>
                <Grid container spacing={1}>
                  <Grid item lg={12} md={12} sm={12} xs={12} className='mb-3'>
                    <Controller
                      name="name"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "Organization Name is required", validate: {
                            noWhitespace: value => !isWhitespace(value) || 'WhiteSpace not allowed'
                        },
                      }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="Organization Name*"
                          placeholder="Enter Organization Name"
                          fullWidth
                          type="text"
                          {...field}
                          error={Boolean(errors.name && errors.name)}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.name && errors.name.message}
                    </FormHelperText>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} className='mb-3'>
                    <Controller
                      name="email"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Mail ID is required", validate: validateEmail }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="Mail ID *"
                          placeholder="Mail ID"
                          fullWidth
                          type="text"
                          {...field}
                          error={Boolean(errors.email && errors.email)}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.email && errors.email.message}
                    </FormHelperText>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} className='mb-3'>
                    <Controller
                      name="phone"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "Phone Number is required",
                        validate: validatePhoneNumber,
                      }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="Phone Number *"
                          placeholder="Enter Phone Number"
                          fullWidth
                          type="text"
                          {...field}
                          onKeyDown={(e) => validateNumberonly(e)}
                          inputProps={
                            { maxLength: 10 }
                          }
                          error={Boolean(errors.phone && errors.phone)}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.phone && errors.phone.message}
                    </FormHelperText>
                  </Grid>

                  <Grid item lg={12} md={12} sm={12} xs={12} className='mb-3'>
                    <Controller
                      name="regYear"
                      control={control}
                      defaultValue=""
                      rules={{ required: true }}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <Autocomplete
                        disableClearable
                        disablePortal
                          options={yearListValue}
                          getOptionLabel={(option) => option}
                          defaultValue={[]}
                          value={value || []}
                          onChange={(event, newValue) => onChange(newValue)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Year of registration *"
                              placeholder="Select Year of registration"
                              error={!!error}
                              helperText={error ? 'Year of registration is required' : ''}
                            />
                          )}
                          fullWidth
                          margin="normal"
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.regYear && errors.regYear.message}
                    </FormHelperText>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                    <Controller
                      name="pan"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "PAN is required",
                        // validate: validatePANNumber
                      }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="PAN*"
                          placeholder="Enter PAN"
                          fullWidth
                          type="text"
                          // inputProps={{ maxLength: 10 }}
                          // onKeyDown={(e) => checkUppernumeric(e)}
                          // onInput={(e) => {
                          //   handlePanChange(e);
                          // }}
                          {...field}
                          error={Boolean(errors.pan && errors.pan)}
                        // InputProps={{
                        //   endAdornment: panTickIcon === 1 ? (
                        //     <InputAdornment position="end">
                        //       <CheckCircleIcon color="success" />
                        //     </InputAdornment>
                        //   ) : panTickIcon === 2 ? (
                        //     <InputAdornment position="end">
                        //       <CloseIcon color="error" />
                        //     </InputAdornment>
                        //   ) : null,
                        // }}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.pan && errors.pan.message}
                    </FormHelperText>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                  {
                      getValues('imageUrl') ? (
                        <>
                          <label >Upload Company Logo<span className="text-danger ml-1">*</span></label>
                          <div className="row">
                            <Grid item lg={7} md={7} sm={12} xs={12} className="mb-3">

                              <Controller
                                name="imageName"
                                control={control}
                                defaultValue=""
                                rules={{ required: "Company Logo is required" }}
                                render={({ field }) => (
                                  <Button
                                    component="label"
                                    variant="contained"
                                    {...field}
                                    fullWidth
                                    onChange={(e) =>
                                      handleFileUpload(e, "image", "imageName")
                                    }
                                    startIcon={<CloudUploadIcon />}
                                    href="#file-upload"
                                  >
                                    Change
                                    <VisuallyHiddenInput type="file" />
                                  </Button>
                                )}
                              />

                            </Grid>
                            <Grid item lg={5} md={5} sm={12} xs={12} className="mb-3 logo-btn-margin">
                              <Button
                                className="view_doc_btn_document c-pointer"

                                size="medium"
                                variant="outlined"
                                onClick={() =>
                                  handleClickOpen(
                                    getValues("image"),
                                    getValues("imageUrl")
                                  )
                                }
                              >
                                Preview
                              </Button>
                            </Grid>
                            <div>
                              <span className="fn-12   mt-1 ">
                                Maximum 5 mb allowed doc (Accepted Format: jpeg, jpg, png)

                              </span>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="form-group mt-2">
                          <Controller
                            name="imageName"
                            control={control}
                            defaultValue=""
                            rules={{ required: "Company Logo is required" }}
                            render={({ field }) => (
                              <TextField
                                variant="outlined"
                                label="Upload Company Logo"
                                placeholder="Upload Image"
                                fullWidth
                                type="text"
                                {...field}
                                disabled
                              />
                            )}
                          />
                          <FormHelperText className="text-danger">
                            {errors.imageName && errors.imageName.message}
                          </FormHelperText>
                          <div className='d-flex align-items-center w-100 my-3'>
                            <p className='font-sm'>
                              Recommended aspect ratio 4:3 <br />
                              Max size upto 5mb <br />
                              Accepted Format: jpeg, jpg, png</p>
                            <label className='ml-auto lbl_btn'
                              onChange={(e) => handleFileUpload(e, "image", "imageName")}
                              href="#file-upload">
                              <img src='/images/admin/upload.png' className='mr-3' alt='' />
                              Upload Image
                              <VisuallyHiddenInput type="file" />
                            </label>
                          </div>
                        </div>
                      )
                    }
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} className='text-center'>
                    <button className='next-btn' type="submit">Next</button>
                  </Grid>
                </Grid>
              </form>
              <Viewimage
                viewImage={viewImage}
                viewImageName={viewImageName}
                open={open}
                handleClose={handleClose}
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default BasicinfoServiceprovider