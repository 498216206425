import { Autocomplete, Button, Dialog, DialogContent, FormHelperText, Grid, InputAdornment, TextField } from '@mui/material'
import React, { useContext, useState } from 'react'
import DataContext from '../../../../context/DataContext';
import { Controller, useForm } from 'react-hook-form'
import IconButton from '@mui/material/IconButton';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import apiService from '../../../../api/apiService'
import cryptoJS from '../../../../hooks/cryptoJS'
import { Link } from 'react-router-dom'
import { auth, googleAuthProvider } from '../../../auth-module/Firebase';
// import LinkedInLogin from '../continue-with/LinkedInLogin';
// import StartupIndia from '../continue-with/StartupIndia';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';
// import UpdatePersona from './UpdatePersona';
// import ForgotPassword from './ForgotPassword';
import notifyService from '../../../../api/notifySerivce';
// import Captchatext from '../../common-pages/Captchatext';
// import cryptodecryptJS from '../../../hooks/cryptodecryptJS';
import ProfileCheckDialog from '../Tn_fest/Ticketbookdialog/ProfileCheckDialog';
import Delegatefreepass from '../Tn_fest/PassDialog/Delegatefreepass';
import Captchatext from '../../../common-pages/Captchatext';
import ForgotPassword from '../../../auth-module/login-details-page/ForgotPassword';
import Timer from '../../../auth-module/signup-details-page/signup-page/Timer';
import ThiruvizhaTimer from './ThiruvizhaTimer';
import axios from 'axios';

const Thiruvizha24ProfileUpdate = ({ getApi, onClose }) => {
    const { validateNumberonly, validateEmail, setPageLoading, handleHomePage, setProfileDialog, setProfileDialogBox, fullWidth, captchaValue, setCaptchaValue, setInputValue, fastUrlPath, handleParsonaClose } = useContext(DataContext)
    const { handleSubmit, control, watch, getValues, setError, setValue, clearErrors, reset, formState: { errors }, } = useForm({ values: {}, mode: 'onBlur' });
    const [formValid, setFormValid] = useState(false);
    useDidMountEffect(() => {
        profileGet()
    }, [])
    const [profileType, setProfileType] = useState([])
    const profileGet = () => {
        axios.get('/json/profiledetails.json', '').then((result) => {
            if (result.data) {
                setProfileType(result.data)
            }
        }).catch((err) => {

        });
    }
    const [profileRoleList, setProfileRoleList] = useState([])
    const handleSelectProfileType = (value) => {
        setValue('persona', value)
        clearErrors('persona')

        var val = profileType.filter((list) => list.profileName === value)
        setProfileRoleList(val[0].role)
        setValue('role', '')
        setError('role', 'Role is required')
    }
    const handleSelectProfileRole = (value) => {
        setValue('role', value)
        clearErrors('role')
    }
    const onProfileSubmit = () => {
        var data = getValues()
        if (data.persona === '') {
            return setError('persona', {
                message: "Profile Type is required"
            })
        }
        if (data.role === '') {
            return setError('role', {
                message: "Profile Role is required"
            })
        }
        setPageLoading(true)
        var type = profileType.filter((list) => list.profileName === data.persona)
        data.persona = type[0].profileType
        var role = profileRoleList.filter((list) => list.roleName === data.role)
        data.role = role[0].roleValue
        data.id = localStorage.getItem('id')
        // console.log(data.id, "idvalue")
        debugger
        apiService('user/register/persona/update', data, "post").then((res) => {
            setPageLoading(false)
            if (res && res.data) {
                if (res.data.responseStatus === "Success") {
                    localStorage.setItem('role', data.role)
                    localStorage.setItem('type', data.persona)
                    reset()
                    notifyService("success", "Success", "");
                    localStorage.removeItem('jwttoken')
                    login()
                }
            }
        });
    }
    const login = () => {
        var google = sessionStorage.getItem('GoogleType')
        var uid = sessionStorage.getItem('Uid',)
        var req = {
            "type": google,
            "uid": uid,
            "persona": localStorage.getItem("type"),
            "role": localStorage.getItem("role"),
        }
        apiService('login', req, 'unauthpost').then((res) => {
            // localStorage.setItem('continueWith', true)
            if (res && res.data) {
                if (res.data.response.responseStatus === 'Success') {
                    localStorage.setItem('jwttoken', res.data.token)
                    getApi()
                }
            }

        })
    }
    return (
        <>
            <Grid
                container
                direction="row"
                justifyContent="center"
                // alignItems="center"
                spacing={2}
                className='mt-3'
            >
                <Grid item lg={4} md={4} sm={12} xs={12}>
                    <Controller name="persona" control={control} defaultValue="" rules={{ required: `Profile Type is required` }}
                        render={({ field }) =>
                            <Autocomplete
                                disableClearable
                                disablePortal
                                {...field}
                                options={profileType.map((val => val.profileName))}
                                onChange={(e, selectedOptions) =>
                                    handleSelectProfileType(selectedOptions)
                                }
                                renderInput={(params) => (
                                    <TextField {...params} label="Select Profile Type  " />
                                )}
                            />} />
                    <FormHelperText className='text-danger'>{errors.persona && errors.persona.message}</FormHelperText>
                </Grid>
                {
                    (profileRoleList.length > 0) && (
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Controller name="role" control={control} defaultValue="" rules={{ required: `Role is required` }}
                                render={({ field }) =>
                                    <Autocomplete
                                        disableClearable
                                        disablePortal
                                        {...field}
                                        options={profileRoleList.map((val => val.roleName))}
                                        onChange={(e, selectedOptions) =>
                                            handleSelectProfileRole(selectedOptions)
                                        }
                                        renderInput={(params) => (
                                            <TextField {...params} label="Select Profile Role  " />
                                        )}
                                    />} />
                            <FormHelperText className='text-danger'>{errors.role && errors.role.message}</FormHelperText>
                        </Grid>
                    )
                }
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <div className='text-center'>
                        <Button onClick={onClose} className="discard-btn"> Close</Button>
                        <Button onClick={onProfileSubmit} className="publish-button">
                            Update
                        </Button>
                    </div>

                </Grid>
            </Grid>
        </>
    )
}

export default Thiruvizha24ProfileUpdate