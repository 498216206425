import React from "react";

const DeptService = ({ servicesList }) => {

  const colors = ["#5DA3A3", "#A291D5", "#3357FF", "#FF33A1", "#FFBD33"];
  return (
    <div>
      {servicesList.length > 0 ? (
        servicesList.map((entry, index) => (
          <div
            key={index}
            className="all-service-details-crd"
            style={{ borderLeft: `8px solid ${colors[index % colors.length]}` }}
          >
            <p className="service-paras-color">
              {index + 1}.&nbsp;{entry.name}
            </p>
            <p className="service-desc-color">{entry.description}</p>

            <div className="dept-serv-details">
              <div className="dept-serv-details-1">
                <div className="servdet-1">
                  <p>
                    <span>Category / Tags:</span> {entry.categories ? entry.categories.toLocaleString() : '-'}
                  </p>
                </div>
                <div className="servdet-1">
                  <p>
                    <span>Impact Created so far:</span>
                    {entry.impact}
                  </p>
                </div>
                {entry.initiatives && (
                  <div className="servdet-1">
                    <p>
                      <span>no. of Projects / Initiatives:</span>
                      {entry.initiatives}
                    </p>
                  </div>
                )}
              </div>
              <div className="dept-serv-details-1">
                <div className="servdet-1">
                  <p>
                    <span>Target Users / Beneficiaries:</span>
                    {entry.beneficiaries ? entry.beneficiaries.toLocaleString() : '-'}
                  </p>
                </div>
                <div className="servdet-1">
                  <p>
                    <span>Current Progress / Milestone:</span>
                    {entry.milestone}
                  </p>
                </div>
                {entry.events !== 0 && (
                  <div className="servdet-1">
                    <p>
                      <span>no. of Events Conducted so far:</span>
                      {entry.events}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        ))
      ) : (
        <>
          <div className='text-center p-3'>
            <h5 className='mb-4'>Service data not found</h5>
            <div>
              <img src="/images/blue horizontal.png" alt="blue horizontal.png" className='logo_dash img-fluid' />
            </div>
            <div className='mt-5'>
              <h3 className='title_p opa'>
                StartupTN 3.0
              </h3>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default DeptService;
