import axios from "axios";
import notifyService from "./notifySerivce";
import cryptoJS from "../hooks/cryptoJS";
const apiService = async (url, body, method) => {
  const URL = `${process.env.REACT_APP_API_URL}${url}`;
  const tenant = localStorage.getItem("jwttoken");
  let zoomTokenlet = localStorage.getItem("zoomToken");
  let encryToken;
  if (zoomTokenlet != null) {
    encryToken = cryptoJS(zoomTokenlet);
  }
  if (method === "unauthpost") {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    return axios.post(URL, body, config).catch(function (error) {
      handleError(error);
    });
  }
  if (method === "unauthget") {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    return axios.get(URL, config).catch(function (error) {
      handleError(error);
    });
  }
  if (method === "post") {
    const config = {
      headers: {
        "Content-Type": "application/json",
        token: tenant,
      },
    };
    return axios.post(URL, body, config).catch(function (error) {
      handleError(error);
    });
  }
  if (method === "get") {
    const config = {
      headers: {
        "Content-Type": "application/json",
        token: tenant,
      },
    };
    return axios.get(URL, config).catch(function (error) {
      handleError(error);
    });
  }
  if (method === "delete") {
    const config = {
      headers: {
        "Content-Type": "application/json",
        token: tenant,
      },
    };
    return axios.delete(URL, config).catch(function (error) {
      handleError(error);
    });
  }
  if (method === "download") {
    const config = {
      headers: {
        "Content-Type":
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        token: tenant,
      },
      responseType: "arraybuffer",
    };
    return axios.get(URL, config).catch(function (error) {
      handleError(error);
    });
  }
  if (method === "fileupload") {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        token: tenant,
      },
    };
    return axios.post(URL, body, config).catch(function (error) {
      handleError(error);
    });
  }
  if (method === "downloadpost") {
    const config = {
      headers: {
        "Content-Type": "application/json",
        token: tenant,
      },
      responseType: "arraybuffer",
    };
    return axios.post(URL, body, config).catch(function (error) {
      handleError(error);
    });
  }
  if (method === "zoom_post") {
    const config = {
      headers: {
        "Content-Type": "application/json",
        token: tenant,
        key: encryToken,
      },
    };
    return axios.post(URL, body, config).catch(function (error) {
      handleError(error);
    });
  }
  if (method === "zoom_get") {
    const config = {
      headers: {
        "Content-Type": "application/json",
        token: tenant,
        key: encryToken,
      },
    };
    return axios.get(URL, config).catch(function (error) {
      localStorage.removeItem("zoomToken");
      handleError(error);
    });
  }
  if (method === "zoom_delete") {
    const config = {
      headers: {
        "Content-Type": "application/json",
        token: tenant,
        key: encryToken,
      },
    };
    return axios.delete(URL, config).catch(function (error) {
      handleError(error);
    });
  }
};

const handleError = (error) => {
  // console.log(error)
  if (error.response) {
    if (error.response.status === 403) {
      window.location.assign('/sesstion-timeout')
    } else if (error.response.status === 400) {
      return notifyService('danger', 'Internal server error !!', 'Please try again later')
    } else if (error.response.data.errorResponseDTO && error.response.data.errorResponseDTO.errorMessage) {
      return notifyService('danger', 'Error', error.response.data.errorResponseDTO.errorMessage)
    } else {
      return notifyService('danger', 'Internal server error !!', 'Please try again later')
    }
  } else if (error.message === 'Network Error') {
    console.log(error.message);
    window.location.assign('/under-maintenance')

  } else {
    return notifyService(
      "danger",
      "Internal server error !!",
      "Please try again later"
    );
  }
}

export default apiService;
