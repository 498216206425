import React from 'react'
import { Button, Dialog, DialogContent, Grid, IconButton } from '@mui/material'
import { useContext } from 'react'
import DataContext from '../../../../context/DataContext'
import DoneIcon from '@mui/icons-material/Done';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
// import DelegateModal from './DelegateModal';
import { useState } from 'react';
// import useDidMountEffect from '../../../../../hooks/useDidMountEffect';
import apiService from '../../../../api/apiService';
import cryptodecryptJS from '../../../../hooks/cryptodecryptJS';
import { useForm } from 'react-hook-form';
import ThiruvizhaLogin from './ThiruvizhaLogin';
import Thiruvizha24DetailsModal from './Thiruvizha24DetailsModal';
import CloseIcon from "@mui/icons-material/Close";

const Thiruvizha24Modal = ({ handleProfileClose }) => {
    const { navigator, ProfileDialogBox, fastUrlPath, handleProfileDialogCloseBox, setPageLoading } = useContext(DataContext)

    // const { handleSubmit, control, setValue, getValues, clearErrors, formState: { errors } } = useForm({ values: formData })

    var id = localStorage.getItem("id");
    var type = localStorage.getItem('type')
    // useDidMountEffect(() => {
    //     if (id !== null && type !== 'NA') {
    //         getApi();
    //     }
    // }, [])
    // const [formData, setFormData] = useState({});
    const getApi = () => {

        if (id !== null && type !== 'NA') {
            setPageLoading(true)

            apiService(`userprofile/get?persona=${type}&userid=${id}`, "", "get")
                .then((result) => {
                    setPageLoading(false)
                    if (result && result.data) {
                        var data = result.data;
                        if (data.email) {
                            handleFreepass(data.email)
                        }
                    }
                })
                .catch((err) => {
                });
        } else {

        }

    };
    const [delegateModalOpen, setDelegateModalOpen] = useState(false);
    const handleDelegateModalOpen = () => {
        setDelegateModalOpen(true);
    };
    const handleDelegateModalClose = () => {
        setDelegateModalOpen(false);
    };

    const handleFreepass = (email) => {
        localStorage.removeItem('currentPath');
        // var email = localStorage.getItem('email')
        // 
        var conferenceurl = `https://event.startuptn.in/conference?email=${cryptodecryptJS(email)}`
        localStorage.setItem("conferencePath", conferenceurl)
        localStorage.removeItem('way')
        window.location.href = conferenceurl;
        navigator("/fest");
    }
    const [openType, setOpenType] = useState(false)
    const [openTypeValue, setOpenTypeValue] = useState('')
    var token = localStorage.getItem("jwttoken");
    var email = localStorage.getItem("email");
    var personaName = localStorage.getItem("type");
    var profileupdated = localStorage.getItem("profile")
    const openTypeModal = (value) => {
        // setDelegateModalOpen(true)
        setOpenTypeValue(value)
        if (value === 'Delegate') {
            // let type = localStorage.getItem('type')
            const currentPath = window.location.pathname;
            localStorage.setItem('currentPath', currentPath);
            setDelegateModalOpen(true)
            // if (!token) {
            //     // setProfileDialog(false);
            //     // navigator('/login');                
            //     return;
            // } else if (token && profileupdated === "false") {
            //     if (type === 'NA') {
            //         var id = Number(localStorage.getItem('userId'))
            //         navigator(`letsstart/${btoa(id)}`)
            //         // setProfileDialog(false);
            //     } else {
            //         setDelegateModalOpen(true)
            //     }

            // }
            // if (token && email) {
            //     if (type === 'NA') {
            //         var id = Number(localStorage.getItem('userId'))
            //         navigator(`letsstart/${btoa(id)}`)
            //         // setProfileDialog(false);
            //     } else {

            //         setDelegateModalOpen(true)
            //     }
            // } else if (profileupdated === "true") {
            //     // setProfileDialog(false);
            //     var email = localStorage.getItem('email')
            //     localStorage.removeItem('currentPath');
            //     var conferenceurl = `https://event.startuptn.in/conference?email=${cryptodecryptJS(email)}`
            //     localStorage.setItem("conferencePath", conferenceurl)
            //     localStorage.removeItem('way')
            //     window.location.href = conferenceurl;
            //     navigator("/fest");
            // } else {
            //     if (type === 'NA') {
            //         var id = Number(localStorage.getItem('userId'))
            //         navigator(`letsstart/${btoa(id)}`)
            //         // setProfileDialog(false);
            //     }
            //     else {
            //         setDelegateModalOpen(true);
            //     }
            // }
        } else {
            const currentPath = window.location.pathname;
            localStorage.setItem('currentPath', currentPath);
            setDelegateModalOpen(true)
            // if (!token) {
            //     // setProfileDialog(false);
            //     const currentPath = window.location.pathname;
            //     localStorage.setItem('currentPath', currentPath);
            //     setOpenThirivzha(true)
            //     return;
            // }
            // if (token && email && profileupdated === "true") {
            //     var conferenceurl = `https://event.startuptn.in/authorize/login?email=${cryptodecryptJS(email)}`
            //     localStorage.setItem("conferencePath", conferenceurl);
            //     window.location.href = conferenceurl;
            // } else {
            //     var type = localStorage.getItem('type')
            //     if (type === 'NA') {
            //         var id = Number(localStorage.getItem('userId'))
            //         navigator(`letsstart/${btoa(id)}`)
            //         // setProfileDialog(false);
            //     } else {
            //         setDelegateModalOpen(true);
            //     }

            // }
        }
    }

    const [openThirivzha, setOpenThirivzha] = useState(false)
    const handelCloseThirivizha = () => {
        setOpenThirivzha(false)
    }
    return (
        <>
            <div>
                <DialogContent sx={{ padding: '30px 47px' }}>
                <div className='text-right'>
                                    <IconButton
                                        aria-label="close"
                                        onClick={handleProfileClose}
                                        sx={{
                                            backgroundColor: "red",
                                            borderRadius: "50%",
                                            color: "white",
                                            padding: "1px",
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </div>
                    <div className='text-center mb-3'>
                        <img src='/images/thiruzila_logo.png' className='thiruzila_logo' />
                    </div>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                        spacing={2}
                    >
                        {/* <Grid item lg={6} md={6} sm={12} xs={12}>
                            <div className='dele-box'>
                                <div className='dele-head' onClick={() => openTypeModal('Delegate')} >
                                    <h5 className='m-0'>Delegate Pass</h5>
                                </div>
                                <div className='price-div'>
                                    Rs. 1000 <span>+Tax</span><br />
                                    <span>Per Person</span>
                                </div>
                                <div className='mx-4'>
                                    <div className='d-flex'>
                                        <DoneIcon className='tick-clr mr-3' />
                                        <p className='tick-clr'> Access to Exhibition Area </p>
                                    </div>
                                    <div className='d-flex'>
                                        <DoneIcon className='tick-clr mr-3' />
                                        <p className='tick-clr'> Access to Conference </p>
                                    </div>
                                    <div className='d-flex'>
                                        <DoneIcon className='tick-clr mr-3' />
                                        <p className='tick-clr'> Two days lunch will be provided </p>
                                    </div>
                                </div>
                            </div>
                        </Grid> */}
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <div className='dele-box'>
                                <div className='dele-head' onClick={() => openTypeModal('Free')}>
                                    <h5 className='m-0'>Visitor Pass</h5>
                                </div>
                                <div className='price-div'>
                                    Free
                                </div>
                                <div className='mx-5'>
                                    <div className='d-flex'>
                                        <DoneIcon className='tick-clr mr-3' />
                                        <p className='tick-clr'> Access to Exhibition Area </p>
                                    </div>
                                    <div className='d-flex'>
                                        <CloseRoundedIcon className='close-clr mr-3' />
                                        <p className='close-clr'> Access to Conference </p>
                                    </div>
                                    <div className='d-flex'>
                                        <CloseRoundedIcon className='close-clr mr-3' />
                                        <p className='close-clr'> No Food will be provided</p>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>

                <div className='text-center pb-3'>
                    <Button
                        variant="contained"
                        className="edit-btn-profile"
                        sx={{
                            textTransform: "none",
                            border: "1px solid",
                            borderColor: "#020671",
                            color: "#fff",
                            padding: "6px 20px",
                            background: "#00023A",
                            marginRight: "10px"
                        }}
                        onClick={handleProfileClose}
                    >
                        Close
                    </Button>
                </div>
            </div>
            <Dialog fullWidth maxWidth="lg" open={delegateModalOpen}>
                <Thiruvizha24DetailsModal onClose={handleDelegateModalClose} openTypeValue={openTypeValue} handleProfileClose={handleProfileClose} />
            </Dialog>
            {/* <Dialog fullWidth={true} open={openThirivzha} onClose={handelCloseThirivizha} maxWidth={'sm'}>
                <ThiruvizhaLogin handelCloseThirivizha={handelCloseThirivizha} handleProfileClose={handleProfileClose} />
            </Dialog> */}
        </>
    )
}


export default Thiruvizha24Modal