import { Grid, Container, Button, Chip, Divider } from "@mui/material";
import { IoCheckmark } from "react-icons/io5";
import { IoMailOpenOutline } from "react-icons/io5";
import { FaArrowLeft } from "react-icons/fa6";
import React, { useContext, useState } from "react";
import DataContext from "../../../../context/DataContext";
import { useParams } from "react-router-dom";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import apiService from "../../../../api/apiService";
import { useFieldArray, useForm } from "react-hook-form";

const SupportTicketDetails = () => {
  const { id } = useParams()
  let ids
  if (id !== null) {
    ids = atob(id)
  }
  const { navigator, setPageLoading } = useContext(DataContext)
  const [formData, setFormData] = useState({});
  const { control, reset, setValue, getValues, handleSubmit, formState: { errors }, } = useForm({ values: formData, });
  useDidMountEffect(() => {
    getApi();
  }, [ids]);
  const {
    fields: photosFields,
    append: photosAppend,
    remove: photosRemove,
  } = useFieldArray({
    control,
    name: "attachments",
  });
  const getApi = () => {
    setFormData({})
    setPageLoading(true)
    apiService(`support/ticket/get?id=${ids}`, "", "get")
      .then((result) => {
        setPageLoading(false)
        if (result && result.data) {
          var data = result.data;
          setFormData(data);
        }
      })
      .catch((err) => { });
  };
  const handleViewQuery = (id) => {
    navigator(`startup/support/query/${btoa(ids)}`)
  }
  return (
    <div className='ticketadd mb-5 pt-4'>
      <div className="pad-x">
        <div className='empower-box mb-3'>
          <p className='empower-p text-center'>{formData.queryTitle}</p>
          <p className="text-center pb-3"><span className="gray">Ticket no : </span><span className="blue">{formData.ticketNo}</span></p>
        </div>
        <div className="mb-3">
          <div className="text-center mb-2">
            <div className="">
              <span className="dar-blue">Created at : </span><span className="grey">{formData.creationTime}</span>
            </div>
          </div>

        </div>
        <hr />
        <div className="mb-4">
          <div className="my-4">
            <span className="dar-blue">Category</span>
          </div>
          <div className="">
            <span >
              {formData.categoryName}
            </span>
          </div>
        </div>
        <div className="mb-4">
          <div className="my-4">
            <span className="dar-blue">Project</span>
          </div>
          <div className="">
            <span >
              {formData.serviceName}
            </span>
          </div>
        </div>
        <div className="mb-4">
          <div className="my-4">
            <span className="dar-blue">Query Description</span>
          </div>
          <div className="">
            <span >
              {formData.queryDesc}
            </span>
          </div>
        </div>
        <div>
          <div className="attach-blue my-4">
            <span>Attachments</span>
          </div>
          <div className="d-flex">
            {photosFields.map((field, index) => (
              <div key={index} >
                {getValues(`attachments.${index}.fileName`) && (
                  <>
                    <div className="">
                      <img
                        src={getValues(`attachments.${index}.fileName`)}
                        className="d-flex"
                        alt={`attachments.${index}.fileName`}
                        style={{
                          width: "67px",
                          objectFit: 'contain',
                          marginLeft: '10px',
                        }}
                      />
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
          <center>
            <Button className='query-btn' onClick={() => handleViewQuery(1)}>
              Go to Query
            </Button>
          </center>
        </div>
      </div>
    </div>
  )
}

export default SupportTicketDetails