
import React from 'react'
import TnFestFooter from './TnFestFooter'
import FastNavbar from './FastNavbar'

const Tnfesttesrmsandcondition = () => {
    return (
        <>

            <section className="termscond-page">
                <FastNavbar/>
                <div className="back-color">
                    <div className="back-layer"></div>
                    <div className="back-layer1"></div>

                    <h1 className='terms-heading mb-3 '>Terms and Conditions</h1>

                    <div className='term-box'>
                        <div className='container'>
                            <h4 className='blck-clr pt-5'>Acceptance of Terms:</h4>
                            <p className='privacypadding'>By accessing or using event.startuptn.in, you agree to comply with and be bound by the following terms and conditions. If you do not agree, please do not use our site.</p>

                            <h4 className='blck-clr pt-5'>Modification of Terms:</h4>
                            <p className='privacypadding'>StartupTN reserves the right to modify these terms at any time without prior notice. Your continued use of the site constitutes acceptance of any changes.</p>

                            <h4 className='blck-clr pt-5'>User Responsibilities:</h4>
                            <p className='privacypadding'>Users must use the website responsibly and ethically. You agree not to misuse the site, including, but not limited to, unauthorized access, data mining, or any activity that disrupts the site's functionality. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.</p>

                            <h4 className='blck-clr pt-5'>Account Registration:</h4>
                            <p className='privacypadding'>Users may be required to create an account to access certain features of the site. During registration, you agree to provide accurate and complete information. You are responsible for updating your account information to keep it current and accurate.</p>

                            <h4 className='blck-clr pt-5'>Intellectual Property Rights:</h4>
                            <p className='privacypadding'>All content on this website, including text, graphics, logos, icons, images, and software, is the property of StartupTN and is protected by international copyright laws. You may not reproduce, distribute, or create derivative works from any content without explicit permission from StartupTN.</p>

                            <h4 className='blck-clr pt-5'>Liability Limitations:</h4>
                            <p className='privacypadding'>StartupTN is not liable for any direct, indirect, incidental, special, or consequential damages arising from the use of, or the inability to use, the website or its services, including damages for loss of profits, use, data, or other intangible losses.</p>

                            <h4 className='blck-clr pt-5'>Indemnification:</h4>
                            <p className='privacypadding'>You agree to indemnify, defend, and hold harmless StartupTN, its affiliates, officers, directors, employees, agents, licensors, and suppliers from and against any claims, liabilities, damages, losses, and expenses, including legal fees, arising out of or in any way connected with your use of the website or violation of these terms.</p>

                            <h4 className='blck-clr pt-5'>Governing Law:</h4>
                            <p className='privacypadding mb-3'>These terms and conditions are governed by the laws of India. Any disputes arising from these terms shall be subject to the exclusive jurisdiction of the courts in Chennai, Tamilnadu.</p>
                        </div>

                    </div>
                </div>
            </section>
            <TnFestFooter />
        </>
    )
}

export default Tnfesttesrmsandcondition