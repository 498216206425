import React from 'react'

const StartupIndia = () => {
    const callBack = `${process.env.REACT_APP_LOGINCALLBACK}`;
    const handleStartupIndia = () => {
        const clientId = 'TN24fa2oSIfAkE';
        const redirectUri = callBack; // Your redirect URI
        const jwt='2163fe9d-784c-46b5-b2ac-ec12992102d3'
        const linkedInAuthUrl = `https://www.startupindia.gov.in/content/sih/en/sso-login.html?clientid=${clientId}&jwt=${jwt}&callback=${encodeURIComponent(redirectUri)}`;
        window.location.href = linkedInAuthUrl;
    }
    return (
        <button className='continue_btn oauth-login' onClick={handleStartupIndia}>Continue with <img src='/images/logo/startupindia_logo.png' alt='' className="startupindia_logo"/></button>
    )
}

export default StartupIndia