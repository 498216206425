import { Button, Grid, Box, Divider, FormHelperText, Autocomplete, FormControl, TextField, DialogTitle, InputAdornment, FormControlLabel, Checkbox } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import Modal from "@mui/material/Modal";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import UserProfileReport from "./UserProfileReport";
import { FaPhoneAlt } from "react-icons/fa";
import { MdOutlineMail } from "react-icons/md";
import { CiGlobe } from "react-icons/ci";
import { styled } from "@mui/material/styles";
import DataContext from "../../../../context/DataContext";
import apiService from "../../../../api/apiService";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import EditIcon from "@mui/icons-material/Edit";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import notifyService from "../../../../api/notifySerivce";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import downloadPDF from "../../../../hooks/downloadPDF";
import cryptoJS from "../../../../hooks/cryptoJS";
import MaskDialog from "../../Mask-Dialogbox/MaskDialog";
import JsBarcode from 'jsbarcode';
import QRCode from 'qrcode.react';
import Qrcode from "../Qr-Barcode/Qrcode";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import { Swiper, SwiperSlide } from "swiper/react";
// import ApplicationStatus from "../Applicationstatus-page/ApplicationStatus";


const Userprofile = ({ names, path }) => {
  const { navigator, validateNumberonly, setPageLoading, VisuallyHiddenInput, validatePhoneNumber, fullScreen, modalstyle,
    linkedInPattern, validateEmail, addbtnShow, setAddbtnShow,
    getApi, para, formData, handleOpenEdit, editBtn, setEditBtn, handleEditBtn, editKey, setEditKey, userProfileId, showViewbtn, handlemaskClickOpen, isWhitespace, showUidNumber } = useContext(DataContext);
    debugger


  const { handleSubmit, control, getValues, setValue, clearErrors, formState: { errors } } = useForm({ values: formData });
  const {
    fields: founderFields,
    append: founderAppend,
    remove: founderRemove,
  } = useFieldArray({
    control,
    name: "founders",
  });
  // var founder = getValues('founders')
  // if (founder) {
  //   setEditKey(founder.length)
  // } else {
  //   setEditKey(0)
  // }

  localStorage.setItem('profID', userProfileId)
  useDidMountEffect(() => {
    getApi();
    setQrModalOpen(false)
    setEditBtn(false)
    setEditKey(false)
  }, [userProfileId]);

  useDidMountEffect(() => {

    if (names === 'add') {
      var founder = getValues('founders')
      console.log(founder);

      if (founder === undefined) {
        founderAppend()
        setEditKey(0)
        console.log(founder, 'founderempty');
        // debugger
      } else {
        // founderAppend()
        addFounderBtn()
        // setEditKey(founder.length+1)/
        console.log(founder.length, 'founder');

        // debugger
      }
    }
    debugger
  }, [formData])

  useDidMountEffect(() => {
    if (userProfileId === 0) {
      businessModelsdropdownApi();
      sectordropdownApi();
      DesignationApi()
      dropdownGenderApi()
    }
  }, []);
  const [businessModelList, setBusinessModelList] = useState([]);
  const businessModelsdropdownApi = () => {
    apiService(
      `admin/dropdownvalues/get?key=${"businessmodel"}`,
      "",
      "unauthget"
    )
      .then((result) => {
        if (result) {
          if (result.data) {
            if (result.data.dropDownValues) {
              var dropDown = result.data.dropDownValues;
              setBusinessModelList(dropDown.values);
            }
          }
        }
      })
      .catch((err) => { });
  };
  const [sectors, setSectors] = useState([]);
  const sectordropdownApi = () => {
    apiService("home/sector/list", "", "unauthget")
      .then((result) => {
        if (result) {
          if (result.data) {
            if (result.data.sectors) {
              setSectors(result.data.sectors);
            }
          }
        }
      })
      .catch((err) => { });
  };
  const [designation, setDesignation] = useState([]);
  // const DesignationApi = () => {
  //   apiService(`home/designation/list`, "", "get")
  //     .then((result) => {
  //       if (result && result.data) {
  //         if (result.data.designations) {
  //           setDesignation(result.data.designations);
  //         }
  //       }
  //     })
  //     .catch((err) => { });
  // };
  const DesignationApi = () => {
    apiService(`admin/dropdownvalues/get?key=${"designation"}`, "", "unauthget")
      .then((result) => {
        if (result && result.data) {
          if (result.data.dropDownValues.values) {
            setDesignation(result.data.dropDownValues.values);

          }
        }
      })
      .catch((err) => { });
  };
  const [gender, setGender] = useState([]);
  const dropdownGenderApi = () => {
    apiService(`admin/dropdownvalues/get?key=${"gender"}`, "", "unauthget")
      .then((result) => {

        if (result) {
          if (result.data) {
            if (result.data.dropDownValues) {
              var dropDown = result.data.dropDownValues;
              setGender(dropDown.values);
            }
          }
        }
      })
      .catch((err) => { });
  };
  const [opens, setOpens] = useState(false);
  const handleReportOpen = () => {
    setOpens(true);
  };
  const handleReportClose = () => {
    setOpens(false);
  };

  const service = [
    "Company Incorporated Certificate",
    "PAN CARD",
    "Funding Projection",
    "BDD Report",
    "Community Certificate",
    "DPIIT Certificate",
    "IT & GST Certificate",
    "Others",
  ];
  const [activeButtons, setActiveButtons] = useState(null);
  const handleButtonClicks = (service) => {
    setActiveButtons(service);
  };


  useDidMountEffect(() => {
    setActiveButtons("Company Incorporated Certificate");
    handleButtonClick("TANSEED 1.0 Beneficiery");
    dropdownApi();
  }, []);

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [applicationStatusOpen, setApplicationStatusOpen] = useState(false);
  const handleappstatusClose = () => setApplicationStatusOpen(false);
  const [activeButton, setActiveButton] = useState(null);

  const handleButtonClick = (service) => {
    setActiveButton(service);
    if (service === "Document Room") {
      setOpen(true);
    } else if (service === "Report") {
      handleReportOpen();
    } else if (service === 'History Card') {
      navigator(`/startup/history-card`)
    } else if (service === 'Application Status') {
      setApplicationStatusOpen(true)
    }
  };



  const [showFull, setShowFull] = useState(false);

  const toggleReadMore = () => {
    setShowFull(!showFull);
  };
  // const fullText = "Apple Inc. is a global Apple Inc. is a globalApple Inc. is a globalApple Inc. is a global";
  const truncateText = (text, wordLimit) => {
    const words = text.split(" ");
    return words.length > wordLimit
      ? words.slice(0, wordLimit).join(" ") + "..."
      : text;
  };
  const wordLimit = 30;
  const displayText = showFull ? para : truncateText(para, wordLimit);
  const isTruncated = para.split(" ").length > wordLimit;

  let name = localStorage.getItem("name");
  const supportPage = () => {
    navigator(`/startup/support/${name}/add`);
  };

  const [startupStages, setStartupStages] = useState([]);
  const dropdownApi = () => {
    apiService(`admin/dropdownvalues/get?key=${"stages"}`, "", "unauthget")
      .then((result) => {
        if (result) {
          if (result.data) {
            if (result.data.dropDownValues) {
              var dropDown = result.data.dropDownValues;
              setStartupStages(dropDown.values);
            }
          }
        }
      })
      .catch((err) => { });
  };


  // const [editBtn, setEditBtn] = useState(false);
  // const handleOpenEdit = () => {
  //   setEditBtn(true);
  // };
  // const [editKey, setEditKey] = useState("");
  // const handleEditBtn = (key) => {
  //   setEditKey(key);
  // };
  let logoselecetdFile = "";
  const handleFileUpload = (event, filekey, fileName, fileUrl) => {
    if (event !== null) {
      if (event.target === undefined) {
        logoselecetdFile = event
      } else {
        logoselecetdFile = event.target.files[0]
      }
      const maxFileSize = 5 * 1024 * 1024; // 5MB in bytes
      if (logoselecetdFile) {
        if (logoselecetdFile.size < maxFileSize) {
          var reader = new FileReader();
          var imagetype = logoselecetdFile.type;
          var imagedatatype = imagetype.split("/");
          var img_crt_type = imagedatatype[1];
          if (
            img_crt_type === "jpg" ||
            img_crt_type === "png" ||
            img_crt_type === "jpeg"
          ) {
            setValue(filekey, "");
            setValue(fileName, "");
            setValue(fileUrl, '')
            var fileValue = logoselecetdFile;
            reader.readAsDataURL(logoselecetdFile);
            reader.onload = () => {
              var logourl1 = reader.result;
              var spl = logourl1.split(",");
              var ImageValue = spl[1];
              var img_name = fileValue.name;
              // setUrl(logourl1)
              setValue(filekey, ImageValue);
              setValue(fileName, img_name);
              setValue(fileUrl, logourl1)
              clearErrors(filekey);
              clearErrors(fileName);
            };
          } else {
            notifyService(
              "danger",
              "File Format Invalided",
              "Please check your file format"
            );
          }
        } else {
          notifyService(
            "danger",
            "File Size Exceeded",
            `Maximum file size allowed is 5 MB`
          );
        }
      }
    }
  };
  const handleSelectSector = (value) => {
    setValue("sector", value);
    clearErrors("sector");
  };
  const addFounderBtn = () => {
    var founder = getValues('founders');

    if (founder) {
      setEditKey(founder.length);
    } else {
      setEditKey(0);
    }

    // Check if founder is defined and has elements
    const lastFounder = founder && founder.length > 0 ? founder[founder.length - 1] : null;

    if (!lastFounder || (lastFounder.id !== 0 && lastFounder.emailVerify === true)) {
      founderAppend({
        id: 0,
        type: localStorage.getItem("role"),
        name: "",
        email: "",
        phone: "",
        gender: "",
        designation: "",
        profile: "",
        profileName: "",
        profileUrl: "",
        emailVerify: ''
      });
      setAddbtnShow(false)
    } else if (lastFounder.id === 0 && lastFounder.emailVerify === true) {
      founderAppend({
        id: 0,
        type: localStorage.getItem("role"),
        name: "",
        email: "",
        phone: "",
        gender: "",
        designation: "",
        profile: "",
        profileName: "",
        profileUrl: "",
        emailVerify: ''
      });
      setAddbtnShow(false)
    } else {
      notifyService('danger', 'Error', 'Please fill the mandatory fields');
    }
  };

  // const addFounderBtn = () => {
  //   var founder = getValues('founders')
  //   if (founder) {
  //     setEditKey(founder.length)
  //   } else {
  //     setEditKey(0)
  //   }

  //   if (founder === undefined || (founder[founder.length - 1].id !== 0 && founder[founder.length - 1].emailVerify === true)) {
  //     founderAppend({
  //       id: 0,
  //       type: localStorage.getItem("role"),
  //       name: "",
  //       email: "",
  //       phone: "",
  //       gender: "",
  //       designation: "",
  //       profile: "",
  //       profileName: "",
  //       profileUrl: "",
  //       emailVerify: ''
  //     });
  //   } else if (founder[founder.length - 1].id === 0 && founder[founder.length - 1].emailVerify === true) {
  //     founderAppend({
  //       id: 0,
  //       type: localStorage.getItem("role"),
  //       name: "",
  //       email: "",
  //       phone: "",
  //       gender: "",
  //       designation: "",
  //       profile: "",
  //       profileName: "",
  //       profileUrl: "",
  //       emailVerify: ''
  //     });
  //   } else {
  //     // debugger
  //     notifyService('danger', 'Error', 'Please fill the mandatory fields')
  //   }

  // };
  const founderDetailsRemove = (index) => {
    var id = getValues(`founders.${index}.id`);
    if (id !== 0) {
      apiService(`userprofile/founder/remove?id=${id}`, '', 'delete').then((result) => {
        if (result && result.data) {
          founderRemove(index);
          // if (index === 0) {
          //   founderAppend()
          // }
          setEditKey(null);
          getApi()
          setAddbtnShow(true)
          notifyService("success", "Success", "Sucessfully Deleted");
        }
      }).catch((err) => {
      });
    } else {
      founderRemove(index);
      setAddbtnShow(true)
    }

  };
  const handleSelectDesignation = (value, index) => {
    setValue(`founders.${index}.designation`, value);
    clearErrors(`founders.${index}.designation`);
  };

  const redirectUrl = localStorage.getItem('conferencePath');
  const onSubmit = (data) => {
    const sector = sectors.filter((val) => val.name === data.sector);
    data.sectorId = sector[0].id.toString();
    data.phone = cryptoJS(data.phone);
    data.email = cryptoJS(data.email);
    if (data.founders) {
      data.founders.forEach(founder => {
        if (founder.email) {
          founder.email = cryptoJS(founder.email);
        }
        if (founder.phone) {
          founder.phone = cryptoJS(founder.phone);
        }
      });
    }
    apiService("userprofile/save", data, "post")
      .then((result) => {
        if (result.data.responseStatus === "Success") {
          if (editKey === "") {
            setEditBtn(false);
          } else {
            setEditKey("");
          }
          if (redirectUrl) {
            // navigator(`/${redirectUrl}`);
            window.location.href = redirectUrl
            localStorage.removeItem('conferencePath');
            debugger
          }
          setAddbtnShow(true)
          getApi();
        }
      })
      .catch((err) => { });
  };
  // const onSubmit = (data) => {
  //   debugger
  //   console.log(data,"FormDatas");
    
  //   var list = data.founders
  //   if (data.founders) {
  //     data.founders.forEach(founder => {
  //       if (founder.email) {
  //         founder.email = cryptoJS(founder.email);
  //         console.log(founder.email,"submit");
          
  //       }
  //       if (founder.phone) {
  //         founder.phone = cryptoJS(founder.phone);
  //         console.log(founder.phone,"submit");
  //       }
  //     });
  //   }
  //   debugger
  //   apiService("userprofile/founder/save", list, "post")
  //     .then((result) => {
  //       if (result.data.responseStatus === "Success") {
  //         if (editKey === "") {
  //           setEditBtn(false);
  //         } else {
  //           setEditKey("");
  //         }
  //         if (redirectUrl) {
  //           window.location.href = redirectUrl
  //           localStorage.removeItem('conferencePath');
  //           debugger
  //         }
  //         setAddbtnShow(true)
  //         getApi();
  //       }
  //     })
  //     .catch((err) => { });
  // };


  const onError = (e) => {
    console.log(e)
  };

  const [qrModalopen, setQrModalOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');

  const handleClickOpen = () => {
    setQrModalOpen(true);
  };

  const handleModalClose = () => {
    setQrModalOpen(false);
  };
  // const downloadBarcode = () => {
  //   const link = document.createElement('a');
  //   link.setAttribute('target', '_blank');
  //   link.setAttribute('href', formData.barcode);
  //   link.setAttribute('download', formData.barcode); // Set desired file name here
  //   document.body.appendChild(link);
  //   link.click();
  //   link.remove();
  // }
  // const downloadQrcode = () => {
  //   const link = document.createElement('a');
  //   link.setAttribute('target', '_blank');
  //   link.setAttribute('href', formData.qr);
  //   link.setAttribute('download', formData.qr); // Set desired file name here
  //   document.body.appendChild(link);
  //   link.click();
  //   link.remove();
  // }
  // var val = formData.uid
  const [emailVerify, setEmailVerify] = useState(null)
  const handleEmailVerfication = (value, index) => {
    setEmailVerify(null)
    if (value.length > 3) {
      var email = value.toLowerCase()
      apiService(`founder/email/verify?email=${cryptoJS(email)}`, '', 'get').then((result) => {
        if (result && result.data && result.data.responseStatus === 'Success') {
          setEmailVerify(true)
          setValue(`founders.${index}.emailVerify`, true)
        } else {
          setEmailVerify(false)
          notifyService('danger', 'Error', result.data.responseMessage)
          setValue(`founders.${index}.emailVerify`, false)
        }
      }).catch((err) => {
        setEmailVerify(null)
        setValue(`founders.${index}.emailVerify`, null)
      });
    }
  }
  const [smartOpen, setSmartOpen] = useState(false)
  const availsmartopen = () => {
    setPageLoading(true)
    setSmartOpen(true)
    apiService('userprofile/smartcard/avail', '', 'get')
      .then((result) => {
        setPageLoading(false)
        setTimeout(() => {
          availsmartClose()
        }, 1500);
        getApi()
      }).catch((err) => {
        console.log(err);
      });
  }
  const availsmartClose = () => {
    setSmartOpen(false)
  }
  const founderSaveapi = (data) => {
    console.log(data, "founder")
    // data.phone = cryptoJS(data.phone);
    // data.email = cryptoJS(data.email);
    // apiService("founder/save", data, "post")
    // .then((result) => {
    //   console.log(result.data)
    //   getApi();
    // }).catch((err) => {
    //   console.log(err)
    // });
  }
  return (
    <div className="user-profile">
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="user-box">
          <div className="first-box">

            <div className="all-userdetails">
              <div className="d-flex align-items-center">
                {/* <div>
                  <img
                    className="user-brandlogo img-fluid"
                    src={formData.companyLogoURL}
                    alt="logo"
                  />
                </div> */}
                <div>
                  {editKey === "imageName" ? (
                    <div className="form-group mt-2">
                      <div className="logo_div">
                        <img
                          src={getValues('imageUrl')}
                          className="user_logo"
                          alt={getValues('imageName')}
                        />
                        <div className="camera_icon">
                          <Controller
                            name="imageName"
                            control={control}
                            defaultValue=""
                            rules={{ required: `logo is required` }}
                            render={({ field }) => (
                              <Button
                                {...field}
                                onChange={(e) =>
                                  handleFileUpload(e, "image", "imageName", "imageUrl")
                                }
                                component="label"
                                startIcon={<CameraAltIcon />}
                              >
                                <VisuallyHiddenInput type="file" />
                              </Button>
                            )}
                          />
                        </div>
                        <FormHelperText className="text-danger">
                          {errors.imageName && errors.imageName.errorMessage}
                        </FormHelperText>
                      </div>

                      <div className="img-btn-div">
                        <Button
                           type="submit" 
                          sx={{ marginLeft: "10px" }}>
                          <DoneOutlineIcon
                            sx={{ fontSize: "16px", cursor: "pointer" }}
                          />
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <span>
                      {" "}
                      <img
                        className="user-brandlogo img-fluid"
                        src={
                          formData.imageName
                            ? formData.imageName
                            : "/images/user.png"
                        }
                        alt="logo"
                      />
                      {editBtn === true && (
                        <EditIcon
                          sx={{
                            fontSize: "14px",
                            cursor: "pointer",
                            marginLeft: "5px",
                          }}
                          onClick={() => handleEditBtn("imageName")}
                        />
                      )}
                    </span>
                  )}
                </div>
                <div className="brand-details ml-3">
                  {/* <h4 className="font-weight-bold">
                    {formData.startupName}
                    <span>
                      <img src={"/images/verified-tick.png"} alt="verified" />
                    </span>
                  </h4> */}
                  {editKey === "name" ? (
                    <div className="d-inline-block">
                      <div className="d-flex align-items-start">
                        <div>
                          <Controller
                            name="name"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: 'Name is required', validate: {
                                noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                              },
                            }}
                            render={({ field }) => (
                              <input
                                placeholder="Enter name"
                                type="text"
                                {...field}
                                className="sml-inp"
                              />
                            )}
                          />
                          <FormHelperText className="text-danger">
                            {errors.name && errors.name.message}
                          </FormHelperText>
                        </div>
                        <Button
                        type="submit"
                        >
                          <DoneOutlineIcon
                            sx={{ fontSize: "16px", cursor: "pointer" }}
                          />
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <span className="inv-prof-name">
                      <h4 className="font-weight-bold">
                        {formData.name}

                        <span>
                          <img
                            src={"/images/verified-tick.png"}
                            alt="verified"
                          />
                        </span>
                      </h4>
                      {editBtn === true && (
                        <EditIcon
                          sx={{
                            fontSize: "14px",
                            cursor: "pointer",
                            marginLeft: "5px",
                          }}
                          onClick={() => handleEditBtn("name")}
                        />
                      )}
                    </span>
                  )}
                  <p className="para mb-0">
                    {editKey === "Sector" ? (
                      <div className="d-inline-block">
                        <div className="d-flex align-items-start">
                          <div>
                            <FormControl sx={{ width: 300 }}>
                              <Controller
                                name="sector"
                                control={control}
                                defaultValue=""
                                rules={{ required: true }}
                                render={({ field, fieldState: { error } }) => (
                                  <Autocomplete
                                    {...field}
                                    // multiple={data.multiple}
                                    options={sectors.map(
                                      (option) => option.name
                                    )}
                                    onChange={(event, newValue) =>
                                      handleSelectSector(newValue)
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Sector *"
                                        placeholder="Select Sector"
                                        error={!!error}
                                        helperText={
                                          error ? "Sector is required" : ""
                                        }
                                      />
                                    )}
                                    fullWidth
                                    margin="normal"
                                  />
                                )}
                              />
                              <FormHelperText className="text-danger">
                                {errors.sector && errors.sector.errorMessage}
                              </FormHelperText>
                            </FormControl>
                          </div>
                          <Button
                          type="submit"
                          >
                            <DoneOutlineIcon
                              sx={{ fontSize: "16px", cursor: "pointer" }}
                            />
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <span>
                        <span>
                          {formData.sector && formData.sector}

                          {/* <span style={{ color: "gray" }}>& More</span> */}
                        </span>

                        {editBtn === true && (
                          <EditIcon
                            sx={{ fontSize: "14px", cursor: "pointer" }}
                            onClick={() => handleEditBtn("Sector")}
                          />
                        )}
                      </span>
                    )}
                    |
                    {editKey === "businessModel" ? (
                      <div className="d-inline-block">
                        <div className="d-flex align-items-start">
                          <div>
                            <FormControl sx={{ width: 150 }}>
                              <Controller
                                name="businessModel"
                                control={control}
                                defaultValue=""
                                rules={{ required: true }}
                                render={({
                                  field: { onChange, value },
                                  fieldState: { error },
                                }) => (
                                  <Autocomplete
                                    options={businessModelList}
                                    getOptionLabel={(option) => option}
                                    defaultValue={[]}
                                    value={value || []}
                                    onChange={(event, newValue) =>
                                      onChange(newValue)
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Business Model *"
                                        placeholder="Select Business Model"
                                        error={!!error}
                                        helperText={
                                          error
                                            ? "Business Model is required"
                                            : ""
                                        }
                                      />
                                    )}
                                    fullWidth
                                    margin="normal"
                                  />
                                )}
                              />
                              <FormHelperText className="text-danger">
                                {errors.businessModel &&
                                  errors.businessModel.message}
                              </FormHelperText>
                            </FormControl>
                          </div>
                          <Button
                           type="submit"
                          >
                            <DoneOutlineIcon
                              sx={{ fontSize: "16px", cursor: "pointer" }}
                            />
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <span>
                        {" "}
                        {formData.businessModel}{" "}
                        {editBtn === true && (
                          <EditIcon
                            sx={{ fontSize: "14px", cursor: "pointer" }}
                            onClick={() => handleEditBtn("businessModel")}
                          />
                        )}
                      </span>
                    )}{" "}
                    |{" "}
                    {editKey === "currentStage" ? (
                      <div className="d-inline-block">
                        <div className="d-flex align-items-start">
                          <div>
                            <FormControl sx={{ width: 150 }}>
                              <Controller
                                name="currentStage"
                                control={control}
                                defaultValue=""
                                fullWidth
                                rules={{
                                  required: `Current Stage is required`,
                                }}
                                render={({
                                  field: { onChange, value },
                                  fieldState: { error },
                                }) => (
                                  <Autocomplete
                                    size="small"
                                    disableClearable
                                    disablePortal
                                    options={startupStages}
                                    // getOptionLabel={(option) => option}
                                    // defaultValue={[]}
                                    value={value || []}
                                    onChange={(event, newValue) =>
                                      onChange(newValue)
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Current Stage *"
                                        placeholder="Select Current Stage"
                                      />
                                    )}
                                  />
                                )}
                              />
                              <FormHelperText className="text-danger">
                                {errors.currentStage &&
                                  errors.currentStage.message}
                              </FormHelperText>
                            </FormControl>
                          </div>
                          <Button
                          type="submit"
                          >
                            <DoneOutlineIcon
                              sx={{ fontSize: "16px", cursor: "pointer" }}
                            />
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <span>
                        {formData.currentStage}{" "}
                        {editBtn === true && (
                          <EditIcon
                            sx={{ fontSize: "14px", cursor: "pointer" }}
                            onClick={() => handleEditBtn("currentStage")}
                          />
                        )}
                      </span>
                    )}
                  </p>
                  <span style={{ fontSize: "12px", color: "gray" }}>
                    {formData.state}, {formData.country}
                  </span>
                  {/* <div className="d-flex align-items-center">
                  <div class="progress">
                    <div
                      class="progress-bar"
                      role="progressbar"
                      style={{ width: "80%", backgroundColor: "#020671" }}
                      aria-valuenow="80"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                  <span id="progressValue">80%</span>
                </div> */}
                  {/* <div style={{ position: "relative" }} className="profile-progress">
                  <BorderLinearProgress>
                    <ProgressFill value={progress} />
                  </BorderLinearProgress>
                  <span className="ml-2">80%</span>
                </div>
                <span className="Profile-complete">Profile Completion</span> */}
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div>
                  <img
                    className="user-cardlogo"
                    src={"/images/debitCrd.png"}
                    alt="bankCards"
                  />
                </div>

                <div className="ml-2">
                  <div className="d-flex align-items-center">
                    <p className="par m-0">SmartCard No:</p>

                    <span
                      style={{
                        color: "#020671",
                        fontSize: "16px",
                        fontWeight: "600",
                        cursor: 'pointer',
                        marginLeft: '5px'
                      }}
                      onClick={handleClickOpen}
                    >
                      {formData.uid}
                    </span>
                  </div>
                  {(userProfileId === 0 && names !== "add") && (
                    <div>

                      {(formData.smartcardstatus === 0) && (
                        <p className="avail-smart-style" onClick={() => availsmartopen()}>Avail SmartCard</p>)}
                      {(formData.smartcardstatus === 1) && (<p className="avail-smart-style-p">Requested</p>)}
                      {(formData.smartcardstatus === 2) && (<p className="avail-smart-style-p">Under Process</p>)}
                      {(formData.smartcardstatus === 3) && (<p className="avail-smart-style-p1 ">Availed</p>)}
                    </div>
                  )

                  }
                </div>
              </div>
              <div>
                <div className="d-flex align-items-center justify-content-end">
                  <div className="mr-2">
                    <span style={{ fontSize: "10px" }}>
                      Engagement Level :
                      <span
                        style={{
                          color: "#020671",
                          fontWeight: "600",
                          fontSize: "12px",
                        }}
                      >
                        ACE
                      </span>
                    </span>
                  </div>
                  <div>
                    <span style={{ fontSize: "10px" }}>
                      Member Since :
                      <span
                        style={{
                          color: "#020671",
                          fontWeight: "600",
                          fontSize: "12px",
                        }}
                      >
                        {formData.memberSince}
                      </span>
                    </span>
                  </div>
                </div>
                {/* <div className="det-flex mt-3">
                  <div className="mobile-resp">
                    <div>
                      <FaPhoneAlt className="ml-2" style={{
                        color: "#020671",
                      }} />
                    </div>
                    <div>
                      <span className="ml-2" style={{ fontSize: "14px" }}>+91 {formData.phone}</span>
                    </div>
                  </div>
                  <div className="mobile-resp">
                    <div>
                      <MdOutlineMail className="ml-2" style={{
                        color: "#020671",
                      }} />
                    </div>
                    <div>
                      <span className="ml-2" style={{ fontSize: "14px" }}>{formData.email}</span>
                    </div>
                  </div>
                  <div className="mobile-resp">
                    <div>
                      <CiGlobe className="ml-2" style={{
                        color: "#020671",
                      }} />
                    </div>

                    <div>
                      <span className="ml-2" style={{ fontSize: "14px" }}>{formData.website}</span>
                    </div>
                  </div>
                </div> */}
                <div className="det-flex mt-3">
                  <div className="mobile-resp">
                    <span className="ml-2" style={{ fontSize: "14px" }}>
                      {editKey === "linkedIn" ? (
                        <div className="d-inline-block">
                          <div className="d-flex align-items-start">
                            <div>
                              <FormControl sx={{ width: 150 }}>
                                <Controller
                                  name="linkedIn"
                                  control={control}
                                  defaultValue=""
                                  rules={{ value: linkedInPattern, message: "Invalid LinkedIn profile URL" }}
                                  render={({ field }) => (
                                    <TextField
                                      variant="outlined"
                                      label="LinkedIn"
                                      placeholder="Enter LinkedIn"
                                      fullWidth
                                      type="text"
                                      {...field}
                                    />
                                  )}
                                />
                                <FormHelperText className="text-danger">
                                  {errors.linkedIn && errors.linkedIn.message}
                                </FormHelperText>
                              </FormControl>
                            </div>
                            <Button
                            type="submit"
                            >
                              <DoneOutlineIcon
                                sx={{
                                  fontSize: "16px",
                                  cursor: "pointer",
                                }}
                              />
                            </Button>
                          </div>
                        </div>
                      ) : (
                        <span>
                          <a
                            href={formData.linkedIn}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img src="/images/linkedin-logo.png" alt="linkedin logo" />
                          </a>
                          {/* {formData.linkedIn}{" "} */}
                          {editBtn === true && (
                            <EditIcon
                              sx={{
                                fontSize: "14px",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                handleEditBtn("linkedIn")
                              }
                            />
                          )}
                        </span>
                      )}
                    </span>
                  </div>
                  {editKey === "phone" ? (
                    <div className="d-inline-block">
                      <div className="d-flex align-items-start">
                        <div>
                          <FormControl sx={{ width: 150 }}>
                            <Controller
                              name="phone"
                              control={control}
                              defaultValue=""
                              rules={{
                                required: `Phone is required`,
                                validate: validatePhoneNumber,
                              }}
                              render={({ field }) => (
                                <TextField
                                  variant="outlined"
                                  label="Phone Number *"
                                  placeholder="Enter Phone Number"
                                  fullWidth
                                  type="text"
                                  {...field}
                                  onKeyDown={(e) => validateNumberonly(e)}
                                  inputProps={{ maxLength: 10 }}
                                />
                              )}
                            />
                            <FormHelperText className="text-danger">
                              {errors.phone && errors.phone.message}
                            </FormHelperText>
                          </FormControl>
                        </div>
                        <Button
                         type="submit"
                        >
                          <DoneOutlineIcon
                            sx={{ fontSize: "16px", cursor: "pointer" }}
                          />
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <span>
                      <FaPhoneAlt
                        className="mx-1"
                        style={{
                          color: "#020671",
                        }}
                      />
                      {
                        showViewbtn !== 0 ? (
                          <span className="blur_css">+91 XXXXX-XXXXX</span>
                        ) : (
                          <span>+91 {formData.phone}</span>
                        )
                      }

                      {editBtn === true && (
                        <EditIcon
                          sx={{
                            fontSize: "14px",
                            cursor: "pointer",
                            marginLeft: "5px",
                          }}
                          onClick={() => handleEditBtn("phone")}
                        />
                      )}
                    </span>
                  )}
                  <div className="mobile-resp">
                    <div>
                      <MdOutlineMail
                        className="ml-2"
                        style={{
                          color: "#020671",
                        }}
                      />
                    </div>
                    <div>
                      {
                        showViewbtn !== 0 ? (
                          <span className="blur_css ml-2" style={{ fontSize: "14px" }}>XXXXXXXXXXX</span>
                        ) : (
                          <span className="ml-2" style={{ fontSize: "14px" }}>{formData.email}</span>
                        )
                      }
                    </div>
                  </div>
                  <div className="mobile-resp">
                    <div>
                      {/* <CiGlobe
                        className="ml-2"
                        style={{
                          color: "#020671",
                        }}
                      /> */}
                    </div>
                    {editKey === "website" ? (
                      <div className="d-inline-block">
                        <div className="d-flex align-items-start">
                          <div>
                            <FormControl sx={{ width: 150 }}>
                              <Controller
                                name="website"
                                control={control}
                                defaultValue=""
                                rules={{
                                  required: `Website is required`,
                                }}
                                render={({ field }) => (
                                  <input
                                    placeholder="Enter Website"
                                    type="text"
                                    {...field}
                                    className="sml-inp"
                                  />
                                )}
                              />
                              <FormHelperText className="text-danger">
                                {errors.website && errors.website.message}
                              </FormHelperText>
                            </FormControl>
                          </div>
                          <Button
                          type="submit"
                          >
                            <DoneOutlineIcon
                              sx={{
                                fontSize: "16px",
                                cursor: "pointer",
                              }}
                            />
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <span className="ml-2">
                        {" "}
                        <a
                          href={formData.website}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src="/images/icon/www.png" alt="website logo" style={{ width: "100%", maxWidth: '33px' }} />
                        </a>
                        {editBtn === true && (
                          <EditIcon
                            sx={{
                              fontSize: "14px",
                              cursor: "pointer",
                            }}
                            onClick={() => handleEditBtn("website")}
                          />
                        )}
                      </span>
                    )}
                  </div>
                </div>
                {showViewbtn !== 0 && (
                  <div className="text-right mt-3">
                    <Button
                      variant="outlined"
                      className="edit-btn-profile"
                      sx={{
                        textTransform: "none",
                        border: "1px solid",
                        borderColor: "#020671",
                        color: "#020671",
                        padding: "6px 30px",
                      }}
                      onClick={handlemaskClickOpen}
                    >
                      View Contact
                    </Button>
                  </div>
                )}
                {(userProfileId === 0 && names !== "add") && (
                  <div className="profile-btn  mt-3">
                    <div className="mr-3 edit-btn-res">
                      {editBtn === true && (
                        <Button
                          variant="outlined"
                          className="edit-btn-profile"
                          sx={{
                            textTransform: "none",
                            border: "1px solid",
                            borderColor: "#020671",
                            color: "#020671",
                            padding: "6px 20px",
                          }}
                          type="submit"
                        >
                          Save Profile
                        </Button>
                      )}
                      {(editBtn === false) && (
                        <Button
                          variant="outlined"
                          className="edit-btn-profile"
                          sx={{
                            textTransform: "none",
                            border: "1px solid",
                            borderColor: "#020671",
                            color: "#020671",
                            padding: "6px 20px",
                          }}
                          onClick={handleOpenEdit}
                        >
                          Edit Profile
                        </Button>
                      )}
                    </div>
                    <>
                      <Button
                        onClick={supportPage}
                        variant="contained"
                        sx={{
                          color: "#fff",
                          fontSize: "14px",
                          background: "#020671",
                          textTransform: "none",
                          "&:hover": { backgroundColor: "#020671" },
                        }}
                      >
                        Raise Support Ticket
                      </Button>
                    </>
                  </div>
                )}

              </div>
            </div>
          </div>
          <div className="mt-2">
            <Grid
              container
              direction="row"
              justifyContent="space-around"
              alignItems="flex-start"
              spacing={1}
            >
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <div className="profile-box1">
                  <div>
                    <h6 style={{ fontWeight: "600" }}>Key Highlights</h6>
                    <ul className="p-0">
                      <li className="d-flex align-items-start justify-content-start">
                        <div className="key-img mt-2">
                          <img src={"/images/pie-chart.png"} alt="pieChart" />
                        </div>
                        <div className="ml-3">
                          <>
                            <span className="list-span">Sector</span>
                          </>
                          <>
                            <p className="list-para ">{formData.sector}</p>
                          </>
                        </div>
                      </li>
                      <li className="d-flex align-items-start justify-content-start">
                        <div className="key-img mt-2">
                          <img src={"/images/grow-up.png"} alt="pieChart" />
                        </div>
                        <div className="ml-3">
                          <>
                            <span className="list-span">Current Stage</span>
                          </>
                          <>
                            <p className="list-para">{formData.currentStage}</p>
                          </>
                        </div>
                      </li>
                      {/* <li className="d-flex align-items-start justify-content-start">
                        <div className="key-img mt-2">
                          <img src={"/images/profits.png"} alt="pieChart" />
                        </div>
                        <div className="ml-3">
                          <>
                            <span className="list-span">Annual Revenue</span>
                          </>
                          <>
                            <p className="list-para">50 Lakh</p>
                          </>
                        </div>
                      </li>
                      <li className="d-flex align-items-start justify-content-start">
                        <div className="key-img mt-2">
                          <img src={"/images/money.png"} alt="pieChart" />
                        </div>
                        <div className="ml-3">
                          <>
                            <span className="list-span">Fund Raised</span>
                          </>
                          <>
                            <p className="list-para">1 Crore</p>
                          </>
                        </div>
                      </li> */}
                      <li className="d-flex align-items-start justify-content-start">
                        <div className="key-img mt-2">
                          <img
                            src={"/images/group-chart.png "}
                            alt="pieChart"
                          />
                        </div>
                        <div className="ml-3">
                          <>
                            <span className="list-span">Team Size</span>
                          </>
                          <>
                            <p className="list-para">
                              {" "}
                              {formData.teamSize} Members
                            </p>
                          </>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                {/* <Grid item xs={12} sm={12} md={userProfileId === 0 ? 6 : 9} lg={userProfileId === 0 ? 6 : 9} xl={userProfileId === 0 ? 6 : 9}> */}
                {/* <div className="profile-box2">
                  <h5 style={{ fontWeight: '600' }}>About</h5>
                  <p className="para">
                    {displayText}
                    {isTruncated && (
                      <span
                        onClick={toggleReadMore}
                        style={{ cursor: 'pointer', color: '#7F9AD2' }}
                      >
                        {showFull ? ' Read Less' : ' Read More...'}
                      </span>
                    )}
                  </p>
                  
                </div> */}
                <div className="profile-box2">
                  <div className="invest-edit-abt">
                    <div>
                      <h5>About</h5>
                    </div>
                    <div>
                      {editKey === "about" ? (
                        <div>
                          {" "}
                          <Button
                          type="submit"
                          >
                            <DoneOutlineIcon
                              sx={{ fontSize: "16px", cursor: "pointer" }}
                            />
                          </Button>
                        </div>
                      ) : (
                        <>
                          {editBtn === true && (
                            <EditIcon
                              sx={{
                                fontSize: "14px",
                                cursor: "pointer",
                                marginLeft: "5px",
                              }}
                              onClick={() => handleEditBtn("about")}
                            />
                          )}
                        </>
                      )}
                    </div>
                  </div>

                  {/* <p className="para">{para}</p> */}
                  {editKey === "about" ? (
                    <div className="d-inline-block">
                      <div className="d-flex align-items-start">
                        <div>
                          <Controller
                            name="about"
                            control={control}
                            defaultValue=""
                            rules={{ required: `Brief is required` }}
                            render={({ field }) => (
                              <textarea
                                type="text"
                                {...field}
                                className="invest-area"
                              />
                            )}
                          />
                          <FormHelperText className="text-danger">
                            {errors.about && errors.about.message}
                          </FormHelperText>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <p className="para">
                      {displayText}
                      {isTruncated && (
                        <span
                          onClick={toggleReadMore}
                          style={{ cursor: "pointer", color: "#7F9AD2" }}
                        >
                          {showFull ? " Read Less" : " Read More..."}
                        </span>
                      )}
                    </p>
                  )}
                </div>

                <div className="profile-box2 mt-2">
                  <div className="found-add-btn-prof">
                    <div className="d-flex align-items-start justify-content-between">
                      <h5 style={{ fontWeight: "600" }}>Team</h5>
                      {addbtnShow && (
                        <div>
                          {
                            editBtn === true && (
                            // (userProfileId === 0) && (
                              <div className="text-right mb-3">
                                <Button
                                  variant="outlined"
                                  onClick={() => addFounderBtn()}
                                >
                                  +
                                </Button>
                              </div>
                            )
                          }
                        </div>
                      )

                      }
                    </div>
                  </div>

                  <div style={{ width: "100%", overflowX: "auto" }}>
                    <table
                      className="table"
                      style={{ width: "100%", borderCollapse: "collapse" }}
                    >
                      <tbody>
                        {founderFields &&
                          founderFields.map((field, i) => (
                            <>
                              {
                                editKey === i ? (
                                  <>
                                    <tr key={i}>
                                      <td
                                        style={{
                                          padding: "0.75rem",
                                          verticalAlign: "top",
                                        }}
                                      >
                                        <div className="d-flex align-items-center founder-profile-img">
                                          <div>
                                            <div className="logo_div">
                                              <img
                                                src={getValues(`founders.${i}.profileUrl`) ? getValues(`founders.${i}.profileUrl`) : "/images/user.png"}
                                                className="founder-logo img-fluid"
                                                alt={'name'}
                                              />
                                              <div className="camera_icon">
                                                <Controller
                                                  name={`founders.${i}.profileUrl`}
                                                  control={control}
                                                  defaultValue=""
                                                  rules={{ required: false }}
                                                  render={({ field }) => (
                                                    <Button
                                                      {...field}
                                                      onChange={(e) =>
                                                        handleFileUpload(e, `founders.${i}.profile`, `founders.${i}.profileName`, `founders.${i}.profileUrl`)
                                                      }
                                                      component="label"
                                                      startIcon={<CameraAltIcon />}
                                                    >
                                                      <VisuallyHiddenInput type="file" />
                                                    </Button>
                                                  )}
                                                />
                                              </div>

                                            </div>
                                            <FormHelperText className="text-danger">
                                              {errors.founders?.[i]?.profileUrl &&
                                                errors.founders?.[i]?.profileUrl.message}
                                            </FormHelperText>
                                          </div>

                                          <div className="ml-3">
                                            <p className="list-para mb-0">
                                              <div>
                                                <FormControl sx={{ width: '200px', marginTop: '5px' }}>
                                                  <Controller
                                                    name={`founders.${i}.name`}
                                                    control={control}
                                                    defaultValue=""
                                                    rules={{ required: `Name is required` }}
                                                    render={({ field }) => (
                                                      <TextField
                                                        label="Name *"
                                                        placeholder="Enter name"
                                                        type="text"
                                                        {...field}
                                                        size="small"
                                                        error={errors.founders?.[i]?.name &&
                                                          errors.founders?.[i]?.name}
                                                      />
                                                    )}
                                                  />
                                                  <FormHelperText className="text-danger">
                                                    {errors.founders?.[i]?.name &&
                                                      errors.founders?.[i]?.name.message}
                                                  </FormHelperText>
                                                </FormControl>
                                              </div>
                                            </p>

                                            <p className="paras mb-0">
                                              <div>
                                                <Controller
                                                  name={`founders.${i}.designation`}
                                                  control={control}
                                                  defaultValue=""
                                                  rules={{ required: true }}
                                                  render={({
                                                    field,
                                                    fieldState: { error },
                                                  }) => (
                                                    <Autocomplete
                                                      disableClearable
                                                      size="small"
                                                      {...field}
                                                      options={designation}
                                                      onChange={(event, newValue) =>
                                                        handleSelectDesignation(newValue, i)
                                                      }
                                                      renderInput={(params) => (
                                                        <TextField
                                                          {...params}
                                                          label="Designation *"
                                                          placeholder="Select Designation"
                                                          error={!!error}
                                                          helperText={
                                                            error
                                                              ? "Designation is required"
                                                              : ""
                                                          }
                                                        />
                                                      )}
                                                      fullWidth
                                                      margin="normal"
                                                      ListboxProps={{
                                                        style: { textAlign: 'left' }
                                                      }}
                                                    />
                                                  )}
                                                />
                                                <FormHelperText className="text-danger">
                                                  {errors.founders?.[i]?.designation &&
                                                    errors.founders?.[i]?.designation
                                                      .message}
                                                </FormHelperText>

                                              </div>
                                              <div className="d-flex align-items-center">
                                                <Controller
                                                  name={`founders.${i}.physicallyChallenged`}
                                                  control={control}
                                                  defaultValue=""
                                                  rules={{ required: false }}
                                                  render={({ field }) => (
                                                    <FormControlLabel
                                                      {...field}
                                                      control={
                                                        <Checkbox
                                                          value={field.value}
                                                          checked={field.value}
                                                        />
                                                      }
                                                      label="Physically Challenged"
                                                    />
                                                  )}
                                                />
                                              </div>
                                            </p>

                                          </div>
                                        </div>
                                      </td>
                                      <td
                                        style={{
                                          padding: "0.75rem",
                                          verticalAlign: "top",
                                        }}
                                      >
                                        <div>
                                          <div>
                                            <FormControl sx={{ width: '200px', marginTop: '5px' }}>
                                              <Controller
                                                name={`founders.${i}.linkedin`}
                                                control={control}
                                                defaultValue=""
                                                rules={{
                                                  required: false,
                                                  // pattern: {
                                                  //   value: linkedInPattern,
                                                  //   message: "Invalid LinkedIn profile URL",
                                                  // },
                                                }}
                                                render={({ field }) => (
                                                  <TextField
                                                    label="LinkedIn"
                                                    placeholder="Enter LinkedIn"
                                                    type="text"
                                                    {...field}
                                                    size="small"
                                                    error={errors.founders?.[i]?.linkedin &&
                                                      errors.founders?.[i]?.linkedin}
                                                  />
                                                )}
                                              />
                                              <FormHelperText className="text-danger">
                                                {errors.founders?.[i]?.linkedin &&
                                                  errors.founders?.[i]?.linkedin.message}
                                              </FormHelperText>
                                            </FormControl>
                                          </div>
                                          <div>

                                            <Controller
                                              name={`founders.${i}.gender`}
                                              control={control}
                                              defaultValue=""
                                              rules={{ required: true }}
                                              render={({
                                                field: { onChange, value },
                                                fieldState: { error },
                                              }) => (
                                                <Autocomplete
                                                  disableClearable
                                                  disablePortal
                                                  options={gender}
                                                  getOptionLabel={(option) => option}
                                                  value={value || []}
                                                  onChange={(event, newValue) =>
                                                    onChange(newValue)
                                                  }
                                                  size="small"
                                                  renderInput={(params) => (
                                                    <TextField
                                                      {...params}
                                                      label="Gender *"
                                                      placeholder="Select Gender"
                                                      error={!!error}
                                                      helperText={
                                                        error ? "Gender is required" : ""
                                                      }
                                                    />
                                                  )}

                                                  margin="normal"
                                                />
                                              )}
                                            />
                                            <FormHelperText className="text-danger">
                                              {errors.founders?.[i]?.gender &&
                                                errors.founders?.[i]?.message}
                                            </FormHelperText>

                                          </div>
                                        </div>
                                      </td>
                                      <td
                                        style={{
                                          padding: "0.75rem",
                                          verticalAlign: "top",
                                        }}
                                      >
                                        <div>
                                          <div>
                                            <FormControl sx={{ width: '200px', marginTop: '5px' }}>
                                              <Controller
                                                name={`founders.${i}.phone`}
                                                control={control}
                                                defaultValue=""
                                                rules={{
                                                  required: "Phone Number is required",
                                                  validate: validatePhoneNumber,
                                                }}
                                                render={({ field }) => (
                                                  <TextField
                                                    variant="outlined"
                                                    label="Phone Number *"
                                                    placeholder="Enter Phone Number"
                                                    fullWidth
                                                    type="text"
                                                    {...field}
                                                    onKeyDown={(e) => validateNumberonly(e)}
                                                    inputProps={{ maxLength: 10 }}
                                                    size="small"
                                                    error={errors.founders?.[i]?.phone &&
                                                      errors.founders?.[i]?.phone}
                                                  />
                                                )}
                                              />
                                              <FormHelperText className="text-danger">
                                                {errors.founders?.[i]?.phone &&
                                                  errors.founders?.[i]?.phone.message}
                                              </FormHelperText>
                                            </FormControl>
                                          </div>
                                          <div>
                                            <FormControl sx={{ width: '200px', marginTop: '5px' }}>

                                              <Controller
                                                name={`founders.${i}.email`}
                                                control={control}
                                                defaultValue=""
                                                rules={{
                                                  required: "Mail ID is required",
                                                  validate: validateEmail,
                                                }}
                                                render={({ field }) => (
                                                  <TextField
                                                    size="small"
                                                    variant="outlined"
                                                    label="Mail ID *"
                                                    placeholder="Enter Mail ID"
                                                    fullWidth
                                                    type="text"
                                                    {...field}
                                                    error={errors.founders?.[i]?.email &&
                                                      errors.founders?.[i]?.email}
                                                    onInput={(e) => handleEmailVerfication(e.target.value, i)}
                                                    InputProps={{
                                                      endAdornment: getValues(`founders.${i}.emailVerify`) === true ? (
                                                        <InputAdornment position="end">
                                                          <CheckCircleIcon color="success" />
                                                        </InputAdornment>
                                                      ) : getValues(`founders.${i}.emailVerify`) === false ? (
                                                        <InputAdornment position="end">
                                                          <CloseIcon color="error" />
                                                        </InputAdornment>
                                                      ) : null,
                                                    }}
                                                  />
                                                )}
                                              />
                                              <FormHelperText className="text-danger">
                                                {errors.founders?.[i]?.email &&
                                                  errors.founders?.[i]?.email.message}
                                              </FormHelperText>
                                            </FormControl>
                                          </div>
                                        </div>

                                      </td>





                                      <td >
                                        

                                        {/* {(userProfileId === 0) && ( */}
                                        {(editBtn === true || names === "add") && (
                                          <div className="setEdittd">


                                            <Button
                                              type="submit"
                                              sx={{ marginLeft: "10px" }}
                                              disabled={!getValues(`founders.${i}.emailVerify`)}
                                            >
                                              <DoneOutlineIcon
                                                sx={{
                                                  fontSize: "16px",
                                                  cursor: "pointer",
                                                }}
                                              />
                                            </Button>
                                            <DeleteForeverIcon
                                              sx={{
                                                fontSize: "16px",
                                                cursor: "pointer",
                                                marginLeft: "5px",
                                                color: 'red'
                                              }}
                                              onClick={() => founderDetailsRemove(i)} />
                                          </div>
                                        )}
                                      </td>

                                    </tr>
                                  </>
                                ) : (
                                  <tr key={i}>
                                    <td
                                      style={{
                                        padding: "0.75rem",
                                        verticalAlign: "top",
                                      }}
                                    >
                                      <div className="d-flex align-items-center founder-profile-img">
                                        <img
                                          src={field.profileUrl ? field.profileUrl : "/images/user.png"}
                                          alt="FounderImage"
                                          className="img-fluid"
                                        />
                                        <div className="ml-3">
                                          <p className="list-para mb-0">
                                            {field.name}
                                          </p>

                                          <p className="paras mb-0">
                                            {field.designation}
                                          </p>


                                        </div>
                                      </div>
                                    </td>
                                    <td
                                      style={{
                                        padding: "0.75rem",
                                        verticalAlign: "top",
                                      }}
                                    >
                                      <div className="d-flex align-items-start">
                                        <div className="mt-3 mr-2">
                                          {field.physicallyChallenged === true && (

                                            <img src={'/images/icon/disability.png'} alt="disable" style={{ width: '100%', maxWidth: '30px' }} />
                                          )

                                          }
                                        </div>
                                        <div className="mt-3">
                                          <a
                                            href={field.linkedin}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            <img
                                              src="/images/linkedin-logo.png"
                                              alt="linkedin-icon"
                                              className="linkedin-icn"
                                            />
                                          </a>
                                        </div>
                                      </div>
                                    </td>
                                    <td
                                      style={{
                                        padding: "0.75rem",
                                        verticalAlign: "top",
                                      }}
                                    >
                                      <div className="mt-2">
                                        <div className="d-flex align-items-center table-resp">
                                          <div>
                                            <FaPhoneAlt
                                              style={{
                                                fontSize: "13px",
                                                color: "#020671",
                                              }}
                                            />
                                          </div>
                                          <div>
                                            <span
                                              style={{
                                                fontSize: "13px",
                                                paddingLeft: "5px",
                                              }}
                                            >
                                              {
                                                showViewbtn !== 0 ? (
                                                  <span className="blur_css">+91 XXXXX-XXXXX</span>
                                                ) : (
                                                  <span> +91 {field.phone}</span>
                                                )
                                              }

                                            </span>
                                          </div>
                                        </div>
                                        <div className="d-flex align-items-center table-resp">
                                          <MdOutlineMail
                                            style={{
                                              fontSize: "13px",
                                              color: "#020671",
                                            }}
                                          />
                                          <div>
                                            {/* <span
                                              style={{
                                                fontSize: "13px",
                                                paddingLeft: "5px",
                                              }}
                                            >
                                              {field.email}
                                            </span> */}
                                            {
                                              showViewbtn !== 0 ? (
                                                <span className="blur_css ml-2" style={{ fontSize: "14px" }}>XXXXXXXXXXX</span>
                                              ) : (
                                                <span className="ml-2" style={{ fontSize: "13px" }}>{field.email}</span>
                                              )
                                            }
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                    <td >
                                      {
                                        addbtnShow && (
                                          <div>
                                            {/* {(userProfileId === 0) && ( */}
                                            {(editBtn === true) && (
                                              <div className="setEdittd">
                                                <EditIcon
                                                  sx={{
                                                    fontSize: "16px",
                                                    cursor: "pointer",
                                                    marginLeft: "5px",
                                                  }}
                                                  onClick={() => handleEditBtn(i)}
                                                />
                                                <DeleteForeverIcon
                                                  sx={{
                                                    fontSize: "16px",
                                                    cursor: "pointer",
                                                    marginLeft: "5px",
                                                    color: 'red'
                                                  }}
                                                  onClick={() => founderDetailsRemove(i)} />
                                              </div>
                                            )
                                            }
                                          </div>
                                        )
                                      }
                                      {/* )} */}
                                    </td>

                                  </tr>
                                )
                              }
                            </>
                          ))}

                        {/* <tr>
            <td style={{ padding: '0.75rem', verticalAlign: 'top',  }}>
              <div className="d-flex align-items-center">
                <img src="/images/timcook.png" alt="timcook" />
                <div className="ml-3">
                  <p className="list-para mb-0">Tim Cook</p>
                  <p className="paras mb-0">CEO</p>
                  <span style={{ fontSize: '15px' }}>Masters in Business Administration</span>
                </div>
              </div>
            </td>
            <td style={{ padding: '0.75rem', verticalAlign: 'top',  }}>
              <div>
                <img
                  src="/images/linkedin-logo.png"
                  alt="linkedin-icon"
                  className="linkedin-icn"
                />
              </div>
            </td>
            <td style={{ padding: '0.75rem', verticalAlign: 'top',  }}>
              <div>
                <div className="d-flex align-items-center">
                  <div>
                  <FaPhoneAlt style={{ fontSize: '13px',color:'#020671' }}/>
                  </div>
                  <div>
                  <span style={{ fontSize: '13px', paddingLeft:'5px' }}>
                      +91 9284857473
                    </span>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div>
                  <MdOutlineMail style={{ fontSize: '13px',color:'#020671' }}/>
                  </div>
                  <div>
                    <span style={{ fontSize: '13px',paddingLeft:'5px' }}>
                      imac@apple.com
                    </span>
                  </div>
                </div>
              </div>
            </td>
          </tr> */}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="profile-box2 mt-2 text-center">
                  <h5 style={{ fontWeight: '600' }}>Incubation & Accelerations</h5>
                  {/* <div className="d-flex align-items-center">
                    <div>
                      <img src={"/images/EDII.png"} alt="edii" />
                    </div>
                    <div className="ml-3">
                      <span style={{ fontSize: "18px", color: '#020671', }}>EDII - Anna Business Incubation Foundation</span>
                    </div>
                  </div> */}
                </div>
                <div className="profile-box2 mt-2">
                  <div className="Services-box text-center">
                    <h5 style={{ fontWeight: '600' }}>Key Investors</h5>
                    {/* <div className="mt-4">
                      <Swiper
                        slidesPerView={4}
                        spaceBetween={30}
                        autoplay={{
                          delay: 2500,
                          disableOnInteraction: false,
                        }}
                        modules={[]}
                        className="mySwiper"
                      >
                        <SwiperSlide>
                          <img
                            src="/images/inv1.png"
                            alt=""
                            className="img-fluid"
                          />
                        </SwiperSlide>
                        <SwiperSlide>
                          <img
                            src="/images/inv2.png"
                            alt=""
                            className="img-fluid"
                          />
                        </SwiperSlide>
                        <SwiperSlide>
                          <img
                            src="/images/inv3.png"
                            alt=""
                            className="img-fluid"
                          />
                        </SwiperSlide>
                        <SwiperSlide>
                          <img
                            src="/images/inv4.png"
                            alt=""
                            className="img-fluid"
                          />
                        </SwiperSlide>
                        <SwiperSlide>
                          <img
                            src="/images/inv1.png"
                            alt=""
                            className="img-fluid"
                          />
                        </SwiperSlide>
                        <SwiperSlide>
                          <img
                            src="/images/inv2.png"
                            alt=""
                            className="img-fluid"
                          />
                        </SwiperSlide>
                        <SwiperSlide>
                          <img
                            src="/images/inv3.png"
                            alt=""
                            className="img-fluid"
                          />
                        </SwiperSlide>
                      </Swiper>
                    </div> */}
                  </div>
                </div>
                {/* <div className="profile-box3 mt-2">
                  <div className="text-center">
                    <h5 style={{ fontWeight: "600" }}>
                      Incubation & Accelerations
                    </h5>
                  </div>
                </div>
                <div className="profile-box3 mt-2">
                  <div className="text-center">
                    <h5 style={{ fontWeight: "600" }}>Key Investors</h5>
                  </div>
                </div> */}
                <div className="profile-box3 mt-2">
                  <div className="text-center">
                    <h5 style={{ fontWeight: "600" }}>Awards & Milestones</h5>
                  </div>
                </div>
                <div className="profile-box3 mt-2">
                  <div className="text-center">
                    <h5 style={{ fontWeight: "600" }}>
                      {" "}
                      Product Showcase & Video
                    </h5>
                  </div>
                </div>
              </Grid>
              {/* {
                userProfileId === 0 && (
                  <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <div className="profile-box2">
                      <div>
                        <Button
                          variant="contained"
                          onClick={() => handleButtonClick("TANSEED 1.0 Beneficiery")}
                          sx={{
                            backgroundColor:
                              activeButton === "TANSEED 1.0 Beneficiery"
                                ? "#020671"
                                : "transparent",
                            width: "100%",
                            margin: "10px",
                            borderRadius: "8px",
                            fontSize: "16px",
                            fontWeight: "600",
                            textTransform: "none",
                            border: "1px solid",
                            borderColor:
                              activeButton === "TANSEED 1.0 Beneficiery"
                                ? "#020671"
                                : "",
                            color:
                              activeButton === "TANSEED 1.0 Beneficiery"
                                ? "white"
                                : "#020671",
                            padding:
                              activeButton === "TANSEED 1.0 Beneficiery"
                                ? "22px"
                                : "10px",
                            "&:hover": {
                              backgroundColor:
                                activeButton === "TANSEED 1.0 Beneficiery"
                                  ? "#020671"
                                  : "transparent",
                            },
                          }}
                        >
                          TANSEED 1.0 Beneficiery
                        </Button>
                        <Button
                          variant="contained"
                          onClick={() => handleButtonClick("Application Status")}
                          sx={{
                            backgroundColor:
                              activeButton === "Application Status"
                                ? "#020671"
                                : "transparent",
                            width: "100%",
                            // padding: "10px",
                            margin: "10px",
                            borderRadius: "8px",
                            fontSize: "16px",
                            fontWeight: "600",
                            textTransform: "none",
                            border: "1px solid",
                            borderColor:
                              activeButton === "Application Status" ? "#020671" : "",
                            color:
                              activeButton === "Application Status"
                                ? "white"
                                : "#020671",
                            padding:
                              activeButton === "Application Status"
                                ? "22px"
                                : "10px",
                            "&:hover": {
                              backgroundColor:
                                activeButton === "Application Status"
                                  ? "#020671"
                                  : "transparent",
                            },
                          }}
                        >
                          Application Status
                        </Button>
                        <Button
                          variant="contained"
                          onClick={() => handleButtonClick("Pitch Deck")}
                          sx={{
                            backgroundColor:
                              activeButton === "Pitch Deck"
                                ? "#020671"
                                : "transparent",
                            width: "100%",
                            // padding: "10px",
                            margin: "10px",
                            borderRadius: "8px",
                            fontSize: "16px",
                            fontWeight: "600",
                            textTransform: "none",
                            border: "1px solid",
                            borderColor:
                              activeButton === "Pitch Deck" ? "#020671" : "",
                            color:
                              activeButton === "Pitch Deck" ? "white" : "#020671",
                            padding:
                              activeButton === "Pitch Deck"
                                ? "22px"
                                : "10px",
                            "&:hover": {
                              backgroundColor:
                                activeButton === "Pitch Deck"
                                  ? "#020671"
                                  : "transparent",
                            },
                          }}
                        >
                          Pitch Deck
                        </Button>
                        <Button
                          // endIcon={<LockIcon />}
                          variant="contained"
                          onClick={() => handleButtonClick("Document Room")}
                          sx={{
                            backgroundColor:
                              activeButton === "Document Room"
                                ? "#020671"
                                : "transparent",
                            width: "100%",
                            // padding: "10px",
                            margin: "10px",
                            borderRadius: "8px",
                            fontSize: "16px",
                            fontWeight: "600",
                            textTransform: "none",
                            border: "1px solid",
                            borderColor:
                              activeButton === "Document Room" ? "#020671" : "",
                            color:
                              activeButton === "Document Room"
                                ? "white"
                                : "#020671",
                            padding:
                              activeButton === "Document Room"
                                ? "22px"
                                : "10px",
                            "&:hover": {
                              backgroundColor:
                                activeButton === "Document Room"
                                  ? "#020671"
                                  : "transparent",
                            },
                          }}
                        >
                          Document Room
                        </Button>
                        <Button
                          variant="contained"
                          onClick={() => handleButtonClick("History Card")}
                          sx={{
                            backgroundColor:
                              activeButton === "History Card"
                                ? "#020671"
                                : "transparent",
                            width: "100%",
                            // padding: "10px",
                            margin: "10px",
                            borderRadius: "8px",
                            fontSize: "16px",
                            fontWeight: "600",
                            textTransform: "none",
                            border: "1px solid",
                            borderColor:
                              activeButton === "History Card" ? "#020671" : "",
                            color:
                              activeButton === "History Card" ? "white" : "#020671",
                            padding:
                              activeButton === "History Card"
                                ? "22px"
                                : "10px",
                            "&:hover": {
                              backgroundColor:
                                activeButton === "History Card"
                                  ? "#020671"
                                  : "transparent",
                            },
                          }}
                        >
                          History Card
                        </Button>
                        <Button
                          variant="contained"
                          onClick={() => handleButtonClick("Report")}
                          sx={{
                            backgroundColor:
                              activeButton === "Report" ? "#020671" : "transparent",
                            width: "100%",
                            // padding: "10px",
                            margin: "10px",
                            borderRadius: "8px",
                            fontSize: "16px",
                            fontWeight: "600",
                            textTransform: "none",
                            border: "1px solid",
                            borderColor: activeButton === "Report" ? "#020671" : "",
                            color: activeButton === "Report" ? "white" : "#020671",
                            padding:
                              activeButton === "Report"
                                ? "22px"
                                : "10px",
                            "&:hover": {
                              backgroundColor:
                                activeButton === "Report" ? "#020671" : "transparent",
                            },
                          }}
                        >
                          Recommendation
                        </Button>
                      </div>
                    </div>
                  </Grid>
                )
              } */}
            </Grid>
          </div>
        </div>
      </form>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalstyle} style={{ borderRadius: "10px" }}>
          <div>
            <span
              style={{ color: "#2260FF", cursor: "pointer" }}
              onClick={handleClose}
            >
              <ArrowBackIcon /> Back
            </span>
          </div>
          <div className="doc">
            <p
              id="modal-modal-title"
              style={{
                color: "#020671",
                fontWeight: "600",
                fontSize: "18px",
                // alignItems: "center",
                textAlign: "center",
              }}
            >
              Document Room
            </p>
          </div>
          <div className="service" style={{ textAlign: "center" }}>
            {service.map((service) => (
              <Button
                key={service}
                variant="contained"
                onClick={() => handleButtonClicks(service)}
                sx={{
                  backgroundColor:
                    activeButtons === service ? "#020671" : "transparent",
                  width: "auto",
                  height: "55px",
                  margin: "10px",
                  fontWeight: "500",
                  fontSize: "16px",
                  padding: "10px 26px",
                  lineHeight: "20px",
                  borderRadius: "10px",
                  textTransform: "none",
                  border: "1px solid",
                  borderColor:
                    activeButtons === service ? "#020671" : "2px solid #020671",
                  color: activeButtons === service ? "white" : "#020671",
                  "&:hover": {
                    backgroundColor:
                      activeButtons === service ? "#020671" : "transparent",
                  },
                }}
              >
                {service}
              </Button>
            ))}
          </div>
        </Box>
      </Modal>
      <Dialog fullScreen={fullScreen} maxWidth={"lg"} open={opens}>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div>
              <span
                style={{ color: "#2260FF", cursor: "pointer" }}
                onClick={handleReportClose}
              >
                <ArrowBackIcon /> Back
              </span>
            </div>
            <UserProfileReport />
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <div>
        <Dialog
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          open={smartOpen}
          onClose={availsmartClose}
        >
          <DialogContent>
            <div className="text-center">
              <img src={'/images/Profile/sucess.png'} alt="" className="sucess-image" />
              <h4>Thank you for Requesting...</h4>
            </div>
          </DialogContent>
        </Dialog>
      </div>
      {/* Qr-modal */}
      {(userProfileId === 0) && (
        <Dialog
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          open={qrModalopen}
          onClose={handleModalClose}
        >

          <DialogContent>

            <Qrcode path={path} />
          </DialogContent>
        </Dialog>
      )}
      <div>
        <MaskDialog />
      </div>
      {/* Application status dialog */}
      {/* <Dialog
        fullWidth={fullWidth}
        maxWidth={"md"}
        open={applicationStatusOpen}
        onClose={handleappstatusClose}
        sx={{ borderRadius: "10px" }}
      >

        <DialogContent sx={{padding:"50px"}}>
       <ApplicationStatus handleappstatusClose={handleappstatusClose}/>
        </DialogContent>
      </Dialog> */}
    </div>
  );
};

export default Userprofile;
