import React, { useContext, useState } from "react";
import { Box, Button, Container, Grid, Tab, Tabs } from "@mui/material";
import MapSection from "../../home-module/home-page/MapSection";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import apiService from "../../../api/apiService";
import DataContext from "../../../context/DataContext";
import { useNavigate } from "react-router-dom";
const EmergingSectorSeedHomePage = ({ name }) => {
  const [value, setValue] = useState("1");
  const [tabName, setTabName] = useState("Upcoming Events");
  const [buttonText, setButtonText] = useState("Explore");
  const navigate = useNavigate();
  const { navigator, setPageLoading } = useContext(DataContext);
  const handleChange = (event, newValue) => {
    setValue(newValue);

    // const firstLetter = tabName.charAt(0);
    // const remainingLetters = tabName.slice(1);
  };
  const [page, setPage] = useState(1);
  useDidMountEffect(() => {
    listApi();
  }, [page]);
  const [eventList, setEventList] = useState([]);

  const [totalPage, setTotalPage] = useState(1);
  const [opens, setOpens] = useState(false);
  const listApi = () => {
    setPageLoading(true);
    var req = {
      listSize: 3,
      pageNumber: page,
    };
    apiService(`event/home/list`, req, "unauthpost")
      .then((result) => {
        setPageLoading(false);
        if (result && result.data && result.data.event) {
          setEventList(result.data.event);
          setTotalPage(result.data.totalPages);
        }
      })
      .catch((err) => {});
  };
  const handleChangePage = (value) => {
    setPage(value);
  };
  const [categoryId, setCategoryId] = useState("");
  const handleRegister = (val) => {
    var token = localStorage.getItem("jwttoken");
    if (token === "") {
      navigator("/login");
    } else if (token === null) {
      navigator("/login");
    } else {
      setCategoryId(val);
      setOpens(true);
    }
  };

  const handlenow = () => {
    navigate("/login");
  };

  return (
    <div>
      <div>
        <div className="radial-back-layer">
          <div className="round-layers"></div>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="banner-text">
                <div className="sm-banner-chip-text">
                  <span>{`${name}  >  TN Emerging Sector Seed Fund`}</span>
                  <h1 className="banner-text-gradient">REGIONAL HUBS</h1>
                  <h6>Promoting Inclusive Entreprenurial Growth!</h6>
                  {/* <div className="banner-content-actions">
                  <button className="banner-viewbtn">Get in Touch</button>
                </div> */}
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="smart-card-info">
        <Container>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={2}
            className="tanseed-info"
          >
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="left-side-smartcardPage angel-title">
                <h2>What is Regional Hubs?</h2>
                <p>
                  StartupTN has launched 10 regional hubs across the state to
                  promote inclusive growth, and bring our services closer to the
                  entrepreneurial minds of Tier II & Tier III Districts of the
                  state.
                </p>
                <div className="left-content-actions">
                  <button className="ser-viewbtn">Register</button>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="smart-crd-img">
                <img
                  className="smartcrdimag img-fluid"
                  src={"/images/startupLogo.png"}
                  alt="smart-card"
                />
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
      <div>
        <MapSection></MapSection>
      </div>
      {/* event-section */}
      <div className="name-tab">
        <Box className="tabs-boxs">
          <Tabs
            sx={{
              padding: "6px",
              border: "1px solid #b6b5b7",
              borderRadius: "50px",
            }}
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab
              label="Upcoming Events"
              value="1"
              style={{
                backgroundColor: value === "1" ? "#083fb2" : "",
                color: value === "1" ? "white" : "#000",
              }}
              className="setFont"
            />
            <Tab
              label="Past Events"
              value="2"
              style={{
                backgroundColor: value === "2" ? "#083fb2" : "",
                color: value === "2" ? "white" : "#000",
              }}
              className="setFont"
            />
          </Tabs>
        </Box>
      </div>
      <Container>
        <Grid container spacing={2} className="mb-2">
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="mt-3">
            {eventList.length > 0 ? (
              <Grid container spacing={2}>
                {eventList && eventList.map((item, index) => (
                  <Grid item sm={12} md={4} lg={4} key={index}>
                    <div className="event-list">
                      <div
                        class="card"
                        style={{
                          width: "18rem",
                          minHeight: "380px",
                          borderRadius: "10px",
                        }}
                      >
                        <div className="event-img">
                          <img
                            src={item.imageName}
                            class="event-home-img-top"
                            alt=""
                          />
                          <div className="img-top">
                            <span>{item.category}</span>
                          </div>
                          <div className="event-time-top">
                            <div className="home-event-days">
                              {item.startDay && (
                                <>
                                  <div className="time-bg">
                                    <h6 className="time-color">
                                      {item.startDay}
                                    </h6>
                                    <span>{item.startMonth}</span>
                                  </div>
                                </>
                              )}
                              {item.endDay && (
                                <>
                                  <div className="time-bg ml-2">
                                    <h6 className="time-color">
                                      {item.endDay}
                                    </h6>
                                    <span>{item.endMonth}</span>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                        <div class="card-body" style={{ padding: "10px" }}>
                          <div className="event-details">
                            <h5>{item.title}</h5>
                            {/* <p>{item.description}</p> */}
                            <h6 className="event-time mt-2">
                              <img
                                src="/images/clock-icon.png"
                                alt=""
                                className="mr-2"
                              />
                              {item.formattedTime}
                              {/* - <span className="event-free">({item.meetSpace})</span> */}
                            </h6>
                            <span className="event-location">
                              <img
                                src="/images/location-icon.png"
                                alt=""
                                className="mr-2"
                              />
                              {item.location}
                            </span>

                            <div className="reg-fees mt-2">
                              {item.paid === false ? (
                                <span className="event-free">Free</span>
                              ) : (
                                <span className="event-free">
                                  ₹{item.amount}
                                </span>
                              )}
                              <div>
                                <button
                                  className="reg-btn"
                                  onClick={() => handleRegister(item.id)}
                                >
                                  Register
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <div className="text-center">
                <h3>Events coming soon</h3>
              </div>
            )}
          </Grid>
          {eventList.length > 0 && totalPage > 1 && (
            <div className="event-pagination text-center w-100">
              <button
                disabled={page === 1}
                className="event_round_btn"
                onClick={() => handleChangePage(page - 1)}
              >{`<`}</button>
              <button
                disabled={page === totalPage}
                className="event_round_btn"
                onClick={() => handleChangePage(page + 1)}
              >{`>`}</button>
            </div>
          )}
        </Grid>
      </Container>
      <div>
        <div className="join-us-now-sec">
          <Container>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="join-us-now-box">
                  <div className="join-us-now-content">
                    <h4>Let’s Get Started</h4>
                    <p>
                      Regional Hubs -Promoting Inclusive Entreprenurial Growth!
                    </p>
                    <Button
                      variant="contained"
                      size="large"
                      className="join-now-btn"
                      onClick={handlenow}
                    >
                      Join us Now
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default EmergingSectorSeedHomePage;
