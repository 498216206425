import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import { Link } from 'react-router-dom';
const SideMenu = () => {
    const [open, setOpen] = React.useState(false);

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    return (
        <div className='sidemenu-sty'>
            <img onClick={toggleDrawer(true)}
                className="c-pointer"
                src="/images/menu_icon.png"
                alt=""
            />
            <Drawer className='side-home-menu' open={open} onClose={toggleDrawer(false)}>
                <div>
                    <div className='d-flex align-items-center'>
                        <Link to="/" onClick={toggleDrawer(false)}>
                            <img
                                className="img-fluid"
                                src="/images/tamilnadu_new_logo.png"
                                alt=""
                            />
                        </Link>
                        <div className='ml-auto'>
                            <img src='/images/remove.png' alt='' className='c-pointer' onClick={toggleDrawer(false)} />
                        </div>
                    </div>
                    <div className='menu-div'>
                        {/* <p className='menu_lable'><b>Menu</b></p> */}
                        <ul className='menu-ui'>
                            {/* <li className='menu-li'>About</li> */}
                            {/* <li className='menu-li'>What we Offers</li> */}
                            {/* <li className='menu-li'>Feeds</li> */}
                            {/* <li className='menu-li'>Sectors</li> */}
                            {/* <Link to="/tender" ><li className='menu-li'>Tenders</li></Link>
                            <Link to="/careers" ><li className='menu-li'>Careers</li></Link> */}
                            {/* <Link to="/grievance-mechanism" ><li className='menu-li'>Grievance Mechanism</li></Link> */}
                            <Link to="/policy-notification" ><li className='menu-li'>Policies & Notifications</li></Link>
                            <Link to="/public-procurement" ><li className='menu-li'>Public Procurement</li></Link>
                            <Link to="/team" ><li className='menu-li'>Team</li></Link>
                            <Link to="/terms-condition" ><li className='menu-li'>Terms & Conditions</li></Link>
                            <Link to="/tender" ><li className='menu-li'>Tenders</li></Link>
                            <Link to="/careers" ><li className='menu-li'>Careers</li></Link>
                            <Link to= "/contactus" ><li className='menu-li'>Contact & Support</li></Link>
                            <Link to="/privacy-policy" ><li className='menu-li'>Privacy Policy</li></Link>
                        </ul>
                        {/* <div>
                            <div className='d-flex align-items-center'>
                                <p className='menu_lable'><b>FAQs</b></p>
                                <p className='ml-auto'>{`>`}</p>
                            </div>
                        </div> */}
                        {/* <div>
                            <div className='d-flex align-items-center'>
                                <p className='menu_lable'><b>Raise Support Ticket</b></p>
                                <p className='ml-auto'>{`>`}</p>
                            </div>
                        </div> */}
                        {/* <div>
                            <div className='d-flex align-items-center'>
                                <p className='menu_lable'><b>Terms & Condition & Privacy Policy</b></p>
                                <p className='ml-auto'>{`>`}</p>
                            </div>
                        </div> */}
                        <div className='social_div'>
                            <ul className='social_media'>
                                <li>
                                    <Link to={'https://www.facebook.com/TheStartupTN/'} target='_blank'>
                                        <img src='/images/facebook_blue.svg' alt='' className='' />
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'https://www.youtube.com/channel/UCr0du18taGeXH35dZZD4RnQ'} target='_blank'>
                                        <img src='/images/youtube_blue.svg' alt='' className='' />
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'https://www.instagram.com/thestartuptn/'} target='_blank'>
                                        <img src='/images/instagram_blue.svg' alt='' className='' />
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'https://x.com/TheStartupTN'} target='_blank'>
                                        <img src='/images/logo/twitter -white.svg' alt='' className='side-menu-logo' />
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'https://www.linkedin.com/company/thestartuptn/?originalSubdomain=in'} target='_blank'>
                                        <img src='/images/linkedin_blue.svg' alt='' className='' />
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Drawer>
        </div>
    );
}
export default SideMenu