import { Button, Container, Grid, Table, TableBody, TableCell,Paper, TableContainer, TableHead, TableRow } from "@mui/material";
import React from "react";
import { useNavigate,} from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa6";
import Navbar from "../navbar-page/Navbar";
import Footer from "../footer-page/Footer";
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';

const Tender = () => {
    const navigator = useNavigate();

    const handleBackBtn = () => {
        navigator(`/`);
    };
    return (
        <>
            <Navbar />
            <div className="back-color">
                <div className="back-layer"></div>
                <div className="back-layer1"></div>
                <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Container>
                            <div className="blog-details-bg">
                                <div className="image-container">
                                    <img
                                        src='/images/usernav.jpg'
                                        alt=""
                                        className="img-fluid blog-res-img"
                                    />
                                    <div className="overlay"></div>
                                </div>
                                <div className="tender-head">
                                    <h3>Tenders</h3>
                                </div>
                            </div>
                        </Container>
                    </Grid>
                </Grid>
                <div className="back-blog mt-2">
                    <div className="allticket-list">
                        <Button className="bloggingss-btnss" onClick={handleBackBtn}>
                            <FaArrowLeft />
                            <span style={{ marginLeft: "5px" }}>Back</span>
                        </Button>
                    </div>
                </div>
                <div  className="my-5">
                <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TableContainer component={Paper} sx={{ backgroundColor: 'transparent', boxShadow: 'none', width: '80%', margin: '0px auto' }}>
                                <Table sx={{ borderCollapse: 'separate', borderSpacing: '0 10px' }}>
                                    <TableHead sx={{ position: 'relative' }} >
                                        <TableRow sx={{
                                            position: 'relative',
                                            '&:after': {
                                                content: '""',
                                                position: 'absolute',
                                                top: 0,
                                                bottom: 0,
                                                left: 0,
                                                right: 0,
                                                borderRadius: '20px',
                                                pointerEvents: 'none',
                                                marginBottom: '-1px',
                                            },
                                            '& > *': {
                                                padding: '18px',
                                                backgroundColor: '#0C0249',
                                                textAlign: 'center',
                                                '&:first-of-type': { borderTopLeftRadius: '20px', borderBottomLeftRadius: '20px' },
                                                '&:last-of-type': { borderTopRightRadius: '20px', borderBottomRightRadius: '20px' },
                                            }
                                        }}>
                                            <TableCell sx={{ color: 'white', textAlign: 'center' }}>No</TableCell>
                                            <TableCell sx={{ color: 'white' }}>Description</TableCell>
                                            <TableCell sx={{ color: 'white', textAlign: 'center' }}>View</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody sx={{ position: 'relative' }}>
                                       
                                                <TableRow
                                                    sx={{
                                                        position: 'relative',
                                                        '&:after': {
                                                            content: '""',
                                                            position: 'absolute',
                                                            top: 0,
                                                            bottom: 0,
                                                            left: 0,
                                                            right: 0,
                                                            // border: '1px solid rgba(224, 224, 224, 1)',
                                                            borderRadius: '20px',
                                                            pointerEvents: 'none',
                                                            marginBottom: '-1px',
                                                        },
                                                        '& > *': {
                                                            // padding: '10px',
                                                            backgroundColor: '#fff',
                                                            '&:first-of-type': { borderTopLeftRadius: '20px', borderBottomLeftRadius: '20px' },
                                                            '&:last-of-type': { borderTopRightRadius: '20px', borderBottomRightRadius: '20px' },
                                                        },
                                                        marginBottom: '10px',
                                                    }}
                                                    className="Sup-list-res"
                                                >
                                                    <TableCell style={{ textAlign: 'center' }}> 1</TableCell>
                                                   
                                                    <TableCell style={{ textAlign: 'left' }}>Notice Inviting Tender - Selection of Event Management partner to support StartupTN for the Tamil Nadu Global Startup Summit (TNGSS)</TableCell>
                                                    <TableCell style={{ textAlign: 'center' }}>
                                                        <a href="https://startuptn-public-files.s3.ap-south-1.amazonaws.com/Tender.pdf" target="_blank" rel="noopener noreferrer">
                                                            <SimCardDownloadIcon style={{ color: '#2b377a', cursor: 'pointer' }} />
                                                        </a>
                                                    </TableCell>
                                                </TableRow>
                                        
                                       
                                    </TableBody>
                                </Table>
                            </TableContainer>
                    </Grid>
                </Grid>
                </div>
                <div >
                <Footer />
                </div>
            </div>
        </>
    )
}

export default Tender