import { Button, Container, TablePagination } from '@mui/material'
import React, { useContext, useState } from 'react'
import { useParams } from 'react-router-dom'
import DataContext from '../../../../context/DataContext'
import Navbar from '../../../home-module/navbar-page/Navbar'
import { IoMdCloseCircle } from "react-icons/io";
import apiService from '../../../../api/apiService'
import notifyService from '../../../../api/notifySerivce'
import useDidMountEffect from '../../../../hooks/useDidMountEffect'

const Smartaddcart = () => {
    const { id, prodid } = useParams()
    const { navigator, setPageLoading, products, setProducts } = useContext(DataContext);
    let usid = localStorage.getItem("userpersonaId");
    let smaridss = localStorage.getItem('joinusId')
    let ids
    if (id !== null) {
        ids = atob(id)
        console.log(ids, "cartpage");

    }
    const handleBackjoin = () => {
        debugger
        navigator(`/smartcard/${btoa(usid)}/profile`)
        debugger
    }

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [count, setCount] = useState(0);
    useDidMountEffect(() => {
        listApi()
    }, [page,rowsPerPage])
    const increaseQuantity = (id, currentQuantity) => {
        const newQuantity = currentQuantity + 1;
        // Update local state here if needed
        quantitySaveApi(id, newQuantity);
    };

    const decreaseQuantity = (id, currentQuantity) => {
        if (currentQuantity > 0) {
            const newQuantity = currentQuantity - 1;
            // Update local state here if needed
            quantitySaveApi(id, newQuantity);
        }
    };
    const quantitySaveApi = (id, quantity) => {
        setPageLoading(true);
        const req = {
            serviceRequestCartId: id,
            quantity: quantity
        };

        apiService('smartcard/servicerequest/cart/quantity/save', req, "post")
            .then((result) => {
                setPageLoading(false);
                listApi()
            })
            .catch((err) => {
                setPageLoading(false);
            });
    };
    let useid = localStorage.getItem('id')
    const listApi = () => {
        setProducts([])
        setPageLoading(true);
        var req = {
            pageNumber: page + 1,
            listSize: rowsPerPage,
            searchString: "",
            requestedUserId: Number(useid),
            smartCardId: Number(smaridss)
        }
        apiService('smartcard/servicerequest/cart/list', req, "post")
            .then((res) => {
                setPageLoading(false);
                if (res && res.data) {
                    setCount(res.data.count)
                    if (res.data.carts) {
                        setProducts(res.data.carts)
                    }
                }
            }).catch((err) => {
                console.log(err);

            });
    }

    const removeProduct = (id) => {
        setPageLoading(true);
        apiService(`smartcard/servicerequest/cart/delete?smartCardProductServiceId=${id}`, "", "delete")
            .then((result) => {
                setPageLoading(false)
                if (result.data.responseStatus === "Success") {
                    notifyService("success", "Success", "Successfully Deleted.");
                    listApi()
                }
            }).catch((err) => {
                console.log(err);

            });
    };
    const handleMore = () => {
        debugger
        navigator(`/smartcard/${btoa(usid)}/profile`)
        debugger
    }
    const handleSendRequest = () => {
        setPageLoading(true)
        const req = {
            smartcardId: Number(smaridss),
            requestcartUserId: Number(useid),
            carts: products.map(product => ({
                serviceRequestcartId: product.id,
                quantity: product.quantity
            }))
        };
        apiService('smartcard/servicerequest/submit', req, "post")
            .then((result) => {
                setPageLoading(false)
                if (result && result.data.responseStatus === "Success") {
                    navigator(`/smartcard/thankyou/${btoa(usid)}`);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    // const handleSendRequest = () => {
    //     const requestData = products.map(product => ({
    //         id:useid ,
    //         quantity:smaridss,
    //     }));
    //     console.log(requestData);
    //     apiService('smartcard/servicerequest/submit', "", "post")
    //         .then((result) => {
    //             if (result && result.data.responseStatus === "Success") {
    //                 navigator(`/smartcard/thankyou/${btoa(ids)}`)
    //             }

    //         }).catch((err) => {
    //             console.log(err);

    //         });
    // };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    return (
        <>
            <Navbar />
            <Container>
                <div className='joinus-design'>
                    <div className='mt-4'>
                        <span className='bck-join c-pointer' onClick={handleBackjoin}>{`< Back`}</span>
                    </div>
                    <div className='title-joinus text-center'>
                        <h2 className='join-h3 mb-2'>Smart Card Cart</h2>
                        <p>Below are the services you've chosen. Adjust quantities as <br />needed before proceeding.</p>
                        <div className='text-center '>
                            <div>
                                <div className="table-container">
                                    <table className="responsive-table">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th className='hed-crt'>Product/ Service Price</th>
                                                <th className='hed-crt'>Smart Card Price</th>
                                                <th className='hed-crt'>Offer Duration</th>
                                                <th className='hed-crt'>Additional Charges</th>
                                                <th className='hed-crt'>Quantity</th>
                                            </tr>
                                        </thead>
                                        {products.length > 0 && (
                                            <tbody>
                                                {products && products.map((product, i) => (
                                                    <tr key={i}>
                                                        <td className='pro-name'>{product.name}</td>
                                                        {/* <td className='pro-name1'>₹{product.price}</td> */}
                                                        <td className='pro-name'>
                                                            ₹ {product.priceRangeTo
                                                                ? `${product.priceRangeFrom} - ${product.priceRangeTo}`
                                                                : product.priceRangeFrom}
                                                        </td>
                                                        {/* <td className='pro-name'>
                                                            {product.smartcardpricefrom === 0 ? ("Free") : (<>
                                                                ₹ {product.smartcardpriceto
                                                                    ? `${product.smartcardpricefrom} - ${product.smartcardpriceto}`
                                                                    : product.smartcardpricefrom}
                                                            </>)}
                                                            {product.discount ? product.discount}
                                                        </td> */}
                                                        {/* <td className="pro-name">
                                                        {product.discount && (<span className="discount">₹ {product.discount}% discount</span>)}
                                                            {product.discount ? (
                                                                product.priceType === "Range" && product.smartcardpriceto ? (
                                                                    <span className="discount">₹ {product.smartcardpricefrom}-{product.smartcardpriceto}</span>
                                                                ) : (
                                                                    <span className="discount">₹ {product.smartcardpricefrom}</span>
                                                                   
                                                                )
                                                            ) : (
                                                                product.smartcardpricefrom === 0 ? (
                                                                    "Free"
                                                                ) : (
                                                                    <>
                                                                        ₹ {product.smartcardpriceto
                                                                            ? `${product.smartcardPriceFrom} - ${product.smartcardPriceTo}`
                                                                            : product.smartcardPriceFrom
                                                                        }
                                                                    </>
                                                                )
                                                            )}
                                                        </td> */}
                                                        <td className="pro-name">

                                                            {/* {product.smartcardpricefrom === 0 && product.discount > 0 ? (
                                                                `${product.discount}% discount`
                                                            ) : (
                                                                `₹ ${product.smartcardpriceto
                                                                    ? `${product.smartcardpricefrom} - ${product.smartcardpriceto}`
                                                                    : product.smartcardpricefrom}`
                                                            )}
                                                            {product.smartcardpricefrom === 0 && (
                                                               "Free" 
                                                            )} */}
                                                            {
                                                                product.smartcardpricefrom === 0
                                                                    ? (product.discount > 0
                                                                        ? `${product.discount}% discount`
                                                                        : "Free")
                                                                    : product.smartcardpriceto
                                                                        ? `₹ ${product.smartcardpricefrom} - ${product.smartcardpriceto}`
                                                                        : `₹ ${product.smartcardpricefrom}`
                                                            }
                                                        </td>

                                                        {/* <td className='pro-name'>{product.smartCardPrice}</td> */}
                                                        <td className='pro-name1'>
                                                            {product.offerTime ? (
                                                                <>
                                                                    {product.offerTime}&nbsp;{product.offerDuration}
                                                                </>
                                                            ) : (
                                                                <>-</>
                                                            )}
                                                        </td>
                                                        <td className='pro-name1'>{product.additionalCharges === "false" ? "NA" : `Rs.${product.additionalCharges}`}</td>
                                                        <td className="quantity-control">
                                                            {/* <span onClick={() => decreaseQuantity(product.id, product.quantity)} className='mr-2 c-pointer'>-</span> */}
                                                            <span
                                                                onClick={() => decreaseQuantity(product.id, product.quantity)}
                                                                className={`mr-2 c-pointer ${product.quantity === 1 ? 'disabled' : ''}`}
                                                                style={{ pointerEvents: product.quantity === 1 ? 'none' : 'auto' }}
                                                            >
                                                                -
                                                            </span>
                                                            <input
                                                                type="text"
                                                                value={product.quantity}
                                                                readOnly
                                                                className="cart-inputs"
                                                            />
                                                            <span onClick={() => increaseQuantity(product.id, product.quantity)} className='ml-2 c-pointer'>+</span>
                                                        </td>
                                                        <td>
                                                            <button onClick={() => removeProduct(product.id)} className='x-btn'>x</button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>)}
                                    </table>
                                    {products.length < 1 && (<div className="mt-2">
                                        <div className="text-center">
                                            <h5 style={{ fontWeight: "600" }}>No Data</h5>
                                        </div>
                                    </div>)}
                                    <div className='ml-auto'>
                                        <TablePagination
                                            component="div"
                                            count={count}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            rowsPerPage={rowsPerPage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                            style={{ float: 'right', margin: '0px' }}
                                            sx={{ margin: 0 }}
                                        />
                                    </div>
                                    <div className='text-left'>
                                        <button className="add-more-btn" onClick={() => handleMore()}>
                                            + Add More
                                        </button>
                                    </div>
                                </div>
                                <div className='join-prod-act pb-5'>
                                    <Button variant='contained' size='large' sx={{ background: "#0B40AA", textTransform: 'none' }} onClick={() => handleSendRequest()} disabled={products.length < 1}>Send Service Request</Button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </Container>
        </>
    )
}

export default Smartaddcart